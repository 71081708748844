import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl =
  process.env.VUE_APP_GROUPS_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/groups-service`;

class GroupService {
  getGroup(groupId, query = "") {
    return axios
      .get(baseUrl + "/groups/" + groupId + query)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  getSimpleGroupValues(
    selectedUserColumn,
    clientId,
    includeSubClients,
    magicLinkToken = null
  ) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(
        baseUrl +
          "/groups/create/column/" +
          clientId +
          "/" +
          includeSubClients +
          "/" +
          selectedUserColumn
      )
      .then(response => {
        console.log(response.data.attributes);
        return response.data.attributes;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  createComplexGroup(obj) {
    console.log(obj);
    return axios
      .post(baseUrl + "/groups/create/complex", obj)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  updateGroup(groupId, fields) {
    return axios
      .patch(baseUrl + "/groups/" + groupId, fields)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  async deleteGroups(groupIds, clientId = null) {
    console.log(clientId);
    console.log("Calling DELETE /groupRules ", groupIds);
    var pairs = groupIds.map(function(value) {
      return "id=" + encodeURIComponent(value);
    });
    var query_string = pairs.join("&");
    return axios
      .delete(
        baseUrl +
          "/groups/" +
          query_string +
          (clientId != null ? `?clientId=${clientId}` : "")
      )
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error deleting groups ", error.response || error);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  }

  downloadTemplate() {
    return axios.get(baseUrl + "/groups/CSV/template").then(response => {
      console.log("Downloading template file");
      let blob = new Blob([response.data], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "Groups_Template.csv");
      document.body.appendChild(link);
      link.click();
    });
  }

  // V2 calls
  getGroupsV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/group-rule", {
        params: params
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting groups ", error.response);
        throw error.response;
      });
  }
  createGroupV2(body, type, params = {}, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(`${baseUrl}/v2/group-rule/${type}`, body, { params })
      .then(response => {
        console.log("Created groups ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error creating group ", error.response.data);
        throw error.response.data;
      });
  } //end createGroupV2

  createListGroupV2(groupName, file, clientId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("groupName", groupName);
    formData.append("clientId", clientId);

    console.log("Form data");
    console.log(formData);
    // console.log(formData.getAll("file"));

    return axios
      .post(baseUrl + "/v2/group-rule/list", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error posting list group ", error.response);
        throw error.response ? error.response.data || error.response : error;
      });
  }
  getGroupAssignmentV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/group-assignment", {
        params: params
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting group assignments", error.response);
        throw error.response;
      });
  }
  searchGroupAssignmentV2(filter, params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(
        baseUrl + "/v2/group-assignment/search",
        { filter },
        {
          params: params
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting group assignments", error.response);
        throw error.response;
      });
  }
  getDistinctUsersFromGroupsV2(groupId, userId = [], magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(
        baseUrl + "/v2/group-assignment/search/distinct",
        { groupId, userId },
        {}
      )
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting group distinct user count", error.response);
        throw error.response;
      });
  } // getDistinctUsersFromGroupsV2
  refreshGroupsV2(groupId = null, clientId = null) {
    var params = groupId ? { groupId: groupId, clientId: clientId } : {};
    return axios
      .get(baseUrl + "/v2/group-rule/refresh", {
        params: params
      })
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error refreshing groups ", error.response);
        throw error;
      });
  }
}

export default new GroupService();
