var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"full-width d-flex justify-end"},[(_vm.permissions.includes('learning:create:module'))?_c('v-btn',{staticClass:"white--text mb-3",attrs:{"depressed":"","rounded":"","color":"brandCyan"},on:{"click":_vm.showSubscriptionDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticStyle:{"margin-top":"2px"}},[_vm._v("New "+_vm._s(_vm.displaySurveys ? "Survey" : "Learning Module"))])],1):_vm._e()],1),_c('v-data-table',{key:_vm.displaySurveys ? 0 : 1,attrs:{"headers":_vm.headers,"items":_vm.visibleItems,"items-per-page":_vm.table.itemsPerPage,"footer-props":_vm.table.footerProps,"options":_vm.table.options,"server-items-length":_vm.table.total,"show-expand":false,"expanded":[],"loading":_vm.table.loading,"loading-text":"loading","must-sort":"","sort-by":"learningModuleId","sort-desc":"","no-data-text":`No ${_vm.displaySurveys ? 'surveys' : 'modules'} available`,"no-results-text":`No ${_vm.displaySurveys ? 'surveys' : 'modules'} found`,"item-key":"learningModuleId","id":"learning-table"},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:`item.displayName`,fn:function({ item }){return [_c('span',{staticClass:"word-break"},[_vm._v(_vm._s(item.displayName))])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end mr-7"},[(_vm.displaySurveys && item.visibilityType == 'PUBLIC')?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.learningModuleId)}}},[_c('v-icon',{attrs:{"color":"brand"}},[_vm._v("mdi-link")])],1):_vm._e(),(
                (item.clientId != 0 ||
                  (item.clientId == 0 &&
                    _vm.userProfile.clientId == _vm.whistleClientId &&
                    _vm.clientId == 0)) &&
                  _vm.permissions.includes('learning:create:module')
              )?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"brand"},on:{"click":function($event){return _vm.loadBuilder(
                    {
                      learningModuleId: item.learningModuleId,
                      clientId: item.clientId,
                      preloadedClientId: item.clientId,
                      editingLearning: true
                    },
                    {
                      learningModuleId: item.learningModuleId,
                      clientId: item.clientId
                    }
                  )}}},[_vm._v("mdi-pencil ")])],1):_vm._e(),(_vm.permissions.includes('learning:create:module'))?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"brand"},on:{"click":function($event){return _vm.duplicate(item)}}},[_vm._v("mdi-content-copy ")])],1):_vm._e(),(_vm.permissions.includes('learning:delete:module'))?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{class:item.clientId != 0 ||
                  _vm.userProfile.clientId == _vm.whistleClientId
                    ? ''
                    : 'hidden',attrs:{"color":"brand"},on:{"click":function($event){return _vm.removeModuleDialog(item.learningModuleId)}}},[_vm._v("mdi-delete ")])],1):_vm._e()],1)]}},{key:"loading",fn:function(){return [_c('v-progress-circular',{staticClass:"my-8",attrs:{"indeterminate":"","color":"primary","size":50,"width":4}})]},proxy:true}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.duplicateModule.loadingModal),callback:function ($$v) {_vm.$set(_vm.duplicateModule, "loadingModal", $$v)},expression:"duplicateModule.loadingModal"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[(_vm.duplicateModule.error != true)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2"},[_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" Duplicating "+_vm._s(_vm.displaySurveys ? "survey" : "module")+"...hold tight! ")])],1),_c('div',{staticClass:"mx-2 mb-4 pb-2 d-flex flex-column align-center"},[_c('Robin',{staticClass:"mx-auto robin-animation",attrs:{"animation":"fluttering","width":120,"loop":true}})],1)]):_c('div',[_c('p',{staticClass:"pl-4 full-width align-text-left"},[_vm._v(" It looks like there was an error duplicating the module. ")]),_c('p',{staticClass:"pl-4 full-width align-text-left"},[_vm._v(" If the problem continues, feel free to reach out to customer service via one of the following methods... ")]),_c('p',{staticClass:"pl-4 align-text-left full-width"},[_vm._v(" Email - "),_c('b',[_c('a',{attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("help@wewhistle.com")])])]),_c('p',{staticClass:"pl-4 align-text-left full-width"},[_vm._v(" Phone (Toll Free) - "),_c('b',[_vm._v("(855) 264-3329")])])]),(_vm.duplicateModule.error == true)?_c('v-card-actions',{staticClass:"mx-auto"},[_c('v-btn',{attrs:{"color":"primary","width":"170"},on:{"click":function($event){_vm.duplicateModule = {
              clientSelectModal: false,
              module: null,
              error: false,
              clientId: null,
              loading: false,
              loadingModal: false
            }}}},[_vm._v("Okay")])],1):_vm._e()],1)],1),(_vm.duplicateModule.clientSelectModal)?_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.duplicateModule.clientSelectModal),callback:function ($$v) {_vm.$set(_vm.duplicateModule, "clientSelectModal", $$v)},expression:"duplicateModule.clientSelectModal"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[(_vm.duplicateModule.error != true)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2"},[_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" Choose the client you would like this duplicate to be added to ")])],1),_c('v-autocomplete',{staticClass:"mb-6 mx-2 px-4",attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.clients,"item-text":"formattedName","item-value":"clientId","label":"Client"},model:{value:(_vm.duplicateModule.clientId),callback:function ($$v) {_vm.$set(_vm.duplicateModule, "clientId", $$v)},expression:"duplicateModule.clientId"}})],1):_c('div',[_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" It looks like there was an error duplicating the module. ")]),_c('p',{staticClass:"pl-4 full-width align-text-left"},[_vm._v(" If the problem continues, feel free to reach out to customer service via one of the following methods... ")]),_c('p',{staticClass:"pl-4 align-text-left full-width"},[_vm._v(" Email - "),_c('b',[_c('a',{attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("help@wewhistle.com")])])]),_c('p',{staticClass:"pl-4 align-text-left full-width"},[_vm._v(" Phone (Toll Free) - "),_c('b',[_vm._v("(855) 264-3329")])])],1),(_vm.duplicateModule.error != true)?_c('v-card-actions',{staticClass:"mx-8"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":"","width":"170","disabled":_vm.duplicateModule.loading == true},on:{"click":function($event){_vm.duplicateModule = {
              clientSelectModal: false,
              module: null,
              error: false,
              clientId: null,
              loading: false,
              loadingModal: false
            }}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","width":"170","disabled":_vm.duplicateModule.clientId == null ||
              _vm.duplicateModule.loading == true},on:{"click":function($event){return _vm.duplicate(_vm.duplicateModule.module)}}},[(_vm.duplicateModule.loading != true)?_c('span',[_vm._v("Duplicate")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":3,"color":"white"}})],1)],1):_c('v-card-actions',{staticClass:"mx-auto"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","width":"170"},on:{"click":function($event){_vm.duplicateModule = {
              clientSelectModal: false,
              module: null,
              error: false,
              clientId: null,
              loading: false,
              loadingModal: false
            }}}},[_vm._v("Okay")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteModuleConfirmation.dialog),callback:function ($$v) {_vm.$set(_vm.deleteModuleConfirmation, "dialog", $$v)},expression:"deleteModuleConfirmation.dialog"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2 mb-2"},[_c('v-icon',{staticClass:"exit-warning-icon mr-2",attrs:{"color":"error","x-large":""}},[_vm._v("mdi-alert")]),_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" Are you sure you want to delete this "+_vm._s(_vm.displaySurveys ? "survey" : "module")+"? ")])],1),_c('v-card-subtitle',{staticClass:"word-break mb-3"},[_vm._v("This deletes all media and questions associated with it ")]),_c('v-card-actions',{staticClass:"mx-8"},[_c('v-btn',{attrs:{"color":"brandCyan","outlined":"","width":"170"},on:{"click":function($event){_vm.deleteModuleConfirmation = {
              dialog: false,
              learningModuleId: null
            }}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"brandCyan","depressed":"","width":"170"},on:{"click":_vm.removeModule}},[_vm._v("Delete")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogDeleteErrorMessage),callback:function ($$v) {_vm.dialogDeleteErrorMessage=$$v},expression:"dialogDeleteErrorMessage"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2 mb-5"},[_c('v-icon',{staticClass:"exit-warning-icon mr-4",attrs:{"color":"error","x-large":""}},[_vm._v("mdi-alert")]),_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" We found programs that are using the "+_vm._s(_vm.displaySurveys ? "survey" : "learning module")+" that you're trying to delete ")])],1),_vm._l((_vm.dialogDeleteErrorArray),function(error){return _c('p',{key:`${error.programId}-${error.learningModuleId}`,staticClass:"mb-2"},[_vm._v(" Program '"),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(error.displayName))]),_vm._v("' ")])}),_c('v-card-actions',{staticClass:"mx-auto"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"brandCyan","depressed":"","width":"140"},on:{"click":function($event){_vm.dialogDeleteErrorMessage = false}}},[_vm._v("Close")])],1)],2)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"multi-line":true,"v-html":_vm.snackbarText},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('LoadingDialog',{attrs:{"showDialog":_vm.loading.display,"header":_vm.loading.header,"line1":_vm.loading.line1,"line2":_vm.loading.line2}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }