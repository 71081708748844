import axios from "../axios";

const baseUrl =
  process.env.VUE_APP_PROGRAM_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/program-service`;

class ProgramService {
  // ############ Programs ############
  uploadProgramImage(programId, attachment, clientId) {
    let formData = new FormData();

    formData.append("file", attachment);
    formData.append("clientId", clientId);
    return axios
      .post(baseUrl + "/programs/image/" + programId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  duplicateProgramImage(oldId, newId, oldFilePath, clientId) {
    var body = {
      newProgramId: newId,
      clientId: clientId
    };

    // If we're duplicating an old program, we use oldProgramId
    // If we're duplicating an image from a learning module, we send the content filepath
    if (oldId) {
      body.oldProgramId = oldId;
    } else if (oldFilePath) {
      body.oldFilePath = oldFilePath;
    }
    return axios
      .post(baseUrl + "/programs/image/duplicate", body)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteProgramImage(programId, clientId) {
    return axios
      .delete(baseUrl + "/programs/image/" + clientId + "/" + programId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getProgramLeaderboard(programId) {
    return axios
      .get(baseUrl + "/programs/leaderboard/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  deleteProgram(programId) {
    return axios
      .delete(baseUrl + "/programs/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e && e.response ? e.response.data || e.response : e;
      });
  }

  // ############ Metrics ############
  getMetrics(queryString = "") {
    return axios
      .get(baseUrl + "/metrics" + queryString)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createMetric(metric) {
    return axios
      .post(baseUrl + "/metrics", metric)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  updateMetric(metricId, metric) {
    return axios
      .patch(baseUrl + "/metrics/" + metricId, metric)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteMetric(metricId) {
    return axios
      .delete(baseUrl + "/metrics/" + metricId)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  // ############ ProgramMetrics ############
  // Takes an array of user ids (users) and an array of metric ids (metrics)
  getLatestMetricValues(body) {
    return axios
      .post(baseUrl + "/programMetrics/recent", body)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  uploadMetricCSV(file, programId, timezone, clientId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("timezone", timezone);
    formData.append("clientId", clientId);
    return axios
      .post(baseUrl + "/programMetrics/csv/" + programId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  downloadMetricTemplateFile(metricId) {
    return axios
      .get(baseUrl + "/programMetrics/template/" + metricId)
      .then(response => {
        console.log("Downloading template file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Program_Metric_Template.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
  postProgramMetricBulk(body) {
    return axios
      .post(baseUrl + "/programMetrics/bulk", body)
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }
  postSelfAssessMetric(metricId, body, programId = null) {
    const q = programId ? `?programId=${programId}` : "";
    return axios
      .post(`${baseUrl}/programMetrics/selfAssess/${metricId}${q}`, body)
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  // ############ Program Attachments ############
  createProgramAttachment(body) {
    return axios
      .post(baseUrl + "/programs/attachments", body)
      .then(response => {
        console.log(response);
        return response.data.programAttachment;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  // V2
  getProgramsV2(params) {
    return axios
      .get(baseUrl + "/v2/program", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting programs for admin page ", error.response);
        throw error.response;
      });
  } // end getProgramsV2

  getProgramsAdminV2(context, clientId) {
    return axios
      .get(
        `${baseUrl}/v2/program/admin?clientId=${clientId}&context=${context}`
      )
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting program (v2)", error.response);
        throw error;
      });
  } // end getProgramsAdminV2

  getProgramForWizardV2(programId, clientId) {
    return axios
      .get(`${baseUrl}/v2/program/admin/${programId}?clientId=${clientId}`)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting program for wizard", error.response);
        throw error;
      });
  } // end getProgramForWizardV2

  getProgramsByUserV2(programId, includeSurveys = true) {
    var endpointString = "/v2/program/user";
    if (programId !== null) endpointString += "/" + programId;
    console.log(endpointString);
    return axios
      .get(baseUrl + endpointString + "?surveys=" + includeSurveys)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting programs for user ", error.response);
        throw error;
      });
  } // end getProgramsByUserV2

  getProgressForProgramElement(elementId) {
    return axios
      .get(`${baseUrl}/v2/program-element/search/progress/${elementId}`)
      .then(response => {
        return response.data.result.rows;
      })
      .catch(error => {
        console.log("Error getting program element progress", error.response);
        throw error;
      });
  } // end getProgressForProgramElement

  createProgramV2(body) {
    return axios
      .post(baseUrl + "/v2/program", body)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating program ", error.response);
        throw error.response;
      });
  } // end createProgramV2

  updateProgramV2(id, body) {
    return axios
      .patch(`${baseUrl}/v2/program/${id}`, body)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating program ", error.response);
        throw error.response;
      });
  } // end updateProgramV2

  deleteMetricV2(id) {
    return axios
      .delete(`${baseUrl}/v2/metric-definition/${id}`)
      .then(response => {
        return response.data.result;
      })
      .catch(e => {
        console.log("Error deleting metric ", e.response);
        throw e && e.response ? e.response.data || e.response : e;
      });
  } // end updateProgramV2

  async getProcoreCompanies(opt = {}) {
    return await axios.get(`${baseUrl}/v2/procore/companies`, opt);
  } // end getProcoreCompanies

  async getProcoreProjects(opt = {}) {
    return await axios.get(`${baseUrl}/v2/procore/projects`, opt);
  } // end getProcoreProjects

  async getProcorePrograms(opt = {}) {
    return await axios.get(`${baseUrl}/v2/procore/programs`, opt);
  } // end getProcorePrograms

  async getProcoreConfigurableFields(projectId, opt = {}) {
    return await axios.get(
      `${baseUrl}/v2/procore/projects/${projectId}/fields`,
      opt
    );
  } // end getProcoreConfigurableFields

  async getProcorePeopleInProject(projectId, opt = {}) {
    return await axios.get(
      `${baseUrl}/v2/procore/projects/${projectId}/people`,
      opt
    );
  } // end getProcorePeopleInProject

  async procoreCompleteRegistration(body, opt = {}) {
    return await axios.post(
      `${baseUrl}/v2/procore/complete-registration`,
      body,
      opt
    );
  } // end procoreCompleteRegistration

  async procoreUpdateRegistration(programId, editType, body, opt = {}) {
    return await axios.patch(
      `${baseUrl}/v2/procore/complete-registration/${programId}/${editType}`,
      body,
      opt
    );
  } // end procoreUpdateRegistration
}

export default new ProgramService();
