<template>
  <v-card elevation="0" rounded="0">
    <v-toolbar
      dark
      flat
      color="brandCyan"
      rounded="0"
      width="100%"
      style="position:fixed; opacity: 1;z-index: 1000;"
    >
      <div>
        <v-toolbar-title>Add card from library</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          @click="addCardsToChapter"
          :disabled="!this.cardsToAdd.length"
        >
          <span class="mt-1">Add</span>
          <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
        <v-btn dark text @click="close">
          <span class="mt-1">Close</span>
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- <v-toolbar dark flat color="brand" rounded="0" width="100%">
      <div>
        <v-toolbar-title class="text-h5 font-weight-light"
          >Add Card</v-toolbar-title
        >
        <p class="text-caption ma-0">Drag cards or select multiple</p>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          @click="addCardsToChapter"
          :disabled="addButtonDisabled"
        >
          Add
          <v-icon class="ml-2">mdi-plus-thick</v-icon>
        </v-btn>
        <v-btn dark text @click="closeLibrary">
          Cancel
          <v-icon class="ml-2">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar> -->
    <v-row
      no-gutters
      justify="space-between"
      full-width
      no-wrap
      class="mb-2 pt-16 px-3"
    >
      <v-col cols="8">
        <v-text-field
          single-line
          clearable
          placeholder="Search"
          v-model="table.debounceSearch"
          fluid
          class="mx-2"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          label="Filter"
          :items="types"
          item-value="value"
          item-text="label"
          v-model="filterVal"
          clearable
          @change="updateFilter"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="!loading" class="px-4 mb-0">
      <!-- <template> -->
      <v-data-table
        v-model="cardsToAdd"
        :headers="computedHeaders"
        :items="filteredCards"
        :options.sync="table.options"
        item-key="learningCardId"
        :server-items-length="table.total"
        :items-per-page="table.itemsPerPage"
        :loading="table.loading"
        show-select
        :footer-props="{ 'items-per-page-options': [15, 25, 50, 100] }"
        no-data-text="No cards found"
        no-results-text="No cards found"
        class="elevation-0 card-table"
        @click:row="addCard"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{
            item.type && types.find(x => x.value == item.type)
              ? types.find(x => x.value == item.type).label
              : "Unknown"
          }}
        </template>
        <!-- <template v-slot:body="props">
            <tbody>
              <tr
                v-for="(item, index) in props.items"
                :key="index"
                v-on:click="selectCard(item)"
                v-on:mousedown="setActiveCard(item)"
                class="card-row py-3 px-2"
              >
                <td class="no-ghost">
                  <v-simple-checkbox
                    @click="selectCard(item)"
                    v-ripple
                    :value="cardsToAdd.includes(item)"
                  ></v-simple-checkbox>
                </td>
                <td class="align-text-left">{{ item.displayName }}</td>
                <td class="no-ghost align-text-left">
                  {{
                    item.type && types.find(x => x.value == item.type)
                      ? types.find(x => x.value == item.type).label
                      : "Unknown"
                  }}
                </td>
                <td class="no-ghost">
                  {{
                    item.LearningQuizQuestions === undefined
                      ? 0
                      : item.LearningQuizQuestions.length
                  }}
                </td>
              </tr>
            </tbody>
          </template> -->
      </v-data-table>
      <!-- </template> -->
    </div>
    <div v-if="loading" class="d-flex justify-center">
      <RobinAnimation
        :height="125"
        :width="125"
        header="Getting learning cards"
        message="They're around here somewhere"
        :largeText="false"
        :showText="true"
        animation="flying"
        class="mt-7"
        :loop="true"
      ></RobinAnimation>
    </div>
  </v-card>
</template>

<script>
import LearningService from "@/services/LearningService";
import { mapState } from "vuex";
import RobinAnimation from "./Robin";

import { debounce } from "@/shared_data/functions";

export default {
  name: "CardLibrary",
  components: {
    RobinAnimation
  },
  props: {
    clientId: Number
  },
  data() {
    return {
      // An array or object of learning cards
      cards: [],
      loading: false,
      activeCard: null,
      cardsToAdd: [],
      displayNameQuery: null,
      filterVal: null,
      types: [
        { label: "Text/Image", value: "text" },
        { label: "Video", value: "video" },
        { label: "Embedded Video", value: "video_embed" },
        { label: "PDF", value: "pdf" },
        { label: "Quiz", value: "quiz" },
        { label: "Survey", value: "survey" },
        { label: "Assessment", value: "assessment" }
      ],
      // this just re-renders the table when something has been dragged
      didDrag: false,
      table: {
        options: {},
        total: 0,
        itemsPerPage: 10,
        search: null,
        debounceSearch: null,
        loading: false
      }
    };
  },
  watch: {
    // libraryOpen(val) {
    //   if (val === true) {
    //     this.didDrag = false;
    //     this.getCardsV2();
    //   } else if (val === false) {
    //     this.cardsToAdd = [];
    //     // resetting search and filter values
    //     // this.activeCard = null;
    //     this.filterVal = null;
    //     this.displayNameQuery = null;
    //     // console.log('clearing cards to add');
    //   }
    // },
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.users.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getCardsV2(reset);
      },
      deep: true
    },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getCardsV2(true);
    }, 500)
  },
  created() {
    console.log("Getting cards!");
    // this.getCardsV2(true);
  },
  mounted() {
    // this.getCards();
  },
  methods: {
    async getCardsV2(reset = false) {
      console.log("Calling getCardsV2");
      try {
        this.table.loading = true;
        if (reset) {
          // this.loading = true;
          this.table.options.page = 1;
        }

        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.table.search
          ? this.table.search.replace(/'/g, "\\'")
          : null;

        let filter = `status != 'Deleted' && type != null && displayName != null && clientId=${this.clientId}`;
        if (this.filterVal) filter += ` && type = '${this.filterVal}'`;
        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (displayName.like('%${search}%') || type.like('%${search}%'))`;
        }

        let limit = options.itemsPerPage; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          console.log("Col ", column);
          // If displayname then we really check display name, first/last name
          if (column == "tableDisplayName")
            sort = `firstName ${type}, lastName ${type}`;
          else sort = column + " " + type;
        } else var sort = "learningCardId DESC";
        const params = {
          expand:
            "Content,LearningCardAssociation,LearningQuizQuestion.LearningQuizAnswer",
          leftJoin: "Content,LearningQuizQuestion.LearningQuizAnswer",
          sort,
          offset,
          limit,
          filter
        };
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let res = await LearningService.getLearningCardsV2(params);

        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort
        ) {
          console.log("Ignoring response");
          return;
        }

        console.log("Got learning cards ", res);

        this.cards = res.result.rows;
        this.table.total = res.result.count;
      } catch (err) {
        console.log("Error getting cards", err);
      } finally {
        this.table.loading = false;
        // this.loading = false;
      }
    },
    // add the array, pass in cards to add and learningChapter that opened library
    addCardsToChapter() {
      this.$emit("addCardsToChapter", this.cardsToAdd);
      this.close();
    },
    addCard(card) {
      let existingIndex = this.cardsToAdd.findIndex(
        x => x.learningCardId == card.learningCardId
      );
      if (existingIndex != -1) {
        this.cardsToAdd = this.cardsToAdd.filter(
          x => x.learningCardId != card.learningCardId
        );
      } else {
        this.cardsToAdd.push(card);
      }
    },
    close() {
      this.$emit("close");
    },
    updateFilter() {
      this.getCardsV2(true);
    },
    // this is a mousedown event on the table row, if a drag end happens
    // it will send the active card to the builder to add to the module
    setActiveCard(item) {
      // console.log(item);
      this.activeCard = item;
      // console.log(this.activeCard);
    },
    // this event fires when we drag a card, it needs to tell the library to close
    // and assignes the current card being dragged to active card
    dragCard() {
      // console.log(this.activeCard);
      // console.log('dragging card and closing library');
      // console.log(e);
      const draggingGhost = document.getElementsByClassName("sortable-ghost");
      // console.log('dragging ghost');
      // console.log(draggingGhost);
      const cardName = this.activeCard.name;
      draggingGhost[0].innerText = cardName;
      this.$emit("libraryClose");
      // console.log(this.activeCard);
      // this.$emit('cardDragStart');
    },
    // this event fires when we end dragging and sends info the builder needs
    endDragHandler(e) {
      const chIndex = e.to.parentElement.className[0];
      const card = this.activeCard;
      const cardIndex = e.newIndex;
      // console.log('handle end card drag');
      // console.log(e);
      // console.log('dropped in chapter ' + e.to.parentElement.className[0]);
      // this tells the library to re-render the table
      this.didDrag = true;
      this.$emit("cardDragEnd", card, chIndex, cardIndex);
      // console.log(e.to.parentElement);
    }
    // computedClass(item) {
    //   let cardId = item.learningCardId;
    //   if (this.cardsToAdd.some((card) => card.learningCardId === cardId)) {
    //     return `selected card-row`;
    //   } else {
    //     return `not-selected card-row`;
    //   }
    // },
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    filteredCards() {
      return this.cards;
    },
    computedHeaders() {
      return [
        {
          text: "File name",
          align: "start",
          sortable: true,
          value: "displayName"
          // cellClass: ["d-flex", "flex-wrap", "word-break"]
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          filterable: false,
          value: "type",
          width: "105px"
          // cellClass: ["d-flex", "flex-wrap", "word-break"]
        },
        {
          text: "# questions",
          align: "start",
          sortable: false,
          filterable: false,
          value: "LearningQuizQuestions.length",
          width: "100px"
          // cellClass: ["d-flex", "flex-wrap", "word-break"]
        }
      ];
    }
  }
};
</script>

<style scoped>
/* .sortable-ghost .no-ghost {
  display: none;
}
.card-row {
  cursor: pointer;
  cursor: -moz-pointer;
  cursor: -webkit-pointer;
  transition: all 0.2s ease-in-out;
}
.card-row:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.card-row:hover {
  transform: scale(1.05);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
} */

.card-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.card-table >>> .v-data-table-header {
  background-color: white;
}
</style>
