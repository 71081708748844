<template>
  <div class="mt-2 flex-column d-flex">
    <LoadingDialog
      :showDialog="loadingQuiz"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
    <v-container v-if="!loadingQuiz" class="pt-0">
      <v-card
        rounded="0"
        width="1000"
        elevation="0"
        v-if="!quizCompleted"
        class="quiz-card text-left pb-8 px-8"
      >
        <p class="brandCyan--text font-weight-bold font-italic">
          {{ questionIndex + 1 }} of {{ questionList.length }}
        </p>
        <QuizQuestionMultipleChoice
          v-if="
            questionList[questionIndex] &&
              questionList[questionIndex].type == 'MULTIPLE_CHOICE'
          "
          :question="questionList[questionIndex]"
          :questionIndex="questionIndex"
          :questionCount="questionList.length"
          :learningCardAssociationId="card.learningCardAssociationId"
          :isSurveyQuestion="isSurvey"
          context="learning"
          @next-question="goToNextQuestion"
          @complete-quiz="completeQuiz"
        />
        <QuizQuestionSelectAll
          v-else-if="
            questionList[questionIndex] &&
              questionList[questionIndex].type == 'SELECT_ALL'
          "
          :question="questionList[questionIndex]"
          :questionIndex="questionIndex"
          :questionCount="questionList.length"
          :learningCardAssociationId="card.learningCardAssociationId"
          :isSurveyQuestion="isSurvey"
          context="learning"
          @next-question="goToNextQuestion"
          @complete-quiz="completeQuiz"
        />
        <QuizQuestionScale
          v-else-if="
            questionList[questionIndex] &&
              questionList[questionIndex].type == 'SCALE'
          "
          :question="questionList[questionIndex]"
          :questionIndex="questionIndex"
          :questionCount="questionList.length"
          :learningCardAssociationId="card.learningCardAssociationId"
          :isSurveyQuestion="isSurvey"
          context="learning"
          @next-question="goToNextQuestion"
          @complete-quiz="completeQuiz"
        />

        <QuizQuestionLikert
          v-else-if="
            questionList[questionIndex] &&
              questionList[questionIndex].type == 'LIKERT'
          "
          :question="questionList[questionIndex]"
          :questionIndex="questionIndex"
          :questionCount="questionList.length"
          :learningCardAssociationId="card.learningCardAssociationId"
          :isSurveyQuestion="isSurvey"
          context="learning"
          @next-question="goToNextQuestion"
          @complete-quiz="completeQuiz"
        />
        <QuizQuestionShortAnswer
          v-else-if="
            questionList[questionIndex] &&
              questionList[questionIndex].type == 'SHORT_ANSWER'
          "
          :question="questionList[questionIndex]"
          :questionIndex="questionIndex"
          :questionCount="questionList.length"
          :learningCardAssociationId="card.learningCardAssociationId"
          :isSurveyQuestion="isSurvey"
          context="learning"
          @next-question="goToNextQuestion"
          @complete-quiz="completeQuiz"
        />
        <div v-else class="d-flex justify-center full-width align-text-center">
          <v-card-title class="mt-0 pt-0">
            Oh no! An error occurred loading this {{ cardType }}</v-card-title
          >
          <Robin
            :showText="false"
            :width="100"
            :height="100"
            animation="curious"
            :loop="true"
            class="mt-2"
          />
        </div>
      </v-card>

      <!-- Survey complete display withOUT score and retake butons -->
      <v-card
        rounded="0"
        elevation="0"
        width="1000"
        v-else-if="cardType == 'survey'"
      >
        <span class="quiz-score">Thank You!</span>
        <!-- <v-row>
          <br />
        </v-row> -->
        <p v-html="computedConclusionMessage"></p>

        <br />
        <div class="d-flex justify-center mt-3">
          <v-btn
            color="brandCyan"
            outlined
            class="retakeButten white--text  mr-5"
            @click="retakeQuiz"
            width="125"
            depressed
            v-if="card.repeatable"
          >
            Take Again
          </v-btn>
          <v-btn
            color="brandCyan"
            class="retakeButten white--text"
            @click="nextCard"
            width="125"
            depressed
          >
            Continue
          </v-btn>
        </div>
      </v-card>

      <!-- Quiz complete display with score and retake butons -->
      <v-card rounded="0" elevation="0" width="1000" v-else>
        <!-- <v-progress-circular
          :rotate="270"
          :size="112"
          :width="14"
          :value="quizScore"
          color="accent"
          class=""
        >
          <span class="black--text">{{ quizScore }}%</span>
        </v-progress-circular> -->
        <p v-html="computedConclusionMessage"></p>
        <div v-if="card.displayResults == true">
          <span class="quiz-score">{{ quizScore }}%</span>
          <!-- <v-row>
            <br />
          </v-row> -->
          <p>{{ numberAnsweredCorrect }} out of {{ questionCount }} correct!</p>
        </div>
        <p>
          <b
            class="align-text-center"
            v-if="card.passingRequired && quizScore < card.passingScore"
            >This {{ card.type }} needs a score of {{ card.passingScore }} in
            order to proceed!
          </b>
        </p>
        <br />
        <div class="d-flex justify-center mt-3">
          <v-btn
            v-if="
              cardType != 'assessment' ||
                (card.passingRequired && quizScore < card.passingScore)
            "
            color="brandCyan"
            outlined
            class="retakeButten white--text mr-5"
            @click="retakeQuiz"
            width="125"
            depressed
          >
            Retake
          </v-btn>
          <v-btn
            color="brandCyan"
            class="retakeButten white--text"
            @click="nextCard"
            width="125"
            depressed
            :disabled="card.passingRequired && quizScore < card.passingScore"
          >
            Continue
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import LearningService from "@/services/LearningService";
import LoadingDialog from "@/components/loadingDialog.vue";
import QuizQuestionMultipleChoice from "@/components/QuizQuestionMultipleChoiceV2.vue";
import QuizQuestionSelectAll from "@/components/QuizQuestionSelectAllV2.vue";
import QuizQuestionScale from "@/components/QuizQuestionScaleV2.vue";
import QuizQuestionLikert from "@/components/QuizQuestionLikertV2.vue";
import QuizQuestionShortAnswer from "@/components/QuizQuestionShortAnswerV2.vue";
import { mapState } from "vuex";
import Robin from "@/components/Robin.vue";
//import momentTz from "moment-timezone";

export default {
  name: "Quiz",
  props: {
    questions: {
      type: Array
    },
    module: Number,
    chapter: Number,
    card: Object,
    cardObject: Object,
    completedStatus: Boolean,
    cardType: String
  },
  components: {
    Robin,
    LoadingDialog,
    QuizQuestionMultipleChoice,
    QuizQuestionSelectAll,
    QuizQuestionScale,
    QuizQuestionLikert,
    QuizQuestionShortAnswer
  },
  data() {
    return {
      loadingQuiz: false,
      loadingMessageLine1: "Please wait",
      loadingMessageLine2: null,
      questionList: [],
      selected: "",
      checkedAnswers: [],
      answerKey: null,
      questionCount: 0,
      chapterList: null,
      questionIndex: 0,
      currentQuestion: null,
      userAnswers: [],
      totalCorrect: 0,
      totalIncorrect: 0,
      quizCompleted: false,
      incorrectAnswers: [],
      correctCount: 0,
      quizScore: 0,
      numberAnsweredCorrect: 0,
      checkedAnswer: null,
      answered: false
    };
  },
  created() {
    if (this.$auth) {
      this.getQuestions();
      //this.getAnswers();
    }
  },
  updated() {},
  mounted() {},
  beforeMount() {
    // this.getQuestions()
  },
  methods: {
    async getQuestions(manualRetake = false) {
      this.loadingQuiz = true;

      var qCard = await LearningService.getLearningCard(
        this.card.learningCardId
      );
      console.log("INSIDE QUIZ COMPONENT", qCard);
      // We have to filter any quiz responses by the learning card association id
      console.log(
        "Filtering responses for card association ",
        this.card.learningCardAssociationId
      );
      qCard.LearningQuizQuestions.forEach(question => {
        question.LearningQuizResponses = question.LearningQuizResponses.filter(
          x =>
            x.learningCardAssociationId == this.card.learningCardAssociationId
        );
        // Sort in descending order so we can grab the latest attempt
        question.LearningQuizResponses.sort(
          (q1, q2) => q2.attempt - q1.attempt
        );
      });
      this.questionList = qCard.LearningQuizQuestions;
      this.questionList.sort((q1, q2) => q1.order - q2.order);
      this.questionCount = this.questionList.length;
      var quizCompleted = false;
      var quizScore;

      // If there is a record of the user taking this previously
      // We also only do this if manualRetake is false. If it's true, the user retook the quiz with random questions, so we're reshuffling
      if (
        this.card.latestLearningLog &&
        this.card.latestLearningLog != null &&
        !manualRetake
      ) {
        quizCompleted = this.card.latestLearningLog.completed;
        quizScore = this.card.latestLearningLog.score;
        console.log("!!!!!!!!!!");
        console.log(quizScore);
        this.loadingQuiz = false;
        console.log("done loading");
        this.$emit("doneLoading");
      }

      this.quizCompleted = quizCompleted;
      this.quizScore = quizScore;
      this.numberAnsweredCorrect = Math.round(
        (quizScore / 100) * this.questionCount
      );

      if (
        !quizCompleted &&
        !manualRetake &&
        this.card.numRandomQuestions == null
      ) {
        // If it hasn't been completed yet, then we load the quiz with the first question they haven't
        // We also don't do any of this for cards with a random question pool
        console.log(this.questionList);
        var loadQuizIndex = null;
        this.questionList.forEach((x, i) => {
          // We only care about questions until we get to the question we need to load the quiz in on
          // loadQuizIndex is null until we find the latest unanswered question
          if (loadQuizIndex === null) {
            if (x.LearningQuizResponses.length == 0) {
              // We found an unanswered question
              loadQuizIndex = i;
            } else {
              // We have responses and we haven't found the index to load the quiz on yet, so we check the correctness
              if (
                x.LearningQuizResponses[0].correctness === null ||
                x.LearningQuizResponses[0].correctness === 1
              ) {
                this.correctCount++;
              }
            }
          }
        });
        if (loadQuizIndex == null) {
          loadQuizIndex = 0;
        }
        this.questionIndex = loadQuizIndex;
        if (loadQuizIndex == 0) {
          this.correctCount = 0;
        }
        console.log("Loading quiz on index: ", loadQuizIndex);
      }
      this.loadingQuiz = false;
      console.log("done loading");
      this.$emit("doneLoading");

      console.log(this.quizCompleted);
      console.log(quizScore);
    },
    answerQuestion(answer) {
      if (!this.answered) {
        this.checkedAnswer = answer;
      }
    },
    goToNextQuestion(correct) {
      // goes to next question
      if (correct) {
        this.correctCount++;
      }
      this.questionIndex += 1;
    },
    // for the complete button
    completeQuiz(correct) {
      if (correct) {
        this.correctCount++;
      }
      //this.submitAnswer();
      //console.log(this.userAnswers)
      var score;
      console.log(this.userAnswers);

      score = Math.round((this.correctCount / this.questionCount) * 100);
      this.quizScore = score;
      this.numberAnsweredCorrect = this.correctCount;

      // upon quiz completion, set status of quizCompleted to true
      this.quizCompleted = true;
      this.postLearningLog();
    },
    nextCard() {
      this.$emit("nextCard");
    },

    // called during quiz completion
    calculateScore() {
      // Compare the selected answers for each question to their correct questions
      // for each question,
      // this.questionList.forEach((question) => {
      //   // get user submitted answers for this question
      //   var selectedIds = [];
      //   var correctIds = [];
      //   var answers = this.userAnswers.find(
      //     (answer) => answer.questionId === question.learningQuizQuestionId
      //   );
      //   // console.log("vvv THIS IS THE ID OF THE USERS ANSWER vvv")
      //   // console.log(answers.selectedAnswers)
      //   answers.selectedAnswers.forEach((id) => {
      //     selectedIds.push(id);
      //   });
      //   // store the ids of the correct answer to this specific question we're checking
      //   // and get its correct answersIds and store in correctIds
      //   question.LearningQuizAnswers.forEach((trueAnswer) => {
      //     // console.log(trueAnswer)
      //     if (trueAnswer.correctness > 0) {
      //       correctIds.push(trueAnswer.learningQuizAnswerId);
      //     }
      //   });
      //   // Calculate the score
      //   // if (selectedIds.equals(correctIds)) {
      //   //   this.totalCorrect += 1;
      //   // } else {
      //   //   this.totalIncorrect += 1;
      //   //   this.incorrectAnswers.push(selectedIds);
      //   // }
      // });
      // score = Math.round((this.totalCorrect / this.questionCount) * 100);
    },

    // posts the Learning Log to save the user's score
    postLearningLog() {
      this.$emit(
        "postQuizLog",
        this.quizScore,
        this.userAnswers,
        this.card.passingRequired,
        this.card.passingScore
      );
    },

    retakeQuiz() {
      this.questionIndex = 0;
      this.quizCompleted = false;
      this.answered = false;
      this.correctCount = 0;
      this.quizScore = 0;
      this.numberAnsweredCorrect = 0;
      // We call this if there are random questions attached so we can get new questions;
      if (this.card.numRandomQuestions) {
        console.log("Calling endpoint to reshuffle questions");
        this.getQuestions(true);
      }
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    isSurvey: function() {
      var isSurvey = false;
      if (this.cardType == "survey") {
        isSurvey = true;
      }
      return isSurvey;
    },
    loadingHeaderMessage() {
      if (this.cardType == "survey") {
        return "Loading Survey";
      } else {
        return "Loading Quiz";
      }
    },
    // We're using the card's content property as a conclusion message, either a custom one or a default one
    computedConclusionMessage() {
      //requires passing and not passed:
      if (
        this.quizCompleted &&
        this.card.passingRequired &&
        this.quizScore < this.card.passingScore
      ) {
        // Didn't pass and linear progress, return failure message
        return "Uh oh! Let's try that again! You'll need to retake this quiz to progress.";
      }

      if (
        !this.card.content ||
        this.card.content == null ||
        this.card.content.trim() == ""
      ) {
        if (this.card.passingRequired) {
          // No content, overwrite with passing message
          return "Congrats, that's a passing score! You can return here anytime to take the quiz again.";
        } else {
          if (this.cardType == "survey" || !this.card.repeatable) {
            return "Thanks for submitting your answers!";
          } else {
            return (
              "Finished! You can return here anytime to take the " +
              this.cardType +
              " again."
            );
          }
        }
      }
      // if (this.card.content.startsWith("<")) {
      //   // Old content, overwrite with passing message
      //   return "Another quiz completed! You can return here anytime to take the quiz again.";
      // } else {
      return this.card.content;
      // }
    }
  },
  watch: {
    // This will watch the card to re-render the quiz if a user clicks a different card while in a quiz
    card: function(newVal) {
      if (newVal != null) {
        this.getQuestions();
        this.questionIndex = 0;
        this.quizCompleted = false;
        this.answered = false;
        this.correctCount = 0;
        this.quizScore = 0;
        this.numberAnsweredCorrect = 0;
      }
    }
  }
};

Array.prototype.equals = function(array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
</script>

<style scoped>
.rounded-card {
  margin: 10px;
  border-radius: 50px;
  border: 1px solid;
  border-color: lightgrey;
}

.grey-background {
  background-color: var(--v-grey-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-pale-green-background {
  background-color: var(--v-brandPaleGreen-base);
}
.red-background {
  background-color: #f44336;
}
.light-red-background {
  background-color: #fcdeda;
}
.purple-background {
  background-color: #818ade;
}
.light-purple-background {
  background-color: #e9e9f5;
}
.accent-background {
  background-color: var(--v-accent-base);
}

.selected-background {
  background-color: var(--v-blue-base);
}
.correct-background {
  color: var(--v-green-base);
}

.nextQ {
  padding-top: 10px;
}

.v-progress-linear {
  display: block;
  width: 100px;
  margin: 0 auto;
  text-align: justify-center;
}

.retakeButten {
  margin-bottom: 15px;
}
.wrong {
  background-color: red;
}
.correct {
  background-color: green;
}

.letter-circle {
  width: 35px;
  height: 35px;
}

.quiz-card .v-card__title {
  word-break: normal;
}
.quiz-score {
  font-size: 3em;
}
</style>
