<template>
  <div>
    <v-img
      v-if="card && card.displayImage"
      :src="card.contentUrlSigned"
      contain
      :max-width="context == 'survey' ? '80%' : '100%'"
      height="auto"
      class="mx-auto mt-4"
    />
    <div v-else-if="card && card.displayPDF && card.contentUrlSigned">
      <vue-pdf-embed :source="card.contentUrlSigned" />
      <!-- <pdf :src="currentLesson.contentUrlSigned" /> -->
    </div>
    <div
      class="iframeContainer"
      v-else-if="card && card.displayVideo"
      @contextmenu="rightClickHandler($event)"
    >
      <video-player
        ref="videoPlayer"
        class=" vjs-custom-skin"
        :playsinline="true"
        :options="videoPlayerOptions"
      />
    </div>
    <div
      class="px-6 d-flex justify-center align-center "
      v-else-if="card && card.displayVideoProcessing"
    >
      <v-progress-circular
        indeterminate
        color="brandCyan"
        size="35"
        width="3"
        class="mr-3"
      />
      The uploaded video is processing... please check back later!
    </div>
    <div
      class="px-6 d-flex justify-center align-center "
      v-else-if="card && card.displayVideoFailed"
    >
      <v-icon color="red" class="mr-2">mdi-close</v-icon>
      The video processing failed... please try again or contact Whistle for
      more details
    </div>
    <div
      class="iframeContainer d-flex justify-center"
      @contextmenu="rightClickHandler($event)"
      v-else-if="card && card.displayEmbedVideo"
    >
      <iframe
        id="ytplayer"
        width="600"
        height="360"
        :src="card.contentUrlSigned"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div
      v-else-if="context == 'survey'"
      class="d-flex justify-center align-center mt-10"
    >
      <div class="outer-circle dark-blue-background">
        <div
          class="inner-circle light-blue-background d-flex justify-center align-center"
        >
          <v-icon color="white" class="reward-icon" x-large>
            mdi-check
          </v-icon>
        </div>
      </div>
    </div>
    <div
      v-if="
        card && (card.displayVideo || card.displayEmbedVideo) && card.transcript
      "
      class="px-6"
    >
      <v-expansion-panels multiple flat outline class="expansion-outlined">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header class="pl-6 pr-6 py-3">
            Video Transcript
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-0 pr-6 pl-0">
            <div style="text-align: left;" v-html="card.transcript"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "LearningMediaDisplay",
  props: {
    card: Object,
    context: String
  },
  components: { VuePdfEmbed },
  data() {
    return {
      value: null,
      column: 5
    };
  },
  created() {},
  updated() {},
  mounted() {},
  beforeMount() {},
  methods: {
    rightClickHandler: function(e) {
      e.preventDefault();
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    videoPlayerOptions() {
      return {
        height: 400,

        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "video/mp4",
            src: this.card
              ? this.card.contentUrlSigned
                ? this.card.contentUrlSigned
                : null
              : null
          }
        ]
      };
    }
  },
  watch: {}
};
</script>

<style scoped>
.expansion-outlined {
  border: 1px solid #ccc;
  margin-top: 20px;
}

.expansion-outlined .v-expansion-panel--active {
  background: rgba(0, 0, 0, 0.04);
}

.dark-blue-background {
  background-color: #97dffc;
}
.light-blue-background {
  background-color: #09acec;
}

.outer-circle {
  border-radius: 1000px;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.reward-icon {
  transform: scale(1.7);
}
</style>
