<template>
  <div class="full-height">
    <v-row justify="center" align="center" class="full-width" no-gutters>
      <v-col cols="12" sm="7" md="8" lg="7" xl="7">
        <div class="rounded-lg">
          <v-card
            v-if="survey !== null"
            :key="0"
            class="main-card text-left"
            elevation="0"
            rounded="2"
          >
            <div class="d-flex align-center pl-4 pt-6">
              <v-btn icon @click="leaveCourse" class="mr-1"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <p class="mb-0">
                Survey
                <span v-if="survey !== null && survey.displayName" class="">
                  |
                  <span class="word-break font-weight-bold">{{
                    survey.displayName
                  }}</span></span
                >
              </p>
            </div>
            <div class="main-card-content">
              <Survey
                :moduleId="survey.learningModuleId"
                :displayName="survey.displayName"
                :chapterId="
                  survey.LearningChapters[currentChapterIndex].learningChapterId
                "
                :currentLessonCard="currentLesson"
                :completedStatus="surveyCompleted"
                @surveyCompleted="goToNextChapter"
                :isSurveyRepeatable="isSurveyRepeatable"
                @retakeSurvey="restartSurvey"
                :isConclusion="isConclusion"
                @exit="leaveCourse"
                @saveConclusionLog="postConclusionCardLog"
              /></div
          ></v-card>
          <v-card
            class="main-card skeleton-div py-16 px-8"
            elevation="0"
            rounded="2"
            v-else-if="loadingSurvey && !moduleNotFound"
          >
            <div>
              <v-skeleton-loader
                class="mb-3 ml-4"
                type="heading"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader
                class="mt-1"
                type="list-item"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mt-1"
                type="list-item"
              ></v-skeleton-loader>
            </div>
          </v-card>
          <v-card
            v-else-if="moduleNotFound"
            class="d-flex main-card skeleton-div flex-column align-center px-4 py-16 word-break"
            elevation="0"
            rounded="2"
          >
            <div class="mx-auto mt-18">
              <Robin
                v-if="moduleNotFound"
                :height="100"
                :width="100"
                :largeText="true"
                :showText="false"
                animation="curious"
                :loop="true"
              ></Robin>
            </div>
            <h2 class="mt-2">We had trouble finding your survey</h2>

            <v-btn
              depressed
              rounded
              color="brandCyan"
              @click="leaveCourse"
              class="mt-6 mx-5 white--text"
              width="180"
              >Go to Programs</v-btn
            >
          </v-card>
        </div>
      </v-col>
    </v-row>

    <!-- Dialog used to display a badge they just earned -->
    <v-dialog
      v-model="badgeDialog"
      width="400"
      transition="dialog-bottom-transition"
    >
      <v-card v-if="survey">
        <div
          class="d-flex justify-start align-center full-width flex-column py-5"
        >
          <h2>Badge awarded!</h2>
          <br />
          <h4>
            {{
              survey.Badge && survey.Badge.displayName
                ? survey.Badge.displayName
                : survey.displayName
            }}
          </h4>
          <v-img
            v-if="survey.Badge != null && survey.Badge.imageUrl"
            :src="survey.Badge.imageUrl"
            :aspect-ratio="1 / 1"
            width="90"
            class="mx-1 my-2"
          />
          <v-img
            v-else
            src="@/assets/Demo/Trophy_Badge.png"
            :aspect-ratio="1 / 1"
            width="90"
            class="my-4"
          />
          <p>{{ survey.Badge != null ? survey.Badge.description : "" }}</p>
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            width="100"
            to="/programs"
            >Finish</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BadgeService from "@/services/BadgeService";
import LearningService from "@/services/LearningService";

import Survey from "@/components/SurveyV2.vue";
import Robin from "@/components/Robin.vue";

import { mapState } from "vuex";
import confetti from "canvas-confetti";
import moment from "moment";

export default {
  name: "SurveysPrivateV2",
  title: "Whistle | Surveys",
  components: {
    Survey,
    Robin
  },
  props: {
    surveyId: {
      default: null,
      type: String
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    sourcePage: {
      default: null,
      type: String
    },
    sourcePageId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      //surveyId: null,
      // Entire object
      survey: null,
      loadingSurvey: false,
      forcedLinearProgress: null,
      // First incomplete chapter
      currentChapterIndex: null,
      // currentLesson is the strinified version of the first incomplete card
      currentLesson: null,
      // First incomplete lesson
      currentLessonIndex: null,
      surveyCompleted: null,
      badgeDialog: false,
      // Used so if the user retakes a survey, we don't pay them twice for the same program
      payoutCounter: 0,
      moduleNotFound: false
    };
  },
  // should this be mounted or created??
  mounted() {
    console.log("Loading survey " + this.$route.params.surveyId);
    this.getModule();
  },
  beforeDestroy() {},
  methods: {
    getModule() {
      this.loadingSurvey = true;
      LearningService.getSurveyContent(this.surveyId).then(modules => {
        if (modules.length > 0) {
          var module = JSON.parse(JSON.stringify(modules))[0];

          this.survey = module;
          console.log("THIS IS THE CURRENT STATS ----------------");
          console.log(module);
          // First, we sort by order, LearningChapters is an array of objects, each object has an array of cards, each card is a Q
          var chapters = this.survey.LearningChapters.sort(function(a, b) {
            return new Date(a.order) - new Date(b.order);
          });

          if (chapters.length > 0 && chapters[0].order == -1) {
            // We reorder if the end card is here with an order of -1
            var tempChapter = chapters[0];
            chapters.splice(0, 1);
            chapters.push(tempChapter);
          }
          // Update LearningChapters with sorted array
          this.survey.LearningChapters = chapters;

          for (var i = 0; i < this.survey.LearningChapters.length; i++) {
            // Sorts cards by order
            var cards = JSON.parse(
              JSON.stringify(this.survey.LearningChapters[i].LearningCards)
            );
            cards.sort(function(a, b) {
              return new Date(a.order) - new Date(b.order);
            });

            this.survey.LearningChapters[i].LearningCards = cards;
          }

          this.survey.LearningChapters.map(x => {
            var obj = x;
            obj.LearningCards.map(y => {
              var card = y;
              card.updatedAtFormatted = moment(card.updatedAt)
                .local()
                .format("MMMM Do YYYY, h:mm a");
              return card;
            });
            return obj;
          });
          // if learning chpts > 0
          if (this.survey.LearningChapters.length >= 1) {
            // if learningCards > 0
            // if (this.course.LearningChapters[0].LearningCards.length > 0) {
            //   this.currentChapterIndex = 0;
            //   this.currentLessonIndex = 0;
            //   this.currentLesson = JSON.parse(
            //     JSON.stringify(this.course.LearningChapters[0].LearningCards[0])
            //   );
            //   this.course.LearningChapters[0].active = true;
            //   this.quizCompleted = this.currentLesson.completed;
            // }

            // This boolean will flip once we parse the chapters/cards and we find the first instance of something not completed. Then we set that as our starting point.
            // TODO: Not sure about this logic and how it will be for surveys
            var completeBool = false;
            this.survey.LearningChapters.forEach((chapter, chapterIndex) => {
              // counting cards in chapter
              var cardCount = chapter.LearningCards.length;
              var completeCards = 0;
              chapter.locked = this.survey.forceLinearProgress;
              chapter.active = false;
              // looping through cards (Q's) in chapter
              chapter.LearningCards.forEach((card, cardIndex) => {
                card.learnerRating = 0;
                card.learnerConfidence = null;
                card.locked = this.survey.forceLinearProgress;
                // this is assigning the ratings and the confidence, don't think we need for surveys
                if (card.latestLearningLog) {
                  card.learnerRating =
                    card.latestLearningLog.learnerRating == null
                      ? 0
                      : card.latestLearningLog.learnerRating;
                  card.learnerConfidence =
                    card.latestLearningLog.learnerConfidence;
                }
                // iterating through cards, executes first incomplete card (Q)
                if (!card.completed && !completeBool) {
                  this.currentChapterIndex = chapterIndex;
                  this.currentLessonIndex = cardIndex;
                  // copy of card object
                  this.currentLesson = JSON.parse(JSON.stringify(card));
                  this.survey.LearningChapters[chapterIndex].active = true;
                  // completed is probably false since that was the condition of the block
                  this.surveyCompleted = card.completed;
                  // stops loop after first incomplete card
                  completeBool = true;
                }

                // Regardless of if this is the card we're loading, we increment if it's complete so we can get an accurate progress number
                if (card.completed) {
                  completeCards++;
                }

                // Finally, we check if completeBool was never flipped, aka they finished the entire thing. If so, we set the current chpt and card index to last
                // We only check this if we've cycled through all chapters and cards and are at the end
                if (
                  !completeBool &&
                  chapterIndex == this.survey.LearningChapters.length - 1 &&
                  cardIndex == chapter.LearningCards.length - 1
                ) {
                  this.currentChapterIndex = chapterIndex;
                  this.survey.LearningChapters[chapterIndex].active = true;
                  if (
                    this.survey.LearningChapters[chapterIndex].LearningCards
                      .length > 0
                  ) {
                    this.currentLessonIndex = cardIndex;
                    this.currentLesson = JSON.parse(
                      JSON.stringify(
                        this.survey.LearningChapters[chapterIndex]
                          .LearningCards[cardIndex]
                      )
                    );
                    this.surveyCompleted = true;
                  }
                }
              });

              if (cardCount == 0) {
                // We avoid a divide by 0 error
                chapter.percentComplete = 0;
              } else {
                chapter.percentComplete = (
                  (completeCards / cardCount) *
                  100
                ).toFixed(0);
              }

              // We get the previous chapter and if they've completed it, then we unlock it
              if (chapterIndex > 0 && this.survey.forceLinearProgress) {
                var previousChapter = this.survey.LearningChapters[
                  chapterIndex - 1
                ];
                if (
                  previousChapter.percentComplete != null &&
                  previousChapter.percentComplete >= 100
                ) {
                  chapter.locked = false;
                }
              } else if (chapterIndex == 0) {
                chapter.locked = false;
              }
            });
          }

          this.loadingSurvey = false;
        } else {
          // TODO: uncomment this
          // this.leaveCourse();
          this.moduleNotFound = true;
        }
      });
    },
    goToNextChapter() {
      // Is next chapter last?
      // This banks on all surveys having a conclusion
      let nextChapter = this.currentChapterIndex + 1;
      // is next index less than survey length - 1 (last chapter index)
      if (nextChapter < this.survey.LearningChapters.length - 1) {
        // post to learning log before incrimenting current chapter
        console.log("Posting learning log for new chapter");
        this.postSurveyLearningLog(false, []);
        // move to next chapter
        this.currentChapterIndex = this.currentChapterIndex + 1;
        // current card object
        let currentCard = this.survey.LearningChapters[this.currentChapterIndex]
          .LearningCards[0];
        // reassign currentLesson to first card in learning chapters
        this.currentLesson = JSON.parse(JSON.stringify(currentCard));
      } else {
        // we must be at the end! move to next chapter - conclusion card!
        // Post log for current chapter completion
        console.log("Posting learning log for quiz x 2");
        var logs = this.postSurveyLearningLog(true, []);

        // then incriment and post log for conclusion completion
        this.currentChapterIndex = this.currentChapterIndex + 1;
        // current card object
        let currentCard = this.survey.LearningChapters[this.currentChapterIndex]
          .LearningCards[0];
        // Mark it as complete so the new card doesn't see itself as incomplete and try and self-heal
        currentCard.completed = true;
        // reassign currentLesson to first card in learning chapters
        this.currentLesson = JSON.parse(JSON.stringify(currentCard));

        this.postSurveyLearningLog(false, logs);
        // We're in the last chapter, play confetti
        confetti({
          particleCount: 300,
          spread: 70,
          scalar: 1.13,
          origin: { y: 0.95, x: 0.56 }
        });
        confetti({
          particleCount: 200,
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
          origin: { y: 0.85, x: 0.52 }
        });
        confetti({
          particleCount: 100,
          spread: 120,
          startVelocity: 45,
          scalar: 0.8,
          origin: { y: 0.99, x: 0.54 }
        });

        if (this.survey.Badge != null && this.survey.badgeId != null) {
          // Then we post that they got the badge
          console.log("Posting user badge assignment");
          this.badgeDialog = true;
          var userBadge = {
            clientId: this.userProfile.clientId,
            userId: this.userProfile.userId,
            badgeId: this.survey.badgeId,
            status: "Active",
            createdBy: this.userProfile.userId,
            updatedBy: this.userProfile.userId
          };
          BadgeService.createUserBadge(userBadge).then(userBadge => {
            console.log("User badge response: ", userBadge);
          });
        }

        this.surveyCompleted = true;

        console.log("The end!");
      }
    },
    // This accepts a boolean to tell the functino if we return the log array rather than inserting
    // This way we can compile an array rather than inserting individually.
    // The extraLogs are the previous array of logs passed in.
    // Payout flag is how we control when programs checks for a payout. Passed to the api and tells it to payout once at 100%
    postSurveyLearningLog(returnLog = false, extraLogs = []) {
      console.log("Posting Survey Learning Log!!");
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      var body = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        learningCardId: this.currentLesson.learningCardId,
        learningCardAssociationId: this.currentLesson.learningCardAssociationId,
        completed: true,
        completedDate: dateTime,
        learnerRating: null,
        learnerConfidence: null,
        score: null
      };

      console.log("-------------this is the new log-------------");
      console.log(body);

      var logs = extraLogs;
      logs.push(body);

      if (!returnLog) {
        console.log("Inserting " + logs.length + " learning logs.");
        // Incrememnt payout counter so we only pay once
        LearningService.createLearningLog(
          logs,
          (this.survey && this.survey.AssociatedPrograms) || []
        );
      } else {
        return logs;
      }

      // dont need surveyCompleted here
      // this.surveyCompleted = true;
      // this.leaveCourse();
    },
    postConclusionCardLog() {
      console.log("Posting Survey Learning Log!!");
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      let conclusionChapter = this.survey.LearningChapters[
        this.survey.LearningChapters.length - 1
      ];
      var conclusionCard;
      if (
        conclusionChapter &&
        conclusionChapter.LearningCards &&
        conclusionChapter.LearningCards.length
      )
        conclusionCard = conclusionChapter.LearningCards[0];
      if (!conclusionCard)
        return console.log("Couldn't find conclusion card to save");
      var body = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        learningCardId: conclusionCard.learningCardId,
        learningCardAssociationId: conclusionCard.learningCardAssociationId,
        completed: true,
        completedDate: dateTime,
        learnerRating: null,
        learnerConfidence: null,
        score: null
      };

      console.log("-------------this is the conclusion card log-------------");
      console.log(body);
      LearningService.createLearningLog(
        [body],
        (this.survey && this.survey.AssociatedPrograms) || []
      );
    },
    restartSurvey() {
      console.log("restarting survey!!");
      // reset data
      this.surveyCompleted = false;
      this.currentChapterIndex = 0;
      this.currentLessonIndex = 0;

      // with a public survey, the data objects look a little weird
      // each learningCard has a .LearningCard obj as well, but survey fetches the card itself with the id
      this.currentLesson = JSON.parse(
        JSON.stringify(
          this.survey.LearningChapters[this.currentChapterIndex].LearningCards[
            this.currentLessonIndex
          ]
        )
      );
      console.log(this.currentLesson);
      console.log("reset current lesson");
    },
    leaveCourse() {
      // console.log("Bye");
      if (this.sourcePage && this.sourcePage == "programs") this.$router.back();
      else
        this.$router.push({
          name: "programs",
          query: {
            filterBy: "surveys"
          }
        });
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "postItArray"]),
    isSurveyRepeatable() {
      if (!this.survey || !this.survey.LearningChapters) return false;
      let repeatBool = false;
      // loop through all cards and if any are repeatable flip boolean!!
      // TODO: check if this is an okay way to handle things
      for (let i = 0; i < this.survey.LearningChapters.length; i++) {
        let currentCh = this.survey.LearningChapters[i];
        for (let j = 0; j < currentCh.LearningCards.length; j++) {
          if (currentCh.LearningCards[j].repeatable == true) {
            repeatBool = true;
            break;
          }
        }
      }
      return repeatBool;
    },
    isConclusion() {
      // surveys have one chapter and a conclusion chapter
      // if chIndex is 1, it's the conclusion
      return this.currentChapterIndex === 1;
    }
  }
};
</script>

<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.border-radius {
  border-radius: 5px;
}
.skeleton-div {
  max-width: 60vw;
}
@media only screen and (max-width: 600px) {
  .skeleton-div {
    max-width: 75vw;
  }
}

/* V2 */
/* Controls styling for the card component in 
the main container. Shows survey card */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 10px 10px 10px 10px !important;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 451px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 450px) {
  .main-card-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
