var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex justify-space-between py-4 px-3 rounded-lg",class:{
    'flex-column': _vm.$vuetify.breakpoint.xs,
    'align-center': !_vm.$vuetify.breakpoint.xs
  },attrs:{"elevation":_vm.elevation}},[_c('div',{staticClass:"d-flex pa-0",style:(_vm.$vuetify.breakpoint.width <= 1035 ? { 'max-width': '330px' } : {})},[_c('v-icon',{staticClass:"pa-2 mr-4 my-2 rounded icon",class:`${_vm.iconColor}-background`,attrs:{"large":!_vm.isMobile,"dark":""}},[_vm._v(_vm._s(_vm.icon))]),_c('p',{staticClass:"font-weight-black word-break brand-text my-auto bold-font page-title text-left"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('div',{staticClass:"py-3 flex-grow-1",class:_vm.$vuetify.breakpoint.md
        ? 'px-8'
        : _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl
        ? 'px-12'
        : 'px-auto'},[_c('v-text-field',{staticClass:"shrink mx-auto",style:(_vm.isMobile ? '' : 'max-width: 450px;'),attrs:{"outlined":"","placeholder":"Search","single-line":"","dense":"","hide-details":"","append-icon":"mdi-magnify","clearable":"","color":"brandCyan"},model:{value:(_vm.debounceSearch),callback:function ($$v) {_vm.debounceSearch=$$v},expression:"debounceSearch"}})],1),(_vm.userProfile?.clientId === 1)?_c('div',{class:_vm.$vuetify.breakpoint.xs
        ? 'px-0'
        : _vm.$vuetify.breakpoint.md
        ? 'px-4 flex-grow-1'
        : _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl
        ? 'px-12 flex-grow-1'
        : 'px-8 flex-grow-1',style:(_vm.$vuetify.breakpoint.xs
        ? { height: 'fit-content', 'max-width': '430px' }
        : { height: 'fit-content', 'max-width': '440px' })},[_c('ClientDropdown',{staticClass:"my-auto full-width"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }