<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="brand" flat rounded="0">
      <v-toolbar-title>People Details </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow(false)">
          Close
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <transition :name="userForm.transitionDirection" mode="out-in">
      <div
        v-if="
          [keys.info, keys.roles, keys.received, keys.spent].includes(
            activeSlide
          )
        "
        key="0"
        class="my-6"
      >
        <div class="d-flex justify-end px-6">
          <v-btn v-if="!editingUser" @click="loadFileWizard" depressed text
            >Upload CSV<v-icon class="ml-2">mdi-open-in-new</v-icon></v-btn
          >
        </div>
        <div class="d-flex justify-space-between align-start px-6">
          <div class="d-flex">
            <!-- Profile Picture -->
            <v-img
              v-bind:src="user.profilePicUrl"
              v-if="user.profilePicUrl"
              height="45"
              width="45"
              max-height="45"
              max-width="45"
              class="rounded-circle profile-picture"
            />
            <v-icon v-else class="icon profile-picture rounded-circle"
              >mdi-account</v-icon
            >
            <div class="d-flex flex-column text-left word-break mx-3">
              <span class="font-weight-bold">{{
                !user.firstName && !user.lastName
                  ? "Enter a name below"
                  : (user.firstName || "") + " " + (user.lastName || "")
              }}</span>
              <span
                >{{ user.organization
                }}{{ user.organization && user.jobTitle ? "," : "" }}
                {{ user.jobTitle }}</span
              >
            </div>
          </div>
          <p class="font-weight-bold" v-if="user.balance !== undefined">
            ${{ user.balance || 0 }}
          </p>
        </div>
        <v-divider class="mb-2 mt-6" />
        <v-card-text class="d-flex flex-column text-left">
          <div class="d-flex justify-space-between">
            <h2
              v-for="tab in visibleTabs"
              :key="tab.key"
              :class="{
                'mediumGrey--text': activeSlide != tab.key,
                hidden: tab.hidden
              }"
              class="tab-button"
              @click="activeSlide = tab.key"
            >
              {{ tab.label }}
            </h2>
          </div>

          <div
            v-if="activeSlide === keys.info"
            :key="keys.info"
            class="mx-2 pt-2"
          >
            <h4 v-if="editingExternalUser" class="grey--text">
              This user is external to your organization - some fields cannot be
              edited
            </h4>
            <h4 v-else-if="ssoEnabled" class="grey--text">
              This user was created via SSO - some fields cannot be edited
            </h4>
            <p v-if="whistleAdmin && editingUser" class="mb-1">
              User ID: <b>{{ user.userId }}</b>
            </p>
            <p v-if="whistleAdmin && editingUser">
              Primary Client:
              <b>{{ user.primaryClientId }} | {{ editingClientName }}</b>
            </p>
            <v-form
              ref="userForm"
              v-model="userForm.valid"
              class="full-width mt-2"
            >
              <div class="d-flex full-width">
                <v-autocomplete
                  :items="clients"
                  item-text="formattedName"
                  item-value="clientId"
                  label="Client*"
                  outlined
                  dense
                  attach
                  :disabled="editingUser"
                  v-model="user.clientId"
                  color="brandCyan"
                  v-if="userProfile.Client.clientId === 1"
                ></v-autocomplete>
                <v-btn
                  icon
                  v-if="displayClientTransfer"
                  class="ml-1"
                  @click="dialog.clientTransfer = true"
                  ><v-icon>mdi-transfer</v-icon></v-btn
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="user.firstName"
                  :rules="[
                    v => !!v || 'First name is required',
                    v =>
                      !!(v && v.length < 100) || 'First name should be shorter'
                  ]"
                  label="First name*"
                  :disabled="editingExternalUser"
                  required
                  outlined
                  dense
                  style="width: 40%;"
                  class="mr-2"
                  color="brandCyan"
                ></v-text-field>
                <v-text-field
                  v-model="user.lastName"
                  :rules="[
                    v => !!v || 'Last name is required',
                    v =>
                      !!(v && v.length < 100) || 'Last name should be shorter'
                  ]"
                  label="Last name*"
                  :disabled="editingExternalUser"
                  outlined
                  dense
                  style="width: 60%;"
                  class="ml-2"
                  color="brandCyan"
                ></v-text-field>
              </div>
              <!-- <v-text-field
              class="pa-1"
              v-model="user.displayName"
              label="Preferred display name"
              outlined
              dense
            ></v-text-field> -->
              <div class="d-flex align-center">
                <v-text-field
                  v-model="user.businessEmail"
                  :rules="[
                    v =>
                      !!(
                        (!passwordEnabled && !emailPasswordlessEnabled) ||
                        v
                      ) || 'Your email is required',
                    v =>
                      !!(!v || (v && v.length < 255)) ||
                      'Your email should be shorter',
                    v =>
                      !v ||
                      (v && emailRegex.test(v)) ||
                      'Your email does not appear valid'
                  ]"
                  :label="
                    passwordEnabled || emailPasswordlessEnabled
                      ? 'Email*'
                      : 'Email'
                  "
                  :disabled="editingExternalUser || ssoEnabled"
                  outlined
                  dense
                  color="brandCyan"
                ></v-text-field>
              </div>
              <vue-tel-input-vuetify
                dense
                outlined
                class=""
                v-model="user.businessPhone"
                :value="user.businessPhone"
                :label="
                  smsPasswordlessEnabled ? 'Phone number*' : 'Phone number'
                "
                :disabled="editingExternalUser"
                color="brandCyan"
                placeholder="xxx-xxx-xxxx"
                :preferredCountries="['US']"
                :defaultCountry="businessCountryCode.code"
                :disabledFetchingCountry="true"
                v-on:country-changed="businessCountryCodeChanged"
                @input="updateBusinessPhone"
                :rules="[
                  v =>
                    !!(!smsPasswordlessEnabled || v) ||
                    'Your phone number is required',
                  v =>
                    !!(!v || (v && v.length < 255)) ||
                    'Your phone number should be shorter',
                  v =>
                    !!(!v || (v && businessPhoneNumberValid)) ||
                    'Your phone number does not appear to be valid'
                ]"
              ></vue-tel-input-vuetify>
              <p class="red--text" v-if="editingUser && !editingExternalUser">
                NOTE: If you change a user's phone number or email, their old
                email or phone will no longer log them in.
              </p>
              <!-- <v-select
                v-if="!editingExternalUser"
                :items="visibleRoles"
                item-text="displayName"
                item-value="id"
                label="Admin Roles"
                v-model="user.roles"
                :loading="loading.roles || loading.userRoles"
                multiple
                chips
                attach
                :menu-props="{ offsetY: true }"
                deletable-chips
                clear
                hide-selected
                hide-details
                outlined
                color="brandCyan"
                class="mb-3"
              ></v-select>
              <v-btn
                text
                small
                class="mb-3"
                @click="routeToRoles"
                v-if="
                  permissions.includes('vue:read:accountmanagement') &&
                    !editingExternalUser
                "
                ><v-icon color="grey">mdi-help-circle</v-icon>What do these
                roles mean?</v-btn
              > -->
              <v-select
                :items="countries"
                item-text="name"
                item-value="code"
                label="Business Country*"
                v-model="user.businessCountry"
                :rules="[v => !!v || 'Country is required']"
                outlined
                dense
                attach
                color="brandCyan"
              ></v-select>
              <v-text-field
                v-if="user.businessCountry === '00'"
                v-model="user.otherBusinessCountry"
                :rules="[
                  v => !!v || 'Country code is required',
                  v =>
                    !!(v && v.length == 2) || 'Country code should be 2 letters'
                ]"
                label="Enter country code here (2 digit code)*"
                outlined
                dense
                color="brandCyan"
              ></v-text-field>

              <v-radio-group
                v-model="userForm.userTypeRadioGroup"
                @change="toggleOnboardingCheckbox"
                v-if="!editingUser"
              >
                <p class="font-weight-bold">Login method:</p>
                <v-radio
                  label="Email & password"
                  value="PASSWORD"
                  color="brandCyan"
                  class="mb-5"
                ></v-radio>
                <v-radio
                  label="One-time password via email"
                  value="PASSWORDLESS_EMAIL"
                  color="brandCyan"
                  class="mb-5"
                ></v-radio>
                <v-radio
                  label="One-time password via SMS"
                  value="PASSWORDLESS_SMS"
                  color="brandCyan"
                ></v-radio>
              </v-radio-group>
              <div
                v-if="
                  !editingExternalUser &&
                    (smsPasswordlessEnabled || passwordEnabled)
                "
              >
                <v-checkbox
                  v-if="displayInviteToggle"
                  :label="
                    passwordEnabled ? 'Send invite email' : 'Send invite text'
                  "
                  v-model="userForm.sendInviteToggle"
                  color="brandCyan"
                  :true-value="true"
                  :false-value="false"
                  @change="toggleOnboardingCheckbox"
                  hide-details
                  class="mt-0"
                />

                <input
                  v-model="user.scheduledOnboardingDate"
                  class="timestamp-input mb-2"
                  type="datetime-local"
                  v-if="displayInviteToggle && userForm.sendInviteToggle"
                />
                <p
                  v-else-if="displayInviteToggle && !userForm.sendInviteToggle"
                  class="mt-2"
                >
                  The invite {{ passwordEnabled ? "email" : "text" }} can be
                  sent anytime from the People page
                </p>

                <v-btn
                  v-if="editingUser && user.onboardingEmailSent"
                  text
                  @click="sendPasswordResetEmail"
                  color="brandCyan"
                  class="d-flex ml-2"
                  width="300"
                  :loading="passwordEmailLoading"
                >
                  <div v-if="!passwordEmailSuccess">
                    <v-icon class="mr-2">mdi-email</v-icon
                    >{{
                      !user.firstLogin
                        ? "Send password reset email"
                        : "Resend Whistle invite email"
                    }}
                  </div>
                  <v-icon v-else class="d-flex justify-center"
                    >mdi-check</v-icon
                  ></v-btn
                >
                <!-- Only show if they're not a password user -->
                <v-btn
                  v-else-if="
                    editingUser && user.onboardingTextSent && !passwordEnabled
                  "
                  text
                  @click="sendSMSInviteText"
                  color="brandCyan"
                  class="d-flex ml-2"
                  width="300"
                  :loading="textInviteLoading"
                >
                  <div v-if="!textInviteSuccess">
                    <v-icon class="mr-2">mdi-comment-text</v-icon>
                    Resend Whistle invite text
                  </div>
                  <v-icon v-else class="d-flex justify-center"
                    >mdi-check</v-icon
                  ></v-btn
                >
              </div>
              <p
                v-else-if="
                  !editingUser &&
                    userForm.userTypeRadioGroup == 'PASSWORDLESS_EMAIL'
                "
              >
                Note: No invite notificiation will get sent for email one-time
                password users. We recommend messaging them from the
                Communications page if you wish to invite them.
              </p>

              <div
                class="auth-types mt-5"
                v-if="editingUser && !editingExternalUser"
              >
                <h3 class="mb-2">Login Methods:</h3>
                <v-btn class="auth-type" disabled v-if="user.passwordEnabled"
                  ><v-icon class="mr-2 ml-1">mdi-check-circle</v-icon>Email &
                  Password</v-btn
                >
                <v-btn
                  class="auth-type"
                  disabled
                  v-if="user.emailPasswordlessEnabled"
                  ><v-icon class="mr-2 ml-1">mdi-check-circle</v-icon>Email
                  One-Time-Passwords</v-btn
                >
                <v-btn
                  class="auth-type"
                  disabled
                  v-if="user.smsPasswordlessEnabled"
                  ><v-icon class="mr-2 ml-1">mdi-check-circle</v-icon>SMS
                  One-Time-Passwords</v-btn
                >

                <v-btn
                  v-if="
                    editingUser && !user.emailPasswordlessEnabled && !ssoEnabled
                  "
                  text
                  @click="addAuth('passwordless_email')"
                  color="brandCyan"
                  class="d-flex ml-1 mb-1 auth-type"
                  outlined
                  rounded
                  width="400"
                  :disabled="
                    authState['passwordless_sms'].loading ||
                      authState['password'].loading
                  "
                  :loading="authState['passwordless_email'].loading"
                >
                  <div>
                    <v-icon class="mr-2">mdi-plus-circle</v-icon
                    >{{ "Add Email One-Time-Passwords" }}
                  </div>
                </v-btn>
                <v-btn
                  v-if="editingUser && !user.smsPasswordlessEnabled"
                  text
                  @click="addAuth('passwordless_sms')"
                  color="brandCyan"
                  class="d-flex ml-1 mb-1 auth-type"
                  outlined
                  rounded
                  width="400"
                  :disabled="
                    authState['passwordless_email'].loading ||
                      authState['password'].loading
                  "
                  :loading="authState['passwordless_sms'].loading"
                >
                  <div>
                    <v-icon class="mr-2">mdi-plus-circle</v-icon
                    >{{ "Add SMS One-Time-Passwords" }}
                  </div></v-btn
                >
                <v-btn
                  v-if="editingUser && !user.passwordEnabled && !ssoEnabled"
                  text
                  @click="addAuth('password')"
                  color="brandCyan"
                  class="d-flex ml-1 mb-1 auth-type"
                  outlined
                  rounded
                  width="400"
                  :disabled="
                    authState['passwordless_email'].loading ||
                      authState['passwordless_sms'].loading
                  "
                  :loading="authState['password'].loading"
                >
                  <div>
                    <v-icon class="mr-2">mdi-plus-circle</v-icon
                    >{{ "Add Email & Password" }}
                  </div></v-btn
                >
              </div>
              <v-expansion-panels
                v-model="userForm.openPanel"
                flat
                class="pa-0 mx-0"
                @change="expansionPanelOpened"
              >
                <v-expansion-panel class="px-0 mx-0">
                  <v-expansion-panel-header class="mx-0 px-0">
                    Optional Fields
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="px-0 mx-0">
                    <v-text-field
                      v-model="user.organization"
                      label="Organization"
                      color="brandCyan"
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) ||
                          'Organization should be shorter'
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="user.displayName"
                      label="Display Name / Nickname"
                      color="brandCyan"
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) ||
                          'Display name should be shorter'
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                    <!-- <v-select
                    :items="user.statusList"
                    label="User Status"
                    v-model="user.status"
                    v-if="editingUser && user.status != 'Blocked'"
                    outlined
                    dense
                    attach
                  ></v-select> -->
                    <v-text-field
                      v-model="user.userIdAtClient"
                      label="External Pax ID"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>

                    <!-- <v-text-field
                    v-model="user.businessPhone"
                    label="Business Phone"
                    outlined
                    dense
                    :rules="[
                      v => !!(!v || v.length < 255) || 'This should be shorter'
                    ]"
                  ></v-text-field> -->

                    <v-text-field
                      v-model="user.jobTitle"
                      label="Job Title"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>

                    <v-menu
                      v-model="userForm.menuBirthday"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :disabled="editingExternalUser"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user.birthday"
                          label="Birthday"
                          :disabled="editingExternalUser"
                          color="brandCyan"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user.birthday"
                        @input="userForm.menuBirthday = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                      v-model="userForm.menuRoleStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user.roleStartDate"
                          label="Role Start Date"
                          color="brandCyan"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user.roleStartDate"
                        @input="userForm.menuRoleStartDate = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                      v-model="userForm.menuJobStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user.jobStartDate"
                          label="Job Start Date"
                          color="brandCyan"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user.jobStartDate"
                        @input="userForm.menuJobStartDate = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-text-field
                      v-model="user.businessAddress"
                      label="Business Address"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.businessCity"
                      label="Business City"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.businessState"
                      label="Business State (2 digit code)"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length == 2) ||
                          'Please enter your 2 digit state code'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.businessPostalCode"
                      label="Business Postal Code"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>

                    <v-text-field
                      v-model="user.homeAddress"
                      label="Home Address"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.homeCity"
                      label="Home City"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.homeState"
                      label="Home State (2 digit code)"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length == 2) ||
                          'Please enter your 2 digit state code'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.homePostalCode"
                      label="Home Postal Code"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-select
                      :items="countries"
                      item-text="name"
                      item-value="code"
                      label="Home Country"
                      v-model="user.homeCountry"
                      outlined
                      dense
                      color="brandCyan"
                    ></v-select>
                    <v-text-field
                      v-if="user.homeCountry === '00'"
                      v-model="user.otherHomeCountry"
                      :rules="
                        !user.otherHomeCountry
                          ? []
                          : [
                              v =>
                                !!(v && v.length == 2) ||
                                'Country code should be 2 letters'
                            ]
                      "
                      label="Enter country code here (2 digit code)"
                      color="brandCyan"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="user.personalEmail"
                      label="Secondary Email"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) ||
                          'The email should be shorter',
                        v =>
                          !v ||
                          emailRegex.test(v) ||
                          'The email does not appear valid'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.personalPhone"
                      label="Personal Phone"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-if="displayAnnualPaymentCap"
                      v-model="user.annualPaymentCap"
                      label="Annual Payment Cap"
                      color="brandCyan"
                      outlined
                      type="number"
                      dense
                      :rules="formRules.optionalAmount(false)"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.token1"
                      label="Custom Field 1"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.token2"
                      label="Custom Field 2"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.token3"
                      label="Custom Field 3"
                      color="brandCyan"
                      outlined
                      dense
                      :rules="[
                        v =>
                          !!(!v || v.length < 255) || 'This should be shorter'
                      ]"
                    ></v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </div>
          <div
            v-else-if="activeSlide === keys.roles"
            :key="keys.roles"
            class="mx-2 pt-2"
          >
            <div
              class="role-container "
              v-for="(role, index) in visibleRoles"
              :key="role.id"
            >
              <div class="d-flex justify-center my-3">
                <v-checkbox
                  :disabled="
                    editingExternalUser || role.displayName === 'Participant'
                  "
                  v-model="user.roles"
                  :value="role.id"
                  dense
                  hide-details
                  color="brandCyan"
                ></v-checkbox>
                <h3>
                  <span class="font-weight-bold">{{ role.displayName }}</span>
                  <span class="font-weight-regular">
                    {{ role.description }}</span
                  >
                </h3>
              </div>
              <v-divider
                v-if="
                  (role?.rank < 90 && visibleRoles?.[index + 1]?.rank >= 90) ||
                    (role?.rank < 1 && visibleRoles?.[index + 1]?.rank >= 1) ||
                    (role?.rank < 10 && visibleRoles?.[index + 1]?.rank >= 10)
                "
                class="my-6"
              />
            </div>
          </div>
          <div v-else-if="activeSlide === keys.received">
            <RewardsSentLog
              level="PaymentRule"
              :elevation="0"
              :byUser="user.userId"
              :clientId="user.clientId"
            />
          </div>
          <div v-else-if="activeSlide === keys.spent">
            <TransactionLog
              :elevation="0"
              :byUser="user.userId"
              :onlyEssentialColumns="true"
              :hideCredits="true"
            />
          </div>
        </v-card-text>
        <v-card-actions
          class="px-8 d-flex justify-space-between"
          v-if="activeSlide <= keys.roles"
        >
          <v-btn
            color="red"
            text
            @click="confirmDelete = true"
            v-if="
              editingUser &&
                user.userId !== userProfile.userId &&
                permissions.includes('users:delete:user')
            "
            class="mr-4"
          >
            Delete
          </v-btn>
          <div v-else></div>
          <v-btn
            color="brandCyan"
            class="white--text px-4"
            :outlined="!userForm.valid || !validScheduledOnboarding"
            depressed
            rounded
            @click="createNewUser"
            :disabled="!userForm.valid || !validScheduledOnboarding"
            v-if="!editingUser"
          >
            Add person
          </v-btn>
          <v-btn
            color="brandCyan"
            class="white--text px-4"
            :outlined="!!(!userForm.valid || !validScheduledOnboarding)"
            depressed
            rounded
            @click="updateUserV2"
            :disabled="!!(!userForm.valid || !validScheduledOnboarding)"
            v-else
          >
            Update info
          </v-btn>
        </v-card-actions>
      </div>

      <div
        v-else-if="activeSlide == keys.loading"
        key="2"
        class="word-break mt-10 d-flex flex-column align-center text-left"
      >
        <v-card-text class="full-width pl-6">
          <h2 v-if="editingUser">
            Updating info...
          </h2>
          <h2 v-else>
            Creating the account...
          </h2>
        </v-card-text>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="inflatableDance"
          :loop="true"
        />
      </div>
      <!-- Success message displayed -->
      <div v-else-if="activeSlide == keys.success" key="3">
        <!-- <v-card-title class="dialog-header" color="primary">
              Add a new person
            </v-card-title> -->
        <!-- <v-divider /> -->
        <v-card-text
          class="d-flex-column align-center justify-center mt-10 word-break"
        >
          <v-card-text class="full-width pl-6">
            <h2>
              Success!!
            </h2>
          </v-card-text>
          <div class="d-flex justify-center">
            <Robin
              :showText="false"
              :width="140"
              :height="140"
              animation="backflip"
              :loop="false"
              class="mb-8"
            />
          </div>
          <v-btn
            outlined
            color="brandCyan"
            @click="resetUserForm"
            v-if="!editingUser"
          >
            <v-icon>mdi-plus</v-icon>Add more
          </v-btn>
          <v-btn
            class="ml-3 white--text"
            color="brandCyan"
            depressed
            @click="closeWindow(false)"
          >
            Close
          </v-btn>
        </v-card-text>
      </div>
      <div v-else-if="activeSlide == keys.error" key="4">
        <v-card-text
          class="d-flex-column align-center justify-center mt-10 word-break"
        >
          <v-card-text class="full-width text-left pl-4">
            <h2>
              {{
                errorMessage ? errorMessage : "Looks like there was a problem"
              }}
            </h2>
          </v-card-text>
          <div class="justify-center">
            <p class="pl-4 full-width text-left">
              Feel free to reach out to customer service via one of the
              following methods...
            </p>
            <p class="pl-4 text-left full-width">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="pl-4 text-left full-width">
              Phone (Toll Free) - <b>(855) 264-3329</b>
            </p>
          </div>

          <v-btn
            outlined
            color="brandCyan"
            @click="goBackFromError"
            class="mt-3"
          >
            Go back
          </v-btn>
          <v-btn
            class="ml-3 mt-3 white--text"
            color="brandCyan"
            depressed
            @click="closeWindow(false)"
          >
            Close
          </v-btn>
        </v-card-text>
      </div>
    </transition>

    <!-- Image Cropping dialog -->
    <!-- <v-dialog persistent v-model="userForm.dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 1 : 1 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="userForm.imageUrlToCrop"
          :stencil-props="{
            aspectRatio: 1 / 1
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- Dialog to show confirmation after resetting password -->
    <v-dialog v-model="emailErrorDialog" persistent width="350">
      <v-card class="py-3">
        <h2 class="mt-2">
          <v-icon large class="mr-2" color="error">mdi-alert</v-icon>Duplicate
          email found
        </h2>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p class="my-3">
            Uh oh! It looks like we already found a user with this email
            address.
          </p>
          <p>Please try using another email.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="emailErrorDialog = false" color="primary" class="mr-2"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to confirm the user wants to delete the person -->
    <v-dialog v-model="confirmDelete" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to delete the selected user?
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="primary"
            @click="confirmDelete = false"
            outlined
            width="130"
            >Don't delete</v-btn
          >
          <v-spacer />
          <v-btn color="primary" @click="deleteUser(user.userId)" width="130"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to transfer users across clients -->
    <v-dialog v-model="dialog.clientTransfer" persistent width="500">
      <v-card
        v-if="!clientTransfer.errorMessage"
        rounded="0"
        class="d-flex justify-center flex-column pa-6"
      >
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            What client do you want to transfer this user to?
          </v-card-title>
        </div>
        <div class="text-left mb-2">
          <p class="mb-1">Caveats to know before you transfer:</p>
          <ul>
            <li>
              The user will be erased from any programs and groups from their
              current client.
            </li>
            <li>
              If the user was previously in this client and got paid for any
              programs, they will get paid again for the same programs as if
              they're a new user.
            </li>
            <li>
              Any unsaved changes in the user editor below will be lost.
            </li>
            <li>
              If the user has a Whistle Card, they cannot be moved to a client
              that isn't set up for Whistle Cards.
            </li>
          </ul>
        </div>
        <v-autocomplete
          :items="
            clients.filter(x => x.clientId != user.clientId && x.clientId != 0)
          "
          item-text="formattedName"
          item-value="clientId"
          label="Select a client to move to"
          outlined
          dense
          attach
          :menu-props="{ top: true, offsetY: true }"
          v-model="clientTransfer.destinationClientId"
          color="brandCyan"
        ></v-autocomplete>
        <v-checkbox
          color="brandCyan"
          label="Keep user in old client as an external/secondary user"
          dense
          v-model="clientTransfer.preserveClient"
        />

        <v-card-actions class="mx-12">
          <v-btn
            color="brandCyan"
            @click="resetClientTransferForm"
            outlined
            :disabled="clientTransfer.loading"
            width="130"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            :disabled="!clientTransfer.destinationClientId"
            :loading="clientTransfer.loading"
            @click="
              transferUserToNewClient(
                user.userId,
                clientTransfer.destinationClientId
              )
            "
            width="130"
            >Transfer</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            There was an error transferring clients
          </v-card-title>
        </div>
        <div>
          <p>{{ clientTransfer.errorMessage }}</p>
        </div>

        <v-card-actions class="mx-12">
          <v-spacer />
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            @click="resetClientTransferForm"
            width="130"
            >Close</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import OnboardingService from "@/services/OnboardingService";
import UserService from "@/services/UserService";
import CommunicationService from "@/services/CommunicationService";

import LoadingDialog from "@/components/loadingDialog.vue";
import Robin from "@/components/Robin.vue";
import ErrorDialog from "@/components/ErrorDialog";
import RewardsSentLog from "@/components/wallet/RewardsSentLog";
import TransactionLog from "@/components/wallet/TransactionLog";

import { mapState } from "vuex";
import moment from "moment";

import { countries, emailRegex, formRules } from "@/shared_data/data.js";

function initialState() {
  return {
    keys: {
      info: 0,
      roles: 2,
      received: 3,
      spent: 4,
      loading: 5,
      success: 6,
      error: 7
    },
    activeSlide: 0,
    showLoadingDialog: false,
    loadingHeaderMessage: null,
    loadingMessageLine1: null,
    loadingMessageLine2: null,
    emailErrorDialog: false,
    confirmDelete: false,
    errorMessage: null,
    emailRegex,
    formRules,
    userForm: {
      openPanel: null,
      menuStartDate: false,
      menuEndDate: false,
      endDateDisabled: false,
      endDateCheckBox: false,
      menuStartDateClient: false,
      menuEndDateClient: false,
      endDateClientDisabled: false,
      endDateClientCheckBox: false,
      menuBirthday: false,
      menuRoleStartDate: false,
      menuJobStartDate: false,
      valid: false,
      isSelectingProfilePic: false,
      imageUrlToCrop: null,
      imageUrlFileName: null,
      dialogImageCropper: false,
      profilePicFile: null,
      profilePicObject: null,
      editing: false,
      showSuccess: false,
      transitionDirection: "topic-left",
      sendInviteToggle: false,
      emailPasswordlessEnabled: false,
      userTypeRadioGroup: null //"PASSWORD"
    },
    user: {
      clientId: null,
      userId: null,
      firstName: null,
      lastName: null,
      userIdAtClient: null,
      profilePicUrl: null,
      originalBusinessEmail: null,
      businessEmail: null,
      // emailDomain: null,
      businessPhone: null,
      personalEmail: null,
      personalPhone: null,
      status: "Active",
      displayName: null,
      jobTitle: null,
      countryCode: null,

      birthday: null,
      roleStartDate: null,
      jobStartDate: null,
      businessAddress: null,
      businessCity: null,
      businessState: null,
      businessPostalCode: null,
      businessCountry: null,
      otherBusinessCountry: null,
      homeAddress: null,
      homeCity: null,
      homeState: null,
      homePostalCode: null,
      homeCountry: null,
      otherHomeCountry: null,
      organization: null,
      token1: null,
      token2: null,
      token3: null,
      scheduledOnboardingDate: null,
      annualPaymentCap: null,

      roles: [],

      statusList: ["Active", "Inactive"]
    },
    countries: countries,
    passwordEmailLoading: false,
    passwordEmailSuccess: false,
    textInviteLoading: false,
    textInviteSuccess: false,
    emailPasswordlessLoading: false,
    emailPasswordlessSuccess: false,
    authState: {
      password: { loading: false, success: false },
      passwordless_email: { loading: false, success: false },
      passwordless_sms: { loading: false, success: false }
    },

    roleArray: [],

    // Phone number validation
    businessCountryCode: { code: null, value: null },
    personalCountryCode: { code: null, value: null },

    businessPhoneNumberValid: false,
    personalPhoneNumberValid: false,

    // Used by the error component
    errorDialog: {
      display: false,
      override: false,
      title: null,
      subtitle: null,
      button1Text: null,
      button2Text: null
    },
    loading: {
      roles: false,
      userRoles: false
    },
    dialog: { clientTransfer: false },
    clientTransfer: {
      destinationClientId: null,
      errorMessage: null,
      loading: false,
      preserveClient: false
    }
  };
}

export default {
  name: "UserCreatorWidget",
  components: {
    LoadingDialog,
    Robin,
    ErrorDialog,
    RewardsSentLog,
    TransactionLog
  },
  props: {
    clientId: Number,
    roles: Array,

    isFinanceAdminOrHigher: Boolean,
    editingUser: Boolean,
    editUserObject: Object,
    preloadAwardId: Number,
    source: String
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    if (this.permissions.includes("users:read:role")) this.getRoles();
    if (this.editingUser) {
      console.log("Loading in user object: ", this.editUserObject);
      this.user = JSON.parse(JSON.stringify(this.editUserObject));
      if (this.user.scheduledOnboardingDate != null) {
        this.userForm.sendInviteToggle = true;
      }

      // If the user doesn't have a password account, then we set the toggle (even though it's hidden) to passwordless
      if (this.editUserObject && this.editUserObject.passwordEnabled)
        this.userForm.userTypeRadioGroup = "PASSWORD";
      else if (
        this.editUserObject &&
        this.editUserObject.emailPasswordlessEnabled
      )
        this.userForm.userTypeRadioGroup = "PASSWORDLESS_EMAIL";
      else if (
        this.editUserObject &&
        this.editUserObject.smsPasswordlessEnabled
      )
        this.userForm.userTypeRadioGroup = "PASSWORDLESS_SMS";

      if (!this.editUserObject.externalUser)
        this.getRolesByUser(this.user.userId);
      // if (this.user.businessState && this.user.businessState.length != 2)
      //   this.user.businessState = null;
    } else {
      // Preload country & clientId
      this.userForm.userTypeRadioGroup = "PASSWORD";
      this.autofillForm(false);
    }

    // Set a timeout so the form has aa moment to set itself up and then we validate
    setTimeout(() => {
      if (this.editingUser && this.$refs.userForm)
        this.$refs.userForm.validate();
    }, 500);
  },
  mounted() {},
  methods: {
    numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    downloadTemplate() {
      OnboardingService.downloadTemplate();
    },
    downloadTemplateInfo() {
      OnboardingService.downloadTemplateInfo();
    },
    closeWindow(clearSelection) {
      // this.$refs.budgetForm.reset();

      this.$emit("close", clearSelection);
    },
    getUsers(preloadId = null) {
      this.$emit("get-users", preloadId);
    },
    async getRoles() {
      try {
        if (!this.roles) {
          var response = await UserService.getRoles();
        } else {
          console.log("Prefilling roles");
          response = this.roles;
        }
        console.log("Roles: ", response);
        this.roleArray = response;
      } catch (err) {
        console.log("error getting roles ", err);
      }
    },
    async getRolesByUser(userId) {
      this.loading.userRoles = true;
      try {
        var response = await UserService.getRolesByUser(userId);
        console.log("Roles for user: ", response);
        this.user.roles = response.map(x => x.id);
        this.loading.userRoles = false;
      } catch (err) {
        console.log("error getting roles for user ", err);
        this.user.roles = [];
        this.loading.userRoles = false;
      }
    },
    async createNewUser() {
      try {
        this.activeSlide = this.keys.loading;

        var returnObj = {
          // clientName: this.userProfile.Client.clientName,
          userIdAtClient: this.user.userIdAtClient,
          status: this.user.status,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          displayName: this.user.displayName
            ? this.user.displayName
            : this.user.firstName + " " + this.user.lastName,
          jobTitle: this.user.jobTitle,
          startDate: this.user.startDate,
          endDate: this.user.endDate,
          endDateAtClient: this.user.endDateAtClient,
          birthday: this.user.birthday,
          roleStartDate: this.user.roleStartDate,
          jobStartDate: this.user.jobStartDate,
          businessEmail: this.user.businessEmail,
          businessPhone: this.user.formattedBusinessPhone,
          businessAddress: this.user.businessAddress,
          businessCity: this.user.businessCity,
          businessState: this.user.businessState,
          businessPostalCode: this.user.businessPostalCode,
          businessCountry:
            this.user.businessCountry === "00"
              ? (this.user.otherBusinessCountry || "").toUpperCase()
              : this.user.businessCountry,
          personalEmail: this.user.personalEmail,
          personalPhone: this.user.personalPhone,
          homeAddress: this.user.homeAddress,
          homeCity: this.user.homeCity,
          homeState: this.user.homeState,
          homePostalCode: this.user.homePostalCode,
          homeCountry:
            this.user.homeCountry === "00"
              ? (this.user.otherHomeCountry || "").toUpperCase()
              : this.user.homeCountry,
          organization: this.user.organization,
          token1: this.user.token1,
          token2: this.user.token2,
          token3: this.user.token3,

          source: this.source || "PEOPLE",
          roles: this.user.roles,
          annualPaymentCap: this.user.annualPaymentCap
        };
        if (
          this.userProfile.Client.clientId === 1 &&
          this.user.clientId !== null
        ) {
          returnObj.clientId = this.user.clientId;
          // var name = this.clients.find(x => x.clientId === this.user.clientId);
          // returnObj.clientName = name.clientName;
        } else {
          returnObj.clientId = this.userProfile.clientId;
        }

        // If the box isn't checked then we mark as null so we never send an email
        if (this.userForm.userTypeRadioGroup == "PASSWORD") {
          // We're setting up a password user
          returnObj.emailPasswordlessEnabled = false;
          returnObj.smsPasswordlessEnabled = false;
          returnObj.passwordEnabled = true;
        } else if (this.userForm.userTypeRadioGroup == "PASSWORDLESS_EMAIL") {
          // We're setting up a passwordless user so we disable the onboarding date
          returnObj.emailPasswordlessEnabled = true;
          returnObj.smsPasswordlessEnabled = false;
          returnObj.passwordEnabled = false;
        } else {
          // We're setting up a passwordless user so we disable the onboarding date
          returnObj.emailPasswordlessEnabled = false;
          returnObj.smsPasswordlessEnabled = true;
          returnObj.passwordEnabled = false;
        }

        if (
          this.userForm.userTypeRadioGroup == "PASSWORDLESS_SMS" ||
          this.userForm.userTypeRadioGroup == "PASSWORD"
        ) {
          if (!this.userForm.sendInviteToggle)
            returnObj.scheduledOnboardingDate = null;
          else
            returnObj.scheduledOnboardingDate = moment(
              this.user.scheduledOnboardingDate
            )
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ");
        }

        // if (!this.editingUser)
        //   returnObj.emailPasswordlessEnabled = this.userForm.emailPasswordlessEnabled;
        console.log("Creating user ", returnObj);
        let response = await OnboardingService.createUserV2(returnObj, {
          wait: "true",
          screen: returnObj.source
        });
        // .then(response => {
        console.log("User creation response: ", response.result || response);

        this.activeSlide = this.keys.success;
        const createdUser =
          response && response.result && response.result.length
            ? response.result[0]
            : undefined;
        if (!createdUser || createdUser.invalid || createdUser.failed)
          throw {
            invalid: !createdUser
              ? "Failed to create the user"
              : createdUser.invalidReason || createdUser.failedReason
          };
        let userId = createdUser ? createdUser.userId : null;
        this.getUsers(userId);
      } catch (err) {
        console.log("Error creating user: ", err);
        this.activeSlide = this.keys.error;
        if (err && err.invalid) this.errorMessage = err.invalid;
        else this.errorMessage = "There was a problem adding the new person";
      }
    },
    async updateUserV2() {
      try {
        this.activeSlide = this.keys.loading;

        if (
          this.user.businessCountry === "00" &&
          this.user.otherBusinessCountry
        )
          this.user.otherBusinessCountry = this.user.otherBusinessCountry.toUpperCase();

        if (this.user.homeCountry === "00" && this.user.otherHomeCountry)
          this.user.otherHomeCountry = this.user.otherHomeCountry.toUpperCase();

        var user = {
          userIdAtClient: this.user.userIdAtClient,
          status: this.user.status,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          displayName: this.user.displayName
            ? this.user.displayName
            : this.user.firstName + " " + this.user.lastName,
          jobTitle: this.user.jobTitle,
          startDate: this.user.startDate,
          endDate: this.user.endDate,
          endDateAtClient: this.user.endDateAtClient,
          birthday: this.user.birthday,
          roleStartDate: this.user.roleStartDate,
          jobStartDate: this.user.jobStartDate,
          businessEmail: this.user.businessEmail,
          businessPhone: this.user.formattedBusinessPhone,
          businessAddress: this.user.businessAddress,
          businessCity: this.user.businessCity,
          businessState: this.user.businessState,
          businessPostalCode: this.user.businessPostalCode,
          businessCountry:
            this.user.businessCountry === "00"
              ? (this.user.otherBusinessCountry || "").toUpperCase()
              : this.user.businessCountry,
          personalEmail: this.user.personalEmail,
          personalPhone: this.user.personalPhone,
          homeAddress: this.user.homeAddress,
          homeCity: this.user.homeCity,
          homeState: this.user.homeState,
          homePostalCode: this.user.homePostalCode,
          homeCountry:
            this.user.homeCountry === "00"
              ? (this.user.otherHomeCountry || "").toUpperCase()
              : this.user.homeCountry,
          organization: this.user.organization,
          token1: this.user.token1,
          token2: this.user.token2,
          token3: this.user.token3,
          roles: this.user.roles,
          annualPaymentCap: this.user.annualPaymentCap
        };

        if (this.userProfile.clientId === 1 && this.user.clientId !== null)
          user.clientId = this.user.clientId;
        else user.clientId = this.userProfile.clientId;

        // We only want to parse this if the email hasn't already gone out
        if (!this.user.onboardingEmailSent) {
          // If the box isn't checked then we mark as null so we never send an email
          if (!this.userForm.sendInviteToggle)
            user.scheduledOnboardingDate = null;
          else
            user.scheduledOnboardingDate = moment(
              this.user.scheduledOnboardingDate
            )
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ");
        }

        console.log("User updating, ", user);
        var updateResponse = await UserService.updateUserV2(
          this.user.userId,
          user,
          { screen: "people" }
        );

        console.log("User update response ", updateResponse);
        // if (updateResponse.data && updateResponse.data.success) {
        if (this.userForm.profilePicFile) {
          var imageResponse = await UserService.uploadProfilePicture(
            this.user.userId,
            this.userForm.profilePicFile,
            user.clientId
          ).catch(error => {
            this.activeSlide = this.keys.error;
            this.errorMessage =
              "There was a problem updating the profile picture";
            console.log("Error uploading file: ", error);
          });

          console.log("Image response ", imageResponse);
        }

        // if (this.user.userId === this.userProfile.userId) {
        //   //Only gets new data if the user is updating themselves
        //   this.$store.dispatch("setUser", this.user.userId);
        // }
        this.closeWindow(false);
        this.getUsers(this.user.userId);
      } catch (err) {
        console.log("Error updating user ", err);
        if (!err || !err.error_code) {
          this.activeSlide = this.keys.error;
          this.errorMessage =
            "There was a problem updating the selected person";
          return;
        }
        switch (err.error_code) {
          case "1030165":
          case "1030166":
            this.errorDialog.display = true;
            this.errorDialog.title = `Uh oh! It looks like we already have a user with this ${
              err.error_code == "1030166" ? "phone number" : "email address"
            }.`;
            this.errorDialog.subtitle = `Please try using another ${
              err.error_code == "1030166" ? "number" : "email"
            }`;
            this.errorDialog.button2Text = "Close";
            this.activeSlide = this.keys.info;
            break;
          case "1030167":
            this.activeSlide = this.keys.error;
            this.errorMessage =
              "There was a problem updating the selected roles. Everything else was updated.";
            break;
          case "1030008":
            this.activeSlide = this.keys.error;
            //  Improper formatting - let's show the error message
            if (
              err.error &&
              (err.error.includes("businessState") ||
                err.error.includes("homeState"))
            )
              this.errorMessage = `The ${
                err.error.includes("homeState") ? "home" : "business"
              } state of the user should be the 2 digit state code`;
            else if (
              err.error &&
              (err.error.includes("businessCountry") ||
                err.error.includes("homeCountry"))
            )
              this.errorMessage = `The ${
                err.error.includes("homeCountry") ? "home" : "business"
              } country of the user should be the 2 digit country code`;
            else if (
              err.error &&
              (err.error.includes("birthday") ||
                err.error.includes("roleStartDate") ||
                err.error.includes("jobStartDate"))
            )
              this.errorMessage = `The ${
                err.error.includes("roleStartDate")
                  ? "role start date"
                  : err.error.includes("jobStartDate")
                  ? "job start date"
                  : "birthday"
              } is not a valid date`;
            else this.errorMessage = err.error;
            break;
          default:
            this.activeSlide = this.keys.error;
            this.errorMessage =
              "There was a problem updating the selected person";
            break;
        }
      }
    },
    deleteUser(id) {
      console.log(`DELETION ID ${id}`);
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting person";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "";
      UserService.deleteUser(id, { clientId: this.user.clientId })
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          if (response.data.success) {
            console.log("User deleted");
            this.closeWindow(true);
            this.getUsers();
          } else {
            console.log("Error deleting user");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    sendPasswordResetEmail() {
      // The button shows a loading spinner and then toggles a checkbox to show success for 3 seconds
      this.passwordEmailLoading = true;
      UserService.resetPassword(this.user.originalBusinessEmail).then(
        response => {
          console.log(response);
          this.showLoadingDialog = false;
          this.passwordEmailLoading = false;
          this.passwordEmailSuccess = true;
          setTimeout(() => {
            this.passwordEmailSuccess = false;
          }, 3000);
        }
      );
    },
    sendSMSInviteText() {
      // The button shows a loading spinner and then toggles a checkbox to show success for 3 seconds
      this.textInviteLoading = true;
      CommunicationService.sendSMSInviteText(this.user.userId).then(
        response => {
          console.log("SMS invite response ", response);
          this.showLoadingDialog = false;
          this.textInviteLoading = false;
          this.textInviteSuccess = true;
          setTimeout(() => {
            this.textInviteSuccess = false;
          }, 3000);
        }
      );
    },
    async transferUserToNewClient(userId, clientId) {
      try {
        this.clientTransfer.loading = true;

        console.log("Updating user to new client, ", { userId, clientId });
        var updateResponse = await UserService.transferUserToClientV2(
          clientId,
          userId,
          {
            preserveClient: this.clientTransfer.preserveClient ? 1 : 0,
            screen: "people"
          }
        );

        console.log("User client transfer ", updateResponse);

        this.resetClientTransferForm();
        this.closeWindow(false);
        this.getUsers(this.user.userId);
      } catch (err) {
        this.clientTransfer.loading = false;
        console.log("Error transferring client ", err);
        if (!err || !err.error_code) {
          this.clientTransfer.errorMessage =
            "There was a problem moving clients";
          return;
        }
        switch (err.error_code) {
          case "1030005":
            this.clientTransfer.errorMessage = `The selected client is not set up for Whistle Cards.`;
            break;
          case "1030403":
            this.clientTransfer.errorMessage = `You are unable to migrate to this client.`;
            break;
          case "1030191":
            this.clientTransfer.errorMessage =
              "The selected client could not be found.";
            break;
          default:
            this.clientTransfer.errorMessage =
              "There was a problem moving clients";
            break;
        }
      }
    },
    resetClientTransferForm() {
      this.dialog.clientTransfer = false;
      this.clientTransfer.destinationClientId = null;
      this.clientTransfer.loading = false;
      this.clientTransfer.errorMessage = null;
      this.clientTransfer.preserveClient = false;
    },
    // addEmailPasswordlessAuth() {
    //   // The button shows a loading spinner and then toggles a checkbox to show success for 3 seconds
    //   this.emailPasswordlessLoading = true;

    //   //pass userId and auth type

    //   OnboardingService.addAuthType(
    //     this.user.userId,
    //     "passwordless_email"
    //   ).then(response => {
    //     console.log(response);
    //     this.showLoadingDialog = false;
    //     this.emailPasswordlessLoading = false;
    //     this.emailPasswordlessSuccess = true;
    //     setTimeout(() => {
    //       this.emailPasswordlessSuccess = false;
    //     }, 3000);
    //   });
    // },

    addAuth(type) {
      console.log("add auth type " + type);
      this.authState[type].success = false; //we don't reset this when we're done like with the password reset, so clear it out each time

      //validate to make sure we have the required fields
      if (type == "passwordless_sms" && !this.user.businessPhone) {
        this.errorDialog.display = true;
        this.errorDialog.title =
          "A business phone number is required to add this login method";
        this.errorDialog.subtitle =
          "Please add a phone number, update the user, and try again";
        this.errorDialog.button1Text = "";
        this.errorDialog.button2Text = "OK";
        return false;
      }

      //validation if email is required
      if (
        ["passwordless_email", "password"].includes(type) &&
        !this.user.businessEmail
      ) {
        this.errorDialog.display = true;
        this.errorDialog.title =
          "A business email address is required to add this login method!!!";
        this.errorDialog.subtitle =
          "Please add an email address, update the user, and try again";
        this.errorDialog.button1Text = "";
        this.errorDialog.button2Text = "OK";
        return false;
      }

      this.authState[type].loading = true;

      OnboardingService.addAuthType(this.user.userId, type, null)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          this.authState[type].loading = false;
          this.authState[type].success = true;

          // Then we need to mark the auth boolean as true now
          if (type == "password") {
            this.user.passwordEnabled = true;
            // Reset the invite toggle just in case
            this.userForm.sendInviteToggle = false;
          } else if (type == "passwordless_email")
            this.user.emailPasswordlessEnabled = true;
          else this.user.smsPasswordlessEnabled = true;
          this.getUsers();
          // setTimeout(() => {
          //   this.user = response.result[0];
          // }, 3000);
        })
        .catch(e => {
          console.log("Error setting up auth ", e);
          this.authState[type].loading = false;
          this.showLoadingDialog = false;

          this.errorDialog.display = true;
          this.errorDialog.title =
            "We had trouble updating the user's login method";
          this.errorDialog.subtitle =
            "Please try again or contact us if the problem continues.";
          this.errorDialog.button1Text = "";
          this.errorDialog.button2Text = "Close";
        });
    },

    resetUserForm() {
      Object.assign(this.$data, initialState());
      this.autofillForm(true);
    },
    goBackFromError() {
      this.errorMessage = null;
      this.showUploadResults = false;
      this.activeSlide = this.keys.info;
    },
    autofillForm(refreshRoles = false) {
      var country = this.userProfile.businessCountry;
      if (countries.map(x => x.code).includes(country)) {
        this.user.businessCountry = country;
      } else {
        this.user.businessCountry = "00";
        this.user.otherBusinessCountry = country;
      }

      if (this.clientId) {
        console.log("Setting clientId to predefined");
        this.user.clientId = this.clientId;
      } else {
        console.log("Setting clientId to user clientId");
        this.user.clientId = this.userProfile.clientId;
      }

      if (
        !this.user.organization &&
        this.user.clientId == this.userProfile.clientId
      )
        this.user.organization = this.userProfile.organization;

      if (this.permissions.includes("users:read:role") && refreshRoles)
        this.getRoles();

      // Prefill scheduled onboarding
      this.user.scheduledOnboardingDate = moment().format("YYYY-MM-DDTHH:mm");
      this.assignParticipantRole();
    },
    uploadProfilePic() {
      this.userForm.isSelectingProfilePic = true;
      window.addEventListener(
        "focus",
        () => {
          this.userForm.isSelectingProfilePic = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    deleteProfilePicture() {
      this.userForm.profilePicFile = null;
      this.userForm.profilePicObject = null;
      this.user.profilePicUrl = null;

      if (this.editingUser && this.user.userId) {
        console.log("Deleting");
        UserService.deleteProfilePicture(this.user.userId, this.user.clientId)
          .then(imageResponse => {
            console.log(imageResponse);
            this.$store.state.userProfile.profilePicUrl = null;

            if (this.user.userId === this.userProfile.userId) {
              // We manually remove the profile image if the user is editing themselves
              console.log("User is editing themselves");
              this.$store.state.userProfile.profilePicUrl = null;
              var userIndex = this.users.findIndex(
                x => x.userId == this.userProfile.userId
              );
              if (userIndex != -1) {
                this.users[userIndex].profilePicUrl = null;
              }
            }
          })
          .catch(error => {
            this.showLoadingDialog = false;
            console.log("Error deleting image: ", error);
          });
      }
    },

    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.userForm.profilePicObject = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.userForm.imageURLFileName);
          this.userForm.profilePicFile = file;

          //Regardless, we close dialog
          this.userForm.dialogImageCropper = false;
          this.userForm.imageURLToCrop = null;
          this.userForm.imageURLFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.userForm.dialogImageCropper = false;
        this.userForm.imageURLToCrop = null;
        this.userForm.imageURLFileName = null;
      }
    },
    dragAndDropCSVFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        console.log(e.dataTransfer.files[0]);
        if (e.dataTransfer.files[0].name.endsWith(".csv"))
          this.file = e.dataTransfer.files[0];
      }
    },
    routeToRoles() {
      // this.$router.push({ name: "accountManagement" });
      let routeData = this.$router.resolve({
        name: "accountManagement",
        query: { tab: "roles" }
      });
      window.open(routeData.href, "_blank");
    },
    toggleOnboardingCheckbox() {
      if (
        this.userForm.sendInviteToggle &&
        !this.user.scheduledOnboardingDate
      ) {
        this.user.scheduledOnboardingDate = moment().format("YYYY-MM-DDTHH:mm");
      }

      // refresh validation so when they change, it removes any old validation from phone or email
      if (this.$refs.userForm) this.$refs.userForm.validate();
    },
    // businessPhoneValidation: function({ number, isValid }) {
    //   console.log("businessPhoneValidation", { number, isValid });
    //   // Do stuff with the arguments passed by the vue-tel-input component
    //   // console.log("Business phone validation: ", number);
    //   // if (isValid) {
    //   //   this.user.formattedBusinessPhone = number.e164; //rfc3966.replace("tel:", "");
    //   //   this.businessPhoneNumberValid = true;
    //   // } else {
    //   //   this.businessPhoneNumberValid = false;
    //   // }
    // },
    updateBusinessPhone: function(num, validation) {
      console.log("updateBusinessPhone", { num, validation });
      const { isValid, number } = validation;
      console.log({ isValid, number });
      // if (!this.user.businessPhone || !this.businessPhoneNumberValid) {
      //   this.user.formattedBusinessPhone = null;
      //   this.businessPhoneNumberValid = false;
      // }

      this.businessPhoneNumberValid = !!isValid;
      if (isValid) {
        this.user.formattedBusinessPhone = number.e164;
      } else {
        this.user.formattedBusinessPhone = null;
      }
      // this.businessPhoneNumberValid = false;
    },
    businessCountryCodeChanged(country) {
      console.log("Business country code changed ", country);
      this.businessCountryCode.code = country.iso2;
      this.businessCountryCode.value = country.dialCode;
    },
    expansionPanelOpened(e) {
      console.log("Additional settings opened", e);
      if (e !== undefined && this.$refs.userForm)
        // this.$refs.userForm.validate();
        // Set a timeout so the form has aa moment to set itself up and then we validate
        setTimeout(() => {
          if (this.$refs.userForm) this.$refs.userForm.validate();
        }, 500);
    },
    loadFileWizard() {
      this.$router.push({
        name: "peoplefilewizard",
        params: {
          roles: this.roles || this.roleArray
        }
      });
    },
    assignParticipantRole() {
      // Assigns Participant role by default
      if (this.editingUser) return;
      const participantRole = this.roles.find(
        r => r.displayName === "Participant"
      );
      if (!participantRole) return;
      const roleAssigned = this.user?.roles?.find(
        r => r === participantRole?.id
      );
      if (!roleAssigned) this.user.roles.push(participantRole.id);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"]),
    visibleRoles() {
      // We grab the props directly in the scenario that the parent component is still fetching the roles.
      // Then once they're returned, we fill them into the array (so we don't have to watch for the response)
      var tempRoles = [];
      if (this.roles) tempRoles = this.roles;
      else tempRoles = this.roleArray;
      console.log("ROLES: ", tempRoles);
      var roles = JSON.parse(JSON.stringify(tempRoles));
      // .filter
      // x => x.name != "Participant"
      // ();
      if (this.user.clientId != 1) {
        return roles.filter(x => !x.name.includes("Whistle"));
      }

      return roles;
    },
    validScheduledOnboarding() {
      if (
        !this.userForm.sendInviteToggle ||
        (this.userForm.sendInviteToggle &&
          this.user.scheduledOnboardingDate &&
          moment(this.user.scheduledOnboardingDate).isValid())
      )
        return true;
      else return false;
    },
    ssoEnabled() {
      return !!(
        this.editingUser && this.editUserObject?.userId?.startsWith("oauth2|")
      );
    },
    passwordEnabled() {
      return !!(
        (this.editingUser &&
          this.editUserObject &&
          this.editUserObject.passwordEnabled) ||
        this.userForm.userTypeRadioGroup == "PASSWORD" ||
        (this.user && this.user.passwordEnabled)
      );
    },
    emailPasswordlessEnabled() {
      return !!(
        (this.editingUser &&
          this.editUserObject &&
          this.editUserObject.emailPasswordlessEnabled) ||
        this.userForm.userTypeRadioGroup == "PASSWORDLESS_EMAIL" ||
        (this.user && this.user.emailPasswordlessEnabled)
      );
    },
    smsPasswordlessEnabled() {
      return !!(
        (this.editingUser &&
          this.editUserObject &&
          this.editUserObject.smsPasswordlessEnabled) ||
        this.userForm.userTypeRadioGroup == "PASSWORDLESS_SMS" ||
        (this.user && this.user.smsPasswordlessEnabled)
      );
      // return false;
    },
    displayInviteToggle() {
      return (
        // !this.ssoOnly &&
        // Show for emails if we haven't sent the email before
        (this.passwordEnabled && !this.user.onboardingEmailSent) ||
        // For SMS, we only show if they're SMS and NOT password and we haven't sent the text yet
        (!this.passwordEnabled &&
          this.smsPasswordlessEnabled &&
          !this.user.onboardingEmailSent &&
          !this.user.onboardingTextSent)
      );
    },
    displayClientTransfer() {
      return !!(
        this.editingUser &&
        this.userProfile &&
        this.userProfile.clientId == 1 &&
        this.clients &&
        this.clients.length > 1 &&
        !this.editingExternalUser
      );
    },
    devEnvironment() {
      return !!(
        process.env.VUE_APP_ENVIRONMENT == "dev" ||
        process.env.VUE_APP_ENVIRONMENT == "test"
      );
    },
    displayAnnualPaymentCap() {
      const otherClient = (this.clients || []).find(
        x => x.clientId == this.clientId
      );
      return (
        !!(this.permissions || []).find(
          x => x == "users:create:annualpaymentcap"
        ) &&
        ((!otherClient && this?.userProfile?.Client?.annualPaymentCap) ||
          otherClient?.annualPaymentCap)
      );
    },
    editingExternalUser() {
      return !!this.user.externalUser;
    },
    whistleAdmin() {
      return !!(
        this.userProfile &&
        this.userProfile.businessEmail &&
        this.userProfile.businessEmail.includes("@wewhistle.com")
      );
    },
    editingClientName() {
      if (
        !this.whistleAdmin ||
        !this.editingUser ||
        !this.user?.primaryClientId
      )
        return null;
      return (
        this.clients?.find(c => c.clientId == this.user?.primaryClientId)
          ?.clientName || "N/A"
      );
    },
    visibleTabs() {
      let tabs = [{ key: this.keys.info, label: "Info", hidden: false }];
      console.log("Tab Permissions", {
        admin: this.isFinanceAdminOrHigher,
        external: this.editingExternalUser
      });
      if (!this.editingExternalUser)
        tabs.push({
          key: this.keys.roles,
          label: "Permissions",
          hidden: false
        });
      if (this.isFinanceAdminOrHigher && this.editingUser)
        tabs.push({
          key: this.keys.received,
          label: "Received",
          hidden: false
        });
      if (
        this.isFinanceAdminOrHigher &&
        this.editingUser &&
        !this.editingExternalUser
      )
        tabs.push({
          key: this.keys.spent,
          label: "Spent",
          hidden: false
        });

      // Fills in empty tabs because we're using justify-space-between
      for (var i = tabs.length; i < 4; i++) {
        tabs.push({ key: i * 10, label: "xxxxxxx", hidden: true });
      }

      return tabs;
    }
  },
  watch: {
    roles: function() {
      this.assignParticipantRole();
    },
    roleArray: function() {
      this.assignParticipantRole();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /* min-width: 235px; */
  /* color: rgba(0, 0, 0, 0.38); */
}

.auth-type.v-btn.v-btn--disabled {
  background: rgba(255, 255, 255, 0) !important;
  border: 1px solid rgba(255, 255, 255, 0);
}

/* Adds border around profile picture */
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}

/* Locks size of placeholder profile picture */
.profile-picture.icon {
  height: 45px;
  width: 45px;
}

/* Tabs for different user sections */
.tab-button {
  cursor: pointer;
  padding: 6px 8px 6px 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  user-select: none;
}
.tab-button:hover {
  background-color: rgba(237, 237, 237, 0.7);
}
</style>
