<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brandCyan" rounded="0">
          <v-toolbar-title
            >Build a Learning Module
            {{
              userProfile.clientId == 1 ? preloadedLearningModuleId : ""
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="
                if (hasEditedModule && slideKey < keys.publishing) {
                  dialog.exit = true;
                } else {
                  closeLearningBuilder();
                }
              "
            >
              Cancel
              <v-icon class="ml-2">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="mt-4 mb-8" no-gutters justify="center" height="90">
          <v-col
            cols="12"
            sm="12"
            md="7"
            lg="7"
            xl="7"
            class="text-left d-flex justify-space-between"
          >
            <v-btn
              :class="isMobile ? 'ml-4' : 'ml-n4'"
              outlined
              :color="
                slideKey == keys.basics || slideKey >= keys.publishing
                  ? 'mediumGrey'
                  : 'brandCyan'
              "
              @click="goToPreviousSlide"
              :disabled="slideKey == keys.basics || slideKey >= keys.publishing"
              ><v-icon class="mr-1">mdi-chevron-left</v-icon>Back</v-btn
            >

            <div
              class="d-flex justify-end"
              :class="isMobile ? 'mr-4' : 'mr-n4'"
            >
              <div
                class="d-flex text-left mx-2 duplicate-warning-box pa-1 word-break"
                v-if="
                  (slideKey == keys.cardEditor ||
                    slideKey == keys.quizEditor) &&
                    !disableContinueButton &&
                    learningCard.LearningCardAssociations &&
                    learningCard.LearningCardAssociations.length > 1
                "
              >
                <v-icon color="error" class="mr-1">mdi-alert</v-icon>
                <p class="mb-0">
                  This
                  {{
                    learningCard.type &&
                    ["quiz", "assessment", "survey"].includes(learningCard.type)
                      ? learningCard.type
                      : "card"
                  }}
                  is used in
                  {{ learningCard.LearningCardAssociations.length }} modules and
                  editing will affect all copies.
                  <br />
                  <a
                    @click="
                      if (slideKey == keys.cardEditor) {
                        dialog.duplicateCard = true;
                        createLearningCard();
                      } else {
                        dialog.duplicateQuiz = true;
                        createLearningQuiz();
                      }
                    "
                    >Click here to clone.</a
                  >
                </p>
              </div>
              <v-tooltip
                top
                :disabled="!disableContinueButton || !continueButtonTooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      :outlined="disableContinueButton"
                      color="brandCyan"
                      class="white--text"
                      depressed
                      @click="goToNextSlide(false)"
                      :disabled="disableContinueButton"
                      >{{
                        route[route.findIndex(x => x == slideKey) + 1] ==
                        keys.review
                          ? "Review"
                          : slideKey == keys.review && !draft
                          ? "Publish"
                          : slideKey == keys.publishing || slideKey == keys.sent
                          ? "Done"
                          : "Save"
                      }}<v-icon class="ml-1">mdi-chevron-right</v-icon></v-btn
                    >
                  </div>
                </template>
                <span class="my-2">{{ continueButtonTooltip }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center full-height"
          no-gutters
          justify="center"
          height="100%"
        >
          <v-col
            cols="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            class="full-height text-left word-break"
            :class="isMobile ? '' : 'mr-8'"
          >
            <!-- <p class="grey--text mt-8">Activity card preview</p> -->
            <!-- <div class="d-flex align-end word-break"> -->
            <v-card-title
              class="text-left word-break mb-0 pb-0 pl-0"
              :class="isMobile ? 'mx-4' : ''"
              >{{ slideTitle }}</v-card-title
            >
            <!-- </div> -->
            <v-divider class="mt-8" />
            <div class="my-6 full-width" :class="isMobile ? 'px-3' : ''">
              <div
                class="d-flex align-center cursor-pointer"
                @click="goToSlide(keys.basics)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': learningModule.name,
                    'light-grey-background': !learningModule.name
                  }"
                  color="white"
                  >mdi-school</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': learningModule.name }"
                >
                  {{ learningModule.name || "Module name" }}
                </div>
              </div>
              <div
                class="d-flex align-center mt-2 cursor-pointer"
                @click="goToSlide(keys.audience)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': audience.length > 0,
                    'light-grey-background': !(audience.length > 0)
                  }"
                  color="white"
                  >mdi-account-multiple</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{
                    'side-nav-bold-text': audience.length > 0
                  }"
                >
                  {{
                    audience.length
                      ? audience.length +
                        (audience.length == 1 ? " group" : " groups")
                      : preloadedLearningModuleId && loading.groups == true
                      ? "Loading"
                      : "Select who can access this"
                  }}
                  <v-progress-circular
                    v-if="preloadedLearningModuleId && loading.groups == true"
                    :width="2"
                    :size="16"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </div>
              <div
                class="d-flex align-start mt-2 cursor-pointer"
                @click="goToSlide(keys.elements)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': learningChapters.length > 0,
                    'light-grey-background': !(learningChapters.length > 0)
                  }"
                  color="white"
                  >mdi-book-open-blank-variant</v-icon
                >
                <div
                  class="d-flex flex-column ml-2 px-3 cursor-pointer module-elements-box module-elements-outline"
                  :class="{
                    'side-nav-bold-text': learningChapters.length > 0,
                    'module-elements-height': learningChapters.length == 0
                  }"
                >
                  <div class="d-flex justify-space-between">
                    {{ "Chapters (" + (learningChapters.length + 1) + ")" }}
                    <v-btn small loading icon v-if="loading.elements"></v-btn>
                    <span
                      class="primary--text mr-1"
                      v-else-if="learningModule.learningModuleId"
                      @click.stop="editChapter(null)"
                      >+ Chapter</span
                    >
                  </div>
                  <div class="my-2">
                    <!-- <div
                      class="mt-1 mb-1 module-elements-box module-elements-outline primary--text text-center"
                      @click.stop="editChapter(null)"
                    >
                      + Chapter
                    </div> -->
                    <draggable
                      :list="learningChapters"
                      group="elements"
                      @change="updateChapterOrder"
                    >
                      <div
                        v-for="(chapter, chapterIndex) in learningChapters"
                        :key="`${chapter.learningChapterId}-${chapterIndex}`"
                        class="d-flex flex-column"
                      >
                        <!-- <div
                          v-if="chapter.order == -1"
                          class="mt-1 mb-1 module-elements-box module-elements-outline primary--text text-center"
                          @click.stop="editChapter(null)"
                        >
                          Add Chapter
                        </div> -->
                        <div
                          class="chapter-box module-elements-outline px-3 my-1"
                          :class="{
                            'module-elements-height': !expanded.chapters.find(
                              x => chapter.learningChapterId == x
                            )
                          }"
                        >
                          <v-hover v-slot="{ hover }">
                            <div
                              class="d-flex align-center module-elements-height"
                              @click.stop="
                                toggleLearningChapter(chapter.learningChapterId)
                              "
                            >
                              <div
                                class="d-flex justify-space-between align-center module-elements-box  pt-0"
                              >
                                <span
                                  class="module-element-label"
                                  :title="
                                    chapter.displayName || 'Unknown chapter'
                                  "
                                  >{{
                                    chapter.displayName || "Unknown chapter"
                                  }}
                                </span>
                                <v-btn
                                  v-if="hover && slideKey < keys.publishing"
                                  class="mt-n2"
                                  icon
                                  x-small
                                  @click.stop.native="editChapter(chapter)"
                                >
                                  <v-icon
                                    color="
                                  grey
                                "
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </v-btn>

                                <v-btn
                                  icon
                                  x-small
                                  v-if="
                                    hover &&
                                      slideKey < keys.publishing &&
                                      chapter.order != -1
                                  "
                                  class="mt-n2 mr-1"
                                  :disabled="
                                    slideKey == keys.cardEditor ||
                                      slideKey == keys.quizEditor
                                  "
                                  @click.stop.native="
                                    dialog.elementDeleteConfirmation = true;
                                    dialog.chapterDeleteId =
                                      chapter.learningChapterId;
                                    dialog.cardDeleteId = null;
                                    dialog.elementDeleteContext = 'chapter';
                                  "
                                >
                                  <v-icon color="red" small
                                    >mdi-trash-can-outline</v-icon
                                  >
                                </v-btn>
                                <v-icon
                                  v-if="
                                    hover &&
                                      slideKey < keys.publishing &&
                                      chapter.order != -1
                                  "
                                  small
                                  class="mt-n2 cursor-grab"
                                  >mdi-drag-horizontal-variant</v-icon
                                >
                                <v-icon
                                  @click.stop.native="
                                    toggleLearningChapter(
                                      chapter.learningChapterId
                                    )
                                  "
                                  class="mt-n2"
                                  id="program-element-expander"
                                  >{{
                                    expanded.chapters.find(
                                      x => x == chapter.learningChapterId
                                    )
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  }}</v-icon
                                >
                              </div>
                            </div>
                          </v-hover>

                          <div
                            class="d-flex flex-column"
                            v-if="
                              expanded.chapters.find(
                                x => x == chapter.learningChapterId
                              )
                            "
                          >
                            <draggable
                              v-if="chapter.order != -1"
                              :list="chapter.LearningCards || []"
                              group="cards"
                              @change="updateCardOrder"
                            >
                              <div
                                v-for="(card,
                                cardIndex) in chapter.LearningCards || []"
                                :key="`${card.learningCardId}-${cardIndex}`"
                                class="px-3 my-1 module-elements-outline"
                                @click.stop="
                                  editCard(chapter.learningChapterId, card)
                                "
                              >
                                <v-hover v-slot="{ hover }">
                                  <div
                                    class="d-flex justify-space-between align-center module-elements-box module-elements-height pt-0"
                                  >
                                    <span
                                      class="module-element-label"
                                      :title="
                                        card.displayName || 'Unknown card'
                                      "
                                      >{{ card.displayName || "Unknown card" }}
                                    </span>
                                    <v-btn
                                      v-if="hover && slideKey < keys.publishing"
                                      icon
                                      x-small
                                      @click.stop.native="
                                        editCard(
                                          chapter.learningChapterId,
                                          card
                                        )
                                      "
                                    >
                                      <v-icon
                                        color="
                                  grey
                                "
                                        small
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      icon
                                      x-small
                                      v-if="
                                        hover &&
                                          slideKey < keys.publishing &&
                                          chapter.order != -1
                                      "
                                      class="mx-1"
                                      :disabled="
                                        slideKey == keys.cardEditor ||
                                          slideKey == keys.quizEditor
                                      "
                                      @click.stop.native="
                                        dialog.elementDeleteConfirmation = true;
                                        dialog.cardDeleteId = card.id;
                                        dialog.elementDeleteContext = 'card';
                                      "
                                    >
                                      <v-icon color="red" small
                                        >mdi-trash-can-outline</v-icon
                                      >
                                    </v-btn>
                                  </div>
                                </v-hover>
                              </div>
                            </draggable>
                            <div v-if="chapter.order == -1">
                              <div
                                v-for="card in chapter.LearningCards || []"
                                :key="card.learningCardId"
                                class="px-3 my-1 module-elements-outline"
                                @click.stop="
                                  editCard(chapter.learningChapterId, card)
                                "
                              >
                                <v-hover v-slot="{ hover }">
                                  <div
                                    class="d-flex justify-space-between align-center module-elements-box module-elements-height pt-0"
                                  >
                                    <span
                                      class="module-element-label"
                                      :title="
                                        card.displayName || 'Unknown card'
                                      "
                                      >{{ card.displayName || "Unknown card" }}
                                    </span>
                                    <v-btn
                                      v-if="hover && slideKey < keys.publishing"
                                      icon
                                      x-small
                                      @click.stop.native="
                                        editCard(
                                          chapter.learningChapterId,
                                          card
                                        )
                                      "
                                    >
                                      <v-icon
                                        color="
                                  grey
                                "
                                        small
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                  </div>
                                </v-hover>
                              </div>
                            </div>
                            <div
                              class="d-flex justify-space-between primary--text mb-2"
                              v-if="chapter.order != -1"
                            >
                              <div
                                class="module-elements-outline px-2 py-1"
                                @click.stop="
                                  editCard(chapter.learningChapterId, null)
                                "
                              >
                                + Card
                              </div>
                              <div
                                class="module-elements-outline px-2 py-1"
                                @click.stop="
                                  editCard(
                                    chapter.learningChapterId,
                                    null,
                                    true
                                  )
                                "
                              >
                                + Quiz
                              </div>
                              <div
                                class="module-elements-outline px-2 py-1"
                                @click.stop="
                                  loadCardLibrary(chapter.learningChapterId)
                                "
                              >
                                Library
                              </div>
                            </div>
                            <!-- <div
                              v-if="chapter.order != -1"
                              class="my-1 module-elements-box module-elements-outline primary--text text-center"
                              @click.stop="
                                editCard(chapter.learningChapterId, null)
                              "
                            >
                              Add card
                            </div>
                            <div
                              v-if="chapter.order != -1"
                              class="my-1 module-elements-box module-elements-outline primary--text text-center"
                              @click.stop="
                                editCard(chapter.learningChapterId, null, true)
                              "
                            >
                              Add quiz
                            </div>
                            <div
                              v-if="chapter.order != -1"
                              class="mt-1 mb-2 module-elements-box module-elements-outline primary--text text-center"
                              @click.stop="
                                loadCardLibrary(chapter.learningChapterId)
                              "
                            >
                              Add from library
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </draggable>
                    <div
                      class="d-flex flex-column module-elements-box module-elements-outline px-3 mt-1 mb-2"
                      :class="{
                        'module-elements-height': !expanded.resources
                      }"
                    >
                      <div
                        class="d-flex align-center module-elements-height"
                        @click.stop="expanded.resources = !expanded.resources"
                      >
                        <div
                          class="d-flex justify-space-between align-center module-elements-box  pt-0"
                        >
                          <span class="module-element-label"
                            >Additional Resources ({{ resources.length }})
                          </span>

                          <v-icon
                            @click.stop.native="
                              expanded.resources = !expanded.resources
                            "
                            class="mt-n2"
                            id="program-element-expander"
                            >{{
                              expanded.resources
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}</v-icon
                          >
                        </div>
                      </div>
                      <div class="d-flex flex-column" v-if="expanded.resources">
                        <draggable
                          :list="resources || []"
                          group="resources"
                          @change="updateResourceOrder"
                        >
                          <div
                            v-for="(resource, resourceIndex) in resources || []"
                            :key="
                              `${resource.learningModuleAttachmentId}-${resourceIndex}`
                            "
                            class=""
                            @click.stop="loadResourceWidget"
                          >
                            <v-hover v-slot="{ hover }">
                              <div class="d-flex align-center my-2">
                                <v-icon
                                  class="side-nav-icons brand-background"
                                  color="white"
                                  >{{ resource.icon }}</v-icon
                                >
                                <div
                                  class="d-flex justify-space-between align-center module-elements-box module-elements-outline module-elements-height pt-0 ml-2 px-3"
                                >
                                  <span
                                    class="module-element-label"
                                    :title="
                                      resource.displayName || 'Unknown resource'
                                    "
                                    >{{
                                      resource.displayName || "Unknown resource"
                                    }}
                                  </span>

                                  <v-btn
                                    icon
                                    x-small
                                    v-if="hover && slideKey < keys.publishing"
                                    class="mx-1"
                                    @click.stop.native="
                                      dialog.elementDeleteConfirmation = true;
                                      dialog.resourceDeleteId =
                                        resource.learningModuleAttachmentId;
                                      dialog.elementDeleteContext = 'resource';
                                    "
                                  >
                                    <v-icon color="red" small
                                      >mdi-trash-can-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </div>
                            </v-hover>
                          </div>
                        </draggable>

                        <div
                          class="mb-2 module-elements-box module-elements-outline primary--text text-center"
                          @click.stop="loadResourceWidget"
                        >
                          Add Resource
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="mt-1 mb-2 module-elements-box module-elements-outline primary--text text-center"
                      @click.stop="editChapter(null)"
                    >
                      Add Chapter
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-center mt-2 cursor-pointer"
                @click="goToSlide(keys.review)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': slideKey >= keys.review,
                    'light-grey-background': !(slideKey >= keys.review)
                  }"
                  color="white"
                  >mdi-rocket-launch</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': slideKey >= keys.review }"
                >
                  Confirm and launch
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class=" d-flex flex-column align-start mb-6"
          >
            <transition :name="slideDirection" mode="out-in">
              <div
                v-if="slideKey === keys.basics"
                :key="keys.basics"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div></div>
                  <v-autocomplete
                    outlined
                    class="mt-2 full-width"
                    :items="clients"
                    item-text="formattedName"
                    item-value="clientId"
                    label="Client"
                    color="brandCyan"
                    v-model="clientId"
                    v-if="userProfile.clientId === 1"
                    :disabled="learningModule.learningModuleId ? true : false"
                    @change="updateClientId"
                  >
                  </v-autocomplete>
                  <v-text-field
                    v-model="learningModule.name"
                    class="mt-2 full-width"
                    outlined
                    label="Module name"
                    color="brandCyan"
                    :required="true"
                    :rules="[
                      v => !!v || 'A module name is required',
                      v =>
                        !!(v && 150 > v.length) ||
                        'We recommend making your module name shorter'
                    ]"
                  ></v-text-field>
                  <!-- <v-textarea
                    label="Brief module description"
                    class="full-width"
                    outlined
                    color="brandCyan"
                    v-model="learningModule.description"
                    :rules="[
                      v => !!v || 'A description is required',
                      v =>
                        !!(
                          v &&
                          formattedModuleDescription &&
                          formattedModuleDescription.length < 240
                        ) || 'Your description should be shorter'
                    ]"
                  ></v-textarea> -->
                  <TextEditor
                    class="mt-n8 mb-6"
                    :shortcodes="false"
                    :programRelated="false"
                    :largeVersion="false"
                    placeholder="Brief module description"
                    :editorContent="learningModule.description"
                    @update-message="learningModule.description = $event"
                  />
                  <p
                    v-if="learningModule.description?.length > 240"
                    class="text-left error--text mt-n5"
                  >
                    Your module description should be shorter
                  </p>
                  <div class="d-flex align-start full-width">
                    <v-combobox
                      outlined
                      multiple
                      chips
                      deletable-chips
                      return-object
                      hide-no-data
                      hide-selected
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                      :loading="loading.tags || loading.moduleTags"
                      v-model="tags"
                      :items="data.tags"
                      item-text="name"
                      item-value="tagDefinitionId"
                      color="brandCyan"
                      label="Module tags (optional)"
                      placeholder="Enter a Tag"
                      class="full-width mr-3"
                    />
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mt-5"
                          color="gray"
                          v-on="on"
                          v-bind="attrs"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <p class="my-1">
                        Tags can be used as a way to classify modules and filter
                        the library.
                      </p>
                    </v-tooltip>
                  </div>
                  <v-menu max-width="260" offset-x offset-y absolute>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="full-width image-container">
                        <v-img
                          :lazy-src="image.url"
                          :src="image.url"
                          :key="image.url"
                          :aspect-ratio="16 / 9"
                          v-bind="attrs"
                          v-on="on"
                          width="100%"
                          max-width="100%"
                          class="cursor-pointer"
                          @drop.prevent="imageFileChanged($event, true)"
                          @dragover.prevent
                        >
                          <template v-slot:placeholder>
                            <div
                              class="light-grey-background full-height full-width"
                            >
                              <h3 class=" pt-5 primary--text">
                                Add a banner photo
                              </h3>

                              <p class="mt-10">
                                We suggest an image size of 740 x 416 pixels (Or
                                any image with a 16 : 9 aspect ratio).
                              </p>
                              <p>
                                JPG, PNG files supported
                              </p>

                              <p
                                v-if="image.invalidFileType"
                                class="font-weight-bold"
                              >
                                Invalid file type uploaded
                              </p>
                            </div>
                          </template>
                        </v-img>
                      </div>
                    </template>
                    <v-list>
                      <input
                        id="uploader"
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="imageFileChanged($event, false)"
                        @blur="imageFileChanged($event, false)"
                      />
                      <v-list-item @click="$refs.uploader.click()">
                        <v-list-item-title class="primary--text text-left"
                          ><v-icon color="black" class="mr-2">mdi-upload</v-icon
                          >Upload from computer</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="removeImage">
                        <v-list-item-title class="primary--text text-left"
                          ><v-icon color="black" class="mr-2">mdi-delete</v-icon
                          >Remove image</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card>
              </div>
              <div
                v-if="slideKey === keys.audience"
                :key="keys.audience"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text font-weight-bold"
                    >Select audience</span
                  >
                  <div class="d-flex align-center full-width mt-5">
                    <v-text-field
                      label="Search"
                      v-model="groupTable.debounceSearch"
                      solo
                      flat
                      dense
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      class="search-field mr-3"
                    >
                    </v-text-field>
                    <v-btn
                      v-if="permissions.includes('groups:create:group')"
                      :width="116"
                      :depressed="true"
                      :outlined="true"
                      color="brandCyan"
                      :height="38"
                      @click="dialog.groupCreator = true"
                      ><v-icon>mdi-plus</v-icon><span> Group</span></v-btn
                    >
                  </div>

                  <div class="d-flex align-center flex-wrap mt-4">
                    <div
                      v-for="(group, index) in audience"
                      :key="group.groupId"
                    >
                      <v-chip
                        class="ma-1"
                        close
                        @click:close="removeGroupFromSelected(index)"
                        >{{ group.groupDisplayName }}</v-chip
                      >
                    </div>
                  </div>
                  <div class="text-left mt-2 full-width">
                    <p class="darkGrey--text ml-4 mb-1">Directory</p>
                    <p class="darkGrey--text ml-4 text-sm">
                      Note: Learning modules don't support external users at
                      this time
                    </p>
                    <v-divider class="mt-4" />
                    <GroupTable
                      ref="group-table"
                      class="full-width"
                      :selected="audience"
                      :search="search.audience"
                      :includeEveryone="true"
                      @select-item="addGroupToSelected($event, false)"
                    />
                  </div>
                </v-card>
                <v-bottom-sheet
                  v-if="dialog.groupInfoSheet"
                  v-model="dialog.groupInfoSheet"
                  inset
                  width="500"
                >
                  <v-sheet
                    class="text-center"
                    :height="
                      data.infoSheetItem.groupId &&
                      (loading.groupAssignments ||
                        !data.infoSheetItem.GroupAssignments ||
                        !data.infoSheetItem.GroupAssignments.length)
                        ? '380px'
                        : '475px'
                    "
                  >
                    <div v-if="data.infoSheetItem" class="pt-4">
                      <div
                        class="d-flex align-center justify-space-between mx-6"
                      >
                        <v-btn fab color="lightGrey" small class="hidden"
                          ><v-icon>mdi-chevron-down</v-icon></v-btn
                        >
                        <v-card-title class="word-break">
                          <b> {{ data.infoSheetItem.groupDisplayName }}</b>
                        </v-card-title>
                        <v-btn
                          fab
                          color="mediumGrey"
                          small
                          elevation="0"
                          @click="
                            dialog.groupInfoSheet = !dialog.groupInfoSheet
                          "
                          ><v-icon color="white"
                            >mdi-chevron-down</v-icon
                          ></v-btn
                        >
                      </div>

                      <div>
                        <div class="d-flex align-center justify-center">
                          <p class="mb-0">
                            {{
                              data.infoSheetItem.groupType + " group " ||
                                "Unknown Group Type "
                            }}
                          </p>
                          <v-icon class="ml-2">mdi-account</v-icon>
                          <h3>
                            {{
                              data.infoSheetItem.GroupAssignments
                                ? data.infoSheetItem.GroupAssignments.length
                                : 0
                            }}
                          </h3>
                        </div>
                        <p
                          v-if="
                            !loading.groupAssignments &&
                              (!data.infoSheetItem.GroupAssignments ||
                                !data.infoSheetItem.GroupAssignments.length)
                          "
                          class="mx-5 mt-7"
                        >
                          It looks like there aren't any people in this group.
                          Try choosing a different group.
                        </p>
                        <v-virtual-scroll
                          height="250"
                          class="mt-2 mb-5"
                          item-height="45"
                          :items="data.infoSheetItem.GroupAssignments"
                          v-else-if="!loading.groupAssignments"
                        >
                          <template v-slot:default="{ item }">
                            <v-list-item
                              :key="`${item.groupId}-${item.userId}`"
                            >
                              <v-list-item-content
                                class="text-left ml-2 mt-2 text-overflow-clip"
                              >
                                <span>{{ item.User.displayName }}</span>
                              </v-list-item-content>
                              <v-list-item-content
                                class="text-left mt-2 text-overflow-clip"
                              >
                                <span>{{
                                  item.User.businessEmail ||
                                    item.User.businessPhone
                                }}</span></v-list-item-content
                              >
                            </v-list-item>
                            <v-divider class="mx-5" />
                          </template>
                        </v-virtual-scroll>
                        <div
                          class="d-flex flex-column align-items-center mt-4"
                          v-else
                        >
                          <Robin
                            :showText="false"
                            :width="160"
                            :height="160"
                            animation="flossing"
                            :loop="true"
                            class="mt-2 mx-auto"
                          />
                          <p>Grabbing users in the group, hold tight...</p>
                        </div>
                      </div>
                      <v-btn
                        color="brandCyan"
                        class="white--text"
                        rounded
                        depressed
                        width="180"
                        @click="addGroupToSelected(data.infoSheetItem, true)"
                        >Add to module</v-btn
                      >
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
              <div
                v-if="slideKey === keys.elements"
                :key="keys.elements"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  rounded="0"
                  elevation="3"
                  width="100%"
                  min-height="550"
                >
                  <span class="header-text font-weight-bold mb-6">
                    Create, view, and edit module elements
                  </span>
                  <p>
                    Use the + buttons
                    {{ isMobile ? "above" : "on the left" }} to add new learning
                    elements to your module, or click an existing learning
                    element to edit.
                  </p>
                  <p>
                    You can also drag and drop elements and chapters to
                    rearrange.
                  </p>
                  <div class="mt-9 full-width"></div>
                </v-card>
              </div>
              <div
                v-else-if="slideKey === keys.cardEditor"
                :key="keys.cardEditor"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div></div>
                  <v-form v-model="form.card" class="full-width">
                    <v-text-field
                      v-model="learningCard.displayName"
                      class="mt-2 full-width"
                      outlined
                      label="Card Title"
                      color="brandCyan"
                      :required="true"
                      :rules="[
                        v => !!v || 'A card title is required',
                        v =>
                          !!(v && 256 > v.length) ||
                          'Your card title should be shorter'
                      ]"
                    ></v-text-field>
                    <v-select
                      v-model="learningCard.type"
                      outlined
                      label="Learning Card Type"
                      color="brandCyan"
                      :items="filteredCardTypes"
                      item-text="label"
                      item-value="value"
                      @change="removeCardMedia"
                      class="full-width"
                      attach
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                    <vue-editor
                      class="full-width"
                      v-model="learningCard.content"
                      :editorToolbar="editorToolbarWithImages"
                      placeholder="Card content"
                    ></vue-editor>
                    <v-menu max-width="260" offset-x offset-y absolute>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="full-width image-container mt-5">
                          <v-img
                            v-if="
                              ![
                                'audio',
                                'video',
                                'video_embed',
                                'pdf'
                              ].includes(learningCard.type)
                            "
                            :lazy-src="cardMedia.url"
                            :src="cardMedia.url"
                            :key="cardMedia.url"
                            :aspect-ratio="16 / 9"
                            v-bind="attrs"
                            v-on="on"
                            width="100%"
                            max-width="100%"
                            class="cursor-pointer"
                            @drop.prevent="imageFileChanged($event, true)"
                            @dragover.prevent
                          >
                            <template v-slot:placeholder>
                              <div
                                class="light-grey-background full-height full-width"
                              >
                                <h3 class=" pt-5 primary--text">
                                  Add an image
                                </h3>

                                <p class="mt-10">
                                  JPG, PNG files supported
                                </p>
                                <p
                                  v-if="cardMedia.invalidFileType"
                                  class="font-weight-bold"
                                >
                                  Invalid file type uploaded
                                </p>
                              </div>
                            </template>
                          </v-img>
                          <div
                            class="light-grey-background full-width cursor-pointer pb-5"
                            v-else-if="learningCard.type == 'audio'"
                            v-bind="attrs"
                            v-on="on"
                            @drop.prevent="imageFileChanged($event, true)"
                            @dragover.prevent
                          >
                            <h3 class=" pt-5 primary--text">
                              Add an audio file
                            </h3>

                            <p class="mt-10">
                              Audio File attached:
                            </p>
                            <p v-if="cardMedia.invalidFileType">
                              Invalid file type uploaded
                            </p>
                            <p
                              v-else-if="
                                !cardMedia.file && !learningCard.bannerContentId
                              "
                            >
                              No file
                            </p>
                            <p
                              v-else-if="cardMedia.file && cardMedia.file.name"
                            >
                              <b> {{ cardMedia.file.name }}</b>
                            </p>
                            <p v-else><b>Audio file previously uploaded</b></p>
                          </div>
                          <div
                            class="light-grey-background full-width cursor-pointer pb-5"
                            v-else-if="learningCard.type == 'pdf'"
                            v-bind="attrs"
                            v-on="on"
                            @drop.prevent="imageFileChanged($event, true)"
                            @dragover.prevent
                          >
                            <h3 class=" pt-5 primary--text">
                              Add a PDF
                            </h3>

                            <p class="mt-10">
                              PDF attached:
                            </p>
                            <p v-if="cardMedia.invalidFileType">
                              Invalid file type uploaded
                            </p>
                            <p
                              v-else-if="
                                !cardMedia.file && !learningCard.bannerContentId
                              "
                            >
                              No file
                            </p>
                            <p
                              v-else-if="cardMedia.file && cardMedia.file.name"
                            >
                              <b> {{ cardMedia.file.name }}</b>
                            </p>
                            <p v-else><b>PDF previously uploaded</b></p>
                          </div>
                          <div
                            class="d-flex flex-column full-width"
                            v-else-if="learningCard.type == 'video_embed'"
                            @drop.prevent="imageFileChanged($event, true)"
                            @dragover.prevent
                          >
                            <v-text-field
                              v-model="learningCard.contentUrl"
                              class="mt-5 mb-1"
                              color="brandCyan"
                              outlined
                              label="Youtube or Vimeo link"
                              :required="true"
                              :rules="[
                                v =>
                                  !!(!v || (v && 256 > v.length)) ||
                                  'The link should be shorter'
                              ]"
                            ></v-text-field>
                            <v-expansion-panels multiple flat>
                              <v-expansion-panel :key="0" class="px-0">
                                <v-expansion-panel-header class="pl-1 pr-2">
                                  Video Transcript (optional)
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="px-0 mt-5">
                                  <vue-editor
                                    v-model="learningCard.transcript"
                                    outlined
                                    class="full-width"
                                    label="Video transcript (optional)"
                                    :editorToolbar="editorToolbar"
                                    :editorOptions="quillOptions"
                                    :required="false"
                                  ></vue-editor>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <div class="mt-3" v-if="learningCard.contentUrl">
                              <iframe
                                id="ytplayer"
                                width="300"
                                height="160"
                                :src="
                                  formatVideoEmbedSource(
                                    learningCard.contentUrl
                                  )
                                "
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column full-width"
                            v-else
                            @drop.prevent="imageFileChanged($event, true)"
                            @dragover.prevent
                          >
                            <div
                              class="light-grey-background full-width cursor-pointer pb-5 mb-4"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <h3 class=" pt-5 primary--text">
                                Add a video
                              </h3>

                              <p class="mt-10">
                                We suggest a video of 5 minutes or less,
                                formatted as an .mp4
                              </p>
                              <p v-if="cardMedia.invalidFileType">
                                Invalid file type uploaded
                              </p>
                              <p
                                v-else-if="
                                  !cardMedia.file &&
                                    !cardMedia.url &&
                                    !(
                                      learningCard.Content &&
                                      learningCard.Content.status ==
                                        'Processing' &&
                                      !cardMedia.file
                                    )
                                "
                              >
                                No video
                              </p>
                              <p
                                v-else-if="
                                  cardMedia.file && cardMedia.file.name
                                "
                              >
                                <b> {{ cardMedia.file.name }}</b>
                              </p>
                              <p v-else>
                                <b>{{
                                  learningCard.contentUrl
                                    ? learningCard.contentUrl.substring(
                                        learningCard.contentUrl.lastIndexOf(
                                          "/"
                                        ) + 1
                                      )
                                    : learningCard.Content &&
                                      learningCard.Content.filePath
                                    ? learningCard.Content.filePath.substring(
                                        learningCard.Content.filePath.lastIndexOf(
                                          "/"
                                        ) + 1
                                      )
                                    : "Unknown file"
                                }}</b>
                              </p>
                            </div>
                            <v-expansion-panels multiple flat>
                              <v-expansion-panel :key="0" class="px-0">
                                <v-expansion-panel-header class="pl-1 pr-2">
                                  Video Transcript (optional)
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="px-0 mt-5">
                                  <vue-editor
                                    v-model="learningCard.transcript"
                                    outlined
                                    class="full-width"
                                    label="Video transcript (optional)"
                                    :editorToolbar="editorToolbar"
                                    :editorOptions="quillOptions"
                                    :required="false"
                                  ></vue-editor>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <h3
                              v-if="cardMedia.url"
                              class="mt-4 mb-4 text-left"
                            >
                              Video preview:
                            </h3>
                            <div
                              v-if="
                                cardMedia.url &&
                                  (cardMedia.file ||
                                    cardMedia.url.startsWith('blob:') ||
                                    (learningCard.Content &&
                                      learningCard.Content.status !=
                                        'Processing') ||
                                    learningCard.bannerContentId)
                              "
                            >
                              <video-player
                                ref="videoPlayer"
                                class=" vjs-custom-skin"
                                :playsinline="true"
                                :options="videoPlayerOptions"
                              />
                            </div>
                            <div
                              v-else-if="
                                learningCard.Content &&
                                  learningCard.Content.status == 'Processing' &&
                                  !cardMedia.file
                              "
                              class="text-left d-flex flex-column align-center"
                            >
                              <h3>
                                This video is currently processing so a preview
                                is unavailable.
                              </h3>
                              <Robin
                                :showText="false"
                                :width="160"
                                :height="160"
                                animation="eating"
                                :loop="true"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <v-list>
                        <input
                          id="cardUploader"
                          ref="cardUploader"
                          class="d-none"
                          type="file"
                          :accept="{
                            'audio/*': learningCard.type == 'audio',
                            'image/*':
                              learningCard.type != 'video' &&
                              learningCard.type != 'audio',
                            'video/*': learningCard.type == 'video'
                          }"
                          @change="imageFileChanged($event, false)"
                          @blur="imageFileChanged($event, false)"
                        />
                        <v-list-item @click="$refs.cardUploader.click()">
                          <v-list-item-title class="primary--text text-left"
                            ><v-icon color="black" class="mr-2"
                              >mdi-upload</v-icon
                            >Upload from computer</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item @click="removeCardMedia">
                          <v-list-item-title class="primary--text text-left"
                            ><v-icon color="black" class="mr-2"
                              >mdi-delete</v-icon
                            >Remove upload</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-form>
                </v-card>
              </div>
              <div
                v-else-if="slideKey === keys.quizEditor"
                :key="keys.quizEditor"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div></div>
                  <v-form v-model="form.quiz" class="full-width">
                    <v-text-field
                      v-model="learningCard.displayName"
                      class="mt-2 full-width"
                      outlined
                      dense
                      label="Quiz Title"
                      color="brandCyan"
                      :required="true"
                      :rules="[
                        v => !!v || 'A quiz title is required',
                        v =>
                          !!(v && 256 > v.length) ||
                          'Your quiz title should be shorter'
                      ]"
                    ></v-text-field>
                    <div
                      class="d-flex align-center darkGrey--text font-weight-bold mb-4 cursor-pointer"
                      @click="expanded.quizSettings = !expanded.quizSettings"
                    >
                      <v-icon color="grey" class="mr-2">mdi-cog-outline</v-icon
                      >More Settings<v-icon color="grey" class="ml-2">{{
                        expanded.quizSettings
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon>
                    </div>
                    <div
                      v-if="expanded.quizSettings"
                      class="full-width mb-3 pa-3 quiz-settings-box"
                    >
                      <div class="d-flex align-start">
                        <v-select
                          v-model="learningCard.type"
                          outlined
                          label="Quiz Type"
                          :items="data.quizTypes"
                          item-text="label"
                          item-value="value"
                          class="full-width"
                          color="brandCyan"
                          attach
                          :menu-props="{ bottom: true, offsetY: true }"
                          @change="updatedQuizType"
                        ></v-select>
                        <v-btn
                          icon
                          class="ml-3 mt-3"
                          @click="dialog.quizTypes = !dialog.quizTypes"
                        >
                          <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                      </div>
                      <v-text-field
                        v-model="learningCard.content"
                        class="mt-2 full-width"
                        outlined
                        label="Optional conclusion message"
                        color="brandCyan"
                        :required="true"
                      ></v-text-field>
                      <v-menu max-width="260" offset-x offset-y absolute>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="full-width image-container my-3">
                            <v-img
                              :lazy-src="cardMedia.url"
                              :src="cardMedia.url"
                              :key="cardMedia.url"
                              :aspect-ratio="16 / 9"
                              v-bind="attrs"
                              v-on="on"
                              width="100%"
                              max-width="100%"
                              class="cursor-pointer"
                              @drop.prevent="imageFileChanged($event, true)"
                              @dragover.prevent
                            >
                              <template v-slot:placeholder>
                                <div
                                  class="light-grey-background full-height full-width"
                                >
                                  <h3 class=" pt-5 primary--text">
                                    Add an image
                                  </h3>

                                  <p class="mt-10">
                                    JPG, PNG files supported
                                  </p>
                                  <p
                                    v-if="cardMedia.invalidFileType"
                                    class="font-weight-bold"
                                  >
                                    Invalid file type uploaded
                                  </p>
                                </div>
                              </template>
                            </v-img>
                          </div>
                        </template>
                        <v-list>
                          <input
                            id="cardUploader"
                            ref="cardUploader"
                            class="d-none"
                            type="file"
                            accept="image/*"
                            @change="imageFileChanged($event, false)"
                            @blur="imageFileChanged($event, false)"
                          />
                          <v-list-item @click="$refs.cardUploader.click()">
                            <v-list-item-title class="primary--text text-left"
                              ><v-icon color="black" class="mr-2"
                                >mdi-upload</v-icon
                              >Upload from computer</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="removeCardMedia">
                            <v-list-item-title class="primary--text text-left"
                              ><v-icon color="black" class="mr-2"
                                >mdi-delete</v-icon
                              >Remove upload</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <div
                        class="d-flex justify-space-between"
                        v-if="learningCard.type == 'quiz'"
                      >
                        <v-switch
                          v-model="learningCard.passingRequired"
                          class="mt-0 mr-9 mb-3"
                          label="Minimum score required to unlock future cards"
                          color="brandCyan"
                          hide-details
                        ></v-switch>

                        <v-text-field
                          v-if="learningCard.passingRequired"
                          v-model="learningCard.passingScore"
                          type="number"
                          style="min-width: 160px; max-width: 160px;"
                          outlined
                          label="Passing Score"
                          suffix="%"
                          color="brandCyan"
                          :rules="[
                            v => !!v || 'A passing score value is required!',
                            v =>
                              !!(v && v > -1) ||
                              'Must be a number between 1 and 100',
                            v =>
                              !!(v && v < 101) ||
                              'Must be a number between 1 and 100'
                          ]"
                        ></v-text-field>
                      </div>
                      <div class="d-flex justify-space-between">
                        <v-switch
                          v-model="learningCard.randomPool"
                          class="mt-0 mr-9 mb-3"
                          label="Populate with random pool of questions"
                          color="brandCyan"
                          hide-details
                        ></v-switch>
                        <v-text-field
                          v-if="learningCard.randomPool"
                          v-model="learningCard.numRandomQuestions"
                          type="number"
                          style="min-width: 160px; max-width: 160px;"
                          outlined
                          label="Question Count"
                          color="brandCyan"
                          :rules="[
                            v => !!v || 'A question pool count is required!',
                            v =>
                              !!(v && v >= 1 && v <= 100) ||
                              'Must be a number between 1 and 100',
                            v =>
                              !!(v && !v.toString().includes('.')) ||
                              'Must be a whole number'
                          ]"
                        ></v-text-field>
                      </div>

                      <v-switch
                        v-if="learningCard.type !== 'survey'"
                        v-model="learningCard.displayResults"
                        label="Display results when finished"
                        color="brandCyan"
                        class="mt-0 mb-2"
                        hide-details
                      ></v-switch>
                      <v-switch
                        v-if="learningCard.type == 'survey'"
                        v-model="learningCard.repeatable"
                        label="Allow survey to be repeated"
                        color="brandCyan"
                        class="mt-0 mb-2"
                        hide-details
                      ></v-switch>
                    </div>
                    <p class="font-weight-bold text-left">
                      Questions ({{ quizQuestions.length }})
                    </p>
                    <draggable
                      class="full-width"
                      :list="quizQuestions || []"
                      group="questions"
                      handle=".question-handle"
                    >
                      <div
                        v-for="(question, questionIndex) in quizQuestions"
                        :key="
                          `${question.learningQuizQuestionId}-${questionIndex}`
                        "
                        class="my-4"
                      >
                        <div
                          class="d-flex justify-space-between align-center cursor-pointer mb-2"
                          @click="toggleQuizQuestion(questionIndex)"
                        >
                          <div class="d-flex align-center quiz-question-name">
                            <v-icon class="question-handle cursor-grab"
                              >mdi-drag-horizontal-variant</v-icon
                            >
                            <v-btn icon small class="mx-2">
                              <v-icon
                                color="red"
                                @click.stop="removeQuizQuestion(questionIndex)"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                            <p class="text-overflow-clip mb-0">
                              {{ questionIndex + 1 }}.
                              {{ question.content || "Untitled question" }}
                            </p>
                          </div>

                          <div class="d-flex align-center">
                            <!-- <v-icon
                              :color="
                                quizValidation[questionIndex]
                                  ? 'success'
                                  : 'red'
                              "
                              >{{
                                quizValidation[questionIndex]
                                  ? "mdi-check-circle"
                                  : "mdi-close-circle"
                              }}</v-icon
                            > -->
                            <span
                              class="mr-2"
                              :class="{
                                'red--text': !quizValidation[questionIndex],
                                'success--text': quizValidation[questionIndex]
                              }"
                              >{{
                                quizValidation[questionIndex]
                                  ? "Complete"
                                  : "Incomplete"
                              }}</span
                            >
                            <v-icon>{{
                              expanded.questions.find(
                                x => x == questionIndex
                              ) !== undefined
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}</v-icon>
                          </div>
                        </div>
                        <div
                          v-if="expanded.questions.includes(questionIndex)"
                          class="px-2 elevation-0 mt-2"
                        >
                          <v-textarea
                            v-model="question.content"
                            outlined
                            rows="2"
                            label="Question*"
                            height="100"
                            color="brandCyan"
                            :required="true"
                            :rules="[
                              v => !!v || `A question's title is required!`,
                              v =>
                                !!(v && v.length < 751) ||
                                'Try and make your question shorter!'
                            ]"
                          ></v-textarea>
                          <div class="d-flex">
                            <div
                              class="d-flex flex-column align-center ml-2 hidden"
                            >
                              <v-icon class="question-handle cursor-grab"
                                >mdi-drag-horizontal-variant</v-icon
                              >
                              <v-btn icon color="red"
                                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                              >
                            </div>
                            <v-select
                              v-model="question.type"
                              outlined
                              dense
                              label="Question Type"
                              :items="data.questionTypes"
                              item-text="label"
                              item-value="value"
                              @change="updatedQuizQuestionType(question)"
                              class="full-width"
                              color="brandCyan"
                              attach
                              :menu-props="{ top: true, offsetY: true }"
                            ></v-select>
                          </div>
                          <div
                            v-if="
                              question.type == 'MULTIPLE_CHOICE' ||
                                question.type == 'SELECT_ALL'
                            "
                            class="text-left"
                          >
                            <p
                              class="grey--text"
                              v-if="learningCard.type != 'survey'"
                            >
                              {{
                                question.type == "MULTIPLE_CHOICE"
                                  ? "Check a correct answer"
                                  : "Check one or more correct answers"
                              }}
                              (optional)
                            </p>
                            <draggable
                              :list="question.LearningQuizAnswers || []"
                              :group="`answers-${questionIndex}`"
                              handle=".answer-handle"
                            >
                              <div
                                v-for="(answer,
                                answerIndex) in question.LearningQuizAnswers"
                                :key="
                                  `${answer.learningQuizAnswerId}-${answerIndex}`
                                "
                                class="d-flex"
                              >
                                <div
                                  class="d-flex flex-column align-center mr-2"
                                  :class="{
                                    hidden: learningCard.type == 'survey'
                                  }"
                                >
                                  <v-checkbox
                                    v-model="answer.correctness"
                                    color="brandCyan"
                                    class="px-0 ml-2 mb-1 mt-0"
                                    hide-details
                                    :false-value="0"
                                    :true-value="1"
                                    @click="
                                      updateQuizCheckboxes(
                                        question,
                                        answer.correctness,
                                        answerIndex
                                      )
                                    "
                                  />
                                </div>

                                <v-textarea
                                  v-model="answer.content"
                                  outlined
                                  rows="2"
                                  label="Answer*"
                                  height="60"
                                  :required="true"
                                  color="brandCyan"
                                  :rules="[
                                    v => !!v || 'An answer is required!',
                                    v =>
                                      !!(v && v.length < 256) ||
                                      'Try and make your answer shorter!'
                                  ]"
                                ></v-textarea>
                                <div
                                  class="d-flex flex-column align-center mr-2"
                                >
                                  <v-icon class="answer-handle cursor-grab"
                                    >mdi-drag-horizontal-variant</v-icon
                                  >
                                  <v-btn
                                    icon
                                    color="grey"
                                    @click="
                                      removeQuizAnswer(question, answerIndex)
                                    "
                                    ><v-icon>mdi-close-circle</v-icon></v-btn
                                  >
                                </div>
                              </div>
                            </draggable>
                            <div class="d-flex">
                              <v-checkbox
                                color="brandCyan"
                                class="px-0 ml-2 mb-3 hidden"
                                hide-details
                              />
                              <div
                                class="mt-3 mb-2 cursor-pointer module-elements-box module-elements-outline primary--text text-center font-weight-bold"
                                @click.stop="newQuizAnswer(question)"
                              >
                                Add answer
                              </div>
                            </div>
                          </div>
                          <div
                            v-else-if="question.type == 'SCALE'"
                            class="text-left"
                          >
                            <p class="grey--text">
                              Enter a range for the slider
                            </p>
                            <v-text-field
                              v-model="question.lowerBound"
                              class="full-width"
                              outlined
                              dense
                              type="number"
                              label="Lower Bound"
                              color="brandCyan"
                              :required="true"
                              :rules="[
                                v =>
                                  !!(v !== undefined && v !== null) ||
                                  'A lower bound is required!',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    !v.toString().includes('.')
                                  ) || 'Must be a whole number',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    (question.upperBound == null ||
                                      parseInt(v) <
                                        parseInt(question.upperBound))
                                  ) ||
                                  'Must be a number less than the upper bound',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    (question.upperBound == null ||
                                      parseInt(question.upperBound) -
                                        parseInt(v) <=
                                        1000)
                                  ) ||
                                  'The range should be less than 1,000 apart',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    parseInt(v) < parseInt(maxIntSize)
                                  ) ||
                                  'The lower bound should be less than ' +
                                    maxIntSize
                              ]"
                            ></v-text-field>
                            <v-text-field
                              v-model="question.upperBound"
                              class="full-width"
                              outlined
                              dense
                              type="number"
                              label="Upper Bound"
                              color="brandCyan"
                              :required="true"
                              :rules="[
                                v =>
                                  !!(v !== undefined && v !== null) ||
                                  'An upper bound is required!',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    !v.toString().includes('.')
                                  ) || 'Must be a whole number',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    parseInt(v) > parseInt(question.lowerBound)
                                  ) ||
                                  'Must be a number greater than the lower bound',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    (question.lowerBound == null ||
                                      parseInt(v) -
                                        parseInt(question.lowerBound) <=
                                        1000)
                                  ) ||
                                  'The range should be less than 1,000 apart',
                                v =>
                                  !!(
                                    v !== undefined &&
                                    v !== null &&
                                    parseInt(v) < parseInt(maxIntSize)
                                  ) ||
                                  'The upper bound should be less than ' +
                                    maxIntSize
                              ]"
                            ></v-text-field>
                            <v-text-field
                              ref="correctScaleAnswer"
                              v-model="question.LearningQuizAnswers[0].content"
                              v-if="
                                learningCard.type != 'survey' &&
                                  question.LearningQuizAnswers.length == 1
                              "
                              outlined
                              dense
                              label="Correct Answer (optional)"
                              type="number"
                              class="full-width"
                              color="brandCyan"
                              :rules="[
                                v =>
                                  !!(
                                    !v ||
                                    (v && !v.toString().includes('.'))
                                  ) || 'Must be a whole number',
                                v =>
                                  !!(
                                    !v ||
                                    (v &&
                                      parseInt(v) >=
                                        parseInt(question.lowerBound))
                                  ) ||
                                  'Must be a number greater than or equal the lower bound',
                                v =>
                                  !!(
                                    !v ||
                                    (v &&
                                      parseInt(v) <=
                                        parseInt(question.upperBound))
                                  ) ||
                                  'Must be a number less than or equal the upper bound'
                              ]"
                            ></v-text-field>
                          </div>
                        </div>
                        <v-divider
                          v-if="questionIndex != quizQuestions.length - 1"
                          class="my-2"
                        />
                      </div>
                    </draggable>
                  </v-form>
                  <div
                    class="mt-4 mb-2 cursor-pointer module-elements-box module-elements-outline primary--text text-center font-weight-bold"
                    @click.stop="newQuizQuestion"
                  >
                    Add question
                  </div>
                  <div></div>
                </v-card>
              </div>
              <div
                :key="keys.review"
                v-else-if="slideKey === keys.review"
                class="full-width"
              >
                <!-- <div class="text-left mb-5"></div> -->

                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text font-weight-bold mb-1"
                    >Review your learning module</span
                  >

                  <div class="d-flex align-center">
                    <v-switch
                      color="brandCyan"
                      v-model="draft"
                      label="Draft mode (hidden)"
                      hide-details
                      dense
                    />
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-2 mt-4"
                          color="gray"
                          v-on="on"
                          v-bind="attrs"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <p class="my-1">
                        Draft mode can be toggled at any time. When activated,
                        it is hidden from participants.<br />Note that this can
                        negatively affect any programs that the module is in.
                      </p>
                    </v-tooltip>
                  </div>
                  <div class="mb-3 d-flex align-center">
                    <v-switch
                      color="brandCyan"
                      v-model="learningModule.forceLinearProgress"
                      label="Force participant to take module linearly"
                      hide-details
                      dense
                    />
                  </div>

                  <div class="mt-6 d-flex justify-space-between full-width">
                    <p class="text-h6 mb-0">Chapters</p>
                    <p class="text-h6 mb-0"># Cards</p>
                  </div>
                  <div
                    class="d-flex justify-space-between full-width"
                    v-for="chapter in learningChapters"
                    :key="chapter.learningChapterId"
                  >
                    <p
                      class="font-weight-bold mb-1 chapter-display-name text-overflow-clip"
                    >
                      {{ chapter.displayName }}
                    </p>
                    <p class="mb-1">
                      {{ (chapter.LearningCards || []).length }}
                    </p>
                  </div>

                  <p class="text-h6 mb-0 mt-6">Resources</p>
                  <div class="d-flex justify-space-between  full-width">
                    <p>
                      Number of resources
                    </p>
                    <p>
                      {{ resources.length }}
                    </p>
                  </div>
                </v-card>
              </div>
              <div
                :key="keys.publishing"
                v-else-if="slideKey === keys.publishing"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div class="text-left d-flex flex-column align-center">
                    <div class="full-width">
                      <span
                        class="header-text word-break font-weight-bold mb-5"
                        >{{ "Saving your module" }}</span
                      >

                      <p class="grey--text mt-2 full-width text-left">
                        Depending on the number of elements this might take a
                        little bit.
                      </p>
                    </div>
                    <Robin
                      :showText="false"
                      :width="160"
                      :height="160"
                      animation="inflatableDance"
                      :loop="true"
                      class="mt-2"
                    />
                  </div>
                </v-card>
              </div>

              <div
                v-else-if="slideKey == keys.sent"
                :key="keys.sent"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text word-break font-weight-bold mb-5">{{
                    "All done! Your module has been published"
                  }}</span>

                  <div class="mx-auto">
                    <Robin
                      :showText="false"
                      :width="160"
                      :height="160"
                      animation="backflip"
                      :loop="false"
                      class="mb-4"
                    />
                  </div>
                  <!-- 
              <v-btn
                depressed
                color="primary"
                class="my-5 mx-auto"
                @click="reset"
                >Done<v-icon class="ml-1">mdi-chevron-right</v-icon></v-btn
              > -->
                </v-card>
              </div>
              <div
                v-else-if="slideKey == keys.error"
                :key="keys.error"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text word-break font-weight-bold mb-5"
                    >Looks like we had some trouble saving your changes to the
                    learning module. Please try again and see if that solves the
                    problem.</span
                  >
                  <p class="full-width">
                    If this continues, feel free to reach out to customer
                    service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <v-btn
                    depressed
                    color="primary"
                    class="my-5 mx-auto"
                    @click="slideKey = keys.review"
                    ><v-icon class="mr-1">mdi-chevron-left</v-icon>Go
                    Back</v-btn
                  >
                </v-card>
              </div>
            </transition>
          </v-col>
        </v-row>

        <!-- Group creator widget -->
        <v-navigation-drawer
          v-model="dialog.groupCreator"
          temporary
          fixed
          right
          width="500"
        >
          <GroupCreatorWidget
            v-if="dialog.groupCreator"
            @get-groups="getGroups($event, true)"
            @close="dialog.groupCreator = false"
            @push-to-groups="pushToGroups"
            :clientId="clientId"
            :version="3"
            source="LEARNING_WIZARD"
          />
        </v-navigation-drawer>
        <!-- Resource selection widget -->
        <v-navigation-drawer
          v-model="dialog.resourceWidget"
          temporary
          fixed
          right
          width="500"
        >
          <ProgramResourceWidget
            v-if="dialog.resourceWidget"
            @close="dialog.resourceWidget = false"
            @get-content="getContent"
            @add-to-program="
              e => {
                dialog.resourceContentObject = e;
                createResource();
              }
            "
            :clientId="clientId"
            :resources="data.resources"
            :loadingResources="loading.resources"
            context="learning"
          />
        </v-navigation-drawer>
        <!-- Card library widget -->
        <v-navigation-drawer
          v-model="dialog.libraryWidget"
          temporary
          fixed
          right
          width="500"
          height="100%"
        >
          <CardLibrary
            v-if="dialog.libraryWidget"
            :clientId="clientId"
            @close="dialog.libraryWidget = false"
            @addCardsToChapter="addCardsFromLibrary"
          />
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="dialog.quizTypes"
          temporary
          fixed
          right
          width="500"
        >
          <v-card
            v-if="dialog.quizTypes"
            elevation="0"
            rounded="0"
            class="text-left"
          >
            <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
              <v-toolbar-title>Quiz Types</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog.quizTypes = false">
                  <span class="mt-1">Close</span>
                  <v-icon class="ml-2">mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-5 px-3"
            >
              <p>
                <span class="font-weight-bold">Quiz: </span>A standard quiz with
                correct & incorrect answers. They can be retaken as many times
                as the participant would like.
              </p>
            </v-row>
            <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-2 px-3"
            >
              <p>
                <span class="font-weight-bold">Assessment: </span>A more formal
                test of a participant's knowledge with correct & incorrect
                answers. They can only be taken once.
              </p>
            </v-row>
            <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-2 px-3"
            >
              <p>
                <span class="font-weight-bold">Survey: </span>A way to collect
                feedback and information. There are no correct answers and can
                be customized to allow users to retake the survey or not.
              </p>
            </v-row>
          </v-card>
        </v-navigation-drawer>
        <!-- Element deletion dialog -->
        <v-dialog
          v-model="dialog.elementDeleteConfirmation"
          width="500"
          class="dialog"
        >
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >

              <v-card-title
                v-if="!dialog.elementDeleteContext"
                class="word-break text-left exit-warning-text"
              >
                Are you sure you want to delete this element?
              </v-card-title>
              <v-card-title
                v-else
                class="word-break text-left exit-warning-text"
              >
                Are you sure you want to delete this
                {{ dialog.elementDeleteContext }}?
              </v-card-title>
            </div>

            <v-card-actions class="mx-12 d-flex justify-center">
              <v-btn
                class="mr-4"
                color="brandCyan"
                depressed
                outlined
                width="130"
                @click="
                  (dialog.elementDeleteConfirmation = false),
                    (dialog.chapterDeleteId = null),
                    (dialog.cardDeleteId = null),
                    (dialog.resourceDeleteId = null),
                    (dialog.elementDeleteContext = null)
                "
                >Cancel</v-btn
              >
              <v-btn
                class="ml-4 white--text"
                color="brandCyan"
                depressed
                width="130"
                @click="deleteItem"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog when coming from program wizard to close tab -->
        <v-dialog v-model="dialog.closeTab" width="500" class="dialog">
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >

              <v-card-title class="word-break text-left exit-warning-text">
                All done? Close this tab and head back to the program wizard
              </v-card-title>
            </div>

            <v-card-actions class="mx-12 d-flex justify-center">
              <v-btn
                class="mr-4"
                color="brandCyan"
                depressed
                outlined
                width="170"
                @click="dialog.closeTab = false"
                >Continue Editing</v-btn
              >
              <v-btn
                class="ml-4 white--text"
                color="brandCyan"
                depressed
                width="170"
                @click="closeLearningBuilder(true)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay
          v-if="
            dialog.groupCreator ||
              dialog.libraryWidget ||
              dialog.resourceWidget ||
              dialog.quizTypes
          "
        />
      </v-card>
    </v-dialog>
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="dialog.imageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            {{
              slideKey == keys.basics
                ? "Please crop the image below to a 16 : 9 aspect ratio."
                : "Please crop the image if desired."
            }}
          </v-card-title>
        </div>
        <cropper
          ref="imageCropper"
          :src="slideKey == keys.basics ? image.cropUrl : cardMedia.cropUrl"
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Unsaved changes dialog -->
    <v-dialog v-model="dialog.exit" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Do you want to save any changes you made before you go?
          </v-card-title>
        </div>

        <v-card-actions
          class="mx-12"
          :class="isMobile ? 'd-flex flex-column' : ''"
        >
          <v-btn
            color="brandCyan"
            @click="closeLearningBuilder(false)"
            outlined
            width="130"
            >Don't save</v-btn
          >
          <v-spacer />
          <v-btn
            color="brandCyan"
            depressed
            @click="createLearningModule"
            width="130"
            class="white--text"
            :class="isMobile ? 'mt-4' : ''"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used to edit learning chapter name -->
    <v-dialog
      v-if="dialog.chapterEditor"
      v-model="dialog.chapterEditor"
      width="500"
    >
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div>
          <div class="d-flex justify-space-between align-center mx-2">
            <v-card-title class="word-break align-text-left exit-warning-text">
              {{
                learningChapters.length > 1 || learningChapter.learningChapterId
                  ? "Enter the name of your chapter"
                  : "Let's start by creating your first chapter"
              }}
            </v-card-title>
          </div>
          <v-form v-model="learningChapter.valid" onSubmit="return false;">
            <v-text-field
              outlined
              dense
              color="brandCyan"
              class="mb-6 mx-2 px-4"
              label="Chapter Title"
              :rules="[
                v => !!v || 'A chapter title is required',
                v =>
                  !!(v && v.length < 256) ||
                  'Your chapter title should be shorter'
              ]"
              v-model="learningChapter.displayName"
              @keyup.enter="createLearningChapter"
            >
            </v-text-field>
          </v-form>
        </div>

        <v-card-actions class="mx-8">
          <v-btn
            color="brandCyan"
            depressed
            outlined
            width="170"
            :disabled="loading.api"
            @click="dialog.chapterEditor = false"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            depressed
            color="brandCyan"
            width="170"
            class="white--text"
            :disabled="!learningChapter.valid"
            @click="createLearningChapter"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="dialog.loadingAPI"
      v-model="dialog.loadingAPI"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              loading.apiError ? computedApiError.header : computedLoadingHeader
            }}
          </v-card-title>
        </div>
        <div v-if="loading.apiError" class="text-left mx-6 my-6">
          {{ computedApiError.message }} Please try again or contact us if you
          need further assistance.
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            {{ computedLoadingMessage }}
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="loading.apiError">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="
                () => {
                  $router.push({
                    name: 'programadmin',
                    params: {
                      preload: 'learning'
                    }
                  });
                }
              "
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="retryApi"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LearningService from "@/services/LearningService";
import ContentService from "@/services/ContentService";
import GroupService from "@/services/GroupService";

import { formatFileType, debounce } from "@/shared_data/functions";
import {
  editorToolbar,
  editorToolbarWithImages,
  editorOptions
} from "@/shared_data/data";

import GroupCreatorWidget from "@/components/groups/GroupTypeSelector.vue";
import ProgramResourceWidget from "@/components/ProgramResourceWidget.vue";
import CardLibrary from "@/components/CardLibrary.vue";
import Robin from "@/components/Robin";
import TextEditor from "@/components/TextEditor.vue";
import GroupTable from "@/components/data-table/Groups.vue";

// import momentTz from "moment-timezone";
import moment from "moment";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";

import { VueEditor, Quill } from "vue2-editor";
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

function initialState() {
  return {
    slideKey: 1,
    dialogConfirmation: false,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    editorToolbar: editorToolbar,
    editorToolbarWithImages: editorToolbarWithImages,
    quillOptions: editorOptions,
    keys: {
      basics: 1,
      audience: 2,
      elements: 3,
      cardEditor: 4,
      quizEditor: 5,
      review: 100,
      publishing: 101,
      sent: 102,
      error: 103
    },
    learningModule: {
      learningModuleId: null,
      name: null,
      description: null,
      type: "Learning",
      visibilityType: "PRIVATE",
      status: "Draft",
      elements: [],
      badgeId: null,
      forceLinearProgress: false,

      resources: []
    },
    audience: [],
    resources: [],
    tags: [],
    draft: true,
    badge: {
      imageUrl: null,
      displayName: null
    },
    image: {
      url: null,
      fileName: null,
      file: null,
      cropUrl: null,
      invalidFileType: false
    },
    cardMedia: {
      url: null,
      fileName: null,
      file: null,
      cropUrl: null,
      invalidFileType: false
    },
    learningChapters: [],
    learningChapter: {
      displayName: null
    },
    learningCard: {
      learningChapterId: null,
      learningCardId: null,
      learningCardAssociationId: null,
      displayName: null,
      type: "text",
      content: null,
      contentUrl: null,
      transcript: null,
      bannerContentId: null,
      passingRequired: false,
      passingScore: null,
      numRandomQuestions: null,
      displayResults: true,
      repeatable: false
    },
    quizQuestions: [],
    deletedQuizQuestions: [],
    deletedQuizAnswers: [],
    // Empty question used to reset & add new questions
    quizQuestion: {
      learningCardId: null,
      content: null,
      type: "MULTIPLE_CHOICE",
      lowerBound: null,
      upperBound: null,
      answers: []
    },
    quizAnswer: {
      learningQuizAnswerId: null,
      learningQuizQuestionId: null,
      type: "text",
      content: null,
      correctness: 0
    },
    dialog: {
      exit: false,
      imageCropper: false,
      groupInfoSheet: false,
      groupCreator: false,
      libraryWidget: false,
      resourceWidget: false,
      // Used to warn when user is editing a message but they click to edit another message or try to leave the page
      elementDeleteConfirmation: false,
      elementDeleteContext: null,
      // Identifier for resource we're going to delete. Stored for API failure
      resourceDeleteId: null,
      // Identifier for chapter we're going to delete. Stored for API failure
      chapterDeleteId: null,
      // Identifier for card we're going to delete. Stored for API failure
      cardDeleteId: null,
      // Content Object of the item that we're going to add to the module. Stored here in case of API error
      resourceContentObject: null,
      // Shows the loading spinner for API calls
      loadingAPI: false,
      // Popup to add / edit a chapter's name
      chapterEditor: false,
      libraryCardsToAdd: [],
      libraryChapterId: null,
      quizTypes: false,
      duplicateCard: false,
      duplicateQuiz: false,
      closeTab: false
    },
    loading: {
      groups: false,
      groupAssignments: false,
      elements: false,
      resources: false,
      tags: false,
      moduleTags: false,
      modules: false,
      apiError: false,
      apiContext: null
    },
    data: {
      groups: [],
      tags: [],
      infoSheetItem: null,
      resources: [],
      cardTypes: [
        { label: "Text/Image", value: "text" },
        { label: "Video Upload", value: "video" },
        { label: "Youtube / Vimeo Embed", value: "video_embed" },
        { label: "Audio", value: "audio" },
        { label: "PDF", value: "pdf" },
        { label: "Giving Card", value: "giving" }
      ],
      quizTypes: Object.freeze([
        { label: "Quiz", value: "quiz" },
        { label: "Assessment", value: "assessment" },
        { label: "Survey", value: "survey" }
      ]),
      questionTypes: Object.freeze([
        { value: "MULTIPLE_CHOICE", label: "Multiple Choice" },
        { value: "SELECT_ALL", label: "Select All That Apply" },
        { value: "SCALE", label: "Scale" },
        { value: "LIKERT", label: "Likert Scale" },
        { value: "SHORT_ANSWER", label: "Short Answer" }
      ])
    },
    search: {
      audience: null,
      audiencePage: 1
    },
    expanded: {
      chapters: [],
      questions: [],
      quizSettings: false,
      resources: false
    },
    form: {
      card: true,
      quiz: true,
      questions: []
    },
    groupTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        {
          text: "Group ID",
          align: "center",
          sortable: true,
          value: "groupId",
          width: "12%"
        },
        { text: "Group", value: "groupName", width: "25%" },
        { text: "", value: "add" }
      ]
    }
  };
}

export default {
  name: "LearningWizard",
  title: "Learning Wizard | Whistle",
  components: {
    Robin,
    GroupCreatorWidget,
    ProgramResourceWidget,
    CardLibrary,
    draggable,
    VueEditor,
    TextEditor,
    Cropper,
    GroupTable
  },
  props: {
    sourcePage: {
      type: String,
      default: null
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    learningModuleId: Number,
    preloadedClientId: Number,
    preloadedStep: String
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created learning wizard", this.$route.query.clientId);
    if (this.$route.query.clientId)
      this.clientId = Number(this.$route.query.clientId);
    if (this.$auth) {
      if (this.$route.query.learningModuleId) {
        this.loadBuilder();
      }
      this.getContent();
      this.getTagDefinitions();
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    closeLearningBuilder(closeTab = false) {
      if (closeTab) window.close();
      else if (this.programWizardPopout) this.dialog.closeTab = true;
      else if (this.sourcePage) {
        this.$router.push({
          name: this.sourcePage,
          params: this.sourcePageProps
        });
      } else
        this.$router.push({
          name: "programadmin",
          params: {
            preload: "learning"
          }
        });
    },
    async loadBuilder() {
      //We have to preload a learning module
      var learningModule;
      this.loading.apiError = false;

      try {
        setTimeout(() => {
          if (!learningModule) {
            this.dialog.loadingAPI = true;
            this.loading.apiContext = "initialLoad";
            this.loadingModule = true;
          }
        }, 250);

        console.log("Going to fetch learning module for builder!");
        let moduleResponse = await LearningService.getModulesAdmin(
          "learning",
          this.clientId,
          false,
          false,
          1,
          this.$route.query.learningModuleId
        );
        console.log("Module res ", moduleResponse);
        if (moduleResponse.modules && moduleResponse.modules.length == 1)
          learningModule = moduleResponse.modules[0];
        else {
          this.loading.apiError = true;
        }
      } catch (err) {
        console.log("Error getting learning module! ", err);
        this.loading.apiError = true;
      }

      // IF we had an error, then we keep the popup showing, otherwise we hide the dialog
      if (this.loading.apiError) return;
      else {
        this.loadingModule = false;
        this.dialog.loadingAPI = false;
      }

      this.learningModule.learningModuleId = learningModule.learningModuleId;
      this.learningModule.name = learningModule.name;
      this.learningModule.description = learningModule.description;
      this.learningModule.imageUrl = this.image.url = learningModule.imageUrl;
      this.learningModule.badgeId = learningModule.badgeId;
      this.learningModule.forceLinearProgress =
        learningModule.forceLinearProgress;
      this.draft = learningModule.status == "Draft";

      if (
        learningModule.LearningModuleGroups.length &&
        !learningModule.LearningModuleGroups.find(group => group.groupId == 0)
      ) {
        const groupFilter = `status != 'Deleted' && clientId = ${
          this.clientId
        } && groupId.isIn(${JSON.stringify(
          learningModule.LearningModuleGroups.map(x => x.groupId)
        )})`;
        this.loading.groups = true;
        let preloadedGroups = await GroupService.getGroupsV2({
          filter: groupFilter,
          limit: learningModule.LearningModuleGroups.length,
          offset: 0,
          extract: "groupDisplayName,clientId,groupId,groupType"
        });
        console.log("Got groups to preload: ", preloadedGroups);
        this.audience = preloadedGroups.result.rows;
        this.loading.groups = false;
      } else if (learningModule.LearningModuleGroups.length) {
        this.audience = [{ groupId: 0, groupDisplayName: "Everyone" }];
      }

      this.resources = this.formatContent(
        learningModule.LearningAttachments.map(x => {
          x.Content.learningModuleAttachmentId = x.learningModuleAttachmentId;
          x.Content.order = x.order;
          return x.Content;
        })
      );

      this.learningChapters = learningModule.LearningChapters.sort(function(
        a,
        b
      ) {
        return new Date(a.order) - new Date(b.order);
      });
      if (
        this.learningChapters.length > 0 &&
        this.learningChapters[0].order == -1
      ) {
        // If -1, then it's the end card so we move to the end
        var tempChapter = this.learningChapters[0];
        this.learningChapters.splice(0, 1);
        this.learningChapters.push(tempChapter);
      }

      // Initialize all cards with a unique id
      this.learningChapters.forEach(chapter => {
        chapter.LearningCards.forEach(card => {
          card.id = uuidv4();
        });
      });

      this.getTagsByModule();
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    goToSlide(index, forceRoute = false) {
      // Figures out what step we're going into so we can insert the proper platform activity

      // We have to block if they've gone too far or if they're on the first slide but haven't actually made the module yet
      if (
        (this.slideKey > 1 || this.learningModule.learningModuleId) &&
        this.slideKey < this.keys.publishing
      ) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse || forceRoute) {
          this.scrollToTop();
          this.slideKey = index;
        }
      }
    },
    goToNextSlide(ignoreApiCalls = false) {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      if (this.slideKey === this.keys.review && !ignoreApiCalls) {
        //We're on the final slide so show confirmation
        this.createLearningModule();
      } else if (this.slideKey == this.keys.review) {
        this.closeLearningBuilder();
      } else {
        if (this.slideKey == this.keys.basics && !ignoreApiCalls) {
          // Store general module details
          this.createLearningModule();
          return;
        } else if (this.slideKey == this.keys.audience && !ignoreApiCalls) {
          this.replaceLearningModuleGroups();
          return;
        } else if (
          this.slideKey == this.keys.audience &&
          ignoreApiCalls &&
          this.learningChapters.length == 1
        ) {
          // If we're on the audience slide and there are no chapters made, we show the pop up and still advance the slidekey
          this.editChapter(null);
        } else if (this.slideKey == this.keys.cardEditor && !ignoreApiCalls) {
          this.dialog.duplicateCard = false;
          this.createLearningCard();
          return;
        } else if (this.slideKey == this.keys.quizEditor && !ignoreApiCalls) {
          this.dialog.duplicateQuiz = false;
          this.createLearningQuiz();
          return;
        }

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        this.slideKey = this.route[routeIndex + 1];
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;
      this.slideKey = this.route[routeIndex - counter];
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.search.audience = undefined;
        this.groupTable.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - learning wizard",
            groupId
          );
      });
    },
    getContent(preloadContentId = null) {
      this.loading.resources = true;
      ContentService.getAllContent(2)
        .then(response => {
          this.data.resources = Object.freeze(
            this.formatContent(
              response.filter(
                resource =>
                  resource.status !== "Deleted" &&
                  (resource.clientId == 0 ||
                    resource.clientId == this.clientId) &&
                  ![
                    "LEARNING_MODULE",
                    "LEARNING_CARD",
                    "LEARNING_CARD_CONTENT",
                    "SURVEY",
                    "badge",
                    "BADGE"
                  ].includes(resource.category)
              )
            )
          );
          console.log("Content: ", this.data.resources);

          this.loading.resources = false;

          console.log("GetContent preloadContentId ", preloadContentId);
          if (preloadContentId && typeof preloadContentId == "number") {
            const foundContent = this.data.resources.find(
              x => x.contentId == preloadContentId
            );
            if (foundContent) this.resources.push(foundContent);
          } else if (
            preloadContentId &&
            Array.isArray(preloadContentId) &&
            preloadContentId.length
          ) {
            this.resources = this.data.resources.filter(x =>
              preloadContentId.some(y => y == x.contentId)
            );
          }
        })
        .catch(error => {
          console.log("Error getting resources ", error);
          this.loading.resources = false;
        });
    },
    formatContent(arr) {
      return arr.map(x => {
        var obj = x;
        obj.id = x.contentId.toString();
        obj.displayName = x.name;
        obj.context = "Content";
        obj.contextResourceId = x.contentId.toString();
        obj.contextColumn = "metricValue";
        obj.fileType = formatFileType(x.filePath);
        obj.label = x.name;
        obj.icon =
          obj.fileType == "PDF"
            ? "mdi-file-pdf-box"
            : obj.fileType == "DOCX"
            ? "mdi-file-word"
            : obj.fileType == "XLSX"
            ? "mdi-file-excel"
            : obj.fileType == "PPTX"
            ? "mdi-file-powerpoint"
            : obj.fileType == "PNG"
            ? "mdi-file-image"
            : obj.fileType == "CSV"
            ? "mdi-file-delimited"
            : "mdi-file";
        obj.iconColor = "brand";
        obj.displayGrabber = false;

        obj.createdAtFormatted = moment(obj.createdAt)
          .utc()
          .format("MMM DD, YYYY");
        return obj;
      });
    },
    async getTagDefinitions() {
      try {
        this.loading.tags = true;
        let tagResponse = await LearningService.getAllTagDefinitions(2);
        this.data.tags = Object.freeze(
          tagResponse.filter(x => x.clientId == this.clientId)
        );
      } catch (err) {
        console.log("Error getting tags ", err);
      } finally {
        this.loading.tags = false;
      }
    },
    async getTagsByModule() {
      try {
        this.loading.moduleTags = true;
        let response = await LearningService.getLearningModuleTags(
          this.learningModule.learningModuleId,
          2
        );
        console.log("Module tag response: ", response);
        this.tags = response;
      } catch (err) {
        console.log("error getting tags for the module ", err);
      } finally {
        this.loading.moduleTags = false;
      }
    },
    removeGroupFromSelected(index) {
      this.audience.splice(index, 1);
    },
    addGroupToSelected(group, dontRemove = false) {
      if (!Array.isArray(group)) var arr = [group];
      else arr = group;
      arr.forEach(item => {
        let idx = this.audience.findIndex(x => x.groupId == item.groupId);
        if (idx == -1) this.audience.push(item);
        else if (!dontRemove) this.removeGroupFromSelected(idx);
        this.dialog.groupInfoSheet = false;
      });
    },
    async createResource() {
      const startTime = new Date();
      let resource = this.dialog.resourceContentObject;
      console.log("Adding resource ", resource);
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "resource";
        let attachmentResponse = await LearningService.createLearningAttachment(
          {
            clientId: this.clientId,
            contentId: resource.contentId,
            learningModuleId: this.learningModule.learningModuleId,
            order: this.resources.length
          }
        );
        console.log("Attachment response ", attachmentResponse);
        // // This way we only add the resource if the api didn't fail
        this.resources = this.resources.concat(
          this.formatContent([
            {
              ...resource,
              learningModuleAttachmentId:
                attachmentResponse.learningModuleAttachmentId
            }
          ])
        );
        this.expanded.resources = true;
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff > 1500) timeDiff = 1500;
        setTimeout(() => {
          this.dialog.loadingAPI = false;
        }, 1500 - timeDiff);
      } catch (err) {
        console.log("error adding resource ", err);
        this.loading.apiError = true;
      }
    },
    async deleteResource() {
      const startTime = new Date();
      let learningAttachmentId = this.dialog.resourceDeleteId;
      console.log("Deleting resource ", learningAttachmentId);
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "resourceDelete";

        // we also want to make an api call to delete that resource from the module
        let attachmentResponse = await LearningService.deleteLearningAttachment(
          learningAttachmentId,
          this.clientId
        );
        console.log("Attachment deletion response ", attachmentResponse);
        // This way we only delete the resource if the api didn't fail
        var resourceIndex = this.resources.findIndex(
          x => x.learningModuleAttachmentId == learningAttachmentId
        );
        if (resourceIndex != -1) this.resources.splice(resourceIndex, 1);
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff > 1500) timeDiff = 1500;
        setTimeout(() => {
          this.dialog.loadingAPI = false;
        }, 1500 - timeDiff);
      } catch (err) {
        console.log("error deleting resource ", err);
        this.loading.apiError = true;
      }
    },
    async deleteItem() {
      console.log("Deleting item ", this.dialog.elementDeleteContext);
      console.log({
        cardId: this.dialog.cardDeleteId,
        resourceId: this.dialog.resourceDeleteId,
        chapterId: this.dialog.chapterDeleteId
      });
      if (
        this.dialog.elementDeleteContext == "resource" &&
        this.dialog.resourceDeleteId != null
      ) {
        this.deleteResource();
      } else if (
        this.dialog.elementDeleteContext == "chapter" &&
        this.dialog.chapterDeleteId
      ) {
        this.deleteLearningChapter();
      } else if (
        this.dialog.elementDeleteContext == "card" &&
        this.dialog.cardDeleteId != null
      ) {
        console.log("Going to delete card");
        this.deleteLearningCard();
      }

      this.dialog.elementDeleteConfirmation = false;
    },
    async createLearningModule() {
      // If we are creating a module for the first time, we are starting from scratch and need to save the end cap learning chapter & learning card too
      try {
        let publishFlag = this.currentSlide == this.keys.review;
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext =
          publishFlag && !this.draft
            ? "learningModulePublish"
            : "learningModule";

        this.learningModule.displayName = this.learningModule.name;
        this.learningModule.updatedBy = this.userProfile.userId;
        this.learningModule.type = "Learning";
        this.learningModule.status = this.draft ? "Draft" : "Active"; //publishFlag ? "Active" : "Draft";
        this.learningModule.clientId = this.clientId;

        // If there is a new image or a bannerContentId, then we set imageUrl to null;
        // Otherwise, we don't touch it because they either removed the image or it's a defined url that we don't touch.
        if (this.learningModule.image) {
          console.log("Setting module image url to null so that we can upload");
          this.learningModule.imageUrl = null;
        }
        console.log("Creating/Updating module: ", this.learningModule);
        //if pre-existing module, update, else create
        if (this.learningModule.learningModuleId) {
          var moduleResp = await LearningService.updateLearningModule(
            this.learningModule.learningModuleId,
            // If we're publishing we only need to update this stuff
            publishFlag
              ? {
                  status: "Active",
                  updatedBy: this.userProfile.userId,
                  forceLinearProgress: this.learningModule.forceLinearProgress
                }
              : this.learningModule,
            2 // version
          );
          console.log("Updated module ", moduleResp);
        } else {
          // If it's a new module, we mark it as draft until they publish for the first time
          moduleResp = await LearningService.createLearningModule(
            this.learningModule
          );
          console.log("Created module ", moduleResp.module.learningModuleId);
          this.learningModule.learningModuleId =
            moduleResp.module.learningModuleId;
          let query = Object.assign(
            {
              learningModuleId: this.learningModule.learningModuleId,
              clientId: this.clientId
            },
            this.$route.query
          );
          this.$router.replace({ query });

          //  We also copy over the conclusion chapter and card
          this.learningChapters = JSON.parse(
            JSON.stringify(moduleResp.module.LearningChapters)
          );
        }

        if (this.image.file) {
          console.log(
            "Uploading a new image for this module ",
            this.image.file
          );

          var updateResp = await LearningService.replaceLearningModuleImage(
            this.learningModule.learningModuleId,
            this.clientId,
            this.image.file,
            2
          );
          console.log("Learning module image response: ", updateResp);

          this.learningModule.imageUrl = updateResp.imageUrl;
          this.image.url = updateResp.imageUrl;
          this.image.file = null;
        }

        try {
          // The combobox allows for new tags to be made. When theyre added, they're just a string rather than an object
          for (let i = 0; i < this.tags.length; i++) {
            if (typeof this.tags[i] === "string") {
              // this.selectedTags[i]
              this.tags[i] = {
                clientId: this.clientId,
                name: this.tags[i]
              };
            }
          }

          if (this.tags.length && !publishFlag) {
            console.log("Replacing module tags");
            var tagsResponse = await LearningService.putLearningModuleTags(
              this.learningModule.learningModuleId,
              this.tags,
              2
            );
            console.log("Replaced module tags ", tagsResponse);
            this.tags = tagsResponse;
          }
        } catch (err) {
          // If it's the module image or tags, we don't want to cry wolf
          console.log("Error saving either the image or the tags ", err);
        }

        this.dialog.loadingAPI = false;
        if (publishFlag) {
          this.closeLearningBuilder();
        } else {
          this.goToNextSlide(true);
        }
      } catch (err) {
        console.log("Error upserting learning module ", err);
        this.loading.apiError = true;
      }
    },
    async replaceLearningModuleGroups() {
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "audience";
        var groupResponse = await LearningService.replaceLearningModuleGroups(
          this.learningModule.learningModuleId,
          this.audience.map(x => {
            return { groupId: x.groupId };
          }),
          `?clientId=${this.clientId}`
        );
        console.log("Replaced module groups", groupResponse);
        this.goToNextSlide(true);
        this.dialog.loadingAPI = false;
      } catch (err) {
        console.log("Error replacing learning groups ", err);
        this.loading.apiError = true;
      }
    },
    async createLearningChapter() {
      if (!this.learningChapter.valid) return;
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "learningChapter";
        let chapter = {
          clientId: this.clientId,
          name: this.learningChapter.displayName,
          displayName: this.learningChapter.displayName,
          updatedBy: this.userProfile.userId,
          learningModuleId: this.learningModule.learningModuleId
        };
        if (this.learningChapter.learningChapterId) {
          var chapterResponse = await LearningService.updateLearningChapter(
            this.learningChapter.learningChapterId,
            chapter,
            2
          );
          // Now update the local value
          var existingChapter = this.learningChapters.find(
            x => x.learningChapterId == this.learningChapter.learningChapterId
          );
          if (existingChapter)
            existingChapter.displayName = existingChapter.name =
              chapter.displayName;
        } else {
          chapter.order = this.learningChapters.length - 1;
          chapterResponse = await LearningService.createLearningChapter(
            chapter,
            2
          );
          // Now store the new chapter in the second to last spot
          this.learningChapters.splice(this.learningChapters.length - 1, 0, {
            ...chapterResponse,
            LearningCards: []
          });
          this.expanded.chapters.push(chapterResponse.learningChapterId);
        }
        console.log("Finished creating/updating chapter ", chapterResponse);
        this.dialog.loadingAPI = false;
      } catch (err) {
        console.log("Error upserting chapter ", err);
        this.loading.apiError = true;
      } finally {
        this.dialog.chapterEditor = false;
      }
    },
    async deleteLearningChapter() {
      let chapterId = this.dialog.chapterDeleteId;
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "learningChapterDelete";

        let chapterResponse = await LearningService.deleteLearningChapter(
          chapterId,
          2
        );

        console.log("Finished deleting chapter ", chapterResponse);
        this.dialog.loadingAPI = false;
        // Wrap this in a different try / catch so the main catch to show API errors doesn't get triggered
        try {
          // Now splice out that chapter
          // We also need to grab all cardId's in that chapter so we can remove association references that are local
          let chapterIndex = this.learningChapters.findIndex(
            x => x.learningChapterId == chapterId
          );
          var cards = [];
          if (chapterIndex != -1) {
            cards = this.learningChapters[chapterIndex].LearningCards.map(
              x => x.learningCardAssociationId
            );
            this.learningChapters.splice(chapterIndex, 1);
          }
          console.log("Cards we need to remove associations for ", cards);
          this.learningChapters.forEach(chapter => {
            for (var i = 0; i < chapter.LearningCards.length; i++) {
              chapter.LearningCards[
                i
              ].LearningCardAssociations = chapter.LearningCards[
                i
              ].LearningCardAssociations.filter(
                x => !cards.includes(x.learningCardAssociationId)
              );
            }
          });
        } catch (err) {
          console.log("Error splicing chapter afterwards");
        }
      } catch (err) {
        console.log("error deleting learning chapter ", err);
        this.loading.apiError = true;
      }
    },
    async createLearningCard() {
      const duplicateFlag = this.dialog.duplicateCard;
      try {
        var duplicatedCardIndex = -1;

        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "learningCard";

        let learningCardId = this.learningCard.learningCardId;
        // var editing = false;
        let embeddedRes = await this.uploadEmbeddedContent(
          this.learningCard.content
        );
        let card = {
          clientId: this.clientId,
          name: this.learningCard.displayName,
          displayName: this.learningCard.displayName,
          type: this.learningCard.type,
          content: embeddedRes.content,
          updatedBy: this.userProfile.userId,
          learningChapterId: this.learningCard.learningChapterId,
          bannerContentId: this.learningCard.bannerContentId,
          contentUrl:
            this.learningCard.type == "video_embed" &&
            this.learningCard.contentUrl
              ? this.formatVideoEmbedSource(this.learningCard.contentUrl)
              : null,
          transcript: this.learningCard.transcript,
          displayResults: false,
          repeatable: false,
          passingRequired: false,
          passingScore: null,
          numRandomQuestions: null
        };

        // If we're duplicating, we prep the card with a new unique id
        if (duplicateFlag || !learningCardId) card.id = uuidv4();

        const chapterIndex = this.learningChapters.findIndex(
          x => x.learningChapterId == card.learningChapterId
        );
        let chapter =
          chapterIndex == -1 ? null : this.learningChapters[chapterIndex];

        if (duplicateFlag && this.learningCard.id) {
          // We want to find the card's index based on it's unique id so we can replace it with the new version
          duplicatedCardIndex = chapter.LearningCards.findIndex(
            x => x.id == this.learningCard.id
          );
        }

        console.log("DUPLICATED CARD INDEX ", duplicatedCardIndex);

        if (learningCardId && !duplicateFlag) {
          // editing = true;
          var cardResponse = await LearningService.updateLearningCard(
            learningCardId,
            card,
            2
          );
        } else {
          var duplicatedContent = undefined;
          // Content won't get copied locally when cloning, so we have to manually do that for previews
          if (duplicateFlag && this.learningCard.Content) {
            duplicatedContent = JSON.parse(
              JSON.stringify(this.learningCard.Content)
            );
          }
          if (duplicateFlag && this.learningCard.learningCardAssociationId) {
            // We want to delete the old association
            const associationId = this.learningCard.learningCardAssociationId;
            console.log(
              "Deleting old association so we can duplicate card ",
              associationId
            );
            var duplicateResponse = await this.removeDuplicateCard(
              associationId,
              learningCardId
            );
            console.log(
              "Got response from deleting association: ",
              duplicateResponse
            );
          }
          card.order =
            duplicateFlag && duplicatedCardIndex != -1
              ? duplicatedCardIndex
              : chapter && chapter.LearningCards
              ? chapter.LearningCards.length
              : 0;
          cardResponse = await LearningService.createLearningCardInChapter(
            card.learningChapterId,
            card,
            2
          );

          learningCardId = cardResponse.card.learningCardId;

          cardResponse.card.learningCardAssociationId =
            cardResponse.association.learningCardAssociationId;
          cardResponse.card.LearningCardAssociations = [
            cardResponse.association
          ];

          if (duplicatedContent) cardResponse.card.Content = duplicatedContent;

          // Update this so if any future API calls fail, it won't make 2 cards
          this.learningCard.learningCardId = learningCardId;

          // Store this in our array so it exists
          if (chapter && !duplicateFlag) {
            // New card
            // Push onto chapter card array
            if (!chapter.LearningCards) chapter.LearningCards = [];
            chapter.LearningCards.push({ ...cardResponse.card, ...card });
          } else if (chapter && duplicateFlag) {
            // We need to replace the old card with this new duplicated card but at a specific index
            chapter.LearningCards.splice(duplicatedCardIndex, 1, {
              ...cardResponse.card,
              ...card
            });
          }
        }
        console.log("Finished creating/updating card ", cardResponse);
        card.content = embeddedRes.local;

        // Now upload media
        if (this.cardMedia.file && card.type != "video_embed") {
          console.log("Uploading new media for this card", this.cardMedia.file);
          var attachment = {
            name: "Learning Card Banner",
            description: "Content used within a learning card.",
            category: "LEARNING_CARD",
            file: this.cardMedia.file,
            type: card.type,
            fileName: this.cardMedia.file.name,
            fileSize: this.cardMedia.file.size,
            clientId: this.clientId,
            generateSignedUrl: false
          };
          var contentResponse = await ContentService.createContent(
            attachment,
            2
          );
          console.log("Response from creating content: ", contentResponse);
          card.bannerContentId = contentResponse.contentId;
          this.learningCard.bannerContentId = contentResponse.contentId;
          card.Content = contentResponse;
          this.learningCard.Content = contentResponse;

          // If the file was too large, the API returns a signed url we can use to upload the file directly to GCS
          if (contentResponse.signedUrl) {
            var largeContentResp = await ContentService.uploadContentWithSignedUrl(
              contentResponse.signedUrl,
              this.cardMedia.file,
              2
            );
            // ).then((resp) => {
            console.log("Response from uploading content ", largeContentResp);
            if (card.type == "video") {
              var encodingResponse = await ContentService.encodeFile(
                contentResponse.filePath,
                card.type,
                2
              );
              // The video has begun encoding and the jobId is stored in the database
              console.log("Encoding response: ", encodingResponse);
            }
          }

          var cardUpdateResponse = await LearningService.updateLearningCard(
            learningCardId,
            {
              bannerContentId: contentResponse.contentId,
              clientId: this.clientId
            },
            2
          );
          console.log("Updated card with contentId: ", cardUpdateResponse);

          // When messing with content, we want to store the local url so we can preview
          card.contentUrlSigned = this.cardMedia.url;

          // Now we can empty out the file since we've uploaded and stored it to the card
          this.cardMedia.file = null;
        } else if (!this.learningCard.bannerContentId) {
          // If no banner content, we wipe the Content and contentUrlSigned
          card.Content = card.contentUrlSigned = null;
        }

        // Now locally store the card changes
        // if (editing) {
        // Editing an existing card
        // We cycle through all chapters in case there are multiple instances of this card
        this.learningChapters.forEach(learningChapter => {
          for (var i = 0; i < learningChapter.LearningCards.length; i++) {
            if (
              learningChapter.LearningCards[i].learningCardId == learningCardId
            ) {
              console.log("Found a learning card to update");
              learningChapter.LearningCards[i] = {
                ...learningChapter.LearningCards[i],
                ...card
              };
            }
          }
        });

        if (duplicateFlag && duplicatedCardIndex != -1) {
          console.log("Updating card order after we duplicate!!");
          this.updateCardOrder();
        }

        this.goToNextSlide(true);
        this.dialog.loadingAPI = false;
      } catch (err) {
        console.log("error upserting learning card ", err);
        this.loading.apiError = true;
      }
    },
    async createLearningQuiz() {
      const duplicateFlag = this.dialog.duplicateQuiz;
      try {
        var duplicatedCardIndex = -1;

        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "learningQuiz";

        let learningCardId = this.learningCard.learningCardId;
        // var editing = false;
        let card = {
          clientId: this.clientId,
          name: this.learningCard.displayName,
          displayName: this.learningCard.displayName,
          type: this.learningCard.type,
          content: this.learningCard.content,
          updatedBy: this.userProfile.userId,
          learningChapterId: this.learningCard.learningChapterId,
          bannerContentId: this.learningCard.bannerContentId,
          contentUrl: null,
          transcript: null,
          displayResults:
            this.learningCard.type != "survey"
              ? this.learningCard.displayResults
              : false,
          repeatable:
            this.learningCard.type == "survey"
              ? this.learningCard.repeatable
              : false,
          passingRequired:
            this.learningCard.type == "quiz"
              ? this.learningCard.passingRequired
              : false,
          passingScore:
            this.learningCard.type == "quiz"
              ? this.learningCard.passingScore
              : null,
          numRandomQuestions: this.learningCard.randomPool
            ? this.learningCard.numRandomQuestions
            : null
        };

        // If we're duplicating, we prep the card with a new unique id
        if (duplicateFlag || !learningCardId) card.id = uuidv4();

        const chapterIndex = this.learningChapters.findIndex(
          x => x.learningChapterId == card.learningChapterId
        );
        let chapter =
          chapterIndex == -1 ? null : this.learningChapters[chapterIndex];

        if (duplicateFlag && this.learningCard.id) {
          // We want to find the card's index based on it's unique id so we can replace it with the new version
          duplicatedCardIndex = chapter.LearningCards.findIndex(
            x => x.id == this.learningCard.id
          );
        }

        if (learningCardId && !duplicateFlag) {
          // editing = true;
          var cardResponse = await LearningService.updateLearningCard(
            learningCardId,
            card,
            2
          );
        } else {
          if (duplicateFlag && this.learningCard.learningCardAssociationId) {
            // We want to delete the old association
            const associationId = this.learningCard.learningCardAssociationId;
            console.log(
              "Deleting old association so we can duplicate card ",
              associationId
            );
            var duplicateResponse = await this.removeDuplicateCard(
              associationId,
              learningCardId
            );
            console.log(
              "Got response from deleting association: ",
              duplicateResponse
            );
          }
          card.order =
            duplicateFlag && duplicatedCardIndex != -1
              ? duplicatedCardIndex
              : chapter && chapter.LearningCards
              ? chapter.LearningCards.length
              : 0;
          cardResponse = await LearningService.createLearningCardInChapter(
            card.learningChapterId,
            card,
            2
          );

          learningCardId = cardResponse.card.learningCardId;

          cardResponse.card.learningCardAssociationId =
            cardResponse.association.learningCardAssociationId;
          cardResponse.card.LearningCardAssociations = [
            cardResponse.association
          ];

          // Update this so if any future API calls fail, it won't make 2 cards
          this.learningCard.learningCardId = learningCardId;

          // Store this in our array so it exists
          if (chapter && !duplicateFlag) {
            // New card
            // Push onto chapter card array
            if (!chapter.LearningCards) chapter.LearningCards = [];
            chapter.LearningCards.push({ ...cardResponse.card, ...card });
          } else if (chapter && duplicateFlag) {
            // We need to replace the old card with this new duplicated card but at a specific index
            chapter.LearningCards.splice(duplicatedCardIndex, 1, {
              ...cardResponse.card,
              ...card
            });
          }
        }
        console.log("Finished creating/updating card ", cardResponse);

        // Now upload media
        if (this.cardMedia.file) {
          console.log("Uploading new media for this card", this.cardMedia.file);
          var attachment = {
            name: "Learning Card Banner",
            description: "Content used within a learning card.",
            category: "LEARNING_CARD",
            file: this.cardMedia.file,
            type: card.type,
            fileName: this.cardMedia.file.name,
            fileSize: this.cardMedia.file.size,
            clientId: this.clientId,
            generateSignedUrl: false
          };
          var contentResponse = await ContentService.createContent(
            attachment,
            2
          );
          console.log("Response from creating content: ", contentResponse);
          card.bannerContentId = contentResponse.contentId;
          this.learningCard.bannerContentId = contentResponse.contentId;

          // If the file was too large, the API returns a signed url we can use to upload the file directly to GCS
          if (contentResponse.signedUrl) {
            var largeContentResp = await ContentService.uploadContentWithSignedUrl(
              contentResponse.signedUrl,
              this.cardMedia.file,
              2
            );
            // ).then((resp) => {
            console.log("Response from uploading content ", largeContentResp);
          }

          var cardUpdateResponse = await LearningService.updateLearningCard(
            learningCardId,
            {
              bannerContentId: contentResponse.contentId,
              clientId: this.clientId
            },
            2
          );
          console.log("Updated card with contentId: ", cardUpdateResponse);

          // When messing with content, we want to store the local url so we can preview
          card.contentUrlSigned = this.cardMedia.url;

          // Now we can empty out the file since we've uploaded and stored it to the card
          this.cardMedia.file = null;
        } else if (!this.learningCard.bannerContentId) {
          // If no banner content, we wipe the Content and contentUrlSigned
          card.Content = card.contentUrlSigned = null;
        }

        // Now locally store the card changes
        // Editing an existing card
        // We cycle through all chapters in case there are multiple instances of this card
        this.learningChapters.forEach(learningChapter => {
          for (var i = 0; i < learningChapter.LearningCards.length; i++) {
            if (
              learningChapter.LearningCards[i].learningCardId == learningCardId
            ) {
              console.log("Found a learning card to update");
              learningChapter.LearningCards[i] = {
                ...learningChapter.LearningCards[i],
                ...card
              };
            }
          }
        });

        if (duplicateFlag && duplicatedCardIndex != -1) {
          console.log("Updating card order after we duplicate!!");
          this.updateCardOrder();
        }

        // Now we get to store the quiz questions!
        this.quizQuestions.forEach((question, questionIndex) => {
          question.clientId = this.clientId;
          question.order = questionIndex;
          question.LearningQuizAnswers.forEach((answer, answerIndex) => {
            answer.order = answerIndex;
          });
        });
        var quizResponse = await LearningService.postLearningQuiz(
          learningCardId,
          this.quizQuestions,
          duplicateFlag, //cloning
          2
        );
        console.log("Store quiz data ", quizResponse);

        // Now we need to store this data back into the learning card.
        this.learningChapters.forEach(chapter => {
          chapter.LearningCards.forEach(card => {
            if (card.learningCardId == learningCardId)
              card.LearningQuizQuestions = JSON.parse(
                JSON.stringify(quizResponse)
              );
          });
        });

        // We want to wipe any deleted quiz questions but only if we're not duplicating because otherwise it's all new
        if (this.deletedQuizQuestions.length && !duplicateFlag) {
          var questionIds = this.deletedQuizQuestions.map(
            x => x.learningQuizQuestionId || x
          );
          let deletedQuestionRes = await LearningService.deleteLearningQuizQuestion(
            questionIds,
            2
          );
          console.log("Deleted quiz questions ", deletedQuestionRes);
        }

        // We want to wipe any deleted quiz questions but only if we're not duplicating because otherwise it's all new
        if (this.deletedQuizAnswers.length && !duplicateFlag) {
          // var answers = this.deletedQuizAnswers.map(id => {
          //   var obj = {
          //     clientId: this.clientId,
          //     status: "Deleted",
          //     learningQuizAnswerId: id
          //   };

          //   return obj;
          // });
          let deletedAnswerRes = await LearningService.deleteLearningQuizAnswer(
            this.deletedQuizAnswers.map(x => `id=${x}`).join("&"),
            2
          );
          console.log("Deleted quiz answers ", deletedAnswerRes);
        }
        this.goToNextSlide(true);
        this.dialog.loadingAPI = false;
      } catch (err) {
        console.log("error creating learning quiz ", err);
        this.loading.apiError = true;
      }
    },
    async deleteLearningCard() {
      let uniqueId = this.dialog.cardDeleteId;
      console.log("Going to delete card ", uniqueId);
      var chapter;
      var cardIndex = -1;
      try {
        this.dialog.loadingAPI = true;
        this.loading.apiError = false;
        this.loading.apiContext = "learningCardDelete";

        this.learningChapters.forEach(ch => {
          let foundCard = ch.LearningCards.findIndex(x => x.id == uniqueId);
          if (foundCard != -1) {
            chapter = ch;
            cardIndex = foundCard;
          }
        });

        if (cardIndex == -1) throw "Couldn't find card";
        let cardResponse = await this.removeDuplicateCard(
          chapter.LearningCards[cardIndex].learningCardAssociationId,
          chapter.LearningCards[cardIndex].learningCardId
        );

        console.log("Finished deleting card ", cardResponse);
        try {
          // Now splice out that card
          if (chapter && cardIndex != -1)
            chapter.LearningCards.splice(cardIndex, 1);
        } catch (err) {
          console.log("Error splicing out card");
        }
        this.dialog.loadingAPI = false;
      } catch (err) {
        console.log("error deleting learning card ", err);
        this.loading.apiError = true;
      }
    },
    async uploadEmbeddedContent(content) {
      try {
        var contentWithCustomTags = content;
        var imgArray = [];
        // Cycle through to parse out the image data if we have any new images to upload
        while (
          contentWithCustomTags != null &&
          contentWithCustomTags.indexOf('<img src="data:') != -1
        ) {
          // We search for an img tag
          var index = contentWithCustomTags.indexOf('<img src="data:');

          // Then we find the img's closing tag
          var endIndex = contentWithCustomTags.indexOf(">", index + 1);

          // String manipulation to get just the img tag (to splice out later) and the raw img data
          var imgTag = contentWithCustomTags.substring(index, endIndex + 1);
          var imgData = imgTag.substring(
            imgTag.indexOf('="') + 2,
            imgTag.length - 2
          );

          var fileExtension = imgData.substring(
            imgData.indexOf(":image/") + 7,
            imgData.indexOf(";")
          );

          var arr = imgData.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          var imgFile = new File(
            [u8arr],
            "learning_card_image." + fileExtension,
            {
              type: mime
            }
          );

          // Generate a random ID to tie to it
          var randomId = Math.floor(Math.random() * Date.now());
          imgArray.push({ data: imgFile, id: randomId });

          // Then we just replace that with a custom img tag to replace later
          var newContent = contentWithCustomTags.replace(
            imgTag,
            `<img-custom ${randomId}>`
          );
          contentWithCustomTags = newContent;
        }
        var contentWithSignedUrl = contentWithCustomTags;

        // Now cycle through and replace any signed url's that we're using for rendering previews
        var signedUrlIndex = !contentWithCustomTags
          ? -1
          : contentWithCustomTags.indexOf('<img id="contentId-');
        while (signedUrlIndex != -1) {
          var endTag = contentWithCustomTags.indexOf('"', signedUrlIndex + 10);
          var closingTag = contentWithCustomTags.indexOf(">", signedUrlIndex);
          var customImageTag = contentWithCustomTags.substring(
            signedUrlIndex,
            closingTag + 1
          );
          var contentId = contentWithCustomTags.substring(
            signedUrlIndex + 19,
            endTag
          );
          console.log("Found signed URL for " + contentId);
          contentWithCustomTags = contentWithCustomTags.replace(
            customImageTag,
            `<img-custom ${contentId}>`
          );
          signedUrlIndex = contentWithCustomTags.indexOf('<img id="contentId-');
        }

        if (imgArray.length) {
          await Promise.all(
            imgArray.map(async (img, index) => {
              var attachment = {
                name: "Learning Card Content",
                description:
                  "Image used within the content of a learning card.",
                category: "LEARNING_CARD_CONTENT",
                clientId: this.clientId,
                file: img.data,
                generateSignedUrl: true,
                type: "image"
              };
              var imageResponse = await ContentService.createContent(
                attachment,
                2
              );
              console.log(`Image ${index} upload response: `, imageResponse);
              img.contentId = imageResponse.contentId;
              img.contentUrlSigned = imageResponse.contentUrlSigned;
            })
          );

          imgArray.forEach(img => {
            contentWithCustomTags = contentWithCustomTags.replace(
              `<img-custom ${img.id}>`,
              `<img-custom ${img.contentId}>`
            );
            contentWithSignedUrl = contentWithSignedUrl.replace(
              `<img-custom ${img.id}>`,
              `<img id="contentId-${img.contentId}" src="${img.contentUrlSigned}">`
            );
          });
        }

        // resolve();
        // });
        return {
          content: contentWithCustomTags,
          local: contentWithSignedUrl
        };
      } catch (err) {
        console.log("Error uploading embedded content", err);
        throw err;
      }
    },
    async getEmbeddedContent(content) {
      var imageIndex = content ? content.indexOf("<img-custom") : -1;
      var imageArray = [];
      while (imageIndex != -1) {
        var closingTag = content.indexOf(">", imageIndex);
        var contentId = content.substring(imageIndex + 12, closingTag);
        console.log("ContentId to load: ", contentId);
        imageArray.push(contentId);

        content = content.replace(
          "<img-custom " + contentId + ">",
          "<img-temp " + contentId + ">"
        );
        imageIndex = content.indexOf("<img-custom");
      }

      await Promise.all(
        imageArray.map(async contentId => {
          let contentResponse = await ContentService.getSignedUrlBasedOnContentId(
            contentId,
            2
          );
          if (contentResponse.url) {
            content = content.replace(
              "<img-temp " + contentId + ">",
              `<img id="contentId-${contentId}" src="${contentResponse.url}">`
            );
          } else {
            // There was some error so no url was returned, so we remove the img tag
            console.log("Removing tag for content " + contentId);
            content = content.replace("<img-temp " + contentId + ">", "");
          }
        })
      );
      return content;
    },
    editChapter(chapter = null) {
      if (chapter) this.learningChapter = JSON.parse(JSON.stringify(chapter));
      else {
        // Object.assign(this.learningChapter, initialState());
        this.learningChapter = initialState().learningChapter;
      }
      this.dialog.chapterEditor = true;
    },
    async editCard(chapterId, card, quiz = false) {
      console.log("Editing card ", card);
      this.learningCard.learningChapterId = null;
      this.learningCard.learningCardId = null;
      this.dialog.libraryCardsToAdd = [];

      if (!this.routeValidation[0])
        return console.log("User has invalid module state so we can't proceed");
      this.goToSlide(
        quiz || (card && ["quiz", "assessment", "survey"].includes(card.type))
          ? this.keys.quizEditor
          : this.keys.cardEditor,
        true
      );
      this.cardMedia = initialState().cardMedia;
      if (card) {
        //Now we need to load in any media for previewing
        if (!card.id) card.id = uuidv4();
        if (
          card.Content &&
          card.Content.status != "Processing" &&
          !card.contentUrlSigned
        ) {
          let url = `${card.Content.filePath}${
            card.type == "video" ? "/encoded/hd.mp4" : ""
          }`;
          console.log("Loading signed url for content " + url, card.Content);
          let signedUrlResponse = await ContentService.getSignedUrlForViewingContent(
            url,
            2
          );

          console.log(
            `Learning card content ${card.Content.contentId} url ${signedUrlResponse.url}`
          );
          card.contentUrlSigned = signedUrlResponse.url;
        }

        // If a quiz, we prep the boolean toggles for quiz settings
        card.randomPool = !!card.numRandomQuestions;

        this.learningCard = JSON.parse(JSON.stringify(card));

        // Now we get embedded content if there is any
        // We store it to the card so then it gets cached. Then we store it to the learning card we're currently editing
        card.content = await this.getEmbeddedContent(card.content);
        this.learningCard.content = JSON.parse(JSON.stringify(card.content));

        // ContentUrlSigned will only have a value if we've already fetched and cached it
        if (card.contentUrlSigned)
          this.cardMedia.url = this.learningCard.contentUrlSigned;
      } else {
        this.learningCard = initialState().learningCard;
        this.learningCard.id = uuidv4();
      }
      this.learningCard.learningChapterId = chapterId;
      if (
        quiz ||
        ["quiz", "assessment", "survey"].includes(this.learningCard.type)
      )
        this.editQuiz(card);
    },
    async editQuiz(card) {
      this.expanded.questions = [];
      this.expanded.quizSettings = false;
      this.deletedQuizAnswers = [];
      this.deletedQuizQuestions = [];
      if (card) {
        console.log("CARD QUESTIONS??? ", card.LearningQuizQuestions);
        this.quizQuestions = card.LearningQuizQuestions
          ? JSON.parse(JSON.stringify(card.LearningQuizQuestions))
          : [];
        this.quizQuestions.forEach(q => {
          if (
            q.type == "SCALE" &&
            (!q.LearningQuizAnswers || !q.LearningQuizAnswers.length)
          )
            q.LearningQuizAnswers = [initialState().quizAnswer];
        });
      } else {
        let question = initialState().quizQuestion;
        question.LearningQuizAnswers = [initialState().quizAnswer];
        this.quizQuestions = [question];
        this.expanded.questions.push(0);

        this.learningCard.type = "quiz";
      }
    },
    newQuizQuestion() {
      let question = initialState().quizQuestion;
      question.LearningQuizAnswers = [initialState().quizAnswer];
      this.quizQuestions.push(question);
      this.expanded.questions.push(this.quizQuestions.length - 1);
    },
    removeQuizQuestion(questionIndex) {
      if (this.quizQuestions[questionIndex].learningQuizQuestionId)
        this.deletedQuizQuestions.push(
          this.quizQuestions[questionIndex].learningQuizQuestionId
        );
      this.quizQuestions.splice(questionIndex, 1);
    },
    newQuizAnswer(question) {
      question.LearningQuizAnswers.push(initialState().quizAnswer);
    },
    removeQuizAnswer(question, answerIndex) {
      console.log("REmoving answer index ", answerIndex);
      if (question.LearningQuizAnswers[answerIndex].learningQuizAnswerId) {
        console.log("Adding to deleted array");
        this.deletedQuizAnswers.push(
          question.LearningQuizAnswers[answerIndex].learningQuizAnswerId
        );
      }
      question.LearningQuizAnswers.splice(answerIndex, 1);
    },
    updateClientId(clientId) {
      // this.resetAllData();
      const initialStateObj = initialState();

      this.data = initialStateObj.data;
      this.clientId = clientId;
      // this.getLearningModules();
      this.getContent();
      // this.getGroupsV2(true);
      this.getTagDefinitions();
    },
    // Image selection handlers after the user exits from the image dropdown
    imageFileChanged(e, dragAndDrop = false) {
      console.log({ e, dragAndDrop });
      const imageRegex = /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/i;
      ///[^\\s]+(.*?)\\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
      // const pdfRegex = /[^\\s]+(.*?)\\.(pdf|PDF)$/;
      const pdfRegex = /\.(pdf|PDF)$/i;
      const videoRegex = /\.(mp4|MP4)$/i;
      var file;
      if (dragAndDrop && e.dataTransfer.files.length)
        file = e.dataTransfer.files[0];
      else if (!dragAndDrop && e.target.files.length) file = e.target.files[0];
      if (file) {
        this.image.invalidFileType = false;
        this.cardMedia.invalidFileType = false;
        // Load file into temp variable for cropping
        console.log("File type: ", file.type);
        console.log("Image Regex test", imageRegex.test(file.name));
        console.log("PDF Regex test", pdfRegex.test(file.name));
        console.log("Vidoe Regex test", videoRegex.test(file.name));
        if (this.slideKey == this.keys.basics) {
          // Add blocker if not image
          if (
            !(
              // Either the file type is png or jpg
              // Or no file type and the extension is png/jpg
              (
                (file.type &&
                  file.type.includes("image/") &&
                  (file.type == "image/png" || file.type == "image/jpeg")) ||
                (!file.type && imageRegex.test(file.name))
              )
            )
          )
            return (this.image.invalidFileType = true);
          this.image.cropUrl = URL.createObjectURL(file);
          this.image.fileName = file.name;
          this.dialog.imageCropper = true;
        } else {
          if (
            !(
              this.learningCard.type == "video" ||
              this.learningCard.type == "audio" ||
              this.learningCard.type == "pdf" ||
              this.learningCard.type == "video_embed"
            )
          ) {
            if (
              !(
                // Either the file type is png or jpg
                // Or no file type and the extension is png/jpg
                (
                  (file.type &&
                    file.type.includes("image/") &&
                    (file.type == "image/png" || file.type == "image/jpeg")) ||
                  (!file.type && imageRegex.test(file.name))
                )
              )
            )
              return (this.cardMedia.invalidFileType = true);

            this.cardMedia.cropUrl = URL.createObjectURL(file);
            this.cardMedia.fileName = file.name;
            this.dialog.imageCropper = true;
          } else {
            if (
              (this.learningCard.type == "audio" &&
                file.type &&
                !file.type.includes("audio/")) ||
              (this.learningCard.type == "pdf" &&
                !(
                  (file.type && file.type.includes("pdf")) ||
                  (!file.type && pdfRegex.test(file.name))
                )) ||
              (this.learningCard.type == "video" &&
                !(
                  (file.type && file.type == "video/mp4") ||
                  (!file.type && videoRegex.test(file.name))
                ))
            )
              return (this.cardMedia.invalidFileType = true);
            this.cardMedia.file = file;

            if (this.learningCard.type == "video")
              this.cardMedia.url = URL.createObjectURL(file);
          }
        }

        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        var uploader = document.querySelector(
          this.slideKey == this.keys.basics ? "#uploader" : "#cardUploader"
        );
        if (uploader) uploader.value = "";
      }
    },
    removeImage() {
      this.image = initialState().image;
    },
    // Called after cropping the image
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false

      this.dialog.imageCropper = false;
      if (submitPressed) {
        const { canvas } = this.$refs.imageCropper.getResult();
        if (this.slideKey == this.keys.basics)
          this.image.url = canvas.toDataURL();
        else this.cardMedia.url = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          console.log("Image blob ", blob);
          var file = new File(
            [blob],
            this.slideKey == this.keys.basics
              ? this.image.fileName
              : this.cardMedia.fileName
          );
          console.log("New image file ", file);
          if (this.slideKey == this.keys.basics) this.image.file = file;
          else this.cardMedia.file = file;

          //Regardless, we close dialog
          this.image.cropUrl = null;
          this.image.fileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.image.cropUrl = null;
        this.image.fileName = null;
      }
    },
    removeCardMedia() {
      this.cardMedia = initialState().cardMedia;
      // We also remove any association to the banner content
      this.learningCard.bannerContentId = null;
      this.learningCard.contentUrlSigned = null;
    },
    // Loads resource widget when on module elements
    loadResourceWidget() {
      this.dialog.resourceWidget = true;
    },
    // Emitted by the GroupCreatorWidget, saves module and pushes to groups page
    async pushToGroups() {
      this.dialog.groupCreator = false;
      this.$router.push({ name: "groups" });
    },
    // Emmitted by draggable when the order of chapters changes. We have to force the last item in case they changed that
    async updateChapterOrder() {
      try {
        var endCardIndex = this.learningChapters.findIndex(x => x.order == -1);
        console.log("End card index ", endCardIndex);
        if (
          endCardIndex != -1 &&
          endCardIndex != this.learningChapters.length - 1
        ) {
          // We have to move that el
          var tempChapter = this.learningChapters[endCardIndex];
          this.learningChapters.splice(endCardIndex, 1);
          this.learningChapters.push(tempChapter);
        }

        var chapters = this.learningChapters.map((x, index) => {
          return {
            clientId: this.clientId,
            learningChapterId: x.learningChapterId,
            learningModuleId: this.learningModule.learningModuleId,
            order: index,
            updatedBy: this.userProfile.userId
          };
        });
        // Update the last chapter to be -1 since it's the end card
        chapters[chapters.length - 1].order = -1;
        console.log("update chapter order ", chapters);

        var orderUpdateResponse = await LearningService.updateLearningChapterBulk(
          chapters,
          2
        );
        console.log("Updated chapter order ", orderUpdateResponse);
      } catch (err) {
        console.log("Error updating chapter order ", err);
      }
    },
    // Emmitted by draggable when the order of cards changes.
    async updateCardOrder() {
      try {
        var endCardIndex = this.learningChapters.findIndex(x => x.order == -1);
        if (
          endCardIndex != -1 &&
          endCardIndex != this.learningChapters.length - 1
        ) {
          // We have to move that el
          var tempChapter = this.learningChapters[endCardIndex];
          this.learningChapters.splice(endCardIndex, 1);
          this.learningChapters.push(tempChapter);
        }

        var associations = [];
        this.learningChapters.forEach(chapter => {
          associations = associations.concat(
            (chapter.LearningCards || []).map((card, index) => {
              return {
                clientId: this.clientId,
                learningChapterId: chapter.learningChapterId,
                learningCardAssociationId: card.learningCardAssociationId,
                order: index,
                updatedBy: this.userProfile.userId
              };
            })
          );
        });
        console.log("update card order ", associations);

        var orderUpdateResponse = await LearningService.updateLearningCardAssociationBulk(
          associations,
          2
        );
        console.log("Updated card order ", orderUpdateResponse);
      } catch (err) {
        console.log("Error updating card order ", err);
      }
    },
    // Emmitted by draggable when the order of resources changes.
    async updateResourceOrder() {
      try {
        var resources = this.resources.map((x, index) => {
          return {
            clientId: this.clientId,
            learningModuleAttachmentId: x.learningModuleAttachmentId,
            learningModuleId: this.learningModule.learningModuleId,
            order: index,
            updatedBy: this.userProfile.userId
          };
        });
        console.log("update resource order ", resources);

        var orderUpdateResponse = await LearningService.createLearningAttachmentBulk(
          resources,
          2
        );
        console.log("Updated resource order ", orderUpdateResponse);
      } catch (err) {
        console.log("Error updating resource order ", err);
      }
    },
    toggleLearningChapter(id) {
      if (this.expanded.chapters.find(x => x == id))
        this.expanded.chapters = this.expanded.chapters.filter(x => x != id);
      else this.expanded.chapters.push(id);
    },
    toggleQuizQuestion(index) {
      if (this.expanded.questions.find(x => x == index) !== undefined)
        this.expanded.questions = this.expanded.questions.filter(
          x => x != index
        );
      else this.expanded.questions.push(index);
    },
    formatVideoEmbedSource(originalSrc) {
      if (!originalSrc || !originalSrc.startsWith("http")) return "";
      let newSource = "";

      if (originalSrc.includes("youtube.com")) {
        newSource = originalSrc.replace("watch?v=", "embed/");

        if (!newSource.includes("?rel=0")) {
          newSource = newSource + "?rel=0";
        }
      } else {
        newSource = originalSrc.replace(
          "https://vimeo.com/",
          "https://player.vimeo.com/video/"
        );
      }

      console.log("compute src", newSource);
      return newSource;
    },
    updateQuizCheckboxes(question, correctness, index) {
      if (correctness != 1 || question.type != "MULTIPLE_CHOICE") return;
      question.LearningQuizAnswers.forEach((answer, i) => {
        if (i != index) answer.correctness = 0;
      });
    },
    updatedQuizQuestionType(question) {
      for (var i = question.LearningQuizAnswers.length - 1; i >= 0; i--) {
        this.removeQuizAnswer(question, i);
      }

      question.lowerBound = null;
      question.upperBound = null;
      if (["MULTIPLE_CHOICE", "SELECT_ALL", "SCALE"].includes(question.type)) {
        question.LearningQuizAnswers = [initialState().quizAnswer];
      } else question.LearningQuizAnswers = [];
    },
    updatedQuizType() {
      if (this.learningCard.type != "survey") return;
      // When the quiz type changes, all we care about is removing any correct answers
      this.quizQuestions.forEach(question => {
        for (var i = question.LearningQuizAnswers.length - 1; i >= 0; i--) {
          if (question.type == "scale") this.removeQuizAnswer(question, i);
          else question.LearningQuizAnswers[i].correctness = 0;
        }

        if (question.type == "scale")
          question.LearningQuizAnswers = [initialState().quizAnswer];
      });
    },
    loadCardLibrary(chapterId) {
      this.dialog.libraryWidget = true;
      this.dialog.libraryChapterId = chapterId;
      this.scrollToTop();
    },
    async addCardsFromLibrary(cards) {
      if (!cards || !cards.length) return;
      console.log("Adding cards from library", cards);

      this.dialog.libraryCardsToAdd = [];
      let chapterIndex = this.learningChapters.findIndex(
        x => x.learningChapterId == this.dialog.libraryChapterId
      );
      if (chapterIndex == -1) return;
      if (!this.learningChapters[chapterIndex].LearningCards)
        this.learningChapters[chapterIndex].LearningCards = [];
      cards.forEach(card => {
        card.id = uuidv4();
        this.learningChapters[chapterIndex].LearningCards.push(card);
      });
      const cardAssociations = cards.map(card => {
        return {
          clientId: this.clientId,
          learningCardId: card.learningCardId,
          learningChapterId: this.dialog.libraryChapterId
        };
      });
      let newAssociations = await LearningService.addExistingCardsToChapter(
        cardAssociations,
        2
      );
      this.learningCard.learningChapterId = null;
      // Now we need to add these new associations to the association arrays
      this.learningChapters.forEach(chapter => {
        for (var i = 0; i < chapter.LearningCards.length; i++) {
          let assoc = newAssociations.find(
            x => x.learningCardId == chapter.LearningCards[i].learningCardId
          );
          if (assoc && chapter.LearningCards[i].LearningCardAssociations) {
            chapter.LearningCards[i].LearningCardAssociations.push(assoc);

            // We also want to store the associationId to the card we just dragged in too
            // This is so if we duplicate this card in the same session, then we have the association id
            let cardIndex = cards.findIndex(
              x => x.id == chapter.LearningCards[i].id
            );
            if (cardIndex != -1)
              chapter.LearningCards[i].learningCardAssociationId =
                assoc.learningCardAssociationId;
          }
        }
      });
    },
    async removeDuplicateCard(assocId, cardId) {
      // this is a helper function to be called in saveLearningCard when we clone a card
      // It's also called when deleting a card so we can remove other references to it
      console.log("Deleting duplicate card ", assocId);
      try {
        var response = await LearningService.deleteLearningCardAssociation(
          assocId,
          2
        );
        console.log("Deleted card association response: " + assocId, response);

        // Now we just need to remove this association from any local references
        this.learningChapters.forEach(chapter => {
          for (var i = 0; i < chapter.LearningCards.length; i++) {
            if (
              chapter.LearningCards[i].learningCardId == cardId &&
              chapter.LearningCards[i].LearningCardAssociations
            ) {
              // If we're looking at the card that we're duplicating, we remove the old references to it
              chapter.LearningCards[
                i
              ].LearningCardAssociations = chapter.LearningCards[
                i
              ].LearningCardAssociations.filter(
                x => x.learningCardAssociationId != assocId
              );
            }
          }
        });
        // chapter.LearningCards.splice(cardIndex, 1);
      } catch (error) {
        console.log("Error removing old duplicate association id", error);
        throw error;
      }
    },
    retryApi() {
      let c = this.loading.apiContext;
      console.log("Retrying api for context ", c);
      if (c == "initialLoad") this.loadBuilder();
      else if (c == "learningModule") this.createLearningModule();
      else if (c == "audience") this.replaceLearningModuleGroups();
      else if (c == "learningChapter") this.createLearningChapter();
      else if (c == "learningChapterDelete") this.deleteLearningChapter();
      else if (c == "learningCard") this.createLearningCard();
      else if (c == "learningCardDelete") this.deleteLearningCard();
      else if (c == "learningQuiz") this.createLearningQuiz();
      else if (c == "resource") this.createResource();
      else if (c == "resourceDelete") this.deleteResource();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    slideTitle() {
      let titles = {
        1: "Start with the basics of your module. A name, description, and image.",
        2: "Select who can access this module.",
        3: "Add your learning elements here. This section is where you put your content.",
        4: "What information do you want to share today?",
        5: "What knowledge do you want to test today?",
        100: "Double check the details of your module.",
        101: "You're almost done!"
      };
      return titles[this.slideKey];
    },
    // formattedModuleDescription() {
    //   // Formats v-textarea with html tags when they enter into a new line
    //   if (!this.learningModule.description) return null;
    //   let arr = [];
    //   if (this.learningModule.description) {
    //     this.learningModule.description
    //       .split("\n")
    //       .forEach(item => arr.push(`<p>${item.trim()}</p>`));
    //   }
    //   return arr.join("");
    // },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    audienceHeaders() {
      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },
        {
          align: "start",
          sortable: true,
          value: "groupDisplayName"
        },
        {
          align: "start",
          sortable: true,
          value: "numUsers",
          width: "50%"
        },

        {
          value: "info",
          width: "100px",
          align: "end",
          sortable: false
        }
      ];
    },
    route: {
      cache: false,
      get: function() {
        if (
          this.slideKey == this.keys.quizEditor ||
          (this.slideKey == this.keys.cardEditor &&
            ["quiz", "assessment", "survey"].includes(this.learningCard.type))
        )
          return [
            this.keys.basics,
            this.keys.audience,
            this.keys.elements,
            // this.keys.cardEditor,
            this.keys.quizEditor,
            this.keys.elements
          ];
        else if (this.slideKey == this.keys.cardEditor)
          return [
            this.keys.basics,
            this.keys.audience,
            this.keys.elements,
            this.keys.cardEditor,
            this.keys.elements
          ];
        return [
          this.keys.basics,
          this.keys.audience,
          this.keys.elements,
          this.keys.review,
          this.keys.publishing,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (
            page == this.keys.basics &&
            this.learningModule.name &&
            this.learningModule.name.length < 150 &&
            this.learningModule.description &&
            this.learningModule.description.length < 240 &&
            this.image.url
          ) {
            array.push(true);
          } else if (page == this.keys.audience && this.audience.length > 0) {
            array.push(true);
          } else if (
            page == this.keys.elements &&
            this.learningChapters.length > 1 &&
            !this.learningChapters.find(x => !x.LearningCards.length)
          ) {
            array.push(true);
          } else if (
            page == this.keys.cardEditor &&
            this.learningCard.displayName &&
            this.form.card
          ) {
            array.push(true);
          } else if (
            page == this.keys.quizEditor &&
            this.form.quiz &&
            this.quizQuestions.length &&
            !this.quizValidation.includes(false) &&
            // Advanced quiz settings
            (!this.learningCard.randomPool ||
              (this.learningCard.numRandomQuestions ==
                parseInt(this.learningCard.numRandomQuestions) &&
                this.learningCard.numRandomQuestions >= 1 &&
                this.learningCard.numRandomQuestions <= 100)) &&
            (!this.learningCard.passingRequired ||
              this.learningCard.type != "quiz" ||
              // this.learningCard.passingScore ==
              // parseInt(this.learningCard.passingScore) &&
              (this.learningCard.passingScore >= 0 &&
                this.learningCard.passingScore <= 100))
          ) {
            array.push(true);
          } else if (
            // if they somehow skipped ahead, we want to block them if no elements, no reward, no name, or no groups
            page == this.keys.review &&
            this.learningChapters.length > 1 &&
            !this.learningChapters.find(x => !x.LearningCards.length) &&
            this.audience.length &&
            this.learningModule.name &&
            this.learningModule.description &&
            this.image.url
          ) {
            array.push(true);
          } else if (page == this.keys.publishing) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    quizValidation: {
      cache: false,
      get: function() {
        if (this.slideKey != this.keys.quizEditor) return [true];
        var array = [];

        this.quizQuestions.forEach(question => {
          if (
            ["SHORT_ANSWER", "LIKERT"].includes(question.type) &&
            question.content &&
            question.content.length <= 750
          )
            array.push(true);
          else if (
            question.type == "SCALE" &&
            question.content &&
            question.content.length <= 750 &&
            // Making sure lower and upper bounds are defined
            question.lowerBound !== undefined &&
            question.lowerBound !== null &&
            question.upperBound !== undefined &&
            question.upperBound !== null &&
            // Checks to make sure the bounds are ints
            parseInt(question.lowerBound) == question.lowerBound &&
            parseInt(question.upperBound) == question.upperBound &&
            // Upper and lower can't be equal
            parseInt(question.lowerBound) < parseInt(question.upperBound) &&
            // We can't render massive ranges, so must be less than 1k
            parseInt(question.upperBound) - parseInt(question.lowerBound) <=
              1000 &&
            // Has to be smaller than max int
            parseInt(question.lowerBound) < parseInt(this.maxIntSize) &&
            parseInt(question.upperBound) < parseInt(this.maxIntSize) &&
            // Either no answer array
            (!question.LearningQuizAnswers.length ||
              // OR there are answers
              (question.LearningQuizAnswers[0] &&
                // Correct answer content either doesn't exist, or if it does, it must be right
                (!question.LearningQuizAnswers[0].content ||
                  (parseInt(question.LearningQuizAnswers[0].content) ==
                    question.LearningQuizAnswers[0].content &&
                    // Must be higher than the lower bound and smaller than the upper
                    parseInt(question.LearningQuizAnswers[0].content) >=
                      parseInt(question.lowerBound) &&
                    parseInt(question.LearningQuizAnswers[0].content) <=
                      parseInt(question.upperBound)))))
          )
            array.push(true);
          else if (
            ["MULTIPLE_CHOICE", "SELECT_ALL"].includes(question.type) &&
            question.content &&
            question.content.length <= 750 &&
            question.LearningQuizAnswers &&
            question.LearningQuizAnswers.length &&
            // No answers that are missing a label or are too long
            !question.LearningQuizAnswers.find(
              x => !x.content || (x.content && x.content.length > 255)
            )
          )
            array.push(true);
          else array.push(false);
        });

        return array;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    hasEditedModule() {
      return false;
      // return (
      //   this.slideKey == this.keys.basics &&
      //   (this.learningModule.name ||
      //     this.learningModule.description ||
      //     this.image.url ||
      //     this.image.file)
      // );
    },
    computedLoadingHeader() {
      if (!this.dialog.loadingAPI || !this.loading.apiContext) return "";

      const loading = {
        initialLoad: "Loading your module...",
        learningModule: "Saving module...",
        learningModulePublish: "Publishing module...",
        audience: "Saving module audience...",
        learningChapter: "Saving chapter...",
        learningChapterDelete: "Deleting chapter...",
        learningCard: "Saving card...",
        learningCardDelete: "Deleting card...",
        learningQuiz: "Saving quiz...",
        resource: "Adding resource...",
        resourceDelete: "Deleting resource..."
      };

      return loading[this.loading.apiContext];
    },
    computedLoadingMessage() {
      if (
        !this.dialog.loadingAPI ||
        !this.loading.apiContext ||
        this.loading.apiError
      )
        return "";

      const loading = {
        initialLoad: "Please wait while we load your learning module.",
        learningModule:
          "Please wait while we save the changes you've made to the learning module.",
        learningModulePublish:
          "Please wait while we publish your learning module.",
        audience: "Please wait while we update the learning module's audience.",
        learningChapter:
          "Please wait while we save the changes you've made to the learning chapter.",
        learningChapterDelete:
          "Please wait while we delete the learning chapter.",
        learningCard:
          "Please wait while we save the changes you've made to the learning card.",
        learningCardDelete: "Please wait while we delete the learning card.",
        learningQuiz:
          "Please wait while we save the changes you've made to the learning quiz.",
        resource:
          "Please wait while we add the resource to the learning module.",
        resourceDelete: "Please wait while we delete the resource."
      };

      return loading[this.loading.apiContext];
    },
    computedApiError() {
      // We use the context variable so we can return friendly error messages
      if (!this.loading.apiContext || !this.loading.apiError) return "";

      const errors = {
        initialLoad: {
          header: "Error loading module",
          message: "There was an issue loading your module."
        },
        learningModule: {
          header: "Error saving module",
          message: "We had an issue when saving your module."
        },
        learningModulePublish: {
          header: "Error publishing module",
          message: "We had an issue when publishing your module."
        },
        audience: {
          header: "Error saving audience",
          message:
            "We had an issue saving your audience changes for the learning module."
        },
        learningChapter: {
          header: "Error saving chapter",
          message: "We had an issue saving your learning chapter changes."
        },
        learningChapterDelete: {
          header: "Error deleting chapter",
          message: "We had an issue deleting your learning chapter."
        },
        learningCard: {
          header: "Error saving card",
          message: "We had an issue saving the changes to your learning card."
        },
        learningCardDelete: {
          header: "Error deleting card",
          message: "We had an issue deleting your learning card."
        },
        learningQuiz: {
          header: "Error saving quiz",
          message: "We had an issue saving the changes to your learning quiz."
        },
        resource: {
          header: "Error adding resource",
          message:
            "We had an issue adding the resource to your learning module."
        },
        resourceDelete: {
          header: "Erorr deleting resource",
          message:
            "We had an issue deleting the resource from your learning module."
        }
      };
      return errors[this.loading.apiContext];
    },
    videoPlayerOptions() {
      // Used for the video preview for video cards
      return {
        height: 400,

        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "video/mp4",
            src:
              this.cardMedia.url ||
              this.learningCard.contentUrlSigned ||
              this.learningCard.contentUrl ||
              null
          }
        ]
      };
    },
    filteredCardTypes() {
      // We want to hide audio and pdf unless the user is editing an older audio/pdf card
      let excludedTypes = [];
      // if (this.learningCard && this.learningCard.type == "pdf")
      // excludedTypes.push()
      // else
      if (this.learningCard && this.learningCard.type !== "audio")
        excludedTypes.push("audio");
      if (!this.givingClient && this.learningCard.type !== "giving")
        excludedTypes.push("giving");
      return this.data.cardTypes.filter(x => !excludedTypes.includes(x.value));
    },
    givingClient() {
      // Indicates if the client is set up to support Giving cards
      return (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom1 == "GIVING"
      );
    },
    maxIntSize() {
      // max int so we can validate integers
      return 2147483648;
    },
    continueButtonTooltip() {
      // A ridiculously long if/else for showing the tooltip above the next button
      if (!this.disableContinueButton) return null;

      var error = null;
      if (this.slideKey == this.keys.basics) {
        error = "Your module needs a";
        if (!this.learningModule.name) error += " name.";
        else if (this.learningModule.name.length >= 150)
          error += " shorter name.";
        else if (!this.learningModule.description) error += " description.";
        // else if (this.formattedModuleDescription.length >= 240)
        //   error += " shorter description.";
        else if (!this.image.url) error += "n image.";
        else error = null;
      } else if (this.slideKey == this.keys.audience && !this.audience.length)
        error = "Your module needs at least one group.";
      else if (this.slideKey == this.keys.elements) {
        // Here we want to catch a bunch of different errors and give descriptive errors
        if (this.learningChapters.length == 0)
          error = "Your module needs some learning chapters.";
        else if (this.learningChapters.length == 1)
          error = "Your module needs more than just your conclusion chapter.";
        else if (
          this.learningChapters.find(
            x => !x.LearningCards || !x.LearningCards.length
          )
        ) {
          error =
            "Chapter " +
            (this.learningChapters.findIndex(
              x => !x.LearningCards || !x.LearningCards.length
            ) +
              1) +
            " is missing learning cards.";
        } else error = null;
      } else if (this.slideKey == this.keys.cardEditor) {
        error = "Your card needs a";
        if (!this.learningCard.displayName) error += " name.";
        else if (this.learningCard.displayName.length >= 256)
          error += " shorter name.";
        else if (
          this.learningCard.type == "video_embed" &&
          this.learningCard.contentUrl &&
          this.learningCard.contentUrl.length > 255
        )
          error += " shorter video link.";
        else error = null;
      } else if (this.slideKey == this.keys.quizEditor) {
        error = "Your quiz needs a";
        if (!this.learningCard.displayName) error += " name.";
        else if (this.learningCard.displayName.length >= 256)
          error += " shorter name.";
        else if (
          this.learningCard.passingRequired &&
          this.learningCard.type == "quiz" &&
          !this.learningCard.passingScore
        )
          error += " a passing score value.";
        else if (
          this.learningCard.passingRequired &&
          this.learningCard.type == "quiz" &&
          this.learningCard.passingScore &&
          (parseInt(this.learningCard.passingScore) < 0 ||
            parseInt(this.learningCard.passingScore) > 100)
        )
          error += " a passing score value that is between 0 and 100.";
        else if (
          this.learningCard.randomPool &&
          !this.learningCard.numRandomQuestions
        )
          error += " a random question pool count.";
        else if (
          this.learningCard.randomPool &&
          this.learningCard.numRandomQuestions &&
          this.learningCard.numRandomQuestions !=
            parseInt(this.learningCard.numRandomQuestions)
        )
          error += " a random question pool count that is a whole number.";
        else if (
          this.learningCard.randomPool &&
          this.learningCard.numRandomQuestions &&
          (parseInt(this.learningCard.numRandomQuestions) < 1 ||
            parseInt(this.learningCard.numRandomQuestions) > 100)
        )
          error += " a random question pool count that is between 1 and 100.";
        else if (this.quizQuestions.find(x => !x.content))
          error =
            "Question " +
            (this.quizQuestions.findIndex(x => !x.content) + 1) +
            " is missing a name.";
        else if (
          this.quizQuestions.find(x => x.content && x.content.length > 750)
        )
          error =
            "Question " +
            (this.quizQuestions.findIndex(
              x => x.content && x.content.length > 750
            ) +
              1) +
            " has a name that is too long.";
        else if (
          this.quizQuestions.find(
            x =>
              (x.type == "MULTIPLE_CHOICE" || x.type == "SELECT_ALL") &&
              (!x.LearningQuizAnswers.length ||
                x.LearningQuizAnswers.find(y => !y.content))
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                (x.type == "MULTIPLE_CHOICE" || x.type == "SELECT_ALL") &&
                (!x.LearningQuizAnswers.length ||
                  x.LearningQuizAnswers.find(y => !y.content))
            ) +
              1) +
            " needs at least one answer.";
        else if (
          this.quizQuestions.find(
            x =>
              (x.type == "MULTIPLE_CHOICE" || x.type == "SELECT_ALL") &&
              x.LearningQuizAnswers.find(
                y => y.content && y.content.length > 255
              )
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                (x.type == "MULTIPLE_CHOICE" || x.type == "SELECT_ALL") &&
                x.LearningQuizAnswers.find(
                  y => y.content && y.content.length > 255
                )
            ) +
              1) +
            " has an answer that is too long.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              !(
                x.lowerBound !== undefined &&
                x.lowerBound !== null &&
                x.upperBound !== undefined &&
                x.upperBound !== null
              )
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                !(
                  x.lowerBound !== undefined &&
                  x.lowerBound !== null &&
                  x.upperBound !== undefined &&
                  x.upperBound !== null
                )
            ) +
              1) +
            " needs a lower and upper bound.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              !(
                parseInt(x.lowerBound) == x.lowerBound &&
                parseInt(x.upperBound) == x.upperBound
              )
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                !(
                  parseInt(x.lowerBound) == x.lowerBound &&
                  parseInt(x.upperBound) == x.upperBound
                )
            ) +
              1) +
            " needs a lower and upper bound that are both whole numbers.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              parseInt(x.lowerBound) >= parseInt(x.upperBound)
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                parseInt(x.lowerBound) >= parseInt(x.upperBound)
            ) +
              1) +
            " needs a lower bound that is less than the upper bound.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              parseInt(x.upperBound) - parseInt(x.lowerBound) > 1000
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                parseInt(x.upperBound) - parseInt(x.lowerBound) > 1000
            ) +
              1) +
            " needs a lower and upper bound that are less than 1000 apart.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              x.LearningQuizAnswers &&
              x.LearningQuizAnswers.length &&
              x.LearningQuizAnswers[0].content &&
              parseInt(x.LearningQuizAnswers[0].content) !=
                x.LearningQuizAnswers[0].content
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                x.LearningQuizAnswers &&
                x.LearningQuizAnswers.length &&
                x.LearningQuizAnswers[0].content &&
                parseInt(x.LearningQuizAnswers[0].content) !=
                  x.LearningQuizAnswers[0].content
            ) +
              1) +
            " needs a correct answer that is a whole number.";
        else if (
          this.quizQuestions.find(
            x =>
              x.type == "SCALE" &&
              x.LearningQuizAnswers &&
              x.LearningQuizAnswers.length &&
              x.LearningQuizAnswers[0].content &&
              (parseInt(x.LearningQuizAnswers[0].content) < x.lowerBound ||
                parseInt(x.LearningQuizAnswers[0].content) > x.upperBound)
          )
        )
          error =
            "Quiz question " +
            (this.quizQuestions.findIndex(
              x =>
                x.type == "SCALE" &&
                x.LearningQuizAnswers &&
                x.LearningQuizAnswers.length &&
                x.LearningQuizAnswers[0].content &&
                (parseInt(x.LearningQuizAnswers[0].content) < x.lowerBound ||
                  parseInt(x.LearningQuizAnswers[0].content) > x.upperBound)
            ) +
              1) +
            " needs a correct answer that is between the lower and upper bound.";
        else error = null;
      }

      return error;
    },
    programWizardPopout() {
      return !!(
        this.$route.query.source && this.$route.query.source == "program_wizard"
      );
    },
    preloadedLearningModuleId() {
      return this.learningModuleId || this.$route.query.learningModuleId;
    }
  },
  watch: {
    "dialog.elementDeleteConfirmation": function(newVal) {
      if (newVal === false) {
        console.log("Watcher for delete box triggered");
        // They closed the delete confirmation so we have to clear the variables so something else doesn't get accidentally deleted
        this.$nextTick(() => {
          this.dialog.chapterDeleteId = null;
          this.dialog.cardDeleteId = null;
          this.dialog.resourceDeleteId = null;
          this.dialog.elementDeleteContext = null;
          this.dialog.resourceContentObject = null;
        });
      }
    },
    "groupTable.debounceSearch": debounce(function(newVal) {
      this.search.audience = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.header-text {
  font-size: x-large;
}

/* Light grey used when hovering over module elements */
.brand-gradient-background {
  background-color: #eef0f8;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Used to cap length of quiz question names */
.quiz-question-name {
  /* width: 300px; */
  /* overflow-wrap: break-word; */
  width: calc(100% - 110px);
  max-width: calc(100% - 110px);
}
/* Adds grey background to search box */
.search-field >>> .v-input__slot {
  /* border-style: none !important;
  border-radius: 2px !important; */
  background: var(--v-lightGrey-base) !important;
}

/* Classes used for elements in left side nav */
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}

/* CSS for inner module element boxes because they have grabber icons */
.module-elements-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* height: 35px; */
  padding-top: 7px;
}
.module-elements-height {
  height: 35px;
  overflow-y: hidden;
}
.module-elements-outline {
  border: 1px solid lightgray;
  border-radius: 5px;
}
/* Special class for chapters because they're a bit different */
.chapter-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* height: 35px; */
  padding-top: 3px;
}

/* Used to cap the text for the display names for elements if they're too long */
.module-element-label {
  width: calc(100%);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Used for dialog popup boxes that  */
.dialog {
  z-index: 999;
}

/* Removes padding for expanstion panel used for video transcript */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

/* Outlined the warning box for cards that are in multiple spots */
.duplicate-warning-box {
  border: 1px solid var(--v-error-base);
  border-radius: 5px;
}

.quiz-settings-box {
  border: 1px solid lightgray;
}
</style>
