<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="brandCyan" flat rounded="0">
      <v-toolbar-title v-if="!clientToEdit">Create client </v-toolbar-title>
      <v-toolbar-title v-else>Update client</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
        <v-btn
          dark
          text
          :disabled="!form.valid"
          @click="
            if (clientToEdit) {
              updateClient();
            } else {
              createClient();
            }
          "
        >
          Save
          <v-icon class="ml-2">mdi-content-save</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div key="0" class="mt-10">
      <v-card-text class="d-flex flex-column text-left pl-6">
        <h2>Enter client information</h2>
        <v-img
          v-if="imageUpload.imageUrl || client.companyPicUrl"
          :src="imageUpload.imageUrl || client.companyPicUrl"
          max-height="150"
          max-width="300px"
          contain
          @click="displayImageSelector"
          @drop.prevent="imageFileChanged($event, true)"
          @dragover.prevent
          class="cursor-pointer ma-3 mx-auto box-border"
        />
        <v-img
          v-else
          src="@/assets/placeholder_image.png"
          max-height="150"
          max-width="300px"
          contain
          @click="displayImageSelector"
          @drop.prevent="imageFileChanged($event, true)"
          @dragover.prevent
          class="cursor-pointer ma-3 mx-auto box-border-global"
        />
        <p class="red--text text-center" v-if="imageUpload.invalid">
          Invalid file type
        </p>
        <div class="d-flex justify-center">
          <v-btn
            class="mx-3"
            medium
            right
            text
            color="brandCyan"
            @click="displayImageSelector"
          >
            Upload Logo
          </v-btn>
          <v-btn
            v-if="client.companyPicUrl || imageUpload.imageUrl"
            class="mr-2"
            medium
            right
            text
            color="black"
            @click="deleteImage"
          >
            Delete Logo
          </v-btn>
        </div>
        <input
          id="uploader"
          ref="uploader"
          class="d-none"
          type="file"
          accept="image/*"
          @change="imageFileChanged($event, false)"
        />

        <v-form ref="form" v-model="form.valid" class="full-width pl-3 mt-6">
          <p v-if="!!clientToEdit">
            Client ID: <b>{{ client.clientId }}</b>
          </p>

          <v-text-field
            v-model="client.clientName"
            :rules="formRules.stringRequired(255)"
            label="Client name*"
            color="brandCyan"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="client.emailDomain"
            :rules="formRules.stringOptional(255)"
            label="Email domain name"
            color="brandCyan"
            outlined
            dense
          ></v-text-field>
          <v-select
            v-model="client.initialRouteOnLoad"
            label="Default page"
            :items="defaultPages"
            item-value="value"
            item-text="label"
            color="brandCyan"
            outlined
            dense
          ></v-select>
          <v-select
            v-model="client.onboardingLanguage"
            label="Language used in onboarding"
            :items="onboardingLanguages"
            item-value="value"
            item-text="label"
            color="brandCyan"
            outlined
            dense
          ></v-select>
          <v-autocomplete
            v-model="client.parentClientId"
            label="Parent client"
            :items="
              !clientToEdit
                ? clients
                : clients.filter(x => x.clientId != client.clientId)
            "
            item-value="clientId"
            item-text="formattedName"
            color="brandCyan"
            outlined
            dense
            clearable
          ></v-autocomplete>
          <v-text-field
            v-model="client.physicalCardFee"
            :rules="formRules.optionalAmount(true)"
            label="Physical Whistle Card fee"
            color="brandCyan"
            type="number"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-if="displayAnnualPaymentCap"
            v-model="client.annualPaymentCap"
            :rules="formRules.optionalAmount(false)"
            label="Annual payment cap"
            color="brandCyan"
            type="number"
            prefix="$"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="client.onboardingToken"
            :rules="[
              v =>
                !!(!v || (v && v.length > 5)) ||
                'Value should be 6+ characters long',
              v => !!(!v || (v && !v.includes('/'))) || 'Cannot contain \'/\'',
              v => !!(!v || (v && v.length < 128)) || 'Value should be shorter'
            ]"
            label="Custom signup link url extension"
            color="brandCyan"
            outlined
            dense
          ></v-text-field>
          <div class="full-width text-left">
            <span class="text-caption"
              >e.g. app.wewhistle.com/signup/dunder-mifflin would be entered as
              <strong>dunder-mifflin</strong></span
            >
          </div>
          <v-btn
            v-if="
              clientToEdit &&
                client.onboardingStatus == 'BLACKLIST' &&
                permissions.includes('clients:write:blacklist')
            "
            :loading="loading.blacklist"
            @click="removeFromBlacklist"
            color="brandCyan"
            class="white--text"
            depressed
            >Remove from blacklist</v-btn
          >
          <v-switch
            v-model="client.whitelabel"
            label="Whitelabel Whistle"
            color="brandCyan"
            hide-details
          ></v-switch>
          <!-- <v-switch
            v-model="client.paid"
            label="Mark as paid client"
            color="brandCyan"
            :disabled="!roles.find(x => x == '91WhistleClientAdminV2')"
            hide-details
          ></v-switch> -->
          <!-- <div
            class="full-width text-left"
            v-if="!roles.find(x => x == '91WhistleClientAdminV2')"
          >
            <span class="text-caption">
              Insufficient permissions to edit the paid status</span
            >
          </div> -->
          <v-switch
            v-model="client.displayUniversalLearning"
            label="Display universal learning"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.displayMobilePopup"
            label="Display mobile popup on web"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.magicLinksEnabled"
            label="Magic links enabled"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.procoreEnabled"
            label="Procore client"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-divider class="my-4" />
          <v-switch
            v-model="client.displayActivityPage"
            label="Display activity page"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.displayWalletPage"
            label="Display wallet page"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.displayProgramPage"
            label="Display program page"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-switch
            v-model="client.displayLibraryPage"
            label="Display library page"
            color="brandCyan"
            hide-details
          ></v-switch>
          <v-divider class="my-4" />
          <v-select
            v-model="client.reportColumn1"
            label="Reporting custom column 1"
            :items="customReportColumns"
            item-value="value"
            item-text="label"
            color="brandCyan"
          ></v-select>
          <v-select
            v-model="client.reportColumn2"
            label="Reporting custom column 2"
            :items="customReportColumns"
            item-value="value"
            item-text="label"
            color="brandCyan"
          ></v-select>
          <v-select
            v-model="client.reportColumn3"
            label="Reporting custom column 3"
            :items="customReportColumns"
            item-value="value"
            item-text="label"
            color="brandCyan"
          ></v-select>
          <v-select
            v-model="client.reportColumn4"
            label="Reporting custom column 4"
            :items="customReportColumns"
            item-value="value"
            item-text="label"
            color="brandCyan"
          ></v-select>
        </v-form>
      </v-card-text>
    </div>

    <!-- Image Cropping dialog -->
    <ImageCropper
      v-if="display.cropper"
      :fileName="imageUpload.fileName"
      :imageUrl="imageUpload.cropUrl"
      :imageisPNG="imageUpload.png"
      @close="resetCroppedImage"
      @success="saveCroppedImage"
    />
    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import OnboardingService from "@/services/OnboardingService";
import UserService from "@/services/UserService";
// import CommunicationService from "@/services/CommunicationService";

import LoadingDialog from "@/components/loadingDialog.vue";
import ImageCropper from "@/components/ImageCropper.vue";
// import Robin from "@/components/Robin.vue";
import ErrorDialog from "@/components/ErrorDialog";

import { mapState } from "vuex";

import { formRules, userColumns } from "@/shared_data/data.js";

function initialState() {
  return {
    showLoadingDialog: false,
    loadingHeaderMessage: null,
    loadingMessageLine1: null,
    loadingMessageLine2: null,
    showUploadResults: false,
    formRules,
    form: {
      valid: false
    },
    client: {
      clientId: null,
      clientName: null,
      emailDomain: null,
      initialRouteOnLoad: "wallet",
      onboardingLanguage: "PAYMENTS",
      parentClientId: null,
      physicalCardFee: 0,
      annualPaymentCap: null,
      // Leave onboardingToken as undefined so it autocreates a link
      // onboardingToken: null,
      whitelabel: false,
      // paid: false,
      displayUniversalLearning: true,
      displayMobilePopup: true,
      magicLinksEnabled: true,
      displayWalletPage: true,
      displayProgramPage: true,
      displayLibraryPage: true,
      displayActivityPage: true,
      reportColumn1: null,
      reportColumn2: null,
      reportColumn3: null,
      reportColumn4: null
    },
    defaultPages: [
      { label: "Activity", value: "activity" },
      { label: "Learning", value: "learning" },
      // { label: "Robin", value: "robin" },
      { label: "Wallet", value: "wallet" },
      { label: "Programs", value: "programs" },
      { label: "Reporting", value: "reporting" }

      // { label: "News", value: "news" },
    ],
    onboardingLanguages: [
      { label: "Payments", value: "PAYMENTS" },
      { label: "Learning", value: "LEARNING" }

      // { label: "News", value: "news" },
    ],
    customReportColumns: userColumns,
    // Used by the error component
    errorDialog: {
      display: false,
      override: false,
      title: null,
      subtitle: null,
      button1Text: null,
      button2Text: "Close"
    },
    loading: {
      blacklist: false
    },
    imageUpload: {
      file: null,
      imageUrl: null,
      cropUrl: null,
      fileName: null,
      invalid: false,
      png: false
    },
    display: {
      cropper: false
    }
  };
}

export default {
  name: "ClientCreatorWidget",
  components: {
    LoadingDialog,
    // Robin,
    ErrorDialog,
    ImageCropper
  },
  props: {
    clientId: Number,
    clientToEdit: Object
  },
  data() {
    return initialState();
  },
  created() {
    if (this.clientToEdit) {
      console.log("Loading in client object: ", this.clientToEdit);
      this.client = JSON.parse(JSON.stringify(this.clientToEdit));
    }

    // Set a timeout so the form has aa moment to set itself up and then we validate
    setTimeout(() => {
      if (this.clientToEdit && this.$refs.form) this.$refs.form.validate();
    }, 500);
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    getClients(e) {
      this.$emit("get-clients", e);
    },
    removeFromBlacklist() {
      this.loading.blacklist = true;
      OnboardingService.removeClientFromBlacklist(this.client.clientId).then(
        x => {
          console.log("Blacklist reponse ", x);
          this.getClients({
            page: 0,
            filter: `status == 'Active' && clientId == ${this.client.clientId}`,
            clientId: this.client.clientId
          });
          delete this.client.onboardingStatus;
          this.loading.blacklist = false;
        }
      );
    },
    resetForm() {
      Object.assign(this.$data, initialState());
    },
    displayImageSelector() {
      this.$refs.uploader.click();
    },
    imageFileChanged(e, dragAndDrop = false) {
      const imageRegex = /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/i;
      let file;
      if (dragAndDrop && e.dataTransfer.files.length)
        file = e.dataTransfer.files[0];
      else if (!dragAndDrop && e.target.files.length) file = e.target.files[0];

      console.log("Image file changed", { e, dragAndDrop, file });
      if (file) {
        console.log("File type: ", file.type);
        console.log("Image Regex test", imageRegex.test(file.name));

        if (
          !(
            // Either the file type is png or jpg
            // Or no file type and the extension is png/jpg
            (
              (file.type &&
                file.type.includes("image/") &&
                (file.type == "image/png" || file.type == "image/jpeg")) ||
              (!file.type && imageRegex.test(file.name))
            )
          )
        )
          return (this.imageUpload.invalid = true);

        // Load file into temp variable for cropping
        this.imageUpload.cropUrl = URL.createObjectURL(file);
        this.imageUpload.fileName = file.name;
        this.display.cropper = true;
        this.imageUpload.png = file.type == "image/png";
      }

      //This wipes the files in the uploader component so it detects a change even if you select the same image twice
      var uploader = document.querySelector("#uploader");
      console.log("Wiping uploader", uploader);
      if (uploader) uploader.value = "";
    },
    resetCroppedImage() {
      this.imageUpload = {
        file: null,
        imageUrl: null,
        cropUrl: null,
        invalid: false,
        fileName: null
      };
      this.display.cropper = false;
    },
    saveCroppedImage(e) {
      console.log("saveCroppedImage", { e });
      this.resetCroppedImage();
      const { file, imageUrl } = e;
      this.imageUpload.file = file;
      this.imageUpload.imageUrl = imageUrl;
    },
    deleteImage() {
      this.client.companyPicUrl = null;
      this.resetCroppedImage();
    },
    async createClient() {
      try {
        this.showLoadingDialog = true;
        this.loadingHeaderMessage = "Creating client";
        this.loadingMessageLine1 = "Please wait...";
        var client = this.client;
        client.systemTenantId = this.client.clientName;
        console.log(client);
        if (client.physicalCardFee && parseFloat(client.physicalCardFee) < 0)
          client.physicalCardFee = 0;

        client.physicalCardFee = parseFloat(client.physicalCardFee);
        const response = await UserService.createClientV2(
          client,
          this.imageUpload.file || null
        );
        console.log("Client creation response ", response);

        const maxClientId = Math.max.apply(
          null,
          this.clients.map(e => e.clientId)
        );
        if (maxClientId)
          this.getClients({
            page: 0,
            filter: `status == 'Active' && clientId > ${maxClientId}`
          });
        else this.getClients({ page: 0 });

        // We also refresh the client array used on the other pages
        this.$store.dispatch("setClients");
        this.close();
      } catch (err) {
        console.log("Client update error ", err);
        this.errorDialog.display = true;
        this.errorDialog.title = "There was an error creating the client";
        if (err.error_code && err.error) this.errorDialog.subtitle = err.error;
        else this.errorDialog.subtitle = null;
      } finally {
        this.showLoadingDialog = false;
      }
    },
    async updateClient() {
      try {
        this.showLoadingDialog = true;
        this.loadingHeaderMessage = "Updating client";
        this.loadingMessageLine1 = "Please wait...";
        console.log(this.client);
        if (
          this.client.physicalCardFee &&
          parseFloat(this.client.physicalCardFee) < 0
        )
          this.client.physicalCardFee = 0;

        this.client.physicalCardFee = parseFloat(this.client.physicalCardFee);
        console.log(this.imageUpload);
        const response = await UserService.updateClientV2(
          this.client.clientId,
          this.client,
          this.imageUpload.file || null
        );
        console.log("Client update response ", response);
        this.getClients({
          page: 0,
          filter: `status == 'Active' && clientId == ${this.client.clientId}`,
          clientId: this.client.clientId
        });
        this.close();
      } catch (err) {
        console.log("Client update error ", err);
        this.errorDialog.display = true;
        this.errorDialog.title = "There was an error updating the client";
        if (err.error_code && err.error) this.errorDialog.subtitle = err.error;
        else this.errorDialog.subtitle = null;
      } finally {
        this.showLoadingDialog = false;
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "roles"]),
    displayAnnualPaymentCap() {
      return !!(this.permissions || []).find(
        x => x == "users:create:annualpaymentcap"
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
