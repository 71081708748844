import axios from "../axios";

const baseUrl =
  process.env.VUE_APP_ROBIN_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/robin-service`;

class RobinService {
  processRecommendations() {
    return axios
      .get(baseUrl + "/robin/cron/process")
      .then(response => {
        return response.data.recommendations;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getRecommendationsByUser() {
    return axios
      .get(baseUrl + "/robin/recommendations")
      .then(response => {
        return response.data.recommendations;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  getRecommendationTypes() {
    return axios
      .get(baseUrl + "/recommendationType")
      .then(response => {
        return response.data.recommendationType;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createRecommendationType(body) {
    return axios
      .post(baseUrl + "/recommendationType", body)
      .then(response => {
        return response.data.recommendationType;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateRecommendationType(id, body) {
    return axios
      .patch(baseUrl + "/recommendationType/" + id, body)
      .then(response => {
        return response.data.recommendationType;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
}

export default new RobinService();
