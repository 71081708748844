import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl =
  process.env.VUE_APP_ONBOARDING_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/onboarding-service`;

class OnboardingService {
  // 3/16/23 DEPRECATED
  // uploadCSV(
  //   file,
  //   clientId,
  //   clientName,
  //   groupName,
  //   groupDisplayName,
  //   roles,
  //   source,
  //   onboardingDate
  // ) {
  //   let formData = new FormData();
  //   if (clientId !== null) {
  //     formData.append("clientId", clientId);
  //   }
  //   formData.append("clientName", clientName);
  //   formData.append("groupName", groupName);
  //   formData.append("groupDisplayName", groupDisplayName);
  //   formData.append("roles", roles);
  //   formData.append("scheduledOnboardingDate", onboardingDate);
  //   // Adds source for platform activity
  //   if (source) formData.append("source", source);
  //   formData.append("file", file);
  //   return axios
  //     .post(baseUrl + "/onboarding/users/bulk/create", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(response => {
  //       return response;
  //     })
  //     .catch(error => {
  //       return error;
  //     });
  // }

  // 3/16/23 DEPRECATED
  // createUser(user) {
  //   const headers = {
  //     "Content-Type": "application/json"
  //   };

  //   return axios
  //     .post(baseUrl + "/onboarding/users", user, {
  //       headers
  //     })
  //     .then(response => {
  //       console.log(response);
  //       return response.data;
  //     });
  // }
  setupClientAndUser(body) {
    return axios
      .post(baseUrl + "/onboarding/users/client/setup", body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error creating client and user ", error.response.data);
        throw error.response.data;
      });
  }
  removeClientFromBlacklist(clientId) {
    return axios
      .post(baseUrl + "/onboarding/users/client/blacklist/" + clientId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error removing client from blacklist ",
          error.response.data
        );
        return error.response.data;
      });
  }
  sendWhistleInviteEmails(userIds, clientId) {
    return axios
      .post(
        baseUrl + "/onboarding/users/onboard/invite",
        {
          users: userIds
        },
        {
          params: {
            clientId
          }
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error sending invite emails", error.response.data);
        return error.response.data;
      });
  }

  downloadTemplate() {
    return axios
      .get(baseUrl + "/onboarding/users/bulk/template")
      .then(response => {
        console.log("Downloading template file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Onboarding_Template.csv");
        document.body.appendChild(link);
        link.click();
      });
  }

  downloadTemplateInfo() {
    return axios
      .get(baseUrl + "/onboarding/users/bulk/templateInfo", {
        responseType: "blob"
      })
      .then(response => {
        console.log("Downloading template info file");
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Onboarding_Template_Description.xlsx");
        document.body.appendChild(link);
        link.click();
      });
  }

  downloadDeletionTemplate() {
    return axios
      .get(baseUrl + "/onboarding/users/bulk/deletionTemplate")
      .then(response => {
        console.log("Downloading template file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Bulk_User_Deletion_Template.csv");
        document.body.appendChild(link);
        link.click();
      });
  }

  downloadLegalDoc(docname) {
    //current available docnames:
    //privacy
    //terms
    return axios
      .get(baseUrl + "/onboarding/users/legal/" + docname, {
        responseType: "blob"
      })
      .then(response => {
        console.log("Downloading template info file");
        let blob = new Blob([response.data], {
          type: "application/pdf"
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (docname == "terms") {
          docname = "Terms_And_Conditions";
        } else if (docname == "privacy") {
          docname = "Privacy_Policy";
        } else if (docname == "privacy_gdpr") {
          docname = "Privacy_Policy_GDPR";
        } else if (docname == "msa_v1") {
          docname = "Whistle_Payments_Agreement_v1_12.3.21";
        }
        link.setAttribute("download", docname + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
  }

  // v2
  createUserFromOnboardingLink(
    onboardingToken,
    user,
    persistentToken = false,
    groupId = null,
    params = {}
  ) {
    return axios
      .post(
        baseUrl +
          `/v2/onboarding/user/onboarding-token/${
            persistentToken ? "persistent/" : ""
          }${onboardingToken}${groupId ? `/group/${groupId}` : ""}`,
        user,
        { params }
      )
      .then(response => {
        console.log("Created user from onboarding link ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error creating user from onboarding link ",
          error.response.data
        );
        throw error.response.data;
      });
  } //end createUserFromOnboardingLink

  setupPasswordForPasswordlessUser(email) {
    return axios
      .post(baseUrl + "/v2/onboarding/user/setup/password/" + email)
      .then(response => {
        console.log(
          "Created password user for passwordless user ",
          response.data
        );
        return response.data;
      })
      .catch(error => {
        console.log("Error creating password user ", error.response.data);
        throw error.response.data;
      });
  } //end setupPasswordForPasswordlessUser
  addAuthType(userId, authType, scheduledOnboardingDate) {
    //need to create the body like it is in postman
    console.log({ userId, authType, scheduledOnboardingDate });
    var body = { userId, scheduledOnboardingDate };
    return axios
      .post(baseUrl + "/v2/onboarding/user/setup/" + authType, body)
      .then(response => {
        console.log("Updated user auth type ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error setting up auth type ", error.response.data);
        throw error.response.data;
      });
  } //end addAuthType
  createUserV2(body, params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/onboarding/user", body, { params })
      .then(response => {
        console.log("Created user ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error creating user ", error.response.data);
        throw error.response.data;
      });
  } //end createUserV2
  async createUserFromFileV2(file, fields, params) {
    try {
      let formData = new FormData();
      formData.append("file", file);

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      let response = await axios.post(
        baseUrl + "/v2/onboarding/user",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          params
        }
      );

      console.log(
        "Created password user for passwordless user ",
        response.data
      );
      return response.data;
    } catch (error) {
      console.log("Error creating password user ", error.response.data);
      throw error.response.data || error.response || error;
    }
  } //end createUserFromFileV2
  async validateUserFileV2(obj, query = "") {
    try {
      let formData = new FormData();
      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });
      let response = await axios.post(
        baseUrl + "/v2/onboarding/csv/validate" + query,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      console.log("Validated onboarding file ", response.data.response);
      return response.data.result;
    } catch (error) {
      console.log("Error validating file ", error.response.data);
      throw error.response.data || error.response || error;
    }
  } //end validateUserFileV2
} //end Class

export default new OnboardingService();
