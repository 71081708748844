import axios from "../axios";

const baseUrl =
  process.env.VUE_APP_AUTH0_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/auth0-service`;

class Auth0Service {
  migrate() {
    return axios
      .get(baseUrl + "/auth0/migrate")
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }
}

export default new Auth0Service();
