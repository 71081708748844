<template>
  <!-- Header card/row -->
  <v-card
    :elevation="elevation"
    class="d-flex justify-space-between py-4 px-3 rounded-lg"
    :class="{
      'flex-column': $vuetify.breakpoint.xs,
      'align-center': !$vuetify.breakpoint.xs
    }"
  >
    <div
      class="d-flex pa-0"
      :style="$vuetify.breakpoint.width <= 1035 ? { 'max-width': '330px' } : {}"
    >
      <v-icon
        :large="!isMobile"
        dark
        class="pa-2 mr-4 my-2 rounded icon"
        :class="`${iconColor}-background`"
        >{{ icon }}</v-icon
      >
      <p
        class="font-weight-black word-break brand-text my-auto bold-font page-title text-left"
      >
        {{ title }}
      </p>
    </div>
    <div
      class="py-3 flex-grow-1"
      :class="
        $vuetify.breakpoint.md
          ? 'px-8'
          : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
          ? 'px-12'
          : 'px-auto'
      "
    >
      <!------------ Search Bar -------------->
      <v-text-field
        outlined
        placeholder="Search"
        single-line
        dense
        hide-details
        v-model="debounceSearch"
        class="shrink mx-auto"
        append-icon="mdi-magnify"
        :style="isMobile ? '' : 'max-width: 450px;'"
        clearable
        color="brandCyan"
      ></v-text-field>
      <!------------ End Search Bar -------------->
    </div>
    <div
      :class="
        $vuetify.breakpoint.xs
          ? 'px-0'
          : $vuetify.breakpoint.md
          ? 'px-4 flex-grow-1'
          : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
          ? 'px-12 flex-grow-1'
          : 'px-8 flex-grow-1'
      "
      :style="
        $vuetify.breakpoint.xs
          ? { height: 'fit-content', 'max-width': '430px' }
          : { height: 'fit-content', 'max-width': '440px' }
      "
      v-if="userProfile?.clientId === 1"
    >
      <ClientDropdown class="my-auto full-width"></ClientDropdown>
    </div>
  </v-card>
</template>

<script>
// @ is an alias to /src

import ClientDropdown from "@/components/ClientDropdown.vue";

import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

export default {
  name: "AdminHeader",
  components: {
    ClientDropdown
  },
  props: {
    title: {
      type: String,
      default: "Admin Management"
    },
    icon: {
      type: String,
      default: "mdi-email"
    },
    iconColor: {
      type: String,
      default: "primary"
    },
    elevation: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      search: null,
      debounceSearch: null
    };
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
  computed: {
    ...mapState(["userProfile", "clients", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  watch: {
    debounceSearch: debounce(function(newVal) {
      this.search = newVal;
      this.$emit("update-search", newVal);
    }, 500)
  }
};
</script>

<style scoped>
.page-title {
  font-size: 1.7em;
}
@media (max-width: 960px) {
  .icon {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  }

  .page-title {
    font-size: 1.2em;
  }
}

.bold-font {
  font-family: "Roboto", sans-serif !important;
}
</style>
