<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :key="1"
          :hide-default-header="!displayHeaders"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="
            includeEveryone && table.total == 0 ? 1 : table.total
          "
          :show-expand="false"
          :expanded="[]"
          :loading="table.loading"
          loading-text="loading"
          must-sort
          sort-by="groupId"
          sort-desc
          no-data-text="No groups found"
          no-results-text="No groups found"
          item-key="groupId"
          id="group-table"
          class="cursor-pointer"
          @click:row="selectItem($event, false)"
        >
          <template v-slot:[`item.select`]="{ item }">
            <v-simple-checkbox
              color="brandCyan"
              @click.stop="selectItem(item, false)"
              :ripple="false"
              :value="
                selected.find(x => x.groupId == item.groupId) ? true : false
              "
            />
          </template>
          <template v-slot:[`item.info`]="{ item }">
            <v-btn icon @click.stop="previewItem(item)" v-if="item.groupId != 0"
              ><v-icon color="grey">mdi-dots-horizontal</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.qr`]="{ item }">
            <v-btn
              icon
              small
              color="brandCyan"
              v-if="showQrCode(item.groupType, item.clientId)"
              @click.stop.native="loadQrCode(item.groupId)"
              ><v-icon>mdi-qrcode</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.groupDisplayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
          <template v-slot:[`item.numInGroup`]="{ item }">
            <div
              class="d-flex align-center"
              v-if="
                item.groupId != 0 ||
                  !(totalUserCount === null || totalUserCount === undefined)
              "
            >
              <v-icon class="mr-2">mdi-account-multiple</v-icon>
              <span class="mr-3">{{
                !item ? "-" : item.numUsers !== undefined ? item.numUsers : "-"
              }}</span>
            </div>
          </template>
        </v-data-table>
        <v-bottom-sheet v-model="dialog.preview" inset width="500">
          <v-sheet
            class="text-center"
            :height="
              loading.assignments &&
              (!item.GroupAssignments || !item.GroupAssignments.length)
                ? '355px'
                : '475px'
            "
            v-if="item"
          >
            <div class="pt-4">
              <div class="d-flex align-center justify-space-between mx-6">
                <v-btn fab color="lightGrey" small class="hidden"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-card-title class="word-break">
                  <b>
                    {{
                      item.displayName ||
                        item.groupDisplayName ||
                        item.groupName
                    }}</b
                  >
                </v-card-title>
                <v-btn
                  fab
                  color="mediumGrey"
                  small
                  depressed
                  elevation="0"
                  @click="dialog.preview = !dialog.preview"
                  ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                >
              </div>
              <div>
                <div class="d-flex align-center justify-center">
                  <p class="mb-0">
                    {{ item.groupType + " group " || "Unknown Group Type " }}
                  </p>
                  <v-icon class="ml-2">mdi-account</v-icon>
                  <h3>
                    {{
                      item.numUsers
                        ? item.numUsers
                        : item.GroupAssignments
                        ? item.GroupAssignments.length
                        : 0
                    }}
                  </h3>
                </div>
                <p
                  v-if="
                    loading.assignments == false &&
                      (!item.GroupAssignments || !item.GroupAssignments.length)
                  "
                  class="mx-5 mt-7"
                >
                  It looks like there aren't any people in this group. Try
                  choosing a different group.
                </p>
                <v-virtual-scroll
                  v-else
                  height="250"
                  class="mt-2 mb-5"
                  item-height="45"
                  :items="
                    (item.numUsers || 0) > (item.GroupAssignments || []).length
                      ? [...(item.GroupAssignments || []), { button: true }]
                      : item.GroupAssignments || []
                  "
                >
                  <template v-slot:default="{ item }">
                    <v-btn
                      v-if="item.button"
                      @click="getGroupAssignments(false)"
                      :loading="loading.assignments"
                      color="brandCyan"
                      class="mb-3 mt-4"
                      rounded
                      text
                      >+ Load more</v-btn
                    >
                    <v-list-item :key="`${item.groupId}-${item.userId}`" v-else>
                      <v-list-item-content class="text-left ml-2 mt-2">
                        <span class="text-overflow-clip">{{
                          item.displayName || item.User.displayName
                        }}</span>
                      </v-list-item-content>
                      <v-list-item-content
                        class="text-left mt-2 text-overflow-clip"
                      >
                        <span class="text-overflow-clip">{{
                          item.User.businessEmail || item.User.businessPhone
                        }}</span></v-list-item-content
                      >
                    </v-list-item>
                    <v-divider class="mx-5" />
                  </template>
                </v-virtual-scroll>
                <!-- <div class="d-flex flex-column align-items-center mt-4" v-else>
                  <Robin
                    :showText="false"
                    :width="160"
                    :height="160"
                    animation="flossing"
                    :loop="true"
                    class="mt-2 mx-auto"
                  />
                  <p>Grabbing users in the group, hold tight...</p>
                </div> -->
              </div>
              <!-- Hide button if loading group assignments or no group assignments and on groups table -->
              <v-btn
                v-if="item && item.groupId"
                color="brandCyan"
                rounded
                depressed
                width="90"
                @click="selectItem(item, true)"
                class="mt-auto white--text"
                >Add</v-btn
              >
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
    <QRViewer
      v-if="qr.dialog"
      :groupId="qr.groupId"
      :clientId="qr.clientId"
      @close="
        (qr.dialog = false),
          (qr.groupId = null),
          (qr.clientId = null),
          (qr.error = null)
      "
    />
  </div>
</template>

<script>
import GroupService from "@/services/GroupService";

import QRViewer from "@/components/QR.vue";

import { mapState } from "vuex";

export default {
  name: "GroupsTable",
  props: {
    selected: {
      type: Array
    },
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "programadmin"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    includeEveryone: {
      type: Boolean,
      default: false
    },
    totalUserCount: {
      type: Number,
      default: null
    },
    displayHeaders: {
      type: Boolean,
      default: false
    },
    displayQrCode: {
      type: Boolean,
      default: true
    }
  },
  components: {
    QRViewer
  },
  data() {
    return {
      whistleClientId: 1,
      loading: {
        assignments: false,
        userCount: false
      },
      dialog: {
        preview: false
      },
      userCount: null,
      item: null,
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        latestOffset: null
      },
      qr: {
        dialog: false,
        error: null,
        groupId: null,
        clientId: null
      }
    };
  },
  created() {
    // this.getTotalUserCount();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    loadAction(item) {
      console.log("loading action ", item);
      this.$emit("load-action", item);
    },
    async getGroups(reset = false, source = null, preloadGroup = null) {
      try {
        console.log("Getting groups from ", source);
        console.log({ reset, source, preloadGroup });

        let addedGroup = false;
        if (preloadGroup && typeof preloadGroup == "object") {
          this.selectItem(preloadGroup);
          addedGroup = true;
          console.log("Adding group before API call because it's an object");
        }

        this.table.loading = true;
        if (reset) {
          this.table.options.page = 1;
          this.table.total = 0;
        }
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.search ? this.search.replace(/'/g, "\\'") : null;
        let filter = `status != 'Deleted' && clientId = ${this.clientId}`;

        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (groupDisplayName.like('%${search}%') || status.like('%${search}%') || groupId.like('%${search}%'))`;
        }
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          //   if (column == "formattedSendDate") sort = `sendDate ${type}`;
          //   else
          var sort = column + " " + type;
        } else sort = "groupId DESC";
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        this.table.latestOffset = offset;
        let params = {
          filter,
          offset,
          limit,
          sort,
          expand: "null",
          count: 1
        };
        let response = await GroupService.getGroupsV2(
          params,
          this.magicLinkToken
        );

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort ||
          offset != this.table.latestOffset ||
          limit != this.table.options.itemsPerPage
        ) {
          console.log("Ignoring group response");
          return;
        }

        console.log("Got groups ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          return r;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.table.items = rows;

        // if (rows.length)
        //   this.getGroupUserCountV2(
        //     rows.map(x => x.groupId),
        //     addedGroup ? null : preloadGroup,
        //     params
        //   );
        // else
        this.table.loading = false;

        if (preloadGroup && !addedGroup) {
          var foundGroup = this.table.items.find(
            x => x.groupId == preloadGroup
          );
          if (foundGroup) this.selectItem(foundGroup);
        }
      } catch (err) {
        console.log("Error getting groups ", err);
        this.table.loading = false;
      }
    },
    async getGroupAssignments(reset = false) {
      console.log("Get group assignments: reset ", reset);
      try {
        let { groupId } = this.item;
        let limit = 30;
        // if (!reset) return;
        let page = reset
          ? 0
          : Math.floor((this.item.GroupAssignments || 0).length / limit);
        console.log("Page ", page);
        // if (page == 0)
        this.loading.assignments = true;

        let filter = `status == 'Active' && User.status != 'Deleted' && groupId=${groupId}`;

        // This array is used to update a group, so it's not paginated
        // that way we have all users email addresses to map

        let offset = page * limit;
        let expand = "User";
        let sort = "User.firstName ASC, User.lastName ASC";

        const params = {
          limit: limit,
          sort: sort,
          filter: filter,
          offset: offset,
          expand: expand
        };

        console.log({ params });

        const response = await GroupService.getGroupAssignmentV2(
          params,
          this.magicLinkToken
        );

        if (this.item && this.item.groupId != groupId)
          return console.log("New group id, so we ignore results");

        let rows = response.result.rows.map(user => {
          user.displayName = user.User
            ? user.User.firstName || user.User.lastName
              ? user.User.firstName + " " + user.User.lastName
              : user.displayName
            : "";
          return user;
        });

        console.log("Got response from group assignment call ", rows);

        if (page == 0) {
          this.item.GroupAssignments = rows;
        } else {
          this.item.GroupAssignments = this.item.GroupAssignments.concat(rows);
        }

        this.loading.assignments = false;
      } catch (error) {
        console.log("Error getting group assignments", error);
        this.loading.assignments = false;
      }
    },
    previewItem(item) {
      console.log("Previewing ", item);
      console.log("Previewing group", item);
      this.item = item;
      this.getGroupAssignments(item.groupId);
      this.dialog.preview = true;
    },
    selectItem(x, preventRemoval = false) {
      console.log("Selecting item ", x);
      this.$emit("select-item", {
        ...x,
        // Added because we can only pass one param in
        dontRemove: preventRemoval ? true : undefined
      });
      this.dialog.preview = false;
    },
    async loadQrCode(groupId) {
      const clientId = Number(
        this.clientId !== undefined && this.clientId !== null
          ? this.clientId
          : this?.userProfile?.clientId
      );
      this.qr = {
        dialog: false,
        error: null,
        groupId,
        clientId
      };
      this.qr.dialog = true;
    },
    showQrCode(type, clientId) {
      let evaluate = false;
      if (type == "List") {
        const client =
          clientId == this.userProfile?.clientId
            ? this.userProfile?.Client
            : this.clients.find(x => x.clientId == clientId);
        if (client?.onboardingTokenPersistent) evaluate = true;
      }
      return evaluate;
    }
    // async getTotalUserCount() {
    //   if (!this.includeEveryone) return;
    //   else if (this.totalUserCount) return;
    //   try {
    //     console.log("getTotalUserCount from Groups Table");
    //     // Used to just get a total number of users in the client
    //     this.loading.userCount = true;
    //     const userRes = await UserService.getUsersV2({
    //       filter: `status = 'Active' && clientId = ${this.clientId}`,
    //       limit: 1,
    //       extract: "userId"
    //     });
    //     console.log("total number of users ", userRes);
    //     this.userCount = userRes.result.count;
    //   } catch (e) {
    //     console.log("Error getting user count");
    //   } finally {
    //     this.loading.userCount = false;
    //   }
    // }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "globalClientId",
      "clients",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      let array = this.table.items || [];

      array = array.filter(x => x.status != "Deleted");

      if (this.table.loading) return [];
      if (
        this.table?.options?.page == 1 &&
        this.includeEveryone &&
        !this.search
      ) {
        array.unshift({
          groupId: 0,
          groupDisplayName: "Everyone",
          displayName: "Everyone",
          numUsers: this.totalUserCount
        });
        console.log("Adding everyone", this.totalUserCount);
      } else if (array?.[0]?.groupId == 0) {
        array.splice(0, 1);
        console.log("Splicing everyone");
      }
      return array;
    },
    headers() {
      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },
        {
          align: "start",
          sortable: true,
          value: "groupDisplayName",
          width: "20%"
        },
        {
          align: "start",
          sortable: true,
          value: "numInGroup",
          width: "20%"
        },
        this.displayQrCode
          ? {
              align: "start",
              sortable: false,
              value: "qr",
              width: "100px"
            }
          : undefined,
        {
          value: "info",
          width: "100px",
          align: "end",
          sortable: false
        }
      ].filter(Boolean);
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getGroups(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getGroups(true, "client watcher");
        // this.getTotalUserCount();
      }
    },
    search: function(newVal) {
      // Pass undefined for the watcher to ignore it
      if (newVal === undefined)
        return console.log("Ignoring search watcher due to group creation");
      this.getGroups(true, "search watcher");
    }
  }
};
</script>

<style scoped></style>
