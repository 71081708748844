<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex justify-end" v-if="!templateView">
          <v-checkbox
            color="brandCyan"
            label="Include archived messages"
            v-model="includeArchived"
          />
        </div>
        <v-data-table
          :key="1"
          :hide-default-header="hideDefaultHeader"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-expand="displayInfo"
          :show-select="false"
          :expanded="table.expanded"
          :loading="table.loading"
          loading-text="loading"
          must-sort
          :sort-by="templateView ? 'updatedAt' : 'sendDate'"
          sort-desc
          no-data-text="No messages found"
          no-results-text="No messages found"
          item-key="messageId"
          id="table"
          class="table"
          color="brandCyan"
          @click:row="rowClick"
          @item-expanded="rowClick"
        >
          <template v-slot:[`item.messageSubject`]="{ item }">
            <span class="word-break">{{
              item.messageSubject || "Untitled"
            }}</span>
          </template>
          <template v-slot:[`item.sendDate`]="{ item }">
            <span class="word-break">{{
              item.status == "Draft" ? item.updatedAt : item.sendDate
            }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="word-break">{{
              item.status === "Active" ? "Sent" : item.status
            }}</span>
          </template>
          <template v-slot:[`item.duplicate`]="{ item }">
            <v-btn
              icon
              @click.stop="
                loadCommunicationWizard(item.messageId, item.clientId, true)
              "
              ><v-icon>mdi-content-duplicate</v-icon></v-btn
            >
          </template>

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
          <template
            v-slot:[`item.data-table-expand`]="{
              item,
              isExpanded,
              expand
            }"
          >
            <v-btn
              @click.stop="expand(!isExpanded)"
              v-if="
                item.clientId != 0 ||
                  (clientId === 0 && userProfile?.clientId === 1)
              "
              icon
              ><v-icon large>{{
                isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon></v-btn
            >
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <!-- <div class="d-flex full-width justify-space-between">
              <span>Left</span><span>Right{{ item }}</span>
            </div> -->
            <td
              id="program-table-inner"
              :colspan="headers.length"
              class="py-4"
              :class="
                $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                  ? 'px-0'
                  : 'px-6'
              "
              v-if="item"
            >
              <v-row
                ><v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  :class="{ 'px-7': isMobile }"
                >
                  <span
                    class=""
                    v-if="
                      item.sendSMS ||
                        item.sendEmail ||
                        item.sendPush ||
                        item.displayOnPlatform
                    "
                    >Notification Preview</span
                  >
                  <div class="d-flex flex-wrap mt-1">
                    <div class="d-flex flex-column mr-6" v-if="item.sendSMS">
                      <div
                        class="d-flex align-center mb-1 preview-button"
                        @click="previewMessage('sms', item)"
                      >
                        <v-icon color="brandCyan" small
                          >mdi-comment-text</v-icon
                        >
                        <span class="pl-2">
                          Text
                        </span>
                      </div>
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.messageId)"
                      ></v-progress-circular>
                      <p
                        v-else-if="item?.analytics"
                        class="text-start"
                        style="margin-left: 7px;"
                      >
                        {{ item?.analytics?.smsCount }} sent
                      </p>
                    </div>
                    <div class="d-flex flex-column mr-6" v-if="item.sendEmail">
                      <div
                        class="d-flex align-center mb-1 preview-button"
                        @click="previewMessage('email', item)"
                      >
                        <v-icon color="brandCyan" small
                          >mdi-email-outline</v-icon
                        >
                        <span class="pl-2">
                          Email
                        </span>
                      </div>
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.messageId)"
                      ></v-progress-circular>
                      <p
                        v-else-if="item?.analytics"
                        class="text-start"
                        style="margin-left: 7px;"
                      >
                        {{ item?.analytics?.emailCount }} sent
                      </p>
                    </div>
                    <div class="d-flex flex-column mr-6" v-if="item.sendPush">
                      <div
                        class="d-flex align-center mb-1 preview-button"
                        @click="previewMessage('push', item)"
                      >
                        <v-icon color="brandCyan" small>mdi-cellphone</v-icon>
                        <span class="pl-2">
                          App
                        </span>
                      </div>
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.messageId)"
                      ></v-progress-circular>
                      <p
                        v-else-if="item?.analytics"
                        class="text-start"
                        style="margin-left: 9px;"
                      >
                        {{ item?.analytics?.pushCount }} sent
                      </p>
                    </div>
                    <div
                      class="d-flex flex-column"
                      v-if="item.displayOnPlatform"
                    >
                      <div
                        class="d-flex align-center mb-1 preview-button"
                        @click="previewMessage('activity', item)"
                      >
                        <v-icon color="brandCyan" small>mdi-monitor</v-icon>
                        <span class="pl-2">
                          Activity
                        </span>
                      </div>
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.messageId)"
                      ></v-progress-circular>
                      <p
                        v-else-if="item?.analytics"
                        class="text-start"
                        style="margin-left: 7px;"
                      >
                        {{ item?.analytics?.activityCount }} sent
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.displayOnPlatform &&
                        item?.analytics?.activityCount > 0
                    "
                    class="page-views-bar"
                  >
                    <span class="mt-2">Page Views</span>
                    <div class="d-flex align-center mt-3">
                      <span>{{ item?.analytics?.activityReadCount }}</span>
                      <v-progress-linear
                        :value="
                          (item?.analytics?.activityReadCount /
                            item?.analytics?.activityCount) *
                            100
                        "
                        color="brandGreen"
                        height="9"
                        class="mx-3"
                      ></v-progress-linear
                      ><span>{{ item?.analytics?.activityCount }}</span>
                      <v-btn
                        v-if="item?.audience?.length"
                        icon
                        class="ml-2"
                        @click="viewAudience(item.audience)"
                        ><v-icon>mdi-account-multiple</v-icon></v-btn
                      >
                    </div>
                  </div></v-col
                ><v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  :class="{ 'px-7': isMobile }"
                >
                  <div class="d-flex flex-column align-start mt-6">
                    <v-btn
                      text
                      color="brandCyan"
                      class="text-none mb-2"
                      :disabled="
                        item.status !== 'Draft' &&
                          item.status !== 'Template' &&
                          item.status !== 'Scheduled'
                      "
                      @click="
                        loadCommunicationWizard(
                          item.messageId,
                          item.clientId,
                          false,
                          item.status === 'Template'
                        )
                      "
                      ><v-icon>mdi-pencil</v-icon
                      ><span class="black--text ml-2">Edit</span></v-btn
                    >
                    <v-btn
                      text
                      color="brandCyan"
                      class="text-none mb-2"
                      @click="
                        loadCommunicationWizard(
                          item.messageId,
                          item.clientId,
                          true
                        )
                      "
                      ><v-icon>{{
                        item.status === "Template"
                          ? "mdi-content-duplicate"
                          : "mdi-content-copy"
                      }}</v-icon
                      ><span class="black--text ml-2">{{
                        item.status === "Template"
                          ? "Use template"
                          : "Duplicate"
                      }}</span></v-btn
                    >
                    <v-btn
                      text
                      color="brandCyan"
                      class="text-none"
                      :disabled="item.status === 'Archived'"
                      @click="confirmDeletion(item.messageId)"
                      ><v-icon>mdi-trash-can-outline</v-icon
                      ><span class="black--text ml-2">Delete</span></v-btn
                    >
                  </div>
                </v-col></v-row
              >
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <MessagePreviewDrawer
      v-if="messagePreview.display"
      :method="messagePreview.method"
      :messageSubject="messagePreview.msg?.messageSubject"
      :messageShortBody="messagePreview.msg?.messageShortBody"
      :messageBody="messagePreview.msg?.messageBody"
      :context="messagePreview.msg?.context"
      :subcontext="messagePreview.msg?.subcontext"
      :contextResourceId="messagePreview.msg?.contextResourceId"
      :imageURL="messagePreview.msg?.imageURL"
      :clientId="messagePreview.msg?.clientId"
      @close="messagePreview.display = false"
    />
    <!-- Message deletion confirmation -->
    <v-dialog v-model="deletion.dialog" width="500">
      <PopupDialog
        v-if="deletion.dialog"
        icon="mdi-alert"
        iconColor="error"
        :centerText="true"
        :title="
          `Are you sure you want to delete this ${
            templateView ? 'template' : 'communication'
          }?`
        "
        :subtitle="
          templateView
            ? null
            : 'This message will disappear from the Activity page for any recipients.'
        "
        :error="deletion.error"
        button1Text="Cancel"
        button1Width="140"
        button2Text="Delete"
        button2Width="140"
        :button2Loading="deletion.loading"
        @button1="deletion.dialog = false"
        @button2="deleteMessage"
      />
    </v-dialog>
    <!-- View message audience -->
    <v-dialog
      v-model="dialog.audience"
      width="500"
      content-class="mx-3 px-0"
      class="mx-0"
    >
      <v-card class="pb-4" style="min-height: 200px;">
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-left">Recipients</v-card-title>
          <v-btn icon @click="dialog.audience = false" class="mr-3"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-divider class="mx-2 mb-4" />
        <p
          v-for="user in messageAudience"
          :key="user.userId || user.groupId"
          class="mx-2"
        >
          {{ user.displayName || user.groupDisplayName }}
        </p></v-card
      ></v-dialog
    >
  </div>
</template>

<script>
import CommunicationService from "@/services/CommunicationService";

import MessagePreviewDrawer from "@/components/communications-admin/MessagePreviewDrawer.vue";
import PopupDialog from "@/components/PopupDialog.vue";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MessageTable",
  props: {
    selected: {
      type: Array
    },
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "spot-communications"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    displayEmail: {
      type: Boolean,
      default: false
    },
    displayInfo: {
      type: Boolean,
      default: true
    },
    external: {
      type: Boolean,
      default: true
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    }
  },
  components: { MessagePreviewDrawer, PopupDialog },
  data() {
    return {
      loading: {
        assignments: false,
        analytics: []
      },
      dialog: {
        preview: false,
        audience: false
      },
      item: null,
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        expanded: []
      },
      messagePreview: {
        display: false,
        msg: {},
        method: null
      },
      deletion: {
        dialog: false,
        messageId: null,
        loading: false,
        error: null
      },
      messageAudience: [],
      // Include archived messages in results
      includeArchived: true
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    rowClick(item) {
      let value = item?.item || item;
      console.log(value);

      if (value?.clientId === 0 && this.clientId !== 0)
        return console.log("Universal so we ignore");
      // this.getBudget(value);
      //add or remove the item from expanded array
      const index = this.table.expanded.findIndex(
        x => x?.messageId == value?.messageId
      );
      console.log("Expanded index ", index);
      if (index === -1) {
        this.table.expanded.push(value);

        if (
          value.status !== "Template" &&
          value.status !== "Draft" &&
          value.status !== "Scheduled"
        )
          this.getMessageAnalytics(value?.messageId, value?.clientId);
      } else {
        this.table.expanded = this.table.expanded.filter(
          x => x?.messageId != value?.messageId
        );
      }
    },
    async getMessageDefinitions(
      reset = false,
      source = null,
      preloadUser = null
    ) {
      try {
        // First, if they have a preloaded Id and it's an object, we add that now just in case the api result gets dismissed
        let alreadyPreloadedUser = false;
        if (preloadUser && typeof preloadUser == "object") {
          alreadyPreloadedUser = true;
          this.selectItem(preloadUser, true);
        }
        console.log("Getting messages from ", source);
        console.log({ reset, source, preloadUser });
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let filter = `status != 'Deleted' && clientId = ${this.clientId}`;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let sort = "messageId DESC";
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          console.log("Sort ", options);
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          sort = column + " " + type;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          offset,
          limit,
          sort,
          clientId: this.clientId,
          screen: this.sourcePage,
          source: this.sourcePage,
          search: this.search,
          archived: this.includeArchived ? 1 : 0,
          extract:
            "clientId,messageId,messageSubject,messageShortBody,messageBody,context,subcontext,contextResourceId,sendDate,status,imageURL,displayOnPlatform,sendEmail,sendPush,sendSMS,updatedAt"
        };
        let response = await CommunicationService.getMessageDefinitionsV2(
          params
          // undefined,
          // this.magicLinkToken
        );

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          // filter != this.table.latestFilter ||
          this.search != params.search ||
          sort != this.table.latestSort ||
          page != this.table.options.page ||
          limit != this.table.options.itemsPerPage ||
          this.clientId != params.clientId
        ) {
          console.log("Ignoring API response");
          return;
        }

        console.log("Got messages ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          if (r.sendDate)
            r.sendDate = moment(r.sendDate).format("MMM D, YYYY h:mm a");
          if (r.updatedAt)
            r.updatedAt = moment(r.updatedAt).format("MMM D, YYYY h:mm a");
          return r;
        });

        this.table.items = rows;
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting messages ", err);
        this.table.loading = false;
      }
    },
    selectItem(x, preventRemoval = false) {
      console.log("Selecting item ", x);
      this.$emit("select-item", {
        ...x,
        // Added because we can only pass one param in
        dontRemove: preventRemoval ? true : undefined
      });
      this.dialog.preview = false;
    },
    async previewMessage(method, msg) {
      this.messagePreview.method = method;
      this.messagePreview.msg = msg;
      this.messagePreview.display = true;
      // try {
      //   let body = {
      //     messageSubject: msg.messageSubject,
      //     messageShortBody: msg.messageShortBody,
      //     contextResourceId: msg.contextResourceId,
      //     context: msg.context,
      //     subcontext: msg.subcontext
      //   };
      //   let response = await CommunicationService.previewMessage(method, body);

      //   console.log("Message preview ", response);
      // } catch (err) {
      //   console.log("Error previewing ", err);
      // } finally {
      //   this.table.loading = false;
      // }
    },
    async getMessageAnalytics(messageId, clientId) {
      try {
        this.loading.analytics.push(messageId);
        let response = await CommunicationService.getMessageAnalytics(
          messageId,
          { clientId }
        );

        console.log("Got message analytics ", response);
        let index = this.table.items.findIndex(x => x.messageId == messageId);
        console.log("INDEX", index);
        if (index !== -1) {
          this.table.items[index].analytics = response?.rows?.[0];
        }
        // this.table.total = response.result.count;
        // // if (reset || wipeArray)
        // //   this.table.messages = new Array(this.table.total).fill(undefined);
        // let rows = response.result.rows.map(r => {
        //   if (r.sendDate) r.sendDate = moment(r.sendDate).format("MMM D, YYYY");
        //   return r;
        // });

        // this.table.items = rows;
        // this.table.loading = false;
      } catch (err) {
        console.log("Error getting message analytics ", err);
        // this.table.loading = false;
      } finally {
        this.loading.analytics = this.loading.analytics.filter(
          x => x != messageId
        );
      }
    },
    loadCommunicationWizard(id, clientId, duplicate = false, template = false) {
      this.$router.push({
        name: "communicationwizard",
        query: {
          messageId: id,
          clientId,
          previousRoute: "communications",
          template: template ? 1 : 0,
          duplicate: duplicate ? 1 : 0
        }
      });
    },
    confirmDeletion(messageId) {
      this.deletion.dialog = true;
      this.deletion.messageId = messageId;
    },
    async deleteMessage() {
      try {
        if (!this.deletion.messageId) throw "No messageId found";
        const messageId = this.deletion.messageId;
        this.deletion.loading = true;
        this.deletion.error = null;
        // this.loading.analytics.push(messageId);
        let response = await CommunicationService.deleteMessageV2(
          messageId,
          {}
        );

        console.log("Deleted message ", response);
        this.deletion.dialog = false;
        this.deletion.messageId = null;

        this.table.expanded = this.table.expanded.filter(
          x => x?.messageId != messageId
        );
      } catch (err) {
        console.log("Error deleting message ", err);
        this.deletion.error =
          "We encountered an issue when deleting this communication.";
      } finally {
        this.deletion.loading = false;
        this.getMessageDefinitions(true, "deleteMessage");
      }
    },
    viewAudience(audience) {
      this.dialog.audience = true;
      this.messageAudience = audience;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      let array = this.table.items || [];

      array = array.filter(x => x.status != "Deleted");

      if (this.table.loading) return [];
      return array;
    },
    headers() {
      return [
        {
          text: "Title",
          align: "start",
          sortable: true,
          value: "messageSubject",
          width: "25%"
        },
        this.templateView
          ? undefined
          : {
              text: "Status",
              align: "start",
              sortable: true,
              value: "status",
              width: "20%"
            },
        this.templateView
          ? {
              text: "Last Updated",
              align: "start",
              sortable: true,
              value: "updatedAt",
              width: "25%"
            }
          : {
              text: "Date Saved / Sent",
              align: "start",
              sortable: true,
              value: "sendDate",
              width: "25%"
            },
        this.templateView
          ? {
              text: "",
              align: "start",
              sortable: true,
              value: "duplicate",
              width: "10%"
            }
          : undefined,
        this.displayInfo
          ? { text: "Details", value: "data-table-expand", width: "10%" }
          : undefined
      ].filter(Boolean);
    },
    templateView() {
      return this.sourcePage === "library-communications";
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        if (apiCall) this.getMessageDefinitions(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getMessageDefinitions(true, "client watcher");
      }
    },
    search: function() {
      this.getMessageDefinitions(true, "search watcher");
    },
    includeArchived: function() {
      this.getMessageDefinitions(true, "archived toggle watcher");
    }
  }
};
</script>

<style scoped>
/* Removes box shadow from table expansion */
#table >>> .v-data-table__expanded__content {
  box-shadow: none;
}

/* Hover and background for expanded buttons */
.preview-button {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
}
.preview-button:hover {
  background-color: #eee;
}

/* Caps width of page view bar */
.page-views-bar {
  min-width: 225px;
  max-width: 330px;
}
</style>
