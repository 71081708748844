<template>
  <div class="full-height">
    <v-card flat rounded="0" class="full-height">
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex justify-start align-center">
          <v-icon
            large
            dark
            class="pa-2 ml-3 my-2 brand-background border-radius"
            >mdi-chart-bar-stacked</v-icon
          >
          <v-card-title> Reporting </v-card-title>
          <v-autocomplete
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            v-model="clientId"
            v-if="userProfile.clientId === 1"
            class="client-dropdown"
          ></v-autocomplete>
        </div>
        <v-btn
          color="primary"
          class="mr-4"
          @click="loadReportForm"
          v-if="userProfile.clientId == 1"
          >New Report</v-btn
        >
      </div>

      <v-divider class="mx-4" />
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab :key="1"> Your Reports </v-tab>
        <!-- <v-tab :key="2"> Claims </v-tab>
        <v-tab :key="3"> Learning </v-tab>
        <v-tab :key="4"> Sales Programs </v-tab>
        <v-tab :key="5"> Communication </v-tab>
        <v-tab :key="7"> Transactions </v-tab>
        <v-tab :key="8"> Home </v-tab> -->
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1">
          <v-card flat>
            <v-card-text>
              <transition :name="transitionDirection" mode="out-in">
                <v-container
                  v-if="showTable"
                  class="d-flex justify-center"
                  key="1"
                >
                  <div class="table mr-12">
                    <v-data-table
                      :headers="columns"
                      :items="filteredReports"
                      item-key="reportId"
                      class="elevation-0 row-pointer report-table"
                      @click:row="loadReport"
                      :loading="loadingReportTable"
                      no-data-text="No reports found"
                    >
                      <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ niceDate(item.updatedAt) }}
                      </template>
                    </v-data-table>
                  </div>
                  <div
                    class="custom-report-text d-flex flex-column align-text ml-12"
                  >
                    <h3 class="mb-4">Custom Reports</h3>
                    <span class="mb-4">
                      Empower your organization by providing the right people
                      with the right data.<br /><br />
                      One of Whistle’s unique capabilities is providing custom
                      data visualization tools for all the activity connected
                      with the Whistle platform and from data sources outside of
                      Whistle. And with a team of data scientists, designers and
                      engineers who specialize in managing program data, Whistle
                      can help your organization discover new insights and
                      create new tools that increase the success of your
                      company.
                    </span>
                    <span>
                      <a
                        class="remove-underline"
                        href="mailto:andrew@wewhistle.com"
                        ><strong>Contact us</strong></a
                      >
                      to learn more</span
                    >
                  </div>
                </v-container>
                <v-container v-else-if="showReport" class="pa-0 ma-0" key="2">
                  <div class="d-flex justify-space-between align-center">
                    <v-card-title
                      ><v-btn icon x-large class="mr-3" @click="leaveReport"
                        ><v-icon>mdi-chevron-left</v-icon></v-btn
                      >Back to Reports</v-card-title
                    >
                  </div>
                  <iframe
                    class="ds-report"
                    :width="windowWidth"
                    :height="windowHeight"
                    :src="currentReportSource"
                    frameborder="0"
                    style="border: 0"
                    allowfullscreen
                  ></iframe>
                </v-container>
              </transition>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-card flat>
            <v-card-text>Coming Soon!</v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="3">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="4">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="5">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="6">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="7">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="8">
          <v-card flat>
            <v-card-text> Coming Soon! </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- Nav drawer for adding reports -->
    <v-navigation-drawer
      v-model="createReportWidget"
      temporary
      fixed
      right
      width="500"
    >
      <v-card flat>
        <v-card flat>
          <v-toolbar dark color="primary" rounded="0">
            <v-toolbar-title>Create New Report</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="resetReportForm">
                <span>Close</span>
              </v-btn>
              <v-btn dark text @click="createReport" :disabled="!report.valid">
                Create
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <v-row justify="center">
              <v-col
                class="d-flex flex-column mt-3"
                cols="12"
                sm="9"
                md="9"
                lg="9"
              >
                <v-form ref="reportForm" v-model="report.valid">
                  <v-autocomplete
                    class="pa-1 mb-2"
                    :items="clients"
                    item-text="formattedName"
                    item-value="clientId"
                    label="Client"
                    v-model="report.clientId"
                    :rules="[
                      v =>
                        !!(v !== undefined && v !== null) ||
                        'Client is required'
                    ]"
                    required
                  ></v-autocomplete>
                  <v-text-field
                    class="pa-1"
                    v-model="report.name"
                    :rules="[v => !!v || 'Report name is required']"
                    label="Name*"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="pa-1"
                    v-model="report.displayName"
                    :rules="[v => !!v || 'Report display name is required']"
                    label="Display Name (Visible to Client)*"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="pa-1"
                    v-model="report.description"
                    label="Description"
                  ></v-text-field>
                  <v-text-field
                    class="pa-1"
                    v-model="report.reportUrl"
                    :rules="[v => !!v || 'Report URL is required']"
                    label="Report URL*"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="pa-1"
                    v-model="report.tokenTTL"
                    label="Token Time to Live (in seconds)"
                    :rules="[
                      v => !!v || 'Token TTL is required',
                      v =>
                        (!!v &&
                          !isNaN(v) &&
                          parseInt(v) > 0 &&
                          !v.includes('.')) ||
                        'Token TTL must be a positive, whole number'
                    ]"
                  ></v-text-field>
                  <v-checkbox
                    label="Open in new tab?"
                    v-model="report.openInNewTab"
                    class="mr-2 mt-0"
                    hide-details
                  />
                  <v-checkbox
                    label="Launch on page load?"
                    v-model="report.openOnLoad"
                    class="mr-2 mt-4"
                    hide-details
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      v-if="showLoadingDialog"
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
      :messageArray="loadingMessageArray"
    ></LoadingDialog>
  </div>
</template>

<script>
import LoadingDialog from "@/components/loadingDialog.vue";
import ReportService from "../services/ReportService.js";
// import UserService from "../services/UserService.js";
import moment from "moment";

import { mapState } from "vuex";

export default {
  name: "Reporting",
  title: "Whistle | Reporting",
  components: {
    LoadingDialog
  },
  data() {
    return {
      windowHeight: 700,
      windowWidth: 700,
      transitionDirection: "topic-right",
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingMessageArray: [],
      tab: 7,
      columns: [
        {
          text: "Custom Report Name",
          align: "start",
          value: "displayName",
          sortable: false,
          width: "60%"
        },
        {
          text: "Description",
          value: "description",
          width: "40%",
          sortable: false
        }
        // {
        //   text: "Report Date",
        //   value: "updatedAt",
        //   width: "30%",
        //   sortable: false
        // }
      ],
      reports: [],
      showTable: true,
      showReport: false,
      currentReportSource: null,
      loadingReportTable: true,
      createReportWidget: false,
      report: {
        valid: false,
        name: null,
        displayName: null,
        description: null,
        reportUrl: null,
        openOnLoad: false,
        openInNewTab: false,
        tokenTTL: null
      }
    };
  },

  created() {
    this.windowWidth = window.innerWidth * 0.8;
    this.windowHeight = this.windowWidth * 0.6;
    // this.clientId = this.userProfile.clientId;
    // 1250 / 750
    // 1.66667 ratio

    //This listener is used to detect screen size changes so the report is resized
    window.addEventListener("resize", this.resizeReport);

    this.getReports();
  },
  mounted() {
    this.windowWidth = window.innerWidth * 0.8;
    this.windowHeight = this.windowWidth * 0.6;
    console.log("Mounted");

    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    // window.divNode = window.document.createElement("div");
    // console.log(window.divNode);
    // window.divNode.setAttribute("id", "tempFreshworks");
    // window.divNode.innerHTML =
    //   "<style>#launcher-frame { right: -55px !important; }</style>";
    // window.document.body.appendChild(window.divNode);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeReport);
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    niceDate(date) {
      var newDate = moment(date)
        .local()
        .format("MMMM Do YYYY");
      return newDate;
    },

    getReports() {
      this.loadingReports = true;
      ReportService.getReports().then(response => {
        console.log("Reports ", response);

        var reports = response.filter(x => x.status != "Deleted");
        this.reports = reports;
        this.loadingReportTable = false;
        var autoLoadReport = reports.find(x => x.openOnLoad);
        if (autoLoadReport != undefined) {
          console.log("Loading report");
          this.loadReport(autoLoadReport);
        }
      });
    },

    resizeReport(e) {
      this.windowWidth = e.currentTarget.innerWidth * 0.8;
      this.windowHeight = this.windowWidth * 0.6;
    },

    loadReport(row) {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Loading Report";
      this.loadingMessageLine1 = "This should just take a second!";
      this.loadingMessageLine2 = "";
      this.loadingMessageArray = [
        "We're busy loading your report",
        "Loading the data",
        "We're making your report lightning fast",
        "We're almost done!"
      ];
      console.log(row.parameterColumn, row.reportId, this.$auth.user.sub);
      // var self = this;
      // We check if we have any tokens for this report
      if (this.reportTokens.length > 0 && this.reportTokens[0] != null) {
        var existingTokens = this.reportTokens.filter(
          x => x && x.meta && x.meta.reportId == row.reportId
        );
        var getToken = false;

        // We sort to get the most recent token for this report (if any) and check to make sure it isn't expired
        var existingToken =
          existingTokens.length == 0
            ? null
            : existingTokens.sort(
                (a, b) => b.meta.createdAt - a.meta.createdAt
              )[0];

        if (
          existingToken != null &&
          (existingToken.meta.tokenTTL == null ||
            moment(existingToken.meta.createdAt).add(
              existingToken.meta.tokenTTL - 180,
              "seconds"
            ) < moment().utc())
        ) {
          getToken = true;
        } else if (existingToken == null) {
          // We didn't preload their token
          getToken = true;
        }
      } else {
        getToken = true;
      }
      console.log("Fetching token? ", getToken);
      if (getToken) {
        // UserService.getUser(this.$auth.user.sub).then(user => {
        // console.log("this user");
        let user = this.userProfile;
        console.log("Value for parameter ", user[row.parameterColumn]);
        ReportService.getToken(
          row.reportId,
          this.userProfile.userId,
          row.parameterColumn,
          user[row.parameterColumn],
          this.userProfile.Client
        ).then(response => {
          console.log(response);

          this.transitionDirection = "topic-right";
          this.currentReportSource =
            row.reportUrl +
            '?params={"token1": "' +
            response.token +
            '", "token2": "' +
            response.token +
            '", "token3": "' +
            response.token +
            '", "token4": "' +
            response.token +
            '", "token5": "' +
            response.token +
            '"}';
          console.log("Current Report Source:");
          console.log(this.currentReportSource);

          this.showLoadingDialog = false;
          if (row.openInNewTab) {
            // If we're opening this report in a new tab, we leave the user on the report page and pop it up in a new tab
            // We have to do some slight string manipulation and remove the /embed in the url
            var tabUrl = this.currentReportSource.replace("/embed", "");
            window.open(tabUrl, "_blank");
          } else {
            this.showReport = true;
            this.showTable = false;
          }

          // Adds this new token to the store array
          //this.$store.dispatch("pushReportTokens", response);
        });
        // });
      } else {
        // Otherwise, we already have the token, so we load the report
        this.transitionDirection = "topic-right";
        this.currentReportSource =
          row.reportUrl +
          '?params={"token1": "' +
          existingToken.token +
          '", "token2": "' +
          existingToken.token +
          '", "token3": "' +
          existingToken.token +
          '", "token4": "' +
          existingToken.token +
          '", "token5": "' +
          existingToken.token +
          '"}';
        console.log("Current Report Source:");
        console.log(this.currentReportSource);

        this.showLoadingDialog = false;
        if (row.openInNewTab) {
          // If we're opening this report in a new tab, we leave the user on the report page and pop it up in a new tab
          // We have to do some slight string manipulation and remove the /embed in the url
          var tabUrl = this.currentReportSource.replace("/embed", "");
          window.open(tabUrl, "_blank");
        } else {
          this.showReport = true;
          this.showTable = false;
        }
      }
    },
    leaveReport() {
      this.transitionDirection = "topic-left";
      this.showTable = true;
      this.showReport = false;
      this.currentReportSource = null;
    },
    createReport() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating Report";
      this.loadingMessageLine1 = "Please wait...";
      this.loadingMessageLine2 = "";
      var report = {
        clientId: this.report.clientId,
        name: this.report.name,
        displayName: this.report.displayName,
        description: this.report.description,
        reportUrl: this.report.reportUrl,
        openOnLoad: this.report.openOnLoad,
        openInNewTab: this.report.openInNewTab,
        status: "Active",
        createdBy: this.userProfile.userId,
        updatedBy: this.userProfile.userId,
        tokenTTL: this.report.tokenTTL
      };

      ReportService.createReport(report).then(report => {
        console.log("Report repsonse ", report);
        this.showLoadingDialog = false;
        this.resetReportForm();
        this.getReports();
      });
    },
    resetReportForm() {
      this.report = {
        valid: false,
        name: null,
        displayName: null,
        description: null,
        reportUrl: null,
        openOnLoad: false,
        openInNewTab: false,
        tokenTTL: null
      };
      this.$refs.reportForm.reset();
      this.createReportWidget = false;
    },
    loadReportForm() {
      this.createReportWidget = true;
      this.$refs.reportForm.reset();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "reportTokens", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    filteredReports() {
      // If not Whistle, then we want to show universal + their client.
      // Otherwise, we show based on the clientId dropdown selector
      if (this.userProfile.clientId != 1)
        return this.reports.filter(x =>
          [0, this.userProfile.clientId].includes(x.clientId)
        );
      else {
        if (this.clientId == null)
          return this.reports.filter(
            x => x.clientId == this.userProfile.clientId
          );
        else return this.reports.filter(x => x.clientId == this.clientId);
      }
    }
  }
};
</script>
<style scoped>
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.table {
  width: 90%;
}
.custom-report-text {
  width: 30%;
}
.align-text {
  text-align: left;
}
.remove-underline {
  text-decoration: none;
}

.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.client-dropdown {
  width: 300px;
  min-width: 300px;
}

.report-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.report-table >>> .v-data-table-header {
  background-color: white;
}
</style>
<style lang="css" scoped></style>
