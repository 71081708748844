import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl =
  process.env.VUE_APP_BUDGET_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/budget-service`;

class BudgetService {
  getBudgets(params = {}, version = 1, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/budgets", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        if (version == 2) throw error;
        return error;
      });
  }
  getBudgetsAdminView(params = {}, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(`${baseUrl}/budgets/admin`, { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting budgets (admin)!", error.response);
        throw error;
      });
  }
  getParentBudgets(params = {}) {
    console.log({ params });
    return axios
      .get(baseUrl + "/budgets/parents", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getBudgetFundingStatuses(magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/budgets/funding")
      .then(response => {
        return response.data.funding;
      })
      .catch(error => {
        console.log("Error getting budget funding status", error.response);
        throw error;
      });
  }
  createBudget(budget) {
    return axios
      .post(baseUrl + "/budgets", budget)
      .then(response => {
        console.log(response.data.budget);
        return response.data.budget;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getBudget(budgetId) {
    return axios
      .get(baseUrl + "/budgets/" + budgetId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  updateBudget(budgetId, budget) {
    return axios
      .patch(baseUrl + "/budgets/" + budgetId, budget)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteBudget(budgetId) {
    return axios
      .delete(baseUrl + "/budgets/" + budgetId)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error deleting budget!", error);
        throw error?.response?.data || error;
      });
  }

  getAwards() {
    return axios
      .get(baseUrl + "/awards")
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createAward(award) {
    return axios
      .post(baseUrl + "/awards", award)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getAward(awardId) {
    return axios
      .get(baseUrl + "/awards/" + awardId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateAward(awardId, award) {
    return axios
      .patch(baseUrl + "/awards/" + awardId, award)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteAward(awardId) {
    return axios
      .delete(baseUrl + "/awards/" + awardId)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  getAwardFulfillmentPartners() {
    return axios
      .get(baseUrl + "/awardFulfillmentPartner")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createAwardFulfillmentPartner(partner) {
    return axios
      .post(baseUrl + "/awardFulfillmentPartner", partner)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getAwardFulfillmentPartner(awardFulfillmentPartnerId) {
    return axios
      .get(baseUrl + "/awardFulfillmentPartner/" + awardFulfillmentPartnerId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateAwardFulfillmentPartner(awardFulfillmentPartnerId, partner) {
    return axios
      .patch(
        baseUrl + "/awardFulfillmentPartner/" + awardFulfillmentPartnerId,
        partner
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteAwardFulfillmentPartner(awardFulfillmentPartnerId) {
    return axios
      .delete(baseUrl + "/awardFulfillmentPartner/" + awardFulfillmentPartnerId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  // Award Orders
  getAwardOrders() {
    return axios
      .get(baseUrl + "/awardOrder")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createAwardOrder(partner) {
    return axios
      .post(baseUrl + "/awardOrder", partner)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getAwardOrder(awardOrderId) {
    return axios
      .get(baseUrl + "/awardOrder/" + awardOrderId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateAwardOrder(awardOrderId, order) {
    return axios
      .patch(baseUrl + "/awardOrder/" + awardOrderId, order)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteAwardOrder(awardOrderId) {
    return axios
      .delete(baseUrl + "/awardOrder/" + awardOrderId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  // runAwardOrderCRON() {
  //   return axios
  //     .get(baseUrl + "/awardOrder/cron/email")
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("Error!", error.response);
  //       return error;
  //     });
  // }

  // V2 API Calls
  getBudgetUsersV2(params) {
    return axios
      .get(baseUrl + "/v2/budget-user", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting budget users!", error.response || error);
        throw error;
      });
  }
  createManagerBudgets(body) {
    return axios
      .post(baseUrl + "/v2/budget/wizard", body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error creating manager budgets", error.response || error);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  }
  getBudgetActivityRequestsV2(params, index = false) {
    return axios
      .get(baseUrl + `/v2/budget-activity/requests${index ? `/index` : ""}`, {
        params
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting budget activity!", error.response || error);
        throw error;
      });
  } //getBudgetActivity

  async getBudgetsV2(params, budgetId = null) {
    return await axios.get(
      `${baseUrl}/v2/budget${budgetId ? `/${budgetId}` : ``}`,
      {
        params
      }
    );
  } //getBudgetsV2
}

export default new BudgetService();
