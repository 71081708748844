import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl =
  process.env.VUE_APP_TRIGGER_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/trigger-service`;

class TriggerService {
  createActivity(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/activity", body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error creating activity ", error.response);
        return error;
      });
  }

  createDebugEvent(data) {
    const headers = {
      "Content-Type": "application/json"
    };
    return axios
      .post(baseUrl + "/v2/platform-debug-event", data, {
        headers
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log("We had a debug error bug don't want to fail everything");
        return { error: true };
      });
  }
}

export default new TriggerService();
