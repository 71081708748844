<template>
  <div class="full-height">
    <div
      width="100%"
      rounded="0"
      class="full-height"
      id="program-manager"
      :class="!isMobile ? 'd-flex flex-column' : ''"
    >
      <!-- Header card/row -->
      <v-card
        class="d-flex justify-space-between mt-3 py-4 mb-5"
        :class="{
          'flex-column': $vuetify.breakpoint.xs,
          'mx-6': !isMobile,
          'align-center': !$vuetify.breakpoint.xs
        }"
      >
        <div
          class="d-flex pa-0"
          :style="
            $vuetify.breakpoint.width <= 1035 ? { 'max-width': '330px' } : {}
          "
        >
          <v-icon
            :large="!isMobile"
            dark
            class="pa-2 ml-3 mr-4 my-2 brand-background border-radius icon"
            >mdi-star</v-icon
          >
          <p
            class="font-weight-black word-break brand-text mb-auto mt-auto bold-font page-title text-left"
          >
            Program Management
          </p>
        </div>
        <div
          v-if="!isMobile"
          class="py-auto flex-grow-1"
          :class="
            $vuetify.breakpoint.md
              ? 'px-8'
              : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'px-12'
              : 'px-auto'
          "
        >
          <!------------ Search Bar -------------->
          <v-text-field
            outlined
            placeholder="Search"
            single-line
            dense
            hide-details
            v-model="table.debounceSearch"
            class="shrink mx-auto"
            append-icon="mdi-magnify"
            :style="{ 'max-width': '450px' }"
            clearable
          ></v-text-field>
          <!------------ End Search Bar -------------->
        </div>
        <div
          :class="
            $vuetify.breakpoint.xs
              ? 'px-0'
              : $vuetify.breakpoint.md
              ? 'px-8 flex-grow-1'
              : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'px-12 flex-grow-1'
              : 'px-8 flex-grow-1'
          "
          :style="
            $vuetify.breakpoint.xs
              ? { height: 'fit-content', 'max-width': '430px' }
              : { height: 'fit-content', 'max-width': '440px' }
          "
          v-if="userProfile.clientId === 1"
        >
          <v-autocomplete
            outlined
            dense
            class="my-auto pa-1 full-width"
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            v-model="clientId"
            @change="refreshClientId"
          >
          </v-autocomplete>
        </div>
      </v-card>
      <!-- Visible Messages row -->
      <v-row
        class="d-flex justify-space-between flex-grow-0"
        :style="{ 'min-height': '175px', height: 'fit-content' }"
        :class="{
          'flex-column mt-2': isMobile,
          'px-6': !isMobile
        }"
      >
        <v-col
          :cols="isMobile ? '12' : '4'"
          v-for="(message, index) in visibleMessages"
          :key="index"
          class="card-col"
        >
          <v-card
            :style="{ height: 'fit-content', 'min-height': '100%' }"
            @click="loadRecommendation(message)"
          >
            <div class="pa-3 d-flex flex-column justify-center">
              <div
                class="d-flex justify-space-between align-center pt-4 pb-2 mb-2 word-break card-header"
              >
                <div class="d-flex align-center pr-2">
                  <v-icon
                    large
                    color="white"
                    class="pa-2 rounded mr-4 brand-background icon"
                    :class="message.backgroundColor"
                    >{{ message.icon }}</v-icon
                  >
                  <!-- <p
                    style="line-height: 22px; font-size: 1.1em;"
                    class="my-auto"
                  >
                    Send a
                    <span class="font-weight-black">one time payment</span>
                  </p> -->
                  <span v-html="message.headline"></span>
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </div>
              <!-- <p class="mt-6 mb-0">
                Send a single payment to one or many people with the option to
                restrict funds for a specific purpose
              </p> -->
              <span v-html="message.body" class="mb-2"></span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Options row -->
      <v-row
        class="d-flex justify-space-between flex-grow-1"
        :style="isMobile ? {} : { 'max-height': '60%' }"
        :class="{
          'flex-column': isMobile,
          'px-6': !isMobile
        }"
      >
        <v-col :cols="isMobile ? '12' : '4'" class="card-col">
          <v-card
            class="text-left d-flex flex-column justify-start py-2"
            :class="isMobile ? 'full-width mb-3' : 'mb-10'"
            :style="{ 'min-height': '100%' }"
          >
            <!-- <h4 class="font-weight-black pt-2">Manage:</h4> -->
            <!-- Disable if there are no not deleted programs for their client -->
            <v-btn
              v-if="permissions.includes('programs:create:program')"
              class="mx-3 d-flex justify-space-between view-button"
              color="white"
              depressed
              :class="viewFilter == 1 ? 'highlighted-button' : ''"
              @click="handleViewChange(1)"
            >
              Edit an existing program
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider
              class="mx-1"
              v-if="permissions.includes('programs:create:program')"
            />
            <v-btn
              v-if="permissions.includes('wallets:create:cashpayment')"
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == 2 ? 'highlighted-button' : ''"
              @click="handleViewChange(2)"
            >
              Rewards library
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider
              class="mx-1"
              v-if="permissions.includes('wallets:create:cashpayment')"
            />
            <v-btn
              v-if="permissions.includes('learning:create:module')"
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == 3 ? 'highlighted-button' : ''"
              @click="handleViewChange(3)"
            >
              Learning management
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider
              class="mx-1"
              v-if="permissions.includes('learning:create:module')"
            />
            <v-btn
              v-if="permissions.includes('learning:create:module')"
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == 4 ? 'highlighted-button' : ''"
              @click="handleViewChange(4)"
            >
              Survey management
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider
              class="mx-1"
              v-if="permissions.includes('learning:create:module')"
            />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == 0 ? 'highlighted-button' : ''"
              @click="handleViewChange(0)"
              v-if="permissions.includes('programs:create:program')"
            >
              Program library
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider
              class="mx-1 mb-6"
              v-if="permissions.includes('programs:create:program')"
            />
            <h4
              class="font-weight-black pt-2 pl-3"
              v-if="
                permissions.includes('programs:create:program') ||
                  permissions.includes('vue:read:reporting')
              "
            >
              Other actions
            </h4>
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadMetricUI(null)"
              v-if="permissions.includes('programs:create:program')"
              >Update Progress</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadMetricManagement"
              v-if="permissions.includes('programs:create:program')"
              >Manage Metrics</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadMetricCSV"
              v-if="permissions.includes('programs:create:program')"
              >Upload Metrics</v-btn
            >
            <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadReporting"
              v-if="permissions.includes('vue:read:reporting')"
              >Program Reporting</v-btn
            >
            <!-- <v-btn
              color="primary"
              class="d-flex justify-start action-button"
              text
              @click="loadProgramBuilder(null)"
              v-if="permissions.includes('programs:create:program')"
              >Create an Advanced Program</v-btn
            > -->
          </v-card>
        </v-col>
        <v-col
          v-if="viewFilter == 2"
          :cols="isMobile ? '12' : '8'"
          class="card-col"
        >
          <v-card
            class="full-width px-0 mx-0"
            :style="{ 'min-height': '100%' }"
            color="transparent"
            elevation="0"
          >
            <RewardsLibrary @load-action="loadPaymentAction" />
          </v-card>
        </v-col>
        <v-col
          v-else-if="viewFilter == 3 || viewFilter == 4"
          :cols="isMobile ? '12' : '8'"
          class="card-col"
        >
          <v-card
            class="full-width py-3"
            :style="{ 'min-height': '100%' }"
            elevation="0"
          >
            <LearningLibrary
              :displaySurveys="viewFilter == 4"
              :search="table.search"
              sourcePage="programadmin"
              :sourcePageProps="{
                preload: viewFilter == 3 ? 'learning' : 'surveys'
              }"
            />
          </v-card>
        </v-col>
        <v-col v-else :cols="isMobile ? '12' : '8'" class="card-col">
          <v-card
            class="text-left d-flex flex-column justify-space-between py-3"
            :style="{ 'min-height': '100%' }"
            :class="isMobile ? 'full-width' : ''"
          >
            <div>
              <v-data-table
                :key="viewFilter == 0 ? 0 : 1"
                :headers="computedHeaders"
                :items="visiblePrograms"
                :items-per-page="table.itemsPerPage"
                :footer-props="table.footerProps"
                :options.sync="table.options"
                :server-items-length="table.total"
                :show-expand="viewFilter == 1"
                :expanded="programsExpanded"
                :loading="table.loading"
                loading-text="loading"
                no-data-text="No programs available"
                no-results-text="No programs found"
                item-key="programId"
                id="program-table"
                @click:row="testMethod"
              >
                <template v-slot:expanded-item="{ item, headers }">
                  <!-- td for client's programs -->
                  <td
                    id="program-table-inner"
                    :colspan="headers.length"
                    class="py-4"
                    :class="
                      $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                        ? 'px-0'
                        : 'px-6'
                    "
                    v-if="viewFilter == 1 && item && item.clientId != 0"
                  >
                    <div class="d-flex full-width">
                      <!-- Left column with participant info -->
                      <v-col
                        :cols="6"
                        class="d-flex flex-column justify-space-between"
                      >
                        <div>
                          <!-- Div for each of the participation data points -->
                          <!-- Total Participants -->
                          <div class="d-flex align-center py-1">
                            <v-col :cols="2" class="px-0">
                              <v-icon
                                v-if="item.totalParticipants.length"
                                :color="offBrandCyan"
                                @click="
                                  messageProgramAudience(item, 'everyone')
                                "
                                >mdi-email-outline</v-icon
                              >
                            </v-col>
                            <v-col
                              :cols="5"
                              class="pl-0 pr-3 d-flex justify-space-between"
                            >
                              <p class="mb-0">
                                Participants
                              </p>
                              <span class="font-weight-black">{{
                                item.totalParticipants.length
                              }}</span>
                            </v-col>
                            <!-- This is always going to be 100% -->
                            <v-col :cols="5" class="px-0">
                              <v-progress-linear
                                class="mb-1"
                                color="mediumGrey"
                                value="100"
                                buffer-value="100"
                                height="14"
                              ></v-progress-linear>
                            </v-col>
                          </div>
                          <!-- Not Started -->
                          <div class="d-flex align-center  py-1">
                            <v-col :cols="2" class="px-0">
                              <v-icon
                                v-if="item.notStartedParticipants.length"
                                :color="offBrandCyan"
                                @click="
                                  messageProgramAudience(item, 'notStarted')
                                "
                                >mdi-email-outline</v-icon
                              >
                            </v-col>
                            <v-col
                              :cols="5"
                              class="pl-0 pr-3 d-flex justify-space-between"
                            >
                              <p class="mb-0">
                                Not started
                              </p>
                              <span class="font-weight-black">{{
                                item.notStartedParticipants.length
                              }}</span>
                            </v-col>
                            <v-col :cols="5" class="px-0">
                              <v-progress-linear
                                class="mb-1"
                                color="brandYellow"
                                :value="
                                  calculatePercentage(
                                    item.notStartedParticipants.length,
                                    item.totalParticipants.length
                                  )
                                "
                                buffer-value="100"
                                height="14"
                              ></v-progress-linear>
                            </v-col>
                          </div>
                          <!-- In Progress -->
                          <div class="d-flex align-center  py-1">
                            <v-col :cols="2" class="px-0">
                              <v-icon
                                v-if="item.inProgressParticipants.length"
                                :color="offBrandCyan"
                                @click="messageProgramAudience(item, 'started')"
                                >mdi-email-outline</v-icon
                              >
                            </v-col>
                            <v-col
                              :cols="5"
                              class="pl-0 pr-3 d-flex justify-space-between"
                            >
                              <p class="mb-0">
                                In progress
                              </p>
                              <span class="font-weight-black">{{
                                item.inProgressParticipants.length
                              }}</span>
                            </v-col>
                            <v-col :cols="5" class="px-0">
                              <v-progress-linear
                                class="mb-1"
                                :color="offBrandCyan"
                                :value="
                                  calculatePercentage(
                                    item.inProgressParticipants.length,
                                    item.totalParticipants.length
                                  )
                                "
                                buffer-value="100"
                                height="14"
                              ></v-progress-linear>
                            </v-col>
                          </div>
                          <!-- Completed -->
                          <div class="d-flex align-center py-1">
                            <v-col :cols="2" class="px-0">
                              <v-icon
                                v-if="item.completedParticipants.length"
                                :color="offBrandCyan"
                                @click="
                                  messageProgramAudience(item, 'complete')
                                "
                                >mdi-email-outline</v-icon
                              >
                            </v-col>
                            <v-col
                              :cols="5"
                              class="pl-0 pr-3 d-flex justify-space-between"
                            >
                              <p class="mb-0">
                                Completed
                              </p>
                              <span class="font-weight-black">{{
                                item.completedParticipants.length
                              }}</span>
                            </v-col>
                            <v-col :cols="5" class="px-0">
                              <v-progress-linear
                                class="mb-1"
                                :color="offBrandBrightGreen"
                                :value="
                                  calculatePercentage(
                                    item.completedParticipants.length,
                                    item.totalParticipants.length
                                  )
                                "
                                buffer-value="100"
                                height="14"
                              ></v-progress-linear>
                            </v-col>
                          </div>
                        </div>
                        <!-- Start and end date (if program has it) -->
                        <div class="d-flex justify-space-between mt-4  py-1">
                          <p class="my-0" v-show="item.formattedStartDate">
                            Start: {{ item.formattedStartDate }}
                          </p>
                          <p
                            class="my-0"
                            v-if="item.formattedEndDate"
                            v-show="item.formattedEndDate"
                          >
                            End: {{ item.formattedEndDate }}
                          </p>
                          <p
                            class="my-0"
                            v-else
                            v-show="item.formattedRollingProgram"
                          >
                            Rolling: {{ item.formattedRollingProgram }}
                          </p>
                        </div>
                      </v-col>
                      <v-col
                        :cols="6"
                        class="d-flex flex-column justify-space-between"
                      >
                        <div
                          class="d-flex"
                          :class="
                            $vuetify.breakpoint.mdAndDown ? 'flex-column' : ''
                          "
                        >
                          <v-col
                            :cols="6"
                            class="d-flex flex-column justify-space-between"
                          >
                            <div class="flex-column justify-start">
                              <!-- Program action buttons -->
                              <v-btn
                                class="d-flex align-center pr-2 pl-0 program-action-button"
                                text
                                @click="
                                  () => {
                                    // if (item.complexProgram) {
                                    //   loadProgramBuilder(item, true);
                                    // } else {
                                    loadProgramWizard(item, true);
                                    // }
                                  }
                                "
                              >
                                <v-icon
                                  :color="offBrandCyan"
                                  v-if="item.status != 'Deleted'"
                                  >mdi-pencil</v-icon
                                >
                                <p class="mb-0">
                                  Edit Program
                                </p>
                              </v-btn>
                              <v-btn
                                class="d-flex align-center pr-2 pl-0 program-action-button"
                                text
                                @click="
                                  () => {
                                    // if (item.complexProgram) {
                                    //   loadProgramBuilder(item, false);
                                    // } else {
                                    loadProgramWizard(item, false);
                                    // }
                                  }
                                "
                              >
                                <v-icon :color="offBrandCyan"
                                  >mdi-content-copy</v-icon
                                >
                                <p class="mb-0">
                                  Duplicate Program
                                </p>
                              </v-btn>
                              <v-tooltip
                                top
                                :disabled="!disableDeleteButton(item)"
                                open-delay="200"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="d-flex align-center pr-2 pl-0 program-action-button"
                                    text
                                    v-on="on"
                                    v-bind="attrs"
                                    @click="deleteProgramConfirmation(item)"
                                  >
                                    <v-icon
                                      :color="offBrandCyan"
                                      :loading="
                                        item.programId ==
                                          displayLoadingIconOnProgram
                                      "
                                      :disabled="disableDeleteButton(item)"
                                      >mdi-trash-can-outline</v-icon
                                    >
                                    <p class="mb-0">
                                      Delete Program
                                    </p>
                                  </v-btn>
                                </template>
                                <span v-if="disableDeleteButton(item)">
                                  You do not have permission to delete this
                                  program</span
                                >
                              </v-tooltip>
                            </div>
                          </v-col>
                          <v-col :cols="6">
                            <div>
                              <v-btn
                                class="d-flex align-center pr-2 pl-0 program-action-button"
                                text
                                v-if="
                                  item.status == 'Active' &&
                                    item.nonLearningMetrics === true
                                "
                                @click="loadMetricUI(item.programId)"
                              >
                                <v-icon
                                  :color="offBrandCyan"
                                  :disabled="
                                    item.status != 'Active' ||
                                      item.nonLearningMetrics == false
                                  "
                                  >mdi-checkbox-marked-outline</v-icon
                                >
                                <p class="mb-0">
                                  Update Progress
                                </p>
                              </v-btn>
                              <!-- Can't add participants in the program builder to an active program -->
                              <v-tooltip
                                top
                                open-delay="200"
                                :disabled="
                                  item.complexProgram != true ||
                                    item.status !== 'Active'
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="d-flex align-center pr-2 pl-0 program-action-button"
                                    text
                                    v-on="on"
                                    v-bind="attrs"
                                    @click="
                                      () => {
                                        // if (item.complexProgram) {
                                        //   loadProgramBuilder(
                                        //     item,
                                        //     true,
                                        //     'participants'
                                        //   );
                                        // } else {
                                        loadProgramWizard(
                                          item,
                                          true,
                                          'participants'
                                        );
                                        // }
                                      }
                                    "
                                  >
                                    <v-icon
                                      :color="offBrandCyan"
                                      :disabled="
                                        item.complexProgram == true &&
                                          item.status == 'Active'
                                      "
                                      >mdi-account-multiple-plus</v-icon
                                    >
                                    <p class="mb-0">
                                      Add Participants
                                    </p>
                                  </v-btn>
                                </template>
                                <span
                                  >Contact your client success manager to add
                                  participants to this program</span
                                >
                              </v-tooltip>
                            </div>
                          </v-col>
                        </div>
                        <div
                          class="d-flex flex-column  py-1"
                          v-if="item.budgetId && budgets[item.budgetId]"
                        >
                          <!-- <p class="mb-0" v-if="!item.Budget.funded">
                            Budget not funded
                          </p> -->
                          <p
                            class="mb-0"
                            v-if="budgets[item.budgetId].errorFetching == true"
                          >
                            Error getting program budget
                          </p>
                          <div v-else>
                            <p class="mb-0">
                              Budget available
                            </p>
                            <!-- loading -->
                            <div v-if="budgets[item.budgetId].loading == true">
                              <v-progress-linear
                                indeterminate
                                :color="offBrandCyan"
                                class="mt-3 mb-3"
                              ></v-progress-linear>
                            </div>
                            <!-- If only current total but not original funded amount, display current total -->
                            <p
                              class="mb-0"
                              v-else-if="
                                budgets[item.budgetId].originalFundedAmount ===
                                  undefined ||
                                  budgets[item.budgetId]
                                    .originalFundedAmount === null
                              "
                            >
                              {{
                                budgets[item.budgetId]
                                  .formattedCurrentBudgetBalance
                              }}
                            </p>
                            <div class="d-flex mt-1" v-else>
                              <p class="mb-0 mr-3 font-weight-black">
                                {{
                                  budgets[item.budgetId]
                                    .formattedCurrentBudgetBalance
                                }}
                              </p>
                              <v-progress-linear
                                class="mb-1"
                                :color="offBrandBrightGreen"
                                background-color="mediumGrey"
                                :value="
                                  (Number(
                                    budgets[item.budgetId].currentBudgetBalance
                                  ) /
                                    Number(
                                      budgets[item.budgetId]
                                        .originalFundedAmount
                                    )) *
                                    100
                                "
                                buffer-value="100"
                                height="14"
                              ></v-progress-linear>
                              <p class="mb-0 ml-3">
                                {{
                                  budgets[item.budgetId]
                                    .formattedOriginalFundedAmount
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </div>
                  </td>
                </template>
                <template v-slot:[`item.actionItems`]="{ item }">
                  <div
                    class="d-flex justify-space-between align-center"
                    :class="
                      $vuetify.breakpoint.xs && userProfile.clientId != 1
                        ? 'flex-column'
                        : ''
                    "
                    :style="
                      $vuetify.breakpoint.xs && userProfile.clientId == 1
                        ? { width: '40%' }
                        : {}
                    "
                    v-if="viewFilter == 0 || (item && item.clientId == 0)"
                  >
                    <!-- Program action buttons -->
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center py-1"
                          v-if="userProfile.clientId == 1"
                        >
                          <v-icon
                            :color="offBrandCyan"
                            v-if="item.status != 'Deleted'"
                            @click="
                              () => {
                                // if (item.complexProgram) {
                                //   loadProgramBuilder(item, true);
                                // } else {
                                loadProgramWizard(item, true);
                                // }
                              }
                            "
                            v-on="on"
                            v-bind="attrs"
                            >mdi-pencil</v-icon
                          >
                        </div>
                      </template>
                      <span>Edit Program</span>
                    </v-tooltip>
                    <!-- If Whistle, they'll see just icons with tooltips -->
                    <!-- Other clients will just see duplicate icon with no tooltip but description -->
                    <v-tooltip
                      top
                      :disabled="userProfile.clientId != 1"
                      open-delay="200"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center py-1">
                          <v-icon
                            :color="offBrandCyan"
                            @click="
                              () => {
                                // if (item.complexProgram) {
                                //   loadProgramBuilder(item, false);
                                // } else {
                                loadProgramWizard(item, false);
                                // }
                              }
                            "
                            v-on="on"
                            v-bind="attrs"
                            >mdi-content-copy</v-icon
                          >
                          <p
                            class="mb-0 mr-3 pl-2"
                            v-if="userProfile.clientId != 1"
                          >
                            Duplicate Program
                          </p>
                        </div>
                      </template>
                      <span>Duplicate Program</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      :disabled="userProfile.clientId != 1"
                      open-delay="200"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center py-1"
                          v-if="userProfile.clientId == 1"
                        >
                          <v-icon
                            :color="offBrandCyan"
                            :disabled="
                              item.status == 'Active' ||
                                item.status == 'Archived' ||
                                !permissions.includes(
                                  'programs:delete:program'
                                ) ||
                                (item.clientId != clientId &&
                                  userProfile.clientId != 1)
                            "
                            @click="
                              (dialogProgramDelete = true),
                                (programIdToDelete = item.programId),
                                (programNameToDelete = item.displayName),
                                (programDeletionConfirmation = null),
                                (programDeletionError = false)
                            "
                            :loading="
                              item.programId == displayLoadingIconOnProgram
                            "
                            v-on="on"
                            v-bind="attrs"
                            >mdi-trash-can-outline</v-icon
                          >
                        </div>
                      </template>
                      <span>Delete Program</span>
                    </v-tooltip>
                  </div>
                </template>
                <template
                  v-slot:[`item.data-table-expand`]="{
                    item,
                    isExpanded,
                    expand
                  }"
                >
                  <v-btn
                    @click.stop="
                      expand(!isExpanded);
                      getBudget(item);
                    "
                    v-if="item.clientId != 0"
                    icon
                    ><v-icon large>{{
                      isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon></v-btn
                  >
                </template>
                <template v-slot:loading>
                  <!-- {{ item }} -->
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="my-8"
                    :size="50"
                    :width="4"
                  ></v-progress-circular>
                </template>
              </v-data-table>
            </div>
            <!-- <div
              v-else-if="loadingPrograms"
              class="d-flex flex-column justify-center align-center my-auto"
            >
              <p>Loading your programs...</p>
              <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="fluttering"
                :loop="true"
              />
            </div> -->
            <!-- <div
              v-else-if="
                !visiblePrograms ||
                  
                  (visiblePrograms &&
                    viewFilter == 1 &&
                    visiblePrograms.filter(x => x.clientId != 0).length == 0)
              "
              class="d-flex flex-column justify-center align-center my-auto"
            >
              <p>It looks like you don't have any programs yet...</p>
              <p>Create your first program</p>
              <p
                v-if="
                  visiblePrograms &&
                    visiblePrograms.filter(x => x.clientId == 0).length > 0
                "
              >
                Get started by duplicating a template program
              </p>
              <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="idleBlink"
                :loop="true"
              />
            </div> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- Nav drawers -->
    <v-navigation-drawer
      v-model="metricCreatorWidget"
      temporary
      fixed
      right
      width="500"
    >
      <MetricCreatorWidget
        @close="metricCreatorWidget = false"
        :clientId="clientId"
      />
    </v-navigation-drawer>
    <!-- Dialogs -->
    <!-- Metric Management Dialog -->
    <v-dialog
      fullscreen
      v-model="metricManagementWidget"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <MetricDefinition
          v-if="metricManagementWidget"
          :metrics="metrics"
          :loading="loadingMetrics"
          @get-metrics="getMetrics"
          @close="metricManagementWidget = false"
          @update-client="refreshClientId"
        ></MetricDefinition>
      </v-card>
    </v-dialog>
    <!-- Deletion confirmation dialog -->
    <v-dialog
      v-model="dialogProgramDelete"
      :persistent="deletingProgram"
      width="500"
    >
      <v-card
        v-if="dialogProgramDelete"
        rounded="0"
        class="d-flex justify-center flex-column pa-6"
      >
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            {{
              programDeletionError
                ? "We ran into a problem while deleting the program"
                : "Are you sure you want to delete this program?"
            }}
          </v-card-title>
        </div>
        <div v-if="programDeletionError" class="text-left word-break">
          Please try again or
          <a
            href="mailto:help@wewhistle.com?subject=Problems while deleting metrics"
            >contact us</a
          >
          if the problem continues.
        </div>
        <div class="text-left word-break" v-else>
          <p class="ml-1">
            '{{ programNameToDelete }}' will be permanently deleted for all
            users within the program. This action cannot be undone.
          </p>
          <p class="ml-1 mb-1">
            Confirm program deletion by typing 'DELETE' below:
          </p>
          <v-text-field
            v-model="programDeletionConfirmation"
            placeholder="DELETE"
            outlined
            dense
            :disabled="deletingProgram"
            :rules="[
              v =>
                !!(v && v.toUpperCase() == 'DELETE') ||
                'Enter DELETE to confirm'
            ]"
          ></v-text-field>
        </div>
        <v-card-actions class="pt-3 d-flex justify-center align-center">
          <v-btn
            color="brandCyan"
            class="mx-1"
            @click="dialogProgramDelete = false"
            outlined
            width="130"
            :disabled="deletingProgram"
            >{{ programDeletionError ? "Close" : "Cancel" }}</v-btn
          >
          <!-- <v-spacer v-if="!programDeletionError" /> -->
          <v-btn
            v-if="!programDeletionError"
            color="brandCyan"
            class="white--text mx-1"
            depressed
            :disabled="
              !(
                programDeletionConfirmation &&
                programDeletionConfirmation.toUpperCase() == 'DELETE'
              )
            "
            @click="deleteProgram(programIdToDelete)"
            width="130"
            :loading="deletingProgram"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- upload metrics dialog -->
    <v-dialog v-model="dialogUploadMetrics" width="450">
      <v-card>
        <MetricUpload
          :metrics="metrics"
          v-if="dialogUploadMetrics"
          @close="dialogUploadMetrics = false"
          :disalbled="loadingMetrics || metrics.length == 0"
          @update-client="refreshClientId"
        >
        </MetricUpload>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.adminPages" width="500">
      <v-card>
        <v-card-text class="align-text-center pt-5">
          <h2 class="my-6">
            <b
              >The {{ dialog.learningPage ? "learning" : "survey" }} admin page
              has moved!</b
            >
          </h2>

          <p class="text-left mx-7">
            The page has been moved to the '{{
              dialog.learningPage ? "Learning" : "Survey"
            }}
            management' tab on the Program Management page.
            <br />
            <br />
            For now, the tabs on the left will remain and redirect you to this
            page while you get the hang of this change.
          </p>
          <v-btn
            @click="dialog.adminPages = false"
            depressed
            color="brand"
            width="140"
            class="mx-2 white--text"
            >Close</v-btn
          >
        </v-card-text></v-card
      ></v-dialog
    >
  </div>
</template>

<script>
// @ is an alias to /src

// import Robin from "@/components/Robin";
// import { truncateNumber, numberWithCommas } from "@/shared_data/functions";
import MetricCreatorWidget from "@/components/MetricCreatorWidget.vue";
import MetricDefinition from "@/components/MetricDefinition.vue";
import MetricUpload from "@/components/MetricUpload.vue";
import RewardsLibrary from "@/components/rewards-admin/RewardsLibrary.vue";
import LearningLibrary from "@/components/program-admin/LearningLibrary.vue";
// import Robin from "@/components/Robin.vue";

import LearningService from "@/services/LearningService";
import ProgramService from "@/services/ProgramService";
import BudgetService from "@/services/BudgetService";

import moment from "moment";
import { mapState } from "vuex";

import { debounce } from "@/shared_data/functions";

// import fz from "fuzzaldrin-plus";

/*

Kelli's working notes
- Test it all
*/

export default {
  name: "ProgramAdminV3",
  title: "Whistle | Program Management",
  components: {
    MetricCreatorWidget,
    MetricDefinition,
    MetricUpload,
    RewardsLibrary,
    LearningLibrary
    // Robin
  },
  props: {
    preload: {
      type: String,
      defualt: null
    },
    // Used if we came from somewhere else and immediately loaded the payments console
    previousRoute: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // STUFF TO KEEP
      // Colors - Chris is using not brand colors in the wireframes
      // Using his colors but making it easy to swap
      offBrandCyan: "#4DC9F9",
      offBrandBrightGreen: "#A2D76D",
      loadingPrograms: false,
      programs: null,
      dialog: { adminPages: false, learningPage: true },
      viewFilter: 1, // 1: All programs for client, 0: Universal
      messages: [
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Create a new
              <span class="font-weight-black">program</span>
            </h5>`,
          body: `<p class="mb-0">
              Use the program builder to create a new program from the ground up
            </p>`,
          icon: "mdi-star",
          backgroundColor: "brand-background",
          action: "program-wizard",
          permission: "programs:create:program"
        },
        // {
        //   headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
        //       Create an
        //       <span class="font-weight-black">onboarding program</span>
        //     </h5>`,
        //   body: `<p class="mb-0">
        //       Great employee loyalty starts with better onboarding.  Use our template to make it easy.
        //     </p>`,
        //   icon: "mdi-star-plus",
        //   backgroundColor: "brand-background",
        //   action: "program-wizard",
        //   permission: "programs:create:program"
        // },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto  rec-header">
              Create a
              <span class="font-weight-black">learning module</span>
            </h5>`,
          body: `<p class="mb-0">
              Use the learning builder to create a new module to use in a program. You can even reward those who take the learning module!
            </p>`,
          icon: "mdi-school",
          backgroundColor: "brand-green-background",
          action: "learning-module",
          permission: "vue:read:learningadmin"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto  rec-header">
              Create a
              <span class="font-weight-black">survey</span>
            </h5>`,
          body: `<p class="mb-0">
              Use the survey builder to create a survey that can be used in a program. Surveys can also stand alone and are either public or private.
            </p>`,
          icon: "mdi-comment-question",
          backgroundColor: "brand-background",
          action: "survey-program",
          permission: "vue:read:surveyadmin"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto  rec-header">
              Update your
              <span class="font-weight-black">program progress</span>
            </h5>`,
          body: `<p class="mb-0">
              Keep your programs up to date with the latest data.
            </p>`,
          icon: "mdi-checkbox-marked-outline",
          backgroundColor: "brand-background",
          action: "update-progress",
          permission: "programs:create:program"
        }
      ],
      metricCreatorWidget: false,
      metricManagementWidget: false,
      dialogProgramDelete: false,
      programIdToDelete: null,
      programNameToDelete: null,
      programDeletionConfirmation: null,
      programDeletionError: false,
      displayLoadingIconOnProgram: null, // an id to dipslay a loading icon on
      deletingProgram: false,
      programToMessage: null,
      preselectedAudience: null,
      preselectedFilter: null,
      search: null,
      budgets: {},
      loadingBudgetIndex: [],
      metrics: [],
      loadingMetrics: false,
      dialogUploadMetrics: false,
      programsExpanded: [],
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        programs: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      }
    };
  },
  created() {
    // this.clientId = this.userProfile.clientId;
    if (
      this.$route.name == "learningadmin" ||
      this.$route.name == "surveyadmin"
    ) {
      this.showLearningAdminPopup(this.$route.name);
    } else if (this.$route.query.tab || this.preload) {
      let tab = this.$route.query.tab || this.preload;
      if (Number(tab) == tab) this.viewFilter = Number(tab);
      else
        this.viewFilter =
          tab == "learning"
            ? 3
            : tab == "surveys"
            ? 4
            : tab == "rewards"
            ? 2
            : tab == "library"
            ? 0
            : 1;
    } else if (!this.permissions.includes("programs:create:program"))
      this.viewFilter = 3;
    // this.getPrograms();
    // this.getProgramsV2();

    if (this.permissions.includes("programs:create:program")) {
      this.getMetrics();
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    testMethod(value) {
      console.log("testing");
      console.log(value);
      this.getBudget(value);

      //add or remove the item from expanded array
      const index = this.programsExpanded.indexOf(value);
      console.log("INDEX ", index);
      if (index === -1) {
        this.programsExpanded.push(value);
      } else {
        this.programsExpanded = this.programsExpanded.filter(x => x != value);
      }
    },
    // getPrograms() {
    //   // console.log("client id", this.clientId);
    //   this.loadingPrograms = true;
    //   const clientIds = [this.clientId, 0];
    //   ProgramService.getPrograms(`?clientId.isIn([${clientIds}])`)
    //     .then(response => {
    //       // console.log("Programs response: ", response);
    //       //Prune the returned programs for the data we need for the table
    //       var allPrograms = response.filter(y => y.status !== "Deleted");
    //       allPrograms.map(x => {
    //         var program = x;
    //         //format dates with Moment (maybe move this to the card component?)
    //         if (program.startDate !== null) {
    //           var formattedStartDate = moment(
    //             program.startDate,
    //             "YYYY-MM-DD hh:mm:ss"
    //           ).format("MMM Do, YYYY");
    //         } else {
    //           formattedStartDate = null;
    //         }
    //         if (program.endDate !== null) {
    //           var formattedEndDate = moment(
    //             program.endDate,
    //             "YYYY-MM-DD hh:mm:ss"
    //           ).format("MMM Do, YYYY");
    //         } else {
    //           formattedEndDate = null;
    //         }

    //         if (program.updatedAt !== null) {
    //           var formattedUpdateDate = moment(program.updatedAt)
    //             .local()
    //             .format("ddd YYYY-MM-DD hh:mm A");
    //         } else {
    //           formattedUpdateDate = null;
    //         }

    //         program.displayName =
    //           program.displayName === null ? program.name : program.displayName;
    //         program.formattedStartDate = formattedStartDate;
    //         program.formattedEndDate = formattedEndDate;
    //         program.formattedRollingProgram = program.rollingProgramLength
    //           ? `${program.rollingProgramLength} ${
    //               program.rollingProgramLength == 1 ? "day" : "days"
    //             }`
    //           : null;
    //         program.formattedUpdateDate = formattedUpdateDate;

    //         program.groupAssignments =
    //           program.GroupRule == null
    //             ? []
    //             : program.GroupRule.GroupAssignments;

    //         program.id = program.programId;

    //         // Calculate number of participants
    //         // Calculate values for inner table graph
    //         program.totalParticipants = program.ProgramCaches.filter(
    //           x => x != "Deleted"
    //         );
    //         program.notStartedParticipants = program.ProgramCaches.filter(
    //           x =>
    //             (!x.progressPercent && !x.progressValue) || // No progress percent or progress value
    //             x.progressPercent == "0" || // Progress percent is 0
    //             (!x.progressPercent &&
    //               x.progressValue &&
    //               x.progressValue == "0") // No progress percent but progress value is 0
    //         );
    //         program.inProgressParticipants = program.ProgramCaches.filter(
    //           x =>
    //             (Number(x.progressPercent) > 0 &&
    //               Number(x.progressPercent < 100)) || // progress percent between 1 & 100
    //             (!x.progressPercent &&
    //               Number(x.progressValue) > 0 &&
    //               Number(x.progressValue) < 100) // no progress percent but progress value between 1 & 100
    //         );
    //         program.completedParticipants = program.ProgramCaches.filter(
    //           x =>
    //             Number(x.progressPercent) >= 100 || // progress percent greater than or equal to 100
    //             (!x.progressPercent && Number(x.progressValue) >= 100) // no progress percent but progress value greater than or equal to 100
    //         );
    //         if (program.Budget) {
    //           program.Budget.originalFundedAmount = null;
    //           program.Budget.currentBudgetBalance = null;
    //           program.Budget.errorFetching = false;
    //           program.Budget.loading = false;
    //         }
    //       });

    //       allPrograms.sort(function(a, b) {
    //         return new Date(b.createdAt) - new Date(a.createdAt);
    //       });
    //       this.programs = allPrograms;
    //       this.loadingPrograms = false;
    //       // console.log("Programs with budgets");
    //       // console.log(this.programs.filter((x) => x.Budget !== null));
    //     })
    //     .catch(error => {
    //       console.log("Error!" + error);
    //     });
    // },
    async getProgramsV2(reset = false, source = null) {
      try {
        console.log("Getting programs from ", source);
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.table.search
          ? this.table.search.replace(/'/g, "\\'")
          : null;
        let filter = `status != 'Deleted' && (type == null || type != 'procore') && clientId = ${
          this.viewFilter == 0 ? 0 : this.clientId
        }`;

        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (displayName.like('%${search}%') || status.like('%${search}%') || programId.like('%${search}%'))`;
        }
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          if (column == "formattedSendDate") sort = `sendDate ${type}`;
          else var sort = column + " " + type;
        } else sort = "programId DESC";
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          filter,
          offset,
          limit,
          sort,
          // Program Cache is used to get group estimates
          // Program Element is used so we know when to show update progress
          expand: "ProgramCache,ProgramElement"
        };
        let response = await ProgramService.getProgramsV2(params);

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort
        ) {
          console.log("Ignoring response");
          return;
        }

        console.log("Got programs ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          r.formattedSendDate = new moment(r.sendDate)
            .local()
            .format("YYYY-MM-DD h:mm a");

          var formattedStartDate = null;
          if (r.startDate)
            formattedStartDate = moment(
              r.startDate,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMM Do, YYYY");

          var formattedEndDate = null;
          if (r.endDate)
            formattedEndDate = moment(r.endDate, "YYYY-MM-DD hh:mm:ss").format(
              "MMM Do, YYYY"
            );

          r.formattedStartDate = formattedStartDate;
          r.formattedEndDate = formattedEndDate;
          r.formattedRollingProgram = r.rollingProgramLength
            ? `${r.rollingProgramLength} ${
                r.rollingProgramLength == 1 ? "day" : "days"
              }`
            : null;
          r.nonLearningMetrics = !!r.ProgramElements.find(x => x.metricId);

          r.totalParticipants = r.ProgramCaches.filter(x => x != "Deleted");
          r.notStartedParticipants = r.ProgramCaches.filter(
            x =>
              (!x.progressPercent && !x.progressValue) || // No progress percent or progress value
              x.progressPercent == "0" || // Progress percent is 0
              (!x.progressPercent && x.progressValue && x.progressValue == "0") // No progress percent but progress value is 0
          );
          r.inProgressParticipants = r.ProgramCaches.filter(
            x =>
              (Number(x.progressPercent) > 0 &&
                Number(x.progressPercent < 100)) || // progress percent between 1 & 100
              (!x.progressPercent &&
                Number(x.progressValue) > 0 &&
                Number(x.progressValue) < 100) // no progress percent but progress value between 1 & 100
          );
          r.completedParticipants = r.ProgramCaches.filter(
            x =>
              Number(x.progressPercent) >= 100 || // progress percent greater than or equal to 100
              (!x.progressPercent && Number(x.progressValue) >= 100) // no progress percent but progress value greater than or equal to 100
          );

          return r;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.table.programs = rows;
        this.table.loading = false;

        // We can reset what is expanded
        this.programsExpanded = [];
        //    rows.concat(
        //     this.table.messages.slice(rows.length)
        //   );
        // else
        //   this.table.messages = this.table.messages
        //     .slice(0, offset)
        //     .concat(rows, this.table.messages.slice(offset + rows.length));
      } catch (err) {
        console.log("Error getting programs ", err);
        this.table.loading = false;
      }
    },
    async getMetrics() {
      this.loadingMetrics = true;
      await ProgramService.getMetrics()
        .then(response => {
          // console.log("Metrics response: ", response);
          var metrics = [];
          response.forEach(metric => {
            if (metric.status !== "Deleted") {
              metrics.push(metric);
            }
          });
          this.metrics = metrics;
          console.log("metrics", this.metrics);
        })
        .catch(error => {
          console.log("Error!" + error);
        })
        .finally(() => {
          this.loadingMetrics = false;
        });
    },
    // fetches budget details, what we really want is the historicalBalance to display the og amount loaded
    async getBudget(p) {
      // console.log(p);
      console.log("Getting budget!!!! ", p);
      // If the program has a budget and we haven't fetched the current info, fetch
      if (!p.budgetId || this.budgets[p.budgetId])
        return console.log("No need to fetch program budget ", p.budgetId);
      // else if () {
      //   console.log("We've already cached this budget");
      //   return (p.Budget = this.budgets[p.budgetId]);
      // }
      // array of loading budget indexes
      let program = this.table.programs.find(x => x.programId == p.programId);
      if (!program) return console.log("Couldn't find program in array");
      program.Budget = { loading: true };

      const budgetId = p.budgetId;
      let obj = {
        loading: true
      };
      this.budgets[budgetId] = obj;
      try {
        let res = await BudgetService.getBudget(budgetId);
        console.log(`GOT BUDGET ${budgetId} `, res);

        // Attach historical account balance to program
        if (
          !res.Award ||
          res.budgetBalance === undefined ||
          res.budgetBalance === null
        )
          throw "Missing award or budget balance";

        // If award id = 1, prepend = "$"
        const prepend = res.Award.awardId == 1 ? "$" : "";
        // Else if Award.awardId != 1 && award.awardDisplayName, append " awardDisplayName"
        const append =
          res.Award.awardId != 1 && res.Award.awardDisplayName
            ? " " + res.Award.awardDisplayName
            : "";

        obj.formattedOriginalFundedAmount = `${prepend}${res.historicalAccountBalance}${append}`;

        obj.originalFundedAmount = res.historicalAccountBalance;

        obj.formattedCurrentBudgetBalance = `${prepend}${res.budgetBalance}${append}`;

        obj.currentBudgetBalance = res.budgetBalance;
        obj.loading = false;
      } catch (err) {
        console.log("Error getting budget for program id", p.programId);
        console.log(err);
        // This will stop the loading and display error message
        obj.errorFetching = true;
        obj.loading = false;
      }

      this.budgets[budgetId] = obj;

      // Set the program to itself so it re-renders
      this.$set(program, {
        ...program
      });
    },
    deleteProgram(programId) {
      this.displayLoadingIconOnProgram = programId;
      this.deletingProgram = true;
      ProgramService.deleteProgram(programId)
        .then(response => {
          console.log("Delete program response: ", response);
          this.getProgramsV2();
          this.programIdToDelete = null;
          this.programNameToDelete = null;
          this.programDeletionConfirmation = null;
          this.dialogProgramDelete = false;
        })
        .catch(error => {
          console.log("Error deleting program ", error);
          this.programDeletionError = true;
        })
        .finally(() => {
          this.displayLoadingIconOnProgram = null;
          this.deletingProgram = false;
        });
    },
    loadRecommendation(message) {
      switch (message.action) {
        case "program-wizard":
          this.loadProgramWizard();
          break;
        case "onboarding-program":
          this.loadProgramWizard();
          break;
        case "survey-program":
          this.loadSurveyBuilder();
          break;
        case "learning-module":
          this.$router.push({
            name: "learningwizard",
            params: {
              clientId: this.clientId,
              preloadedClientId: this.clientId,
              editingLearning: false,
              sourcePage: "programadmin",
              sourcePageProps: {
                preload: "learning"
              }
            },
            query: {
              clientId: this.clientId
            }
          });
          break;
        case "update-progress":
          this.loadMetricUI(null);
          break;
      }
    },
    loadProgramWizard(program = null, editing, step) {
      if (program) {
        // Called from inside table, we pass in program and if we're editing
        this.$router.push({
          name: "programwizard",
          query: {
            programId: program.programId,
            clientId: program.clientId,
            duplicate: !editing
          },
          params: {
            // preloadedProgram: program,
            // editingProgram: editing,
            preloadedStep: editing && step ? step : null
          }
        });
      } else {
        this.$router.push({ name: "programwizard" });
      }
    },
    loadSurveyBuilder() {
      this.$router.push({
        name: "surveybuilder",
        params: {
          previousRoute: this.$route.name,
          preloadedClientId: this.clientId,
          previousRouteProps: {
            preload: "surveys"
          }
        }
      });
    },
    loadMetricUI(programId = null) {
      // console.log("program");
      // console.log(program);
      if (programId) {
        this.$router.push({
          name: "progress",
          params: {
            programId: programId
          }
        });
      } else {
        this.$router.push({ name: "progress" });
      }
    },
    loadMetricCreator() {
      this.metricCreatorWidget = true;
    },
    loadMetricManagement() {
      this.metricManagementWidget = true;
    },
    loadProgramBuilder(program = null, editing, step) {
      if (program) {
        // Called from inside table, we pass in program and if we're editing
        this.$router.push({
          name: "programbuilder",
          params: {
            preloadedProgram: program,
            editingProgram: editing,
            activeProgram: program.status == "Active",
            preloadedStep: editing && step ? step : null
          }
        });
      } else {
        this.$router.push({ name: "programbuilder" });
      }
    },
    loadMetricCSV() {
      this.dialogUploadMetrics = true;
    },
    handleViewChange(index) {
      this.viewFilter = index;
      // this.search = null;
      // this.table.search = null;
      // this.table.debounceSearch = null;
      if (this.table.options) this.table.options.page = 1;
    },
    loadReporting() {
      this.$router.push({ name: "reporting" });
    },
    refreshClientId() {
      console.log("Refreshing client");
      this.getProgramsV2();
    },
    // For v-progress-linear needs a buffer value of 100 to take up full width
    // Ex: (inProgressParticipants / totalParticipants) * 100
    calculatePercentage(num1, num2) {
      return (num1 / num2) * 100;
    },
    messageProgramAudience(program, filter) {
      this.$router.push({
        name: "communicationwizard",
        query: {
          previousRoute: this.$route.name,
          programId: program?.programId || program,
          programFilter: filter,
          context: "program"
        },
        params: {
          programId: program?.programId || program,
          programFilter: filter
        }
      });
    },
    // Deprecated so we use the computed property
    // searchPrograms() {
    //   // const preparedQuery = fz.prepareQuery(this.search);
    //   // const scores = {};
    //   const programs =
    //     this.viewFilter == 0
    //       ? this.programs.filter(x => x.status !== "Deleted" && x.clientId == 0)
    //       : this.programs.filter(
    //           x => x.status != "Deleted" && x.clientId == this.clientId
    //         );
    //   // const programArray = programs
    //   //   .map(item => {
    //   //     let scorableFields = [
    //   //       item.programId,
    //   //       item.name,
    //   //       item.displayName,
    //   //       item.description,
    //   //       item.Budget?.budgetDisplayName
    //   //     ];
    //   //     scorableFields = scorableFields.map(toScore =>
    //   //       fz.score(toScore, this.search, { preparedQuery })
    //   //     );

    //   //     scores[item.id] = Math.max(...scorableFields);

    //   //     return item;
    //   //   })
    //   //   .filter(item => scores[item.id] > 1)
    //   //   .sort((a, b) => scores[b.id] - scores[a.id]);

    //   return !this.search
    //     ? programs
    //     : programs.filter(x =>
    //         x.displayName.toLowerCase().includes(this.search.toLowerCase())
    //       );
    // },
    disableDeleteButton(item) {
      if (
        // item.status == "Active" ||
        (item.status == "Archived" && this.userProfile.clientId != 1) ||
        !this.permissions.includes("programs:delete:program") ||
        (item.clientId != this.clientId && this.userProfile.clientId != 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    deleteProgramConfirmation(program) {
      if (this.disableDeleteButton(program)) {
        return;
      } else {
        this.dialogProgramDelete = true;
        this.programIdToDelete = program.programId;
        this.programNameToDelete = program.displayName;
        this.programDeletionConfirmation = null;
        this.programDeletionError = false;
      }
    },
    loadPaymentAction(message) {
      if (!message.action) return;
      switch (message.action) {
        case "one-time-payment":
          this.$router.push({
            name: "paymentwizard",
            params: {
              previousRoute: "programadmin"
            }
          });
          break;
        case "budget-wizard":
          console.log("Loading budget wizard", message.label);
          this.$router.push({
            name: "budgetwizard",
            params: {
              previousRoute: "programadmin",
              previousRouteProps: {
                preload: "rewards"
              },
              paymentTileName: message.label
            }
          });
          break;
        case "recurring-payment":
          this.$router.push({
            name: "paymentadmin",
            params: {
              preload: "payment",
              previousRoute: "programadmin",
              previousRouteProps: {
                preload: "rewards"
              }
            }
          });
          break;
        case "annual-reward-birthday":
          console.log("loading birthday reward");
          this.loadPaymentConfigWizard("BIRTHDAY");
          break;
        case "annual-reward-service-anniversary":
          console.log("loading service anniversary");
          this.loadPaymentConfigWizard("SERVICE_ANNIVERSARY");
          break;
      }
    },
    loadPaymentConfigWizard(type = undefined) {
      this.$router.push({
        name: "annualrewardwizard",
        params: {
          previousRoute: "programadmin",
          previousRouteProps: { preload: "rewards" },
          type
        }
      });
    },
    showLearningAdminPopup(route) {
      if (route == "learningadmin") {
        this.viewFilter = 3;
        this.dialog.learningPage = true;
        this.dialog.adminPages = true;
        this.$router.replace({ name: "programadmin" });
      } else if (route == "surveyadmin") {
        this.viewFilter = 4;
        this.dialog.learningPage = false;
        this.dialog.adminPages = true;
        this.$router.replace({ name: "programadmin" });
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "clients", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    // Randomized array of 3 messages
    // We currently only have 3 to display, so I'll keep the randomization logic
    visibleMessages() {
      const messages = [];
      for (let i = 0; i < this.messages.length && messages.length < 3; i++) {
        let message = this.messages[i];
        if (this.permissions.includes(message.permission))
          messages.push(message);
      }
      return messages;
      // return this.messages;
    },
    visiblePrograms() {
      let programs = this.table.programs || this.programs || [];
      if (this.viewFilter !== null) {
        // Program library
        if (this.viewFilter == 0) {
          programs = programs.filter(
            x => x.status !== "Deleted" && x.clientId == 0
          );
        } else {
          // if (this.clientId === 1) {
          //   programs = programs.filter(x => x.status != "Deleted");
          // } else {
          programs = programs.filter(
            x => x.status != "Deleted" && x.clientId == this.clientId
          );
          // }
        }
      }

      if (this.table.loading) return [];
      return programs;
      // return !this.search
      //   ? programs
      //   : programs.filter(x =>
      //       x.displayName.toLowerCase().includes(this.search.toLowerCase())
      //     );
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedHeaders() {
      if (this.viewFilter == 1) {
        return [
          {
            text: "Id",
            value: "programId",
            width: "10%",
            align:
              this.userProfile && this.userProfile.clientId == 1
                ? "start"
                : " d-none"
          },
          {
            text: "Program Name",
            value: "displayName",
            width: "50%"
          },
          {
            text: "Status",
            value: "status",
            width: "40%"
          },
          { text: "Details", value: "data-table-expand", width: "10%" }
        ];
      } else {
        return [
          {
            text: "Program Name",
            value: "displayName",
            width: "50%"
          },
          {
            text: "",
            value: "actionItems",
            width: "50%"
          }
        ];
      }
    }
  },
  watch: {
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null && this.viewFilter <= 1) {
        this.getProgramsV2();
      }
    },
    "$route.name": function(newVal) {
      // If client id changes, we get budgets!
      console.log("ROUTE CHANGE ", newVal);
      this.showLearningAdminPopup(newVal);
    },
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getProgramsV2(reset, "watcher");
      },
      deep: true
    },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      if (this.viewFilter <= 1) this.getProgramsV2(true, "debounce search");
    }, 500)
  }
};
</script>

<style scoped>
.brand-background {
  background-color: var(--v-brand-base);
}

.border-radius {
  border-radius: 5px;
}

/* Hides gray background when clicking v-card */
.v-card--link:before {
  background: none;
}

.card-header {
  /* max-height: 60px; */
  height: fit-content;
}

.icon {
  min-width: 52px;
  max-width: 52px;
  width: 52px;
  min-height: 52px;
  max-height: 52px;
  height: 52px;
}

.page-title {
  font-size: 1.7em;
}
@media (max-width: 960px) {
  .icon {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  }

  .page-title {
    font-size: 1.2em;
  }
}
/* Changes the default padding of all cols that have a card in them in the payment manager div */
#program-manager .card-col.col {
  padding-top: 8px;
  padding-bottom: 8px;
}

#program-manager .v-card {
  border-radius: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

/* Removes box shadow from program table expansion */
#program-table >>> .v-data-table__expanded__content {
  box-shadow: none;
}

/* Decreases padding in the table's expanded content */
#program-table-inner >>> .col {
  padding-top: 4px;
  padding-bottom: 4px;
}

/* Adds padding to the right of inner table icons */
#program-table-inner >>> .v-icon {
  padding-right: 6px;
}

.view-button {
  line-height: 1.5;
  border-radius: 50px;
  height: 40px;
  margin-bottom: 16px;
  margin-top: 10px;
  padding: 0 0 0 16px !important;
}

.highlighted-button {
  background: #eeeeee !important;
}

.view-button >>> .v-btn__content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.action-button {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
  padding-left: 20px !important;
}

.program-action-button {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
}

/* .pointer-rows >>> tr {
  cursor: pointer;
} */

.bold-font {
  font-family: "Roboto", sans-serif !important;
}

.card-header >>> .rec-header {
  font-weight: normal !important;
}
</style>
