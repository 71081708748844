<template>
  <v-card flat>
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title v-if="!editingBudget"
        >Create a New Budget</v-toolbar-title
      >
      <v-toolbar-title v-else>Update a Budget</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!displayBudgetUserEditor">
        <v-btn dark text @click="closeWindow">
          <span>Cancel</span>
        </v-btn>

        <v-btn
          v-if="!editingBudget"
          dark
          text
          :disabled="!budgetForm.valid"
          @click="createBudget"
        >
          Create
        </v-btn>
        <v-btn
          v-else
          dark
          text
          :disabled="!budgetForm.valid"
          @click="updateBudget"
        >
          Update
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider />
    <v-card-text v-if="!displayBudgetUserEditor">
      <v-form ref="budgetForm" v-model="budgetForm.valid">
        <v-autocomplete
          class="pa-1 full-width"
          :items="clients"
          item-text="formattedName"
          item-value="clientId"
          label="Client*"
          v-model="budget.clientId"
          :disabled="editingBudget"
          v-if="userProfile.clientId == 1 && !clientId"
          :rules="[v => !!v || 'Client is required']"
          @change="refreshClientId"
        ></v-autocomplete>
        <v-tooltip top :disabled="!editingBudget" v-if="!preloadAwardId">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-select
                class="pa-1"
                :items="visibleAwards"
                item-text="awardDisplayName"
                item-value="awardId"
                label="Award Type*"
                v-model="budget.awardId"
                :readonly="editingBudget"
                :rules="[
                  v => !!v || 'Award is required',
                  v => (v && v !== -1) || 'Award is required'
                ]"
                @change="budget.parentBudgetId = null"
                attach
                :menu-props="{ offsetY: true }"
              ></v-select>
            </div>
          </template>
          <span>You can't change the award type for a funded budget.</span>
        </v-tooltip>

        <v-tooltip top :disabled="!editingBudget || !budget.masterBudget">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-text-field
                class="pa-1"
                v-model="budget.budgetDisplayName"
                :readonly="budget.masterBudget"
                :rules="[
                  v => !!v || 'Budget name is required',
                  v =>
                    !!(v && v.length < 256) || 'Budget name should be shorter'
                ]"
                label="Budget name*"
              ></v-text-field>
            </div>
          </template>
          <span>You can't edit the name of the top level budget.</span>
        </v-tooltip>
        <v-select
          class="pa-1"
          v-if="!budget.masterBudget"
          :items="visibleParentBudgets"
          item-text="budgetDisplayName"
          item-value="budgetId"
          :disabled="!budget.awardId"
          :label="budget.awardId == 1 ? 'Parent Budget*' : 'Parent Budget'"
          v-model="budget.parentBudgetId"
          no-data-text="No budgets available"
          :rules="parentBudgetRules"
          :loading="loadingBudgetTable"
          attach
          :menu-props="{ offsetY: true }"
        ></v-select>
        <!-- <p
          class="red--text text-left"
          v-if="displayEndDateError && panels == null"
        >
          {{ displayEndDateError }}
        </p> -->

        <v-expansion-panels v-model="panels" flat class="text-left">
          <v-expansion-panel :key="0" v-if="budget.awardId == 1">
            <v-expansion-panel-header class="font-weight-bold"
              >Alerts</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-text-field
                v-if="!budgetForm.alertCheckBox"
                v-model="budget.alertThreshold"
                label="Minimum balance for alerts"
                type="number"
              ></v-text-field>
              <v-checkbox
                class="checkBoxes mt-0 pt-0"
                v-model="budgetForm.alertCheckBox"
                label="No alerts"
              >
              </v-checkbox>

              <!-- <v-menu
                v-model="budgetForm.menuEndDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="v-flex flex-row">
                    <v-text-field
                      v-if="!budgetForm.endDateCheckBox"
                      v-model="budget.endDate"
                      label="Budget Expiration Date"
                      prepend-icon="mdi-calendar"
                      :disabled="!budget.startDate"
                      :rules="budgetEndDateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                    <v-checkbox
                      class="checkBoxes mt-0 pt-0"
                      v-model="budgetForm.endDateCheckBox"
                      @change="checkBoxUpdated"
                      label="No expiration"
                    >
                    </v-checkbox>
                  </div>
                </template>
                <v-date-picker
                  v-model="budget.endDate"
                  @input="budgetForm.menuEndDate = false"
                ></v-date-picker>
              </v-menu> -->
              <!-- <p class="red--text" v-if="displayEndDateError">
                {{ displayEndDateError }}
              </p> -->
              <p class="text-caption">
                If a budget falls below this balance, you will be alerted via
                email.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :key="1">
            <v-expansion-panel-header class="font-weight-bold"
              >Permissions ({{
                computedBudgetUsers.length
              }})</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-btn
                color="brandCyan"
                text
                class="mt-4"
                @click="loadBudgetUserPage"
                ><v-icon>mdi-account-multiple</v-icon>Add budget managers</v-btn
              >
              <v-text-field
                placeholder="Search"
                prepend-icon="mdi-magnify"
                clearable
                single-line
                dense
                hide-details
                v-model="budgetUserTable.search"
                class="mb-2"
              ></v-text-field>
              <v-data-table
                :headers="permissionColumns"
                :items="visibleBudgetUsers"
                item-key="budgetUserId"
                :footer-props="budgetUserTable.footerProps"
                :search="budgetUserTable.search"
                class="elevation-0 budget-table mt-2"
                loading-text="Loading... Please wait"
                :loading="budgetUserTable.loading"
                no-data-text="No people found"
              >
                <!-- <template v-slot:[`item.displayName`]="{ item }">
                  <span class="selectable-blue-text">{{
                    item.displayName
                  }}</span>
                </template> -->
                <template v-slot:[`item.displayName`]="{ item }">
                  <span class="word-break">{{ item.displayName }}</span>
                </template>
                <template v-slot:[`item.owner`]="{ item }">
                  <v-checkbox v-model="item.owner"></v-checkbox>
                </template>
                <template v-slot:[`item.admin`]="{ item }">
                  <v-checkbox v-model="item.admin"></v-checkbox>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn icon color="red" @click="removeBudgetUser(item.userId)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- <div class="d-flex">
          <h3 class="text-left">Start and End</h3>
          <v-icon>mdi-arrow-down</v-icon>
        </div> -->
      </v-form>
    </v-card-text>
    <div v-else class="pt-4 px-4 d-flex flex-column">
      <v-btn
        outlined
        width="120"
        class="ml-3"
        :color="'primary'"
        @click="displayBudgetUserEditor = false"
        ><v-icon class="mr-1">mdi-chevron-left</v-icon>Back</v-btn
      >

      <v-text-field
        placeholder="Search"
        prepend-icon="mdi-magnify"
        clearable
        single-line
        dense
        hide-details
        v-model="userTable.debounceSearch"
        class="my-3"
      ></v-text-field>
      <p class="darkGrey--text text-sm text-left">
        Note: Budgets don't support external users at this time
      </p>
      <UserTable
        ref="user-table"
        :displayEmail="true"
        :selected="budgetUserTable.users"
        :search="userTable.search"
        :external="false"
        :displayInfo="false"
        @select-item="addBudgetUser($event)"
      />
      <!-- <v-data-table
        :headers="userTable.columns"
        :items="computedUsers"
        item-key="userId"
        class="my-2 budget-table"
        :loading="userTable.loading"
        :search="userTable.search"
        :options.sync="userTable.options"
        :server-items-length="userTable.total"
        :items-per-page="userTable.itemsPerPage"
        :footer-props="userTable.footerProps"
        no-data-text="No people found"
      >
        <template v-slot:[`item.add`]="{ item }">
          <v-btn
            icon
            color="primary"
            @click="addBudgetUser(item)"
            v-if="!budgetUserTable.users.find(x => x.userId == item.userId)"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="removeBudgetUser(item.userId)" v-else>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.businessEmail`]="{ item }">
          {{ item.businessEmail || item.businessPhone }}
        </template>
        <template v-slot:[`item.displayName`]="{ item }">
          <span class="word-break">{{ item.displayName }}</span>
        </template>
      </v-data-table> -->
    </div>
    <v-card-actions v-if="!displayBudgetUserEditor">
      <v-tooltip
        top
        :disabled="disableDelete === null"
        v-if="editingBudget && permissions.includes('budgets:delete:budget')"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              v-if="
                editingBudget && permissions.includes('budgets:delete:budget')
              "
              color="red"
              text
              @click="deleteBudget"
              :disabled="disableDelete !== null"
            >
              Delete
            </v-btn>
          </div>
        </template>
        <span>{{ disableDelete }}</span>
      </v-tooltip>
      <v-btn color="blue darken-1" text @click="closeWindow">
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="createBudget"
        :disabled="!budgetForm.valid"
        v-if="!editingBudget"
      >
        Create
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="updateBudget"
        :disabled="!budgetForm.valid || budget.clientId === 0"
        v-else
      >
        Update
      </v-btn>
    </v-card-actions>

    <v-navigation-drawer
      v-model="showAwardCreatorDialog"
      temporary
      fixed
      right
      width="500"
    >
      <AwardCreatorWidget
        v-if="showAwardCreatorDialog"
        @close="closeAwardCreator"
        @getPartners="getAwardFulfillmentPartners"
        @refreshAwards="getAwards"
        :budgets="parentBudgets"
        :awardFulfillmentPartners="awardFulfillmentPartners"
        :clientId="clientId"
        :editingAward="awardForm.editing"
        :editAwardObject="award"
        source="BUDGETS"
      />
    </v-navigation-drawer>
    <v-dialog v-model="unsavedChangesDialog" width="500" class="ignore-click">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <!-- Error message to block a user from deleting themselves -->

          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to exit? You will lose any unsaved changes.
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="primary"
            @click="unsavedChangesDialog = false"
            outlined
            width="140"
            >Go back
          </v-btn>
          <v-spacer />
          <v-btn color="primary" depressed @click="closeWindow" width="140"
            >Exit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
    <!-- Delete error dialog for programs -->
    <v-dialog v-model="dialogDeleteErrorMessage" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            We found programs attached to this budget
          </v-card-title>
        </div>
        <p
          v-for="error in dialogDeleteErrorArray"
          :key="`${error.programId}`"
          class="mb-2"
        >
          Program '<span class="font-weight-bold">{{ error.displayName }}</span
          >'
        </p>

        <v-card-actions class="mx-auto">
          <v-btn
            color="primary"
            depressed
            @click="dialogDeleteErrorMessage = false"
            width="140"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import BudgetService from "@/services/BudgetService";
import UserService from "@/services/UserService";

import LoadingDialog from "@/components/loadingDialog.vue";
import AwardCreatorWidget from "@/components/AwardCreatorWidget";
import UserTable from "@/components/data-table/Users";

import moment from "moment";
import { debounce } from "@/shared_data/functions";
import { mapState } from "vuex";

export default {
  name: "BudgetCreatorWidget",
  components: {
    LoadingDialog,
    AwardCreatorWidget,
    UserTable
  },
  props: {
    clientId: Number,

    editingBudget: Boolean,
    editBudgetObject: Object,
    preloadAwardId: Number,
    preloadParentBudget: Object,
    preloadPanel: Number,
    budgets: Array,
    users: Array,
    awards: Array,
    source: String
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      dialogDeleteErrorMessage: false,
      dialogDeleteErrorArray: [],
      awardsArray: [],
      userArray: [],
      parentBudgets: [],
      budgetForm: {
        valid: false,
        menuStartDate: false,
        menuEndDate: false,
        alertCheckBox: true
      },
      budget: {
        clientId: null,
        budgetId: null,
        parentBudgetId: null,
        awardId: 1,
        budgetName: null,
        budgetDisplayName: null,
        sourceBudgetId: null,
        startDate: null,
        endDate: null,
        purchaseOrderNum: null,
        BudgetUsers: []
      },

      budgetConfirmationDialog: false,
      showAwardCreatorDialog: false,
      awardForm: {
        menuStartDate: false,
        menuEndDate: false,
        endDateDisabled: false,
        endDateCheckBox: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      award: {
        clientId: null,
        budgetId: null,
        awardId: null,
        budgetName: null,
        budgetDisplayName: null,

        sourceBudgetId: null,
        startDate: null,
        endDate: null,
        purchaseOrderNum: null
      },
      awardFulfillmentPartners: [],
      permissionColumns: [
        { text: "Name", value: "displayName", width: "50%" },
        // {
        //   text: "Owner",
        //   value: "owner",
        //   width: "20%",
        //   align: "start"
        //   // sortable: false,
        // },
        // {
        //   text: "Admin",
        //   value: "admin",
        //   width: "20%",
        //   align: "start"
        //   // sortable: false,
        // },
        {
          text: "",
          value: "delete",
          width: "10%",
          sortable: false,
          cellClass: "mx-0 px-0"
        }
      ],
      panels: 1,
      budgetUserTable: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        users: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      },
      userTable: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        users: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        columns: [
          { text: "", value: "add" },
          { text: "Name", value: "displayName", width: "40%" },
          {
            text: "Email / Phone",
            value: "businessEmail",
            width: "50%"
          }
        ]
      },
      displayBudgetUserEditor: false,
      unsavedChangesDialog: false
    };
  },
  created() {
    // The expansion panels only allow a single expanded panel, so we want an integer, not an array
    // This allows us to preload that and show something pre-expanded
    if (typeof this.preloadPanel == "number") {
      this.panels = this.preloadPanel;
    }

    if (this.editingBudget) {
      this.budget = JSON.parse(JSON.stringify(this.editBudgetObject));
      if (this.budget.alertThreshold != null) {
        this.budgetForm.alertCheckBox = false;
      }

      this.getBudgetUsersV2(0, true);
    } else {
      // We preload the awardId if we're working from the learning builder
      if (this.preloadAwardId) {
        this.budget.awardId = this.preloadAwardId;
      }

      // Used to shortcut via the "create a child budget" button for a parent
      if (this.preloadParentBudget) {
        this.budget.parentBudgetId = this.preloadParentBudget.budgetId;
        this.budget.awardId = this.preloadParentBudget.awardId;
      }

      if (this.clientId) {
        console.log("Setting clientId to predefined");
        this.budget.clientId = this.clientId;
      } else {
        console.log("Setting clientId to user clientId");
        this.budget.clientId = this.userProfile.clientId;
      }

      // Preload the start date as today
      // this.budget.startDate = moment().format("YYYY-MM-DD");

      // Add this user as a budget owner if we're in the same client
      if (this.userProfile.clientId == this.budget.clientId)
        this.budgetUserTable.users.push({
          budgetUserId: new Date().getTime(),
          userId: this.userProfile.userId,
          clientId: this.userProfile.clientId,
          displayName:
            this.userProfile.firstName + " " + this.userProfile.lastName,
          owner: true,
          admin: false
        });
    }
    // this.getUsersV2();
    this.getParentBudgets();
    // If we're editing a budget, we can't change the award, so we don't need to go fetch all awards
    if (this.editingBudget && this.budget.Award)
      this.awardsArray = [this.budget.Award];
    else this.getAwards();
    if (!this.editingBudget) this.getAwardFulfillmentPartners();
  },
  methods: {
    async getAwards(awardId) {
      if (!this.awards) {
        // if an awardId is passed then the awardBuilder called this with a newly created award passed in
        // we want to set it to the current selected award
        var awardsResponse = await BudgetService.getAwards();

        console.log("Got awards", awardsResponse);
        awardsResponse = awardsResponse.filter(x => x.status != "Deleted");
        this.awardsArray = awardsResponse;
      } else {
        this.awardsArray = this.awards;
      }
      if (awardId) {
        // console.log('setting award id to ', awardId);
        this.budget.awardId = awardId;

        // if (this.preloadParentBudget)
      }
    },
    // async getUsers() {
    //   if (!this.users) {
    //     this.loadingUsers = true;
    //     var users = await UserService.getUsers(
    //       `?lite=true&clientId=${this.budget.clientId}`
    //     );
    //     // .then((response) => {
    //     console.log(users);
    //     users = users.filter(x => x.status != "Deleted");
    //     // .map((x) => {
    //     //   x.owner = null;
    //     //   x.admin = null;
    //     //   x.displayName = x.displayName
    //     //     ? x.displayName
    //     //     : x.firstName + " " + x.lastName;
    //     //   return x;
    //     // });
    //     this.userArray = users;
    //     this.loadingUsers = false;
    //     // })
    //     // .catch((error) => {
    //     //   console.log("Error!", error);
    //     //   this.loadingUsers = false;
    //     // });'
    //   } else {
    //     this.userArray = JSON.parse(JSON.stringify(this.users));
    //   }
    //   this.userArray = this.userArray.map(x => {
    //     x.owner = null;
    //     x.admin = null;
    //     x.displayName = x.displayName
    //       ? x.displayName
    //       : x.firstName + " " + x.lastName;

    //     var permission = this.budget.BudgetUsers.find(
    //       y => y.userId == x.userId
    //     );
    //     if (permission) {
    //       x.owner = permission.owner;
    //       x.admin = permission.admin;
    //     }
    //     return x;
    //   });

    //   // If we're creating a new budget, add this user as owner
    //   // Only do if the user is creating a budget for their client
    //   if (!this.editingBudget) {
    //     var owner = this.userArray.findIndex(
    //       x => x.userId == this.userProfile.userId
    //     );
    //     console.log("Owner index ", owner);
    //     if (owner !== -1) this.userArray[owner].owner = true;
    //   }
    // },
    async getBudgetUsersV2(page, reset = false) {
      if (reset) page = 0;
      if (page == 0) this.budgetUserTable.loading = true;

      let limit = 50; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
      let offset = page * limit;
      let filter = `status != 'Deleted' && budgetId=${this.budget.budgetId}`;

      let response = await BudgetService.getBudgetUsersV2({
        filter,
        limit,
        offset,
        expand: "User"
      });
      console.log("Budget user response ", response);
      let rows = response.result.rows;

      this.budgetUserTable.total = response.result.count;

      //Prune the returned users for the data we need for the table
      rows = rows.map(function(user) {
        user.displayName = user.User.firstName + " " + user.User.lastName;
        return user;
      });

      if (page == 0) {
        this.budgetUserTable.users = rows;
      } else {
        this.budgetUserTable.users = this.budgetUserTable.users.concat(rows);
      }

      if (
        Number(this.budgetUserTable.users.length) <
        Number(response.result.count)
      ) {
        console.log("GETTING MORE USERS");
        page = page + 1;
        this.getBudgetUsersV2(page, false);
      } else {
        this.budgetUserTable.loading = false;
      }
    },
    getParentBudgets() {
      this.loadingBudgetTable = true;
      BudgetService.getParentBudgets({ lite: 1 })
        .then(response => {
          console.log("Got parent budgets");
          response = response.filter(x => x.status != "Deleted");
          response.forEach(function(budget) {
            budget.id = budget.budgetId;
            budget.displayName =
              budget.budgetDisplayName !== null
                ? budget.budgetDisplayName
                : budget.budgetName;
            budget.name =
              budget.budgetDisplayName !== null
                ? budget.budgetDisplayName
                : budget.budgetName;
            // if (budget.startDate) {
            //   budget.startDate = moment(budget.startDate, "YYYY-MM-DD hh:mm:ss")
            //     .local()
            //     .utc()
            //     .format("MMM DD, YYYY");
            // }
            // if (budget.endDate) {
            //   budget.endDate = moment(budget.endDate, "YYYY-MM-DD hh:mm:ss")
            //     .local()
            //     .utc()
            //     .format("MMM DD, YYYY");
            // }

            budget.formattedBudgetBalance = budget.budgetBalance;
          });

          response = response.sort((a, b) => a.budgetId - b.budgetId);
          this.parentBudgets = response;

          this.loadingBudgetTable = false;

          // Then we update the end date if the parent's end date is not null
          // if (!this.editingBudget && this.preloadParentBudget) {
          //   var parent = response.find(
          //     x => x.budgetId == this.preloadParentBudget.budgetId
          //   );
          //   console.log(this.budget);
          //   console.log(parent);
          //   if (parent) {
          //     if (parent.endDate !== null) {
          //       this.budgetForm.endDateCheckBox = false;
          //       this.budget.endDate = moment(parent.endDate).format(
          //         "YYYY-MM-DD"
          //       );
          //     } else {
          //       this.budget.endDate = null;
          //     }

          //     if (
          //       this.budget.endDate &&
          //       this.budget.startDate &&
          //       moment(this.budget.endDate).isBefore(
          //         moment(this.budget.startDate)
          //       )
          //     ) {
          //       this.budget.startDate = moment(this.budget.endDate)
          //         .subtract(1, "M")
          //         .format("YYYY-MM-DD");
          //     }
          //   }
          // }
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    createBudget() {
      console.log("Creating budget");

      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating a new budget";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      var obj = {
        awardId: this.budget.awardId,
        budgetName: this.budget.budgetDisplayName,
        budgetDisplayName: this.budget.budgetDisplayName,
        parentBudgetId: this.budget.parentBudgetId,

        sourceBudgetId: this.budget.sourceBudgetId,
        startDate: moment()
          // .local()
          .utc(),
        // .format("YYYY-MM-DD"),
        // endDate: this.budget.endDate
        //   ? moment(this.budget.endDate)
        //       .local()
        //       .utc()
        //   : null,
        purchaseOrderNum: this.budget.purchaseOrderNum,
        alertThreshold:
          this.budgetForm.alertCheckBox || this.budget.awardId != 1
            ? null
            : this.budget.alertThreshold,
        BudgetUsers: this.computedBudgetUsers
      };
      if (this.budget.clientId !== null) {
        obj.clientId = this.budget.clientId;
      } else if (this.clientId) {
        obj.clientId = this.clientId;
      } else {
        obj.clientId = this.userProfile.clientId;
      }

      // Now we add a source tag for the platform activity that'll get created
      if (this.source) obj.source = this.source;

      console.log("Budget being created, ", obj);
      BudgetService.createBudget(obj)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          console.log("Budget created");
          this.getBudgets(response.budgetId);
          this.closeWindow();
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    updateBudget() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating the budget";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      var returnObj = {
        awardId: this.budget.awardId,
        budgetName: this.budget.budgetDisplayName,
        budgetDisplayName: this.budget.budgetDisplayName,

        sourceBudgetId: this.budget.sourceBudgetId,
        parentBudgetId: this.budget.parentBudgetId,
        // startDate: moment(this.budget.startDate)
        //   .local()
        //   .utc(),
        // endDate: null,
        // this.budget.endDate
        //   ? moment(this.budget.endDate)
        //       .local()
        //       .utc()
        //   : null,
        purchaseOrderNum: this.budget.purchaseOrderNum,
        alertThreshold: this.budgetForm.alertCheckBox
          ? null
          : this.budget.alertThreshold,
        BudgetUsers: this.computedBudgetUsers
      };

      if (this.budget.clientId !== null) {
        returnObj.clientId = this.budget.clientId;
      } else if (this.clientId) {
        returnObj.clientId = this.clientId;
      } else {
        returnObj.clientId = this.userProfile.clientId;
      }

      console.log("Budget updated, ", returnObj);
      BudgetService.updateBudget(this.budget.budgetId, returnObj)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          if (response) {
            console.log("Budget updated");
            this.getBudgets(null);
            this.closeWindow();
          } else {
            console.log("Error updating budget");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    deleteBudget() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting the budget";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      BudgetService.deleteBudget(this.budget.budgetId)
        .then(response => {
          console.log(response);
          if (response) {
            console.log("Budget deleted");
            this.getBudgets(null);
            this.closeWindow();
          } else {
            console.log("Error updating budget");
            throw response;
          }
        })
        .catch(err => {
          console.log("Failed to delete budget", err);
          this.showLoadingDialog = false;

          if (err.data && err.data.length) {
            this.dialogDeleteErrorMessage = true;
            this.dialogDeleteErrorArray = err.data;
          }
        });
    },
    async closeWindow() {
      if (this.$refs.budgetForm) this.$refs.budgetForm.reset();
      this.$emit("close");
    },
    getBudgets(preloadBudgetId) {
      this.$emit("get-budgets", preloadBudgetId);
    },
    openAwardCreator() {
      // get budgets then open award dialog
      // console.log('getting budgets');
      // console.log(this.getBudgets());

      this.showAwardCreatorDialog = true;
    },
    closeAwardCreator() {
      this.showAwardCreatorDialog = false;
      // setting the awardId to null if it was -1 (we were creating a new award)
      // if an award was made it will emit that Id and we can set it to the current awardId
      // closeAwardCreator fires first, then getAwards
      if (this.budget.awardId === -1) {
        // console.log('setting awardId to null');
        this.budget.awardId = null;
      }
    },
    getAwardFulfillmentPartners() {
      BudgetService.getAwardFulfillmentPartners()
        .then(response => {
          console.log("Award Fulfillment Partners: ", response);

          this.awardFulfillmentPartners = response;
        })
        .catch(error => {
          console.log("Error!", error);
        });
    },
    refreshClientId() {
      // this.getUsersV2(true);
    },
    loadBudgetUserPage() {
      this.displayBudgetUserEditor = true;
      // this.getUsersV2(true);
    },
    addBudgetUser(user) {
      console.log("Adding user: ", user);
      if (!this.budgetUserTable.users.find(x => x.userId == user.userId)) {
        // user.displayName = user.firstName + " " + user.lastName;
        let obj = {
          budgetUserId: new Date().getTime(),
          userId: user.userId,
          owner: false,
          admin: true,
          local: true,
          displayName: user.firstName + " " + user.lastName,
          User: user
        };
        this.budgetUserTable.users.push(obj);
        this.budgetUserTable.total++;
        // var index = this.editingGroup.otherUsers.findIndex(
        //   (x) => x.userId === user.userId
        // );
        // this.editingGroup.otherUsers.splice(index, 1);
      } else this.removeBudgetUser(user.userId || user);

      this.displayBudgetUserEditor = false;
    },
    removeBudgetUser(userId) {
      console.log("Removing user: ", userId);
      // var idx = this.budgetUserTable.users.findIndex((x) => x.userId == userId);
      let newArr = this.budgetUserTable.users.filter(x => x.userId != userId);
      this.$set(this.budgetUserTable, "users", newArr);
      // this.budgetUserTable.total = newArr.length;
      // console.log(idx);
      // if (idx !== -1) {
      //   this.budgetUserTable.users.splice(idx, 1);
      //   this.budgetUserTable.total--;
      // }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"]),
    visibleAwards() {
      let newAward = {
        awardName: "Create a New Award",
        awardDisplayName: "Create a New Award",
        awardId: -1
      };
      if (this.budget.clientId !== null) {
        let awardArr = this.awardsArray.filter(x =>
          [this.budget.clientId, 0].includes(x.clientId)
        );
        if (this.permissions.includes("awards:create:award"))
          awardArr.unshift(newAward);
        return awardArr;
      }
      if (this.permissions.includes("awards:create:award")) return [newAward];

      return [];
    },
    visibleParentBudgets() {
      if (this.budget.clientId !== null) {
        var acctBal = this.parentBudgets.find(
          x =>
            x.clientId == this.budget.clientId &&
            x.masterBudget &&
            x.awardId == this.budget.awardId
        );
        let budgets = this.parentBudgets.filter(
          x =>
            x.clientId == this.budget.clientId &&
            !x.masterBudget &&
            x.awardId == this.budget.awardId &&
            (!this.editBudgetObject ||
              !this.editBudgetObject.allChildren.includes(x.budgetId))
        );
        if (this.budget.budgetId)
          budgets = budgets.filter(x => x.budgetId !== this.budget.budgetId);
        if (acctBal) {
          budgets.unshift(acctBal);
        }

        budgets = budgets.filter(
          x =>
            x.status !== "Expired" || this.budget.parentBudgetId == x.budgetId
        );

        return budgets;
      }
      return [];
    },
    // budgetEndDateRules() {
    //   return [
    //     () =>
    //       !!(
    //         this.budget.startDate < this.budget.endDate ||
    //         this.budget.endDate === null
    //       ) || "Budget end date must be later than the start date"
    //   ];
    // },
    disableDelete() {
      // We disable if the budget is tied to a program. This will be done in the future

      // Moved to backend
      // if (this.editBudgetObject && this.editBudgetObject.Programs) {
      //   console.log("Checking programs that use this budget.");
      //   if (
      //     this.editBudgetObject.Programs.filter(
      //       x =>
      //         ((x.endDate && moment(x.endDate) > moment()) ||
      //           x.rollingProgramLength) &&
      //         x.status != "Archived"
      //     ).length > 0
      //   ) {
      //     return "A program is currently tied to this budget";
      //   }
      // }

      if (
        this.editBudgetObject &&
        this.editBudgetObject.allChildren &&
        this.editBudgetObject.allChildren.length > 0
      ) {
        return "This budget has child budgets attached to it";
      }

      if (
        this.editBudgetObject &&
        this.editBudgetObject.BudgetActivities &&
        this.editBudgetObject.BudgetActivities.filter(
          x => x.stripeStatus == "pending" && x.status == "Active"
        ).length > 0
      ) {
        return "This budget has pending incoming transfers";
      }

      return null;
      // }
    },
    visibleBudgetUsers() {
      return this.budgetUserTable.users;
    },
    computedUsers() {
      // Return chunk of users in array based on page
      var startingIndex = this.userTable.options.page - 1 || 0;
      // if (this.userFilterValue == "uninvited")
      //   return this.users.filter(
      //     (x) => !x.onboardingEmailSent //&& x.clientId == this.clientId
      //   );
      let itemsPerPage =
        (this.userTable.options && this.userTable.options.itemsPerPage) ||
        this.userTable.itemsPerPage;
      // if (this.userFilterValue)
      //   return this.table.users.filter(
      //     (x) => x.onboardingEmailSent == false && x.passwordEnabled
      //   );
      return this.userTable.users
        .slice()
        .slice(
          startingIndex * itemsPerPage,
          startingIndex * itemsPerPage + itemsPerPage
        )
        .filter(x => x !== undefined);
    },
    computedBudgetUsers: {
      cache: false,
      get: function() {
        if (this.budgetUserTable.loading) return this.budget.BudgetUsers;
        return this.budgetUserTable.users
          .filter(x => x !== undefined && (x.admin || x.owner))
          .map(x => {
            var obj = {
              userId: x.userId,
              clientId: x.clientId,
              admin: true,
              owner: true
            };
            return obj;
          });
      }
    },
    parentBudgetRules() {
      if (!this.budget.parentBudgetId && this.budget.awardId == 1) {
        return ["Parent budget is required"];
      }
      return [true];
    },
    budgetBalance() {
      if (this.budget.formattedBudgetBalance)
        return this.budget.formattedBudgetBalance;
      else if (this.budget.budgetBalance) return this.budget.budgetBalance;
      else return 0;
    }
    // displayEndDateError() {
    //   if (this.budget.parentBudgetId) {
    //     var parent = this.parentBudgets.find(
    //       x => x.budgetId == this.budget.parentBudgetId
    //     );
    //     if (parent && parent.endDate)
    //       if (
    //         this.budget.endDate === null ||
    //         moment(parent.endDate).isBefore(this.budget.endDate)
    //       ) {
    //         return (
    //           "The parent budget expires on " +
    //           moment(parent.endDate).format("MMM Do, YYYY-MM-DD") +
    //           " so your child budget must expire before the parent, or select a different parent budget."
    //         );
    //       }
    //   }
    //   return null;
    // }
  },
  watch: {
    "budget.awardId": {
      handler(value) {
        if (value === -1) {
          // this.showAwardCreatorDialog = true;
          this.openAwardCreator();
        }
      },
      deep: true
    },
    "userTable.debounceSearch": debounce(function(newVal) {
      this.userTable.search = newVal;
      // this.getUsersV2(true);
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.budget-confirmation-number {
  width: 30%;
}
.budget-confirmation-text {
  width: 70%;
}

.budget-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.budget-table >>> .v-data-table-header {
  background-color: white;
}
</style>
